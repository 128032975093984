import React from 'react';
import './../../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, NavBar, Icon, Toast, Modal } from 'antd-mobile';
import Api from './../../http/api';
import { Control } from 'react-keeper';


class MineCash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            money: '',
            pwd: '',
            bankInfo: ''
        }
    }
    componentDidMount() {
        this.getMinePayInfo();
    }
    getMinePayInfo = () => {
        Api.minePayInfo({}).then(res => {
            console.log('银行卡信息：', res)
            if (res.code === 200) {
                console.log(res.data.length)
                if (res.data.length > 0) {
                    this.setState({
                        bankInfo: res.data
                    })
                } else {
                    Modal.alert('提示', '立即添加银行卡？', [
                        { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                        { text: '去绑定', onPress: () => { Control.go('/mine_add_bank') } },
                    ]);

                }

            }
        })
    }


    handleChange = (name, value) => {
        console.log(name, value);
        this.setState({
            [name]: value
        })
    }
    cash = () => {
        const { money } = this.state;
        if (!money) {
            Toast.fail('请输入金额');
            return false;
        }
        Api.cash({ money: money, ip: localStorage.ip ? JSON.parse(localStorage.ip) : '' }).then(res => {
            console.log('提现申请：', res)
            if (res.code === 200) {
                Toast.success(res.msg);
                this.setState({
                    money: ''
                })
            }
        })
    }
    setNum = (num) => {
        console.log(num)
        if (num) {
            num = String(num);
            return num.slice(0, 3) + ' **** **** ' + num.slice(-4)
        } else {
            return ' '
        }

    }
    render() {
        const { bankInfo } = this.state;
        const bankList = bankInfo.length > 0 ? bankInfo.map(item => {
            return (
                <div className='bankBox' key={item.id}>
                    <div>
                        <span>
                            <img src={require('../../assets/imgs/icon/bank2.png').default} alt='' />
                            {item.information.bank_name}
                        </span>
                        <span>{item.information.name}</span>
                    </div>
                    <div>{this.setNum(item.information.card_number)}</div>
                </div>
            )
        }) : <div className='empty'>暂无银行卡</div>
        return (
            <div className='login-page change_pwd_page mine_question_page mine_cash_page' style={{ padding: 0, }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                    rightContent={<div className='ft14 opacity7' onClick={() => {
                        Control.go('/mine_cash_record');
                    }}>提现记录</div>}
                >提现</NavBar>
                <WingBlank size='lg' className='login-content'>
                    <div>
                        <div className='title'>银行卡信息</div>
                        {bankList}
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='title'>提现金额</div>
                    <InputItem
                        className='input-box'
                        value={this.state.money}
                        type="number"
                        placeholder="请输入提现金额"
                        name='money'
                        pattern="[0-9]*"
                        onChange={(v) => { this.handleChange('money', v) }}
                    >
                        提现金额
                    </InputItem>
                    <WhiteSpace size="lg" />
                    {/* <InputItem
                        className='input-box'
                        value={this.state.pwd}
                        type="pwd"
                        placeholder="请输入支付密码"
                        name='pwd'
                        onChange={''}
                    >
                        支付密码
                    </InputItem> */}
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' onClick={this.cash}>确认提现</Button>



                </WingBlank>



            </div >

        )
    }
}
export default MineCash;

