/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { WhiteSpace, NavBar, Icon, ListView } from 'antd-mobile';
import './../../assets/style/chat.scss';
import WxImageViewer from 'react-wx-images-viewer';
import Api from '../../http/api';
import { Control } from 'react-keeper';


let audio_now = null;
class ChatRecord extends Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            friendInfo: {},

            page: 1, //页码
            limit: 20, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据

            isOpen: false,
            imgs: [],

            audioNowPlayId: '',

            ch: ''
        }
    }
    onClose = () => {
        this.setState({
            isOpen: false
        })
    }

    openViewer(src) {
        this.setState({
            imgs: [src],
            isOpen: true
        })
    }

    //  初始化
    componentDidMount() {
        // console.log(this.props.location.state)
        this.setState({
            friendInfo: { id: this.props.params.id }
        }, () => {
            this.getList();
            setTimeout(() => {
                this.setState({ ch: 0 })
            }, 200);
        })

    }

    getList = () => {
        const { limit, friendInfo, page } = this.state;
        let params = {
            fid: friendInfo.id,
            size: limit,
            page: page
        }

        Api.msgRecordList(params).then(res => {
            console.log(res)
            if (res.code == 200) {
                let allbi = this.state.preBillData.concat(res.data);
                this.setState({
                    billData: res.data,
                    preBillData: allbi,
                    allBillData: allbi,
                    page: Number(this.state.page) + 1,
                    dataSource: this.state.dataSource.cloneWithRows(allbi),
                    isHasMore: res.data.length > 0 ? true : false,
                    isLoading: false
                })

            }
        })


    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };

    }
    playAudio = (id) => {
        const { audioNowPlayId } = this.state;
        console.log(id, audioNowPlayId)
        if (audio_now && audioNowPlayId && id == audioNowPlayId) {
            if (audio_now.paused) {
                audio_now.play();
                this.setState({
                    audioNowPlayId: id
                })
            } else {
                audio_now.pause();
                this.setState({
                    audioNowPlayId: ''
                })
            }
            return false
        }
        if (audio_now) {
            console.log('停止播放上一个')
            audio_now.pause();
            this.setState({
                audioNowPlayId: ''
            })
            audio_now = '';
        }
        audio_now = document.getElementById(id);
        if (audio_now !== null) {
            audio_now.load();
            audio_now.play();
            this.setState({
                audioNowPlayId: id
            })
        }
    }
    //语音播放结束
    onAudioPlayEnd = (id) => {
        const { audioNowPlayId } = this.state
        if (id == audioNowPlayId) {
            console.log('播放完毕', audioNowPlayId)
            this.setState({
                audioNowPlayId: ''
            })
        }

    }
    getLong = (id) => {
        let audio_now = document.getElementById(id);
        if (audio_now !== null) {
            let long = Math.ceil(audio_now.duration) * 3;
            if (long > 50) {
                long = 50
            }
            let num = {
                width: long + 'vw',

            }
            console.log(id, num)
            return num
        } else {
            return {
                width: '30vw',
            }
        }

    }

    row = (item, sectionID, rowID) => {
        const { friendInfo, isOpen } = this.state;
        let type;
        if (item.from.id == friendInfo.id) {
            type = 1;
        } else {
            type = 2;
        }
        return (
            <div className={type === 1 ? 'item' : 'item mine'} key={rowID} style={{ padding: '0 3%', }}>
                <div className='imgBox'>
                    <img src={item.from.head_img} alt='' />
                </div>
                <div className='chatBox'>
                    <div className='text'
                        style={{ display: item.message.type === 1 ? 'block' : 'none' }}
                        dangerouslySetInnerHTML={{
                            __html: item.message.content.replace(
                                /#[\u4E00-\u9FA5]{1,3};/gi,
                                this.emotion
                            )
                        }}>
                    </div>
                    <div className='text2' style={{ display: item.message.type === 2 ? 'block' : 'none' }}>
                        {item.message.type === 2 ? <img className='imgggg' src={item.message.content} alt='' onClick={this.openViewer.bind(this, item.message.content)} /> : ''}
                        {
                            isOpen ? <WxImageViewer onClose={this.onClose} index={0} urls={this.state.imgs} /> : ""
                        }
                    </div>
                    {item.message.type === 3 &&
                        <div className={(this.state.audioNowPlayId == (item.message_id) ? 'isPlay' : '') + '   text text3'} onClick={() => { this.playAudio(item.message_id) }}  >
                            <audio
                                src={item.message.content}
                                controls='controls'
                                id={item.message_id}
                                onEnded={() => { this.onAudioPlayEnd(item.message_id) }}
                                style={this.getLong(item.message_id)}
                            />
                        </div>
                    }
                </div>
                <div className='state'>{item.message.time}</div>
            </div>
        )


    }

    // 将匹配结果替换表情图片
    emotion = (res) => {
        // console.log(res);
        let word = res.replace(/\#|\;/gi, "");
        // console.log(res, word);
        const list = [
            "微笑",
            "撇嘴",
            "色",
            "发呆",
            "得意",
            "流泪",
            "害羞",
            "闭嘴",
            "睡",
            "大哭",
            "尴尬",
            "发怒",
            "调皮",
            "呲牙",
            "惊讶",
            "难过",
            "酷",
            "冷汗",
            "抓狂",
            "吐",
            "偷笑",
            "可爱",
            "白眼",
            "傲慢",
            "饥饿",
            "困",
            "惊恐",
            "流汗",
            "憨笑",
            "大兵",
            "奋斗",
            "咒骂",
            "疑问",
            "嘘",
            "晕",
            "折磨",
            "衰",
            "骷髅",
            "敲打",
            "再见",
            "擦汗",
            "抠鼻",
            "鼓掌",
            "糗大了",
            "坏笑",
            "左哼哼",
            "右哼哼",
            "哈欠",
            "鄙视",
            "委屈",
            "快哭了",
            "阴险",
            "亲亲",
            "吓",
            "可怜",
            "菜刀",
            "西瓜",
            "啤酒",
            "篮球",
            "乒乓",
            "咖啡",
            "饭",
            "猪头",
            "玫瑰",
            "凋谢",
            "示爱",
            "爱心",
            "心碎",
            "蛋糕",
            "闪电",
            "炸弹",
            "刀",
            "足球",
            "瓢虫",
            "便便",
            "月亮",
            "太阳",
            "礼物",
            "拥抱",
            "强",
            "弱",
            "握手",
            "胜利",
            "抱拳",
            "勾引",
            "拳头",
            "差劲",
            "爱你",
            "NO",
            "OK",
            "爱情",
            "飞吻",
            "跳跳",
            "发抖",
            "怄火",
            "转圈",
            "磕头",
            "回头",
            "跳绳",
            "挥手",
            "激动",
            "街舞",
            "献吻",
            "左太极",
            "右太极",
        ];
        let index = list.indexOf(word);
        return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" style="
      vertical-align: middle;
  ">`;
    }


    render() {
        const { isHasMore, isLoading, billData } = this.state;

        return (
            <div className='chatPage'>
                <div className='navBarBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >聊天记录</NavBar>
                </div>
                <WhiteSpace size="lg" />
                <div className='chatContent' style={{ marginTop: 50, paddingBottom: 20 }}
                    ref={el => this.chatListView = el}>
                    <ListView
                        ref={el => this.listView = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                {
                                    isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                }
                                {
                                    (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                }

                            </div>
                        )}
                        renderRow={this.row} //每行数据渲染
                        style={{
                            minHeight: 'calc(100vh - 20px)', //高度需要定义
                            overflow: 'auto',
                            paddingTop: '15px'
                        }}
                        pageSize={20}  //一次渲染几条数据
                        initialListSize={20}
                        onEndReached={this.onEndReached}//加载新的数据
                        onEndReachedThreshold={40}//距离底部40时候 加载数据
                    />
                </div>
            </div >
        )
    }
}

export default ChatRecord;
