import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, ListView } from 'antd-mobile';
import '../../assets/style/user_info.scss';
import '../../assets/style/mine.scss';
import Tab2Item from './../../components/Tab2_item';
import Api from '../../http/api';
import { Control } from 'react-keeper';


class MineInfo extends Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            list: [],
            itemType: 'mine',
            showBtns: false,//显示 发布动态的按钮

            page: 1, //页码
            limit: 5, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {
        const { page, limit, isHasMore } = this.state;
        let params = {
            page: page,
            limit: limit
        }
        if (isHasMore) {
            Api.mineDongTai(params).then(res => {
                console.log(res)
                if (res.code === 200) {
                    let allbi = this.state.preBillData.concat(res.data);
                    this.setState({
                        billData: res.data,
                        preBillData: allbi,
                        allBillData: allbi,
                        page: Number(this.state.page) + 1,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.length > 0 ? true : false,
                        isLoading: false
                    })
                }
            })
        }
    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    row = (item, sectionID, rowID) => {
        return (
            <div key={rowID + item.id} >
                <Tab2Item item={item} itemType={'mineInfo'} />
            </div>
        )
    }

    render() {
        const { isHasMore, isLoading, billData, showBtns } = this.state;
        return (
            <div className='user-info-page  mineInfoPage'>
                {/* navbar */}
                <div className='navBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                        rightContent={[
                            // <Icon key="0" type="ellipsis" onClick={() => {
                            //     this.setState({
                            //         showBtns: !showBtns
                            //     })
                            // }} />

                            <img key="0" type="ellipsis"
                                src={require('../../assets/imgs/icon/icon_fabu.png').default}
                                width='22'
                                onClick={() => {
                                    Control.go('/mine_send_dynamic')
                                }}
                            />
                        ]}
                        className='nav'
                    >我的动态</NavBar>
                </div>
                <div className='dtType' style={{ display: showBtns ? 'block' : 'none' }}>
                    <div onClick={() => { Control.go('/mine_send_dynamic') }} >发布图文</div>
                    <div onClick={() => { Control.go('/mine_send_dynamic2') }} >发布视频</div>
                </div>
                <WhiteSpace size='lg' />
                <div>
                    <ListView
                        ref={el => this.listView = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                {
                                    isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                }
                                {
                                    (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                }

                            </div>
                        )}
                        renderRow={this.row} //每行数据渲染
                        style={{
                            minHeight: 'calc(100vh - 20px)', //高度需要定义
                            overflow: 'auto',
                            paddingTop: '15px'
                        }}
                        pageSize={5}  //一次渲染几条数据
                        initialListSize={10}
                        onEndReached={this.onEndReached}//加载新的数据
                        onEndReachedThreshold={40}//距离底部40时候 加载数据
                    />
                </div>
            </div>
        )
    }
}
export default MineInfo;
