/* eslint-disable eqeqeq */
import { Toast } from 'antd-mobile';
import React from 'react';
import Api from './../http/api';

export class Topic extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            topic: this.props.topic,
            topicList: [],
        }
    }
    componentDidMount() {
        Api.dongTaiChat({}).then(res => {
            console.log(res)
            if (res.code == 200) {
                this.setState({
                    topicList: res.data
                })
            }
        })
    }
    addTopic = (tip) => {
        const { topic } = this.state;
        let tips = [...topic];
        let index = tips.indexOf(tip);
        console.log(index)
        if (index == -1) {
            if (topic.length > 2) {
                Toast.info('最多三个话题')
                return false;
            }
            tips.push(tip)
        } else {
            tips.splice(index, 1)
        }
        this.setState({
            topic: [...tips]
        })


    }
    render() {
        const { topic, topicList } = this.state
        return (
            <div className='positonSet'>
                <div className='topBox'>
                    <div className='top ft14'>
                        <div onClick={() => { this.props.showTopicSet(null) }}>取消</div>
                        <div className='ft_bold ft18'>添加话题</div>
                        <div onClick={() => { this.props.showTopicSet(topic) }} className='ok'>确定</div>
                    </div>

                </div>
                <div className='searchBox'>
                    <div className='title ft15 color255'>热门话题 <span className='ft14 opacity7'>(最多选三个)</span> </div>
                    {topicList.length > 0 ? topicList.map((item, index) => {
                        return (
                            <div className='search-item' key={item.id} onClick={() => {
                                this.addTopic(item)
                            }}>
                                <span className={topic.indexOf(item) != -1 ? 'active' : ''} >{'#' + item.title}</span>
                            </div>
                        )
                    }) : <div className='empty'>暂无话题</div>}


                </div>
            </div>
        )
    }
}
export default Topic;
