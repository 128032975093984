import { Icon, Modal, NavBar, Toast, WhiteSpace, WingBlank } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import { Component } from 'react'
import { Control } from 'react-keeper'
import '../../assets/style/user_info.scss'
import Photos from '../../components/Photos'
import Api from './../../http/api'

class User_info extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgList: [], //用户相册
      friendInfo: [], //初始本资料
      friendInfoNew: {}, //基本详细资料

      itemType: 'userInfo',
      showBtns: false, //右上角 黑名单和举报按钮

      showWxBox: false, //弹出 已经解锁的社交账号框
      showUnlockLinks: false, //  未解锁的联系方式框
      showUnlockChat: false, //   未解锁的私聊框

      dataInfo: [],
      showDataInfo: false,
      dataInfoId: '',
      hasCollect: false,
    }
  }
  componentDidMount() {
    // console.log(this.props.params.id);
    console.log(this.props.params.id, this.props.params.id2)
    window.scrollTo(0, 0)
    this.setState(
      {
        friendInfo: { id: this.props.params.id },
      },
      () => {
        this.getUserInfo()
      }
    )

    if (
      this.props.params.id === this.props.params.id2 &&
      this.props.params.id2 !== 0 &&
      sessionStorage.dataInfo
    ) {
      console.log(JSON.parse(sessionStorage.dataInfo))
      let dataInfo = JSON.parse(sessionStorage.dataInfo)
      let arr = []
      if (dataInfo.content_img.length > 0) {
        dataInfo.content_img.forEach(it => {
          let img = {
            img: it,
          }
          arr.push(img)
        })
      }

      dataInfo.imgArr = arr

      let arr2 = [{ title: '简介', value: dataInfo.title }]
      for (let it in dataInfo.branch_info) {
        console.log(it)
        arr2.push({ title: it, value: dataInfo.branch_info[it][0] })
      }
      console.log('id', dataInfo.id, dataInfo.is_collection)
      this.setState({
        imgList: dataInfo.imgArr,
        dataInfo: arr2,
        showDataInfo: true,
        dataInfoId: dataInfo.id,
        hasCollect: dataInfo.is_collection,
      })
    }
  }
  getUserInfo = (type = 0) => {
    const { showDataInfo, imgList } = this.state
    console.log(type)
    let params = { uid: this.state.friendInfo.id }
    Api.userInfo(params).then(res => {
      console.log('用户信息', res)
      if (res.code == 200) {
        //eslint-disable-line

        if (res.data.cancellation == 1) {
          Toast.fail('该用户已注销', 2)
          setTimeout(() => {
            Control.go(-1)
          }, 2000)
          return
        }
        this.setState({
          friendInfoNew: res.data,
          showWxBox: type == 2 ? true : false,
          imgList: showDataInfo ? imgList : res.data.album,
        })
      }
    })
  }
  follow = isFollow => {
    const { friendInfo, friendInfoNew } = this.state
    let params = {
      fid: friendInfo.id,
    }
    if (!isFollow) {
      Api.follow(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          //eslint-disable-line
          const obj = Object.assign({}, friendInfoNew, {
            is_follow: isFollow === 1 ? 0 : 1,
          })
          this.setState({
            friendInfoNew: obj,
          })
        }
      })
    } else {
      Api.unFollow(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          //eslint-disable-line
          const obj = Object.assign({}, friendInfoNew, {
            is_follow: isFollow === 1 ? 0 : 1,
          })
          this.setState({
            friendInfoNew: obj,
          })
        }
      })
    }
  }
  chat = type => {
    // type   1 j解锁私聊   2解锁联系方式
    const { friendInfoNew } = this.state
    console.log(type, friendInfoNew.unlock)
    if (friendInfoNew.unlock == 0) {
      // console.log('可以聊天');
      if (type === 1) {
        this.goChat()
      } else if (type === 2) {
        this.setState({
          showWxBox: true,
        })
      }
    } else if (friendInfoNew.unlock == 1 || friendInfoNew.unlock == 5) {
      // unlock == 5 非会员
      // console.log('会员次数解锁');
      // Modal.alert('提示', `使用VIP特权 申请解锁？`, [
      //     { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
      //     { text: '解锁', onPress: () => this.openChatLimitVip() },
      // ]);
      if (type == 1) {
        // 用户申请聊天 且无权限
        this.setState({
          showUnlockChat: true,
        })
      } else if (type == 2) {
        // 用户申请看联系方式 且无权限
        this.setState({
          showUnlockLinks: true,
        })
      }
    } else if (friendInfoNew.unlock == 2) {
      // console.log('付费解锁');
      Modal.alert('提示', `消费 ${friendInfoNew.unlock_price}元 申请解锁？`, [
        {
          text: '取消',
          onPress: () => console.log('cancel'),
          style: 'default',
        },
        { text: '解锁', onPress: () => this.openChatLimit() },
      ])
    } else if (friendInfoNew.unlock == 4) {
      Toast.loading('等待对方同意', 3)
    }
  }
  setStatee = state => {
    if (state === 0) {
      return '私聊'
    } else if (state === 1 || state === 2) {
      return '私聊'
    } else if (state === 4) {
      return '等待同意'
    } else if (state === 5) {
      return '私聊'
    }
  }
  setJuli = item => {
    if (!item && item !== 0) {
      return ''
    } else if (item === 0) {
      return '0m'
    } else {
      item = Number(item)
      if (item > 1000) {
        return (item / 1000).toFixed(2) + 'km'
      } else {
        return item + 'm'
      }
    }
  }
  //花费解锁
  openChatLimit = () => {
    const { friendInfoNew } = this.state
    let params = {
      uid: friendInfoNew.id,
    }
    Api.openChatLimit(params).then(res => {
      console.log('解锁聊天权限：', res)
      if (res.code == 200) {
        //eslint-disable-line
        if (friendInfoNew.unlock_state == 1) {
          Toast.success('解锁申请已发送', 3)
          setTimeout(() => {
            this.getUserInfo(1)
          }, 3000)
        } else {
          this.getUserInfo(2)
        }
      }
    })
  }
  // vip 次数解锁
  openChatLimitVip = type => {
    this.setState({
      showUnlockLinks: false,
      showUnlockChat: false,
    })
    console.log(type)
    const { friendInfoNew } = this.state
    let params = {
      uid: friendInfoNew.id,
    }
    Api.openChatLimitVip(params).then(res => {
      console.log('解锁聊天权限：', res)
      if (res.code == 200) {
        //eslint-disable-line
        console.log('解锁成功：', res)
        if (friendInfoNew.unlock_state == 1) {
          Toast.success('解锁申请已发送', 3)
          setTimeout(() => {
            this.getUserInfo()
          }, 3000)
        } else {
          if (type == 1) {
            this.goChat()
          } else if (type == 2) {
            this.getUserInfo(2)
          }
        }
      }
    })
  }
  goChat = () => {
    console.log('立即去聊天')
    const { friendInfoNew } = this.state
    // Control.go('/chat/' + friendInfoNew.id + '/' + friendInfoNew.nickname)
    Control.go('/chat/' + friendInfoNew.id + '/' + friendInfoNew.nickname)
  }
  blankName = type => {
    if (type) {
      this.delBlank()
    } else {
      this.addBlank()
    }
  }
  addBlank = () => {
    const { friendInfoNew } = this.state
    Api.addBlack({ lock_id: friendInfoNew.id }).then(res => {
      if (res.code == 200) {
        //eslint-disable-line
        Toast.success(res.msg, 2)
        this.getUserInfo(1)
      }
    })
  }
  delBlank = () => {
    const { friendInfoNew } = this.state
    Api.delBlack({ lock_id: friendInfoNew.id }).then(res => {
      if (res.code == 200) {
        //eslint-disable-line
        Toast.success(res.msg, 2)
        this.getUserInfo(1)
      }
    })
  }
  copyBtn = (wx, qq) => {
    if (wx) {
      copy(wx)
      Toast.success('复制成功', 3)
      setTimeout(() => {
        this.setState({
          showWxBox: false,
        })
      }, 3000)
    } else if (qq) {
      copy(qq)
      Toast.success('复制成功', 3)
      setTimeout(() => {
        this.setState({
          showWxBox: false,
        })
      }, 3000)
    } else {
      Toast.fail('复制失败')
    }
  }
  showBT = () => {
    const { showBtns } = this.state
    this.setState({
      showBtns: !showBtns,
    })
    setTimeout(() => {
      this.setState({
        showBtns: false,
      })
    }, 3000)
  }

  collect = () => {
    const { dataInfoId } = this.state
    Api.collect({ list_id: dataInfoId }).then(res => {
      if (res.code == 200) {
        //eslint-disable-line
        this.setState({
          hasCollect: res.data.is_collection,
        })
      }
    })
  }

  render() {
    const {
      friendInfoNew,
      friendInfo,
      showBtns,
      showUnlockLinks,
      showWxBox,
      showUnlockChat,
      dataInfo,
      showDataInfo,
      hasCollect,
    } = this.state
    return (
      <div className="user-info-page u-i">
        {/* navbar */}
        <div
          style={{
            height: 'auto',
            zIndex: '888',
            overflow: 'hidden',
            backgroundColor: '#222',
            width: '100%',
          }}
          id="head"
        >
          <div className="navBox">
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
              onLeftClick={() => {
                Control.go(-1)
              }}
              rightContent={[
                <Icon
                  key="0"
                  type="ellipsis"
                  style={{
                    display:
                      this.state.friendInfo.id != localStorage.mineId
                        ? 'flex'
                        : 'none',
                  }}
                  onClick={this.showBT}
                />,
              ]}
              className="nav"
            />
          </div>
          {/* 头部 */}
          <div
            className="head"
            style={{ backgroundImage: `url(${friendInfoNew.head_img})` }}
          >
            <div className="topBox">
              <WingBlank size="lg" className="userName">
                <div className="rz flex flex_column flex_column_left_center">
                  {friendInfoNew.brand ? (
                    <div
                      style={{ marginBottom: '5px', display: 'flex' }}
                      className="rzItem"
                    >
                      <div className="tip" style={{ background: '#4f4ee0' }}>
                        车辆认证
                      </div>
                      <div>人工车辆审核为{friendInfoNew.brand}</div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className="rzItem"
                    style={{
                      marginBottom: '5px',
                      display:
                        friendInfoNew.face_album === 1 ||
                        friendInfoNew.video == 1
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    <div className="tip tip2">相册认证</div>
                    <div>Ai面容识别照片为本人</div>
                  </div>
                  <div
                    style={{
                      marginBottom: '5px',
                      display:
                        friendInfoNew.real_head == 1 || friendInfoNew.video == 1
                          ? 'flex'
                          : 'none',
                    }}
                    className="rzItem"
                  >
                    <div className="tip">头像认证</div>
                    <div>
                      {friendInfoNew.real_head == 1 ? 'Ai面容识别' : ''}
                      {friendInfoNew.real_head == 1 && friendInfoNew.video == 1
                        ? '+'
                        : ''}
                      {friendInfoNew.video == 1 ? '人工视频审核' : '头像'}
                      为本人
                    </div>
                  </div>
                </div>
              </WingBlank>
            </div>
          </div>
          <div
            className="dtType"
            style={{ display: showBtns ? 'block' : 'none' }}
          >
            <div
              onClick={() => {
                this.blankName(friendInfoNew.is_black)
              }}
            >
              {friendInfoNew.is_black ? '移除黑名单' : '加入黑名单'}{' '}
            </div>
            <div
              onClick={() => {
                Control.go('/mine_question2/' + friendInfoNew.id)
              }}
            >
              匿名举报
            </div>
          </div>
          {/* 资料概括 */}
          <WingBlank size="lg">
            <div className="user-info">
              <div
                className="name flex flex_left_center"
                style={{ color: friendInfoNew.is_vip === 1 ? '#eccda4' : '' }}
              >
                {friendInfoNew.nickname}
                <img
                  src={require('../../assets/imgs/icon/vip2.png').default}
                  alt=""
                  style={{
                    display: friendInfoNew.is_vip === 1 ? 'flex' : 'none',
                  }}
                  width="26px"
                />
                <span
                  className="flex_center_center"
                  style={{
                    display: friendInfoNew.distance_show == 1 ? 'flex' : 'none',
                  }}
                >
                  <img
                    src={
                      require('../../assets/imgs/icon/icon_dingwei.png').default
                    }
                    width="15px"
                    alt=""
                  />
                  {this.setJuli(friendInfoNew.distance)}
                </span>
              </div>
              <WhiteSpace />
              <div className="rz2">
                {/* <span hidden={!friendInfoNew.sex}>{friendInfoNew.sex === 1 ? '男' : '女'}</span> */}
                <span hidden={!friendInfoNew.age}>{friendInfoNew.age}岁</span>
                <span hidden={!friendInfoNew.occupation}>
                  {friendInfoNew.occupation}
                </span>
                <span hidden={!friendInfoNew.region_name}>
                  {friendInfoNew.region_name}
                </span>
                <span hidden={!friendInfoNew.off_line}>
                  {friendInfoNew.off_line}
                </span>
              </div>
            </div>
          </WingBlank>
        </div>
        {/* tabs 内容 */}
        <WingBlank size="lg">
          <div className="userInfoBox">
            <WhiteSpace size="lg" />
            {/* 用户信息 */}
            <div style={{ display: showDataInfo ? 'none' : 'block' }}>
              <div
                className="ft16  mb10  les1"
                style={{ color: '#efcdaf', marginTop: 20, fontWeight: 500 }}
              >
                {friendInfoNew.sex === 2 ? '她' : '他'}的相册
              </div>
              <Photos list={this.state.imgList} photoType={'abc'} />
              <WhiteSpace size="lg" />
              <div className="userInfoList">
                <div hidden={!friendInfoNew.height}>
                  {' '}
                  <b> 身高</b>
                  <span> {friendInfoNew.height}cm</span>
                </div>
                <div hidden={!friendInfoNew.weight}>
                  {' '}
                  <b> 体重</b>
                  <span> {friendInfoNew.weight}kg</span>
                </div>
                {/* <div hidden={!friendInfoNew.birthday}> <b> 生日</b><span> {friendInfoNew.birthday}</span></div> */}
                <div hidden={!friendInfoNew.education}>
                  {' '}
                  <b> 学历</b>
                  <span> {friendInfoNew.education}</span>
                </div>
                <div hidden={!friendInfoNew.often_city}>
                  {' '}
                  <b> 常出没</b>
                  <span> {friendInfoNew.often_city}</span>
                </div>

                {friendInfoNew.brief ? (
                  <div>
                    <b>自我简介</b>
                    <span> {friendInfoNew.brief}</span>
                  </div>
                ) : (
                  ''
                )}

                <div hidden={!friendInfoNew.about_me}>
                  <b>个人标签</b>
                  <span>
                    {friendInfoNew.about_me
                      ? friendInfoNew.about_me.map((item, index) => {
                          return (
                            <i
                              key={item + index}
                              style={{
                                display: item ? 'inline-block' : 'none',
                              }}
                            >
                              {item}
                            </i>
                          )
                        })
                      : ''}
                  </span>
                </div>
              </div>

              <div
                className="ft16 ft_bold mb10 les1  flex_center_center"
                style={{
                  color: '#efcdaf',
                  padding: '15px 0',
                  borderBottom: '1px solid #ffffff12',
                  justifyContent: 'space-between',
                  display: friendInfoNew.is_dynamic == 1 ? 'flex' : 'none',
                }}
                onClick={() => {
                  // Control.go('/user_info_about/' + friendInfo.id)
                  Control.go('/user_info_about/' + friendInfo.id)
                }}
              >
                {friendInfoNew.sex === 2 ? '她' : '他'}的动态
                <div className="flex flex_center_center flex_s_b">
                  <div
                    style={{
                      width: 160,
                      height: 50,
                      justifyContent: 'flex-end',
                    }}
                    className="flex flex_center_center"
                  >
                    {friendInfoNew.dynamic_img
                      ? friendInfoNew.dynamic_img
                          .slice(0, 3)
                          .map((item, index) => {
                            return (
                              <div
                                key={index + 'dt'}
                                style={{
                                  width: '30%',
                                  marginLeft: '3%',
                                  height: 0,
                                  paddingBottom: '30%',
                                  background: '#333',
                                  borderRadius: 4,
                                  position: 'relative',
                                  overflow: 'hidden',
                                }}
                              >
                                <img
                                  src={item}
                                  alt=""
                                  width="100%"
                                  style={{
                                    position: 'absolute',
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </div>
                            )
                          })
                      : ''}
                  </div>
                  <Icon type="right" />
                </div>
              </div>
            </div>
            {/* 约会信息 */}
            <div style={{ display: showDataInfo ? 'block' : 'none' }}>
              <div
                className="ft16 ft_bold  mb10 "
                style={{ color: '#efcdaf', marginTop: 40, fontWeight: 600 }}
              >
                约会信息
              </div>
              <div className="userInfoList">
                {dataInfo.map(item => {
                  return (
                    <div key={item.title}>
                      <b>{item.title}</b>
                      <span>{item.value}</span>
                    </div>
                  )
                })}
              </div>
              {this.state.imgList.length > 0 && (
                <div>
                  <div
                    className="ft15  mb10  les1"
                    style={{ color: '#efcdaf', marginTop: 12, fontWeight: 500 }}
                  >
                    补充说明
                  </div>
                  <Photos list={this.state.imgList} photoType={'abc'} />
                </div>
              )}
            </div>
          </div>
        </WingBlank>

        <div
          className="btns"
          style={{
            display:
              this.state.friendInfo.id != localStorage.mineId ? 'flex' : 'none',
          }}
        >
          {/* 解锁 */}
          <div
            onClick={() => {
              this.chat(1)
            }}
            style={{ background: '#db5353' }}
          >
            <img
              src={require('../../assets/imgs/icon/liaotian.png').default}
              alt=""
            />
            {this.setStatee(friendInfoNew.unlock)}
          </div>
          {/* 喜欢和约会收藏 */}

          {showDataInfo ? (
            <div
              onClick={() => {
                this.collect()
              }}
            >
              <img
                src={
                  require(hasCollect === 1
                    ? '../../assets/imgs/icon/aixin2.png'
                    : '../../assets/imgs/icon/aixin.png').default
                }
                alt=""
              />
              {hasCollect === 1 ? '已收藏' : '收藏'}
            </div>
          ) : (
            <div
              onClick={() => {
                this.follow(friendInfoNew.is_follow)
              }}
            >
              <img
                src={
                  require(friendInfoNew.is_follow === 1
                    ? '../../assets/imgs/icon/aixin2.png'
                    : '../../assets/imgs/icon/aixin.png').default
                }
                alt=""
              />
              {friendInfoNew.is_follow === 1 ? '取消' : '喜欢'}
            </div>
          )}

          {/* 联系方式 */}
          <div
            onClick={() => {
              this.chat(2)
            }}
          >
            <img
              src={require('../../assets/imgs/icon/shejiao.png').default}
              alt=""
            />
            微信
          </div>
        </div>
        {/* 复制联系方式 */}
        <div
          className="wxBox"
          style={{ display: showWxBox ? 'block' : 'none' }}
        >
          <div className="wxBoxBg">
            <div
              className="close"
              onClick={() => {
                this.setState({ showWxBox: false })
              }}
            >
              <Icon type="cross-circle-o" color="#db5353" />
            </div>
            <div
              className="item"
              style={{ display: friendInfoNew.wx ? 'flex' : 'none' }}
            >
              <img
                src={
                  require('./../../assets/imgs/icon/Icon_WeChat.png').default
                }
                alt=""
              />{' '}
              微信 {friendInfoNew.wx}
            </div>
            <div
              className="item"
              style={{
                display:
                  friendInfoNew.qq && !friendInfoNew.wx ? 'flex' : 'none',
              }}
            >
              <img
                src={require('./../../assets/imgs/icon/Icon_QQ.png').default}
                alt=""
              />{' '}
              QQ {friendInfoNew.qq}
            </div>
            <div
              className="copy"
              onClick={() => {
                this.copyBtn(friendInfoNew.wx, friendInfoNew.qq)
              }}
            >
              复制
            </div>
          </div>
        </div>
        {/* 解锁私聊 */}
        <div
          className="wxBox"
          style={{ display: showUnlockChat ? 'block' : 'none', height: '35%' }}
        >
          <div className="wxBoxBg">
            <div
              className="close"
              onClick={() => {
                this.setState({ showUnlockChat: false })
              }}
            >
              <Icon type="cross-circle-o" color="#db5353" />
            </div>
            <div className="item ft20 ft_bold les2">
              {friendInfoNew.unlock == 5 ? '升级会员' : '解锁私聊'}
            </div>
            <div className="item  les1">
              是否和{friendInfoNew.sex == 2 ? '她' : '他'}私聊，同时解锁联系方式
            </div>

            <div
              className="copy"
              onClick={() => {
                friendInfoNew.unlock == 5
                  ? Control.go('/mine_vip')
                  : this.openChatLimitVip(1)
              }}
            >
              {friendInfoNew.unlock == 5
                ? '升级诚意会员，免费解锁'
                : `免费解锁(今日剩${friendInfoNew.vip_unlock_count}次)`}
            </div>
          </div>
        </div>
        {/* 解锁联系方式 */}
        <div
          className="wxBox"
          style={{ display: showUnlockLinks ? 'block' : 'none' }}
        >
          <div className="wxBoxBg">
            <div
              className="close"
              onClick={() => {
                this.setState({ showUnlockLinks: false })
              }}
            >
              <Icon type="cross-circle-o" color="#db5353" />
            </div>
            <div className="item ft20 ft_bold les2">
              {friendInfoNew.unlock == 5 ? '升级会员' : '解锁联系方式'}
            </div>
            <div
              className="item  les1  flex_left_center"
              style={{
                flexDirection: 'row',
                display: friendInfoNew.wx ? 'flex' : 'none',
              }}
            >
              微信
              <span className="opacity7" style={{ marginLeft: 15 }}>
                {friendInfoNew.wx || ''}
              </span>
            </div>
            <div
              className="item  les1  flex_left_center"
              style={{
                flexDirection: 'row',
                display:
                  !friendInfoNew.wx && friendInfoNew.qq ? 'flex' : 'none',
              }}
            >
              QQ
              <span className="opacity7" style={{ marginLeft: 15 }}>
                {friendInfoNew.qq || ''}
              </span>
            </div>
            <div
              className="item  les1 flex flex_left_center"
              style={{ flexDirection: 'row' }}
            >
              私聊
              <span className="opacity7" style={{ marginLeft: 15 }}>
                解锁后即可聊天
              </span>
            </div>
            <div
              className="copy"
              onClick={() => {
                friendInfoNew.unlock == 5
                  ? Control.go('/mine_vip')
                  : this.openChatLimitVip(2)
              }}
            >
              {friendInfoNew.unlock == 5
                ? '升级诚意会员，免费解锁'
                : `免费解锁(今日剩${friendInfoNew.vip_unlock_count}次)`}
            </div>
          </div>
        </div>
        <div
          className="mask"
          style={{
            display:
              showUnlockLinks || showWxBox || showUnlockChat ? 'block' : 'none',
          }}
        ></div>
      </div>
    )
  }
}
export default User_info
