/* eslint-disable eqeqeq */
import React, { Component } from 'react';

import { NavBar, Icon, WhiteSpace, WingBlank, Toast } from 'antd-mobile';
import Api from '../../http/api';
import { Control } from 'react-keeper';

import "../../assets/style/mine_setting.scss";

class MineAc extends Component {
    constructor(props) {
        super(props)
        this.state = {
            real: {
                face: 0,
                video: 0,
                house: 0,
                vehicle: 0
            }
        }
    }
    getCode = () => {
        Api.realState({}).then(res => {
            console.log('状态', res)
            if (res.code == 200) {
                this.setState({
                    real: res.data
                })
            }
        })
    }
    componentDidMount() {
        this.getCode()
    }
    rz = (item, index) => {
        if (index === 1) {
            if (item.state == 1) {
                Toast.success('已认证!')
            } else {
                Control.go(item.path)
            }

        } else {
            Control.go(item.path)
        }
    }

    render() {
        const { real } = this.state;
        const funcList = [
            { name: '真人认证', state: real.face, path: real.face == 1 ? '/mine_real' : '/mine_real2', src: require('../../assets/imgs/set/User_icon_zhenren@2x.png').default },
            { name: '视频认证', state: real.video, path: '/mine_real_list/3', src: require('../../assets/imgs/set/User_icon_zhenren@2x.png').default },
            { name: '车辆认证', state: real.vehicle, path: '/mine_real_list/2', src: require('../../assets/imgs/set/User_icon_che@2x.png').default },
            { name: '房产认证', state: real.house, path: '/mine_real_list/1', src: require('../../assets/imgs/set/User_icon_fangchan@2x.png').default }
        ]

        const funcListMap = funcList.map((item, index) => {
            return (
                <div className='item' key={index} onClick={() => {
                    this.rz(item, index)
                }}>
                    <div className='left'>
                        <img src={item.src} alt='' />
                        <span>{item.name}</span>
                    </div>
                    <div className='right ft12 flex'>
                        <span style={{ opacity: 0.8, color: item.state == 1 ? '#e3c3ac' : '#fff' }}>{item.state == 1 ? '已认证' : '未认证'}</span>
                        <Icon type='right' />
                    </div>
                </div>
            )
        })
        return (
            <div>
                <div className='navBoxBB'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >认证中心</NavBar>
                </div>
                <div>
                    <img width='100%' src={require('./../../assets/imgs/real/Certification_banner@2x.png').default} alt="" />
                </div>
                <div className='mine-setting mine-team'>
                    <WingBlank size='lg'>
                        <WhiteSpace size='lg' />
                        {funcListMap}
                    </WingBlank>
                </div>

            </div>
        )
    }
}
export default MineAc;
