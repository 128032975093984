import React, { Component } from 'react';

import Login from "./../pages/Login";
import Tab1 from "./../pages/Tab1";
import Tab2 from "./../pages/Tab2";
import Tab3 from "./../pages/Tab3";
import Tab4 from "./../pages/Tab4";
import Tab5 from "./../pages/Tab5";
import Tab6 from "./../pages/Tab6";
import serveHome from "../pages/serveHome";  //客服聊天列表


import ScrollToTop from "./../components/ScrollToTop";
import MineSetting from "../pages/mine/MineSetting";
import MineChangeInfo from "../pages/mine/MineChangeInfo";
import MineChangeInfoEdit from "../pages/mine/MineChangeInfoEdit";
import MinePhotos from "../pages/mine/MinePhotos";
import MineLike from "../pages/mine/MineLike";
import MineAgree from "../pages/mine/MineAgree";

import MineDislike from "../pages/mine/MineDislike";
import MineView from "../pages/mine/MineView";
import MineAC from "../pages/mine/MineAC";
import MineZan from "../pages/mine/MineZan";
import MineMsg from "../pages/mine/MineMsg";
import MinePayWay from "../pages/mine/MinePayWay";
import MineWallet from "../pages/mine/MineWallet";
import MineWalletRecord from "../pages/mine/MineWalletRecord";
import MineChangePwd from "../pages/mine/MineChangePwd";
import MineInfo from "../pages/mine/MineInfo";
import MineQuestion from "../pages/mine/MineQuestion";
import MineQuestion2 from "../pages/mine/MineQuestion2";

import MineCash from "../pages/mine/MineCash";
import MineCashRecord from "../pages/mine/MineCashRecord";
import MineRealName from "../pages/mine/MineRealName";
import MineRealHouse from "../pages/mine/MineRealHouse";
import MineRealList from "../pages/mine/MineRealList";
import MineRealCar from "../pages/mine/MineRealCar";

import MineReal from "../pages/mine/MineReal";
import MineReal2 from "../pages/mine/MineReal2";
import MineReal3 from "../pages/mine/MineReal3";
import MineAddBank from "../pages/mine/MineAddBank";
import MineVip from "../pages/mine/MineVip";
import MineLuck from "../pages/mine/MineLuck";
import MineLuckRecord from "../pages/mine/MineLuckRecord";



import Chat from "../pages/mine/Chat";// 用户之间聊天
import ChatServe from "../pages/mine/ChatServe";// 用户主动与客服聊天
import ChatServe2 from "../pages/mine/ChatServe2";// 客服主动与用户聊天

import ChatRoom from "../pages/mine/ChatRoom";// 用户聊天室


import ChatRecord from "../pages/mine/ChatRecord";
import User_info from "../pages/mine/User_info";
import Data_info from "../pages/mine/Data_info";
import User_info_about from "../pages/mine/User_info_about";
// import SendDongTai from "../pages/mine/SendDongTai";
// import SendDongTai2 from "../pages/mine/SendDongTai2";
import SendDongTai0 from "../pages/mine/SendDongTai0";
import SendData from "../pages/mine/SendData";
import NotFound from "../pages/NotFound";
import AboutUs from "../pages/mine/MineAboutUs";
import MineRecharge from "../pages/mine/MineRecharge";
import MineRechargeRecord from "../pages/mine/MineRechargeRecord";

import MineInvite from "../pages/mine/MineInvite"; //团队说明
import MineInviteAd from "../pages/mine/MineInviteAd";
import MineInviteAd2 from "../pages/mine/MineInviteAd2";

import SetState from "../pages/set/setState";
import SetPrivacy from "../pages/set/setPrivacy";
import SetInvite from "../pages/set/setInvite";
import SetWx from "../pages/set/setWx";
import SetAddr from "../pages/set/setAddr";


import SetAgreement from "../pages/set/setAgreement";
import SetPrivacyAgreement from "../pages/set/setPrivacyAgreement";
import SetInfo from "../pages/set/setInfo";

import Team from "../pages/team/Team";
import TeamInfo from "../pages/team/TeamInfo";
import InviteInfo from "../pages/team/InviteInfo";
import TeamInviteInfo from "../pages/team/TeamInviteInfo";


// import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
// import { Route, Switch, HashRouter as Router, Redirect } from 'react-router-dom';
import { Route, HashRouter as Router } from 'react-keeper';



class RouterIndex extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const routes = [
            {
                path: "/",
                exact: true,
                component: Login
            },
            {
                path: "/login",
                component: Login
            },
            {
                path: "/tab1",
                component: Tab1
            },
            {
                path: "/tab2",
                component: Tab2
            },
            {
                path: "/tab3",
                component: Tab3
            },
            {
                path: "/tab4",
                component: Tab4
            },
            {
                path: "/tab5",
                component: Tab5
            },
            {
                path: "/mine_data",
                component: Tab6
            },
            {
                path: "/serveHome",
                component: serveHome
            },
            {
                path: "/user_info/:id/:id2",
                component: User_info
            },
            {
                path: "/data_info/:id",
                component: Data_info
            },
            {
                path: "/user_info_about/:id",
                component: User_info_about
            },
            {
                path: "/mine_setting",
                component: MineSetting
            },
            {
                path: "/mine_like",
                component: MineLike
            },
            {
                path: "/mine_agree",
                component: MineAgree
            },
            {
                path: "/mine_dislike",
                component: MineDislike
            },
            {
                path: "/mine_ac",
                component: MineAC
            },
            {
                path: "/mine_view",
                component: MineView
            },
            {
                path: "/mine_zan",
                component: MineZan
            },
            {
                path: "/mine_msg",
                component: MineMsg
            },
            {
                path: "/mine_luck",
                component: MineLuck
            },
            {
                path: "/mine_luck_record",
                component: MineLuckRecord
            },
            {
                path: "/mine_photos",
                component: MinePhotos
            },
            {
                path: "/mine_payway",
                component: MinePayWay
            },
            {
                path: "/mine_wallet",
                component: MineWallet
            },
            {
                path: "/mine_wallet_record",
                component: MineWalletRecord
            },
            {
                path: "/mine_change_pwd",
                component: MineChangePwd
            },
            {
                path: "/mine_info",
                component: MineInfo
            },
            {
                path: "/mine_question",
                component: MineQuestion
            },
            {
                path: "/mine_question2/:id",
                component: MineQuestion2
            },
            {
                path: "/mine_cash",
                component: MineCash
            }, {
                path: "/mine_cash_record",
                component: MineCashRecord
            },
            {
                path: "/mine_real_name",
                component: MineRealName
            },
            {
                path: "/mine_real_house",
                component: MineRealHouse
            },
            {
                path: "/mine_real_list/:type",
                component: MineRealList
            },
            {
                path: "/mine_real_car",
                component: MineRealCar
            },
            {
                path: "/mine_add_bank",
                component: MineAddBank
            },
            {
                path: "/mine_vip",
                component: MineVip
            },
            {
                path: "/chat/:id/:nickname",
                component: Chat
            },
            {
                path: "/chat_room",
                component: ChatRoom
            },
            {
                path: "/chat_serve2/:id/:nickname",
                component: ChatServe2
            },
            {
                path: "/chat_serve",
                component: ChatServe
            },
            {
                path: "/msg_record/:id",
                component: ChatRecord
            },
            {
                path: "/mine_change_info",
                component: MineChangeInfo
            },
            {
                path: "/mine_change_info_edit/:name",
                component: MineChangeInfoEdit
            },
            {
                path: "/mine_send_dynamic",
                component: SendDongTai0
            },
            {
                path: "/mine_send_data/:id",
                component: SendData
            },
            // {
            //     path: "/mine_send_dynamic2",
            //     component: SendDongTai2
            // },
            // {
            //     path: "/mine_send_dynamic0",
            //     component: SendDongTai0
            // },
            {
                path: "/mine_recharge",
                component: MineRecharge
            },
            {
                path: "/mine_recharge_record",
                component: MineRechargeRecord
            },

            // {
            //     path: "/404",
            //     component: NotFound
            // },
            {
                path: "/about_us",
                component: AboutUs
            },
            {
                path: "/mine_invite",
                component: MineInvite
            },
            {
                path: "/mine_invite_ad",
                component: MineInviteAd
            },
            {
                path: "/mine_invite_ad2",
                component: MineInviteAd2
            },
            {
                path: "/mine_real",
                component: MineReal
            },
            {
                path: "/mine_real2",
                component: MineReal2
            },
            {
                path: "/mine_real3",
                component: MineReal3
            },
            {
                path: "/set_state",
                component: SetState
            },
            {
                path: "/set_privacy",
                component: SetPrivacy
            },
            {
                path: "/set_invite",
                component: SetInvite
            },
            {
                path: "/set_wx",
                component: SetWx
            },
            {
                path: "/set_addr",
                component: SetAddr
            },
            {
                path: "/set_agreement",
                component: SetAgreement
            },
            {
                path: "/set_privacy_agreement",
                component: SetPrivacyAgreement
            }
            ,
            {
                path: "/set_info/:step",
                component: SetInfo
            }
            ,
            {
                path: "/team",
                component: Team
            },
            {
                path: "/team_info",
                component: TeamInfo
            },
            {
                path: "/invite_info",
                component: InviteInfo
            },
            {
                path: "/team_invite_info",
                component: TeamInviteInfo
            }

        ];
        const cacheList = ['/tab1', '/tab2'];
        return (
            <Router>
                <div>
                    {routes.map(item => (
                        <Route
                            cache={cacheList.indexOf(item.path) != -1 ? true : false}
                            key={item.path}
                            path={item.path}
                            exact={item.exact || false}
                            component={item.component}
                        />
                    ))}
                </div>
            </Router>
        )
    }
}
export default RouterIndex
