/* eslint-disable eqeqeq */
import React from 'react';
import './../../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, NavBar, Icon, Toast } from 'antd-mobile';
import Api from '../../http/api';
import { Control } from 'react-keeper';


class MineChangePwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            addr: '',
            info: '',
            edit: true
        }
    }
    componentDidMount() {
        this.getAddr()
    }
    handleChange = (name, e) => {
        this.setState({
            [name]: e
        })
    }
    getAddr = () => {
        Api.addrGet({}).then(res => {
            console.log('默认地址', res)
            if (res.code == 200 && res.data) {
                this.setState({
                    name: res.data.name,
                    phone: res.data.phone,
                    addr: res.data.addr,
                    info: res.data,
                    edit: false
                })
            }
        })
    }
    setAddr = () => {
        const { name, phone, addr } = this.state;

        if (!name) {
            Toast.info('请填写收货人姓名')
            return false
        }
        if (!phone || phone.length != 11) {
            Toast.info('请填写正确手机号')
            return false
        }
        if (!addr) {
            Toast.info('请填写详细地址')
            return false
        }
        Api.addrEdit({ name, phone, addr }).then(res => {
            console.log('设置地址', res)
            if (res.code == 200) {
                Toast.success(res.msg, 3)
                this.setState({
                    info: { name, phone, addr },
                    edit: false
                })
                setTimeout(() => {
                    Control.go(-1)
                }, 3000);
            }
        })
    }

    render() {

        return (
            <div className='login-page change_pwd_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                    rightContent={[
                        <div key='0' className='ft14 opacity7' onClick={() => {
                            this.setState({
                                edit: !this.state.edit
                            })
                        }} style={{ display: this.state.info ? 'block' : 'none' }}>  {this.state.edit ? '取消' : '修改'} </div>,
                    ]}

                >收货地址</NavBar>

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>

                    <InputItem
                        className='input-box'
                        value={this.state.name}
                        type="text"
                        placeholder="收货人姓名"
                        editable={this.state.edit}
                        onChange={(v) => { this.handleChange('name', v) }}
                    >
                        姓名
                    </InputItem>
                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.phone}
                        type="text"
                        placeholder="收货人手机号"
                        editable={this.state.edit}
                        onChange={(v) => { this.handleChange('phone', v) }}
                    >
                        手机
                    </InputItem>
                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.addr}
                        type="text"
                        editable={this.state.edit}
                        placeholder="收货人详细地址"
                        onChange={(v) => { this.handleChange('addr', v) }}
                    >
                        地址
                    </InputItem>

                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    {this.state.edit &&
                        <Button className='login-btn change-btn' onClick={this.setAddr}>提交</Button>
                    }

                </WingBlank>



            </div >

        )
    }
}
export default MineChangePwd;

