/* eslint-disable eqeqeq */
import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, Toast } from 'antd-mobile';
import SetInfo1 from "./../../components/SetInfo1";
import SetInfo2 from "./../../components/SetInfo2";
import SetInfo3 from "./../../components/SetInfo3";
import SetInfo4 from "./../../components/SetInfo4";
import { Control } from 'react-keeper';


import Api from '../../http/api';
import Upload from '../../http/upload';


class MineChangePwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '选择性别',
            steps: 1,//当期步骤
            jobList: [],//系统预设职业
            tipList: [],//系统预设标签


            cols: 2,
            asyncValue: [],
            data: [],

            // step1
            sex: 3,
            // step2
            file1: [],
            head_img: '',
            nickname: '',
            date: '',

            // step3
            age: '',
            height: [160],
            weight: [50],
            job: '',
            gexingtip: [],//标签
            city: localStorage.city || '',
            about_info: '',//简介
            education: '',//学历

            // step4
            wx: '',
            qq: '',

        }
    }
    // beforeStep = () => {
    //     const step = this.state.steps;
    //     if (step > 1) {
    //         this.setState({
    //             steps: step - 1
    //         })
    //     }
    // }
    componentDidMount() {
        let step = Number(this.props.params.step);
        this.setState({
            steps: step
        })
        if (step == 3) {
            this.getTips()
            this.getProvince()
        }
        this.getJobs();

    }
    getJobs = () => {
        Api.job({}).then(res => {
            console.log('职业列表', res)
            if (res.code === 200) {
                this.setState({
                    jobList: res.data
                })
            }
        })

    }
    getTips = () => {
        Api.tip({}).then(res => {
            console.log('个性列表', res)
            if (res.code === 200) {
                this.setState({
                    tipList: res.data
                })
            }
        })
    }

    addTips = (tip) => {
        const { gexingtip } = this.state;
        let tips = [...gexingtip];
        let index = tips.indexOf(tip);
        console.log(index)
        if (index == -1) {
            if (gexingtip.length > 9) {
                Toast.info('最多十个标签')
                return false;
            }
            tips.push(tip)
        } else {
            tips.splice(index, 1)
        }
        this.setState({
            gexingtip: [...tips]
        })
    }
    nextStep = () => {
        const {
            steps,
            // step1
            sex,
            // step2
            nickname, date, head_img,
            // step3
            age, city, height, weight, job, about_info, gexingtip, education, asyncValue,
            // step4
            qq, wx } = this.state;

        if (steps === 1) {
            if (!sex || sex === 3) {
                Toast.info('请选择性别');
                return false;
            } else {
                let param = {
                    sex: sex
                }
                this.editInfo(param)
            }
        } else if (steps === 2) {
            if (!head_img) {
                Toast.info('请上传您的头像');
                return false;
            } else if (!nickname) {
                Toast.info('请填写您的昵称');
                return false;
            } else if (!age) {
                Toast.info('请设置您的年龄');
                return false;
            } else if (!height) {
                Toast.info('请设置您的身高');
                return false;
            } else if (!weight) {
                Toast.info('请设置您的体重');
                return false;
            } else if (!job) {
                Toast.info('请设置您的职业');
                return false;
            }
            // else if (!date) {
            //     Toast.info('请选择您的生日');
            //     return false;
            // }
            else {
                let param = {
                    head_img,
                    nickname,
                    age: age[0],
                    height: height[0],
                    weight: weight[0],
                    occupation: job[1],
                    // birthday: new Date(date).getTime() //生日
                }
                this.editInfo(param)
            }

        } else if (steps === 3) {
            if (!education) {
                Toast.info('请设置您的学历');
                return false;
            } else if (gexingtip.length === 0) {
                Toast.info('请设置您的标签');
                return false;
            } else if (asyncValue.length == 0) {
                Toast.info('请设置您的常居地');
                return false;
            } else if (!about_info) {
                Toast.info('请输入您的简介');
                return false;
            } else {
                let param = {
                    about_me: gexingtip,
                    brief: about_info,
                    often_city: asyncValue[asyncValue.length - 1],
                    education: education[0]
                }

                this.editInfo(param)
            }

        } else if (steps === 4) {
            if (!wx && !qq) {
                Toast.fail('请填写社交账号');
                return false;
            } else {
                let param = { qq, wx }
                this.editInfo(param)
            }
        }
    }
    // step1   人脸识别自动上传
    onChange1 = (file1, type, index) => {
        console.log('上传头像', file1, type, index);
        this.setState({
            file1,
        }, () => {
            this.uploadImg(file1, 'userHead', res => {
                if (res) {
                    console.log('上传完毕：', res)
                    if (res) {
                        this.setState({
                            head_img: res[0].pathName
                        })
                    }
                }
            })
        });
    }
    editInfo = (params) => {
        const { steps } = this.state;
        console.log(params)
        params.info_cast = steps;
        Api.editInfoCast(params).then(res => {
            if (res.code === 200) {
                if (steps == 1) {
                    // 设置性别
                    localStorage.sex = params.sex
                } else if (steps == 2 || steps == 3) {
                    this.getTips();
                    this.getProvince();
                }

                console.log('性别  步骤', localStorage.sex, steps)

                if (steps == 1 || (steps == 2 && localStorage.sex != 1) || steps == 3) {
                    this.setState({
                        steps: steps + 1
                    })
                } else {
                    Toast.success('资料已完善', 3)
                    setTimeout(() => {
                        this.getMineInfo();
                        Control.go('/tab1')
                    }, 3000);

                }

            }
        })

    }
    getMineInfo = () => {
        Api.mineInfo({}).then(res => {
            // console.log('我的信息', res)
            if (res.code == 200) {
                localStorage.userInfo = JSON.stringify(res.data);
                localStorage.sex = res.data.sex;
                localStorage.mineId = res.data.id || '';
            }
        })
    }

    // step1   上传照片
    uploadImg = (files, pathName, callback = () => { }) => {
        console.log(files, pathName)
        let upLoad = new Upload({
            files: files,
            path: pathName,
            onSuccess: (res) => {
                console.log('照片上传完毕：', res)
                callback(res)

            },
            onFail: (err) => {
                console.log('照片上传失败：', err)
                callback(false)
            }

        })
        upLoad.open()
    }
    handleChange = (name, e) => {
        this.setState({
            [name]: e
        })
    }
    // 设置性别
    setSex = (type) => {
        console.log(type);
        if (type === 3) {
            return false
        }
        this.setState({ sex: type }, () => {
            this.nextStep()
        })
    }
    setTitle = () => {
        const { steps } = this.state;
        let title = '选择性别';
        if (steps === 1) {
            return '选择性别'
        } else if (steps === 2) {
            return '完善基本信息'
        } else if (steps === 3) {
            return '完善详细信息'
        } else if (steps === 4) {
            return '完善社交账号'
        }
        this.setState({ title })
    }
    changeStepsContent = () => {
        const { steps } = this.state;
        console.log(steps)
        if (steps === 1) {
            return (
                <SetInfo1 state={this.state} setSexx={this.setSex} />
            )
        } else if (steps === 2) {
            return (
                <SetInfo2 state={this.state} nextStep={this.nextStep} handleChange={this.handleChange} onChange1={this.onChange1} />
            )

        } else if (steps === 3) {
            return (
                <SetInfo3 state={this.state} nextStep={this.nextStep} handleChange={this.handleChange} addTips={this.addTips} onPickerChange={this.onPickerChange} onVisibleChange={this.onVisibleChange} onDismiss={this.onDismiss} />
            )

        } else if (steps === 4) {
            return (
                <SetInfo4 state={this.state} nextStep={this.nextStep} handleChange={this.handleChange} />
            )

        }
    }


    // 获取省市
    getProvince = () => {
        Api.province({}).then(res => {
            console.log('省列表：', res)
            if (res.code === 200) {
                let provinces = '';
                provinces = res.data;
                provinces.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                })
                this.setState({ data: provinces })
            }
        })
    }
    // 选择城市每一项改变
    onPickerChange = (val) => {
        console.log(val);
        const { cols } = this.state;
        let colNum = cols;
        let d = [...this.state.data];

        let asyncValue = [...val];
        Api.city({ region_id: val }).then(citys => {
            console.log('市级列表：', citys)
            if (citys.code === 200) {
                let city2 = [...citys.data];
                city2.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                })
                d.forEach((item, index) => {
                    if (item.value === val[0]) {
                        console.log(index)
                        if (!item.children) {
                            item.children = [...city2]
                            colNum = 2;
                            asyncValue.push(item.children[0].value, item.children[0].label)
                        }
                    }
                })
                this.setState({
                    data: d,
                    cols: colNum,
                    asyncValue,
                });
            }
        })
        console.log('hh ', '2')
        // this.setState({
        //     data: d,
        //     cols: colNum,
        //     asyncValue,
        // });

    };

    onVisibleChange = (v) => {
        console.log(v)
        const { data } = this.state;
        if (v && this.state.asyncValue.length == 0) {
            this.setState({
                asyncValue: [data[0].label]
            })
        }

    }
    // 取消选择城市
    onDismiss = () => {
        console.log('取消', this.state.asyncValue)
    }

    render() {
        return (
            <div className='login-page change_pwd_page  setInfoPage'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                > {this.setTitle()}</NavBar>

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>
                    {this.changeStepsContent()}
                </WingBlank>



            </div >

        )
    }
}
export default MineChangePwd;

