const Tab6Item = (props) => {
    // console.log(props);
    const { item, goInfo, delData, type, unCollect } = props;

    // const setJuli = (item) => {
    //     if (!item && item !== 0) {
    //         return '';
    //     } else if (item === 0) {
    //         return '0m';
    //     } else {
    //         item = Number(item)
    //         if (item > 1000) {
    //             return (item / 1000).toFixed(2) + 'km'
    //         } else {
    //             return item + 'm'
    //         }
    //     }
    // }
    // console.log(item, goInfo)
    const ListItem = <div className='item' onClick={() => { goInfo(item) }}>
        {type === 2 &&
            <div className='item-left'>
                <img src={item.head_img} onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} alt='head' />
            </div>
        }
        <div className='item-right' style={{ width: type === 2 ? '54vw' : '' }}>
            <div className='info ft_line1' style={{ marginBottom: 10 }}>
                {item.state === 1 && type === 1 && <span>展示中</span>}
                {type === 2 && item.nickname && <b className='ft14' style={{ color: '#e4d0a4', fontWeight: 500 }}>{item.nickname + '   '}</b>}
                {item.title}
            </div>
            <div className='addr flex flex_left_center'>
                {type === 1 ? '发布时间：' : '收藏时间：'}
                {item.ctime}
            </div>

        </div >
        {
            item.content_img.length > 0 && <div className='imgBox'>
                <img src={(item.content_img)[0]} alt=""
                    onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} />
            </div>
        }
        {
            type === 1 ?
                <div className='delbt' onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    delData(item.id)
                }}>删除</div>
                :
                <div className='delbt' onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    unCollect(item.id)
                }}>取消</div>
        }


    </div >
    return (
        <div>
            {ListItem}
        </div>
    );

}
export default Tab6Item;