/* eslint-disable eqeqeq */
import { Toast } from 'antd-mobile';
import React from 'react';

export class Positon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            location: '',
            src: ''
        }
    }
    componentDidMount() {
        // 选择地址时候
        this.setSrc();
        window.addEventListener('message', this.getPositinn, false);
    }
    setSrc = () => {
        let type = localStorage.isApp == 1 ? 1 : 3;
        this.bMapToQQMap(res => {
            let mapSrc = `https://apis.map.qq.com/tools/locpicker?search=1&type=1&${res}&coordtype=${type}&key=7V7BZ-UHZKO-TH5WM-SALFV-TQM4Z-YMB67&referer=myapp`;
            console.log(res, mapSrc)
            this.setState({
                src: mapSrc
            })
        })
    }
    // 百度地图坐标转换成腾讯地图坐标
    bMapToQQMap = (callback = () => { }) => {
        let coord = '';
        if (localStorage.lat && localStorage.lon) {
            coord = `coord=${localStorage.lat},${localStorage.lon}`;
        }
        callback(coord)
    }

    getPositinn = (event) => {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        let that = this;
        let loc = event.data;
        //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        if (loc && loc.module == 'locationPicker') {
            console.log('location', loc);
            if (loc.poiname == '我的位置') {
                loc.poiname = loc.poiaddress
            }
            that.setState({
                location: loc
            })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.getPositinn)
        // this.setMapZindex(-999)
        this.setState = () => {
            return
        }
    }
    // 选择地址确认按钮
    ok = () => {
        const { location } = this.state;
        if (location) {
            this.props.showPositionSet(location)
            let map = document.getElementById('mapPage1');
            if (map) {
                map.remove()
            }
        } else {
            Toast.info("请您选择地址")
        }
    }
    render() {
        const { location, src } = this.state
        return (
            <div className='positonSet'>
                <div className='topBox'>
                    <div className='top ft14'>
                        <div onClick={() => { this.props.showPositionSet(null) }}>不显示</div>
                        <div className='ft_bold ft18'>所在位置</div>
                        <div onClick={() => { this.ok() }} style={{ background: location ? '' : '#907c62' }} className='ok'>确定</div>
                    </div>
                </div>
                <div className='mapBox'>
                    {src ? <iframe
                        id="mapPage"
                        width="100%"
                        height="100%"
                        frameBorder='0'
                        scrolling="no"
                        src={src} /> : ''}


                </div>
            </div>
        )
    }
}
export default Positon;
