import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, ImagePicker, Toast } from 'antd-mobile';
import Api from './../../http/api';
import Photos from './../../components/Photos';
import Upload from '../../http/upload';
import '../../assets/style/mine.scss';
import { Control } from 'react-keeper';


class MinePhotos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hideAddImg: false,
            showManage: false,
            showBtn2: true,
            files: [],
            path: '/minePhoto',
            imgList: [],
            acceptType: 1,
            // acceptTypeImg:'video/*',
            // acceptTypeVideo:'image/gif,image/jpeg,image/jpg,image/png',

        }
    }
    componentDidMount() {
        this.minePhotos();
    }
    // 切换管理和上传
    addImg = (type = 1) => {
        const { hideAddImg, showBtn2 } = this.state;
        if (type === 2) {
            //  video
            this.setState({
                acceptType: 2
            })
        } else {
            // images 
            this.setState({
                acceptType: 1
            })
        }
        this.setState({
            hideAddImg: !hideAddImg,
            showBtn2: !showBtn2,
            files: []
        })


    }
    // 管理相册
    manage = () => {
        const { showManage, showBtn2 } = this.state;
        this.setState({
            showManage: !showManage,
            showBtn2: !showBtn2
        })
    }
    // 关闭管理相册
    manage2 = () => {
        this.setState({
            showManage: false,
            showBtn2: true
        })
    }


    // 我的相册
    minePhotos = () => {
        Api.mineAlbm({}).then(res => {
            console.log('我的相册', res)
            if (res.code === 200) {
                this.setState({
                    imgList: res.data
                })
            }

        })
    }
    // 上传图片到本项目服务器
    minePhotosUp = (imgs) => {
        let imgsBox = [];
        for (let i in imgs) {
            imgsBox.push('/' + imgs[i].path + '/' + imgs[i].name)
            if (imgsBox.length === imgs.length) {
                console.log('imgsBox:', imgsBox)
                Api.mineAlbmAdd({ img: imgsBox }).then(res => {
                    console.log('上传图片', res)
                    if (res.code === 200) {
                        Toast.success(res.msg, 1)
                        setTimeout(() => {
                            this.setState({
                                showManage: false,
                                hideAddImg: false,
                                showBtn2: true,
                                files: [],
                            }, () => {
                                this.minePhotos();
                            })
                        }, 1100);

                    }

                })
            }
        }


    }
    // 上传图片到阿里
    uploadImg = () => {
        const { files } = this.state;
        console.log(files);
        let upLoad = new Upload({
            files: files,
            path: 'minePhoto',
            onSuccess: (res) => {
                console.log('imgBoxs', res)
                this.minePhotosUp(res);
            },
            onFail: (err) => {
                console.log(err)
            }

        });
        try {
            upLoad.open()
        } catch (e) {
            console.log('err', e)

        }

    }
    // 删除照片
    delPhoto = (id, index) => {
        console.log(id, index)
        Api.mineAlbmDel({ album_id: id }).then(res => {
            console.log('删除照片', res)
            if (res.code == 200) {
                let imgArr = [...this.state.imgList];
                imgArr.splice(index, 1);
                this.setState({
                    imgList: imgArr
                })
            }

        })
    }

    onChange = (files, type, index) => {
        console.log(files, type, index);
        this.setState({
            files,
        });
    }

    render() {
        const { files, hideAddImg, imgList, showManage, showBtn2, acceptType } = this.state;
        const videoUrl = files.length > 0 ? URL.createObjectURL(files[0].file) : '';
        const videoBox = acceptType === 2 ? (files.length > 0 ?
            <div className='videoBox' style={{ textAlign: 'left', padding: '10px', }}>
                <video width='70%' height='auto' id="video" poster="" src={videoUrl}
                    controls="controls" x5-playsinline="" playsInline="" webkit-playsinline="true" controlsList='nodownload' disablePictureInPicture preload="auto"></video>
            </div>
            : <div className='color255 ft14 ft_bold'>选择视频</div>) : ''
        return (
            <div className='minePhotoPage'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                    rightContent={
                        <div>
                            <div key='0' style={{ display: hideAddImg ? 'none' : 'block' }} className='ft14 opacity7 color255' onClick={this.manage} >{showManage ? '完成' : '管理'}</div>
                            <div key='1' style={{ display: hideAddImg ? 'block' : 'none' }} className='ft14 opacity7 color255' onClick={this.addImg} >取消上传</div>
                        </div>

                    }

                >我的相册</NavBar>
                <WingBlank size='lg'>
                    <WhiteSpace size='lg' />
                    <WhiteSpace size='lg' />
                    <div>
                        <div hidden={hideAddImg} style={{ display: !hideAddImg ? 'none' : 'block' }}>
                            {videoBox}
                            <ImagePicker
                                style={{ display: (acceptType === 2 && files.length > 0) ? 'none' : 'block' }}
                                files={files}
                                onChange={this.onChange}
                                onImageClick={(index, fs) => console.log(index, fs)}
                                selectable={acceptType === 1 ? files.length < 9 : files.length < 1}
                                multiple={acceptType === 1 ? true : false}
                                disableDelete={acceptType === 1 ? false : true}
                                accept={acceptType === 1 ? 'image/gif,image/jpeg,image/jpg,image/png' : 'video/*'}
                            />
                            <div className='btn' onClick={() => { this.uploadImg() }}>立即上传</div>
                        </div>
                        <div hidden={hideAddImg} style={{ display: hideAddImg ? 'none' : 'block' }}>
                            <Photos pageType={'minePhoto'} showManage={showManage} list={imgList} delPhoto={this.delPhoto} manage={this.manage2} />
                        </div>
                        <div className='btn btn2' style={{ display: showBtn2 ? 'block' : 'none' }}
                            onClick={() => { this.addImg(1) }}>
                            上传照片
                        </div>
                        <div className='btn btn2 btn3' style={{ display: showBtn2 ? 'block' : 'none' }}
                            onClick={() => { this.addImg(2) }}>
                            上传视频
                        </div>

                    </div>
                </WingBlank >

            </div >
        )
    }
}
export default MinePhotos;
