import { Carousel, Icon, NavBar, Toast, WingBlank } from 'antd-mobile'
import html2canvas from 'html2canvas'
import React from 'react'
import { Control } from 'react-keeper'
import Api from '../../http/api'
import './../../assets/style/mine.scss'

//用户推广图
class MineInvite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myInvite: {
        share_code: '',
        qr_code: '',
      },
      imgHeight: 176,
      nowIndex: 0,
      inviteImgSrc: '',
      dotsShow: true, //指示点
      inviteImg: true, //邀请二维码是否正确
      inviteImgList: [], // 邀请图列表
    }
  }
  componentDidMount() {
    this.getInviteCode()
  }
  canvasImg = () => {
    this.setState(
      {
        dotsShow: false,
      },
      () => {
        Toast.loading('处理中', 30)
        let canvasEle = document.getElementById('html2canvasTest') //获取元素
        let that = this
        html2canvas(canvasEle, {
          width: canvasEle.offsetWidth, //设置canvas的宽度
          height: canvasEle.offsetHeight,
          scale: window.devicePixelRatio, //缩放
          useCORS: true,
          //   allowTaint: false,
        }).then(canvas => {
          Toast.loading('保存中', 3)
          let imgSrc = canvas.toDataURL()
          that.setState({
            inviteImgSrc: imgSrc,
            dotsShow: false,
          })
          if (localStorage.isApp == 1) {
            // app内
            that.imageDown(imgSrc)
          } else {
            // 非app
            if (imgSrc) {
              var a = document.createElement('a')
              a.download = 'InviteImg' + that.randomString(5) // 设置图片地址
              a.href = imgSrc
              a.click()
            }
          }
        })
      }
    )
  }
  // 获取邀请图
  getInviteCode = async () => {
    await Api.inviteImgs({}).then(res => {
      // console.log('邀请图', res)
      if (res.code === 200) {
        this.setState({
          inviteImgList: res?.data,
        })
      }
    })
    Api.invite({}).then(res => {
      // console.log('邀请码', res)
      console.log('邀请码', res.data.qr_code)
      if (res.code === 200) {
        this.setState({
          myInvite: res.data,
        })
      }
    })
    // 获取团队收益排行
    // Api.teamRank({}).then(res => {
    //     console.log('排行榜', res)
    //     if (res.code === 200) {
    //         this.setState({
    //             rank: res.data
    //         })
    //     }
    // })
  }
  imageDown = (base64, success, error) => {
    let that = this
    var bitmap = new window.plus.nativeObj.Bitmap('image')
    bitmap.loadBase64Data(
      base64,
      function () {
        that.bigmapToSave(bitmap, success, error)
      },
      function (err) {
        window.plus.nativeUI(err.message)
      }
    )
  }
  bigmapToSave = (bitmap, success, error) => {
    let that = this
    let name = 'InviteImg' + this.randomString(5)
    bitmap.save(
      `_doc/${name}.png`,
      { overwrite: false },
      function (event) {
        console.log(event.target)
        that.imageSaveByGallery(event.target, success, error)
      },
      function (err) {
        window.plus.nativeUI(err.message)
      }
    )
  }
  imageSaveByGallery = (url, success, error) => {
    window.plus.gallery.save(
      url,
      function () {
        Toast.success('已保存至相册')
        const tarbitmap = window.plus.nativeObj.Bitmap.getBitmapById('image')
        tarbitmap.clear()
        success()
      },
      function (err) {
        Toast.fail('保存失败: ' + err.message)
        window.plus.nativeUI(err.message)
        error()
      }
    )
  }
  randomString = e => {
    e = e || 32
    var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    var a = t.length
    var n = ''
    for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
    return n
  }
  downloadCodeImg = () => {
    const { myInvite, inviteImg } = this.state
    if (!inviteImg) {
      Toast.loading('邀请码二维码错误')
      return false
    } else if (!myInvite.qr_code) {
      Toast.loading('获取分享码失败')
      return false
    } else {
      this.canvasImg()
    }
  }
  render() {
    const { myInvite, nowIndex, inviteImgSrc, dotsShow, inviteImgList } =
      this.state
    return (
      <div className="mineInvitePage mineInviteAdPage">
        <div
          className="navBarBox"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: 'auto',
            zIndex: '999',
          }}
        >
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            推广素材
          </NavBar>
        </div>
        <div>
          <WingBlank size="lg" style={{ marginTop: 45 }}>
            <div className="inviteImgBox" id="html2canvasTest">
              <Carousel
                autoplay={false}
                selectedIndex={nowIndex}
                infinite
                beforeChange={(from, to) =>
                  console.log(`slide from ${from} to ${to}`)
                }
                afterChange={index => console.log('slide to', index)}
                dots={dotsShow}
              >
                {inviteImgList.map(val => (
                  <img
                    src={val.url}
                    key={val.url}
                    style={{
                      verticalAlign: 'top',
                      display: 'inline-block',
                      width: '100%',
                      height: 'auto',
                    }}
                    alt=""
                    // crossorigin="anonymous"
                    onLoad={() => {
                      // fire window resize event to change height
                      window.dispatchEvent(new Event('resize'))
                      // this.setImgHeight('auto');
                    }}
                    crossOrigin="anonymous"
                  />
                ))}
              </Carousel>
              <div
                className="inviteImg"
                style={{ display: myInvite.qr_code ? 'block' : 'none' }}
              >
                <img
                  src={myInvite.qr_code}
                  alt=""
                  onError={e => {
                    this.setState({ inviteImg: false })
                    console.log('图片地址错误')
                    e.target.onerror = null
                  }}
                  onLoad={e => {
                    console.log('正确加载', e)
                    this.setState({ inviteImg: true })
                  }}
                />
              </div>
            </div>
            {inviteImgSrc ? (
              <div className="inviteImgSrcBg">
                <img className="inviteImgSrc" src={inviteImgSrc} alt="邀请图" />
                <div
                  className="closeBtn"
                  onClick={() => {
                    this.setState({
                      inviteImgSrc: '',
                      dotsShow: true,
                    })
                  }}
                >
                  <Icon type="cross-circle-o" color="#f5dbbf"></Icon>
                </div>
                <div className="ft14 color255 opacity7 ts">
                  温馨提示: 若下载失败，请长按本图片保存
                </div>
              </div>
            ) : (
              <div
                className="saveInvite"
                onClick={this.downloadCodeImg}
                style={{
                  background:
                    myInvite.qr_code && this.state.inviteImg
                      ? 'linear-gradient(to right, #f5dbbf, #c89e78)'
                      : '#dcbcbc',
                }}
              >
                保存推广图
              </div>
            )}
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default MineInvite
