import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, List, Switch } from 'antd-mobile';
import Api from '../../http/api';
import "../../assets/style/mine_setting.scss";
import { Control } from 'react-keeper';

class MineSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            unlock_state: false,
            distance_show: false
        }
    }
    componentDidMount() {
        this.getMineInfo()
    }

    getMineInfo = () => {
        Api.getMineInfo({}).then(res => {
            console.log('我的资料详情', res)
            if (res.code == 200) {
                this.setState({
                    unlock_state: res.data.unlock_state,
                    distance_show: !res.data.distance_show
                })
            }
        })
    }
    // 社交申请
    ok = () => {
        const { unlock_state } = this.state;
        let parmas = { unlock_state: unlock_state ? 0 : 1 }
        Api.editInfo(parmas).then(res => {
            if (res.code === 200) {
                this.setState({
                    unlock_state: !unlock_state
                })
            }
        })
    }
    // 隐藏距离
    ok2 = () => {
        const { distance_show } = this.state;
        let parmas = { distance_show: !distance_show ? 0 : 1 }
        Api.editInfo(parmas).then(res => {
            if (res.code === 200) {
                this.setState({
                    distance_show: !distance_show
                })
            }
        })
    }

    render() {

        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >隐私设置</NavBar>
                <div className='mine-setting mine-setting-state'>
                    <WingBlank size='lg'>
                        <WhiteSpace size='lg' />
                        <List.Item
                            className='itemm'
                            extra={<Switch
                                checked={this.state.unlock_state}
                                color='#d2b793'
                                onChange={() => { this.ok() }}
                            />}
                        >社交账号 需向我申请</List.Item>
                        <WhiteSpace />
                        <List.Item
                            className='itemm'
                            extra={<Switch
                                checked={this.state.distance_show}
                                color='#d2b793'
                                onChange={() => { this.ok2() }}
                            />}
                        >位置距离 对他人隐藏我的距离</List.Item>

                    </WingBlank>
                </div>

            </div>
        )
    }
}
export default MineSetting;
