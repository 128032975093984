import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, Modal } from 'antd-mobile';
import "../../assets/style/mine.scss";
import Api from './../../http/api';
import { Control } from 'react-keeper';

class MinePayWay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bankInfo: [],
            hideDelBtn: true
        }
    }
    componentDidMount() {
        this.getMinePayInfo();
    }
    getMinePayInfo = () => {
        Api.minePayInfo({}).then(res => {
            console.log('银行卡信息：', res)
            if (res.code === 200) {
                console.log(res.data.length)
                if (res.data.length > 0) {
                    this.setState({
                        bankInfo: res.data
                    })
                } else {
                    Modal.alert('提示', '立即添加银行卡？', [
                        { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                        { text: '去绑定', onPress: () => { Control.go('/mine_add_bank') } },
                    ]);

                }

            }
        })
    }
    manage = () => {
        this.setState({
            hideDelBtn: !this.state.hideDelBtn
        })
    }
    delBank = (id) => {
        console.log(id)
        Modal.alert('提示', '立即删除此银行卡？', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '删除', onPress: () => {
                    Api.delBank({ bank_card_id: id }).then(res => {
                        console.log('删除银行卡：', res)
                        if (res.code === 200) {
                            this.setState({
                                bankInfo: [],
                                hideDelBtn: true
                            }, () => {
                                this.getMinePayInfo()
                            })
                        }
                    })

                }
            },
        ]);

    }
    setNum = (num) => {
        console.log(num)
        if (num) {
            num = String(num);
            return num.slice(0, 3) + ' **** **** ' + num.slice(-4)
        } else {
            return ' '
        }

    }
    render() {
        const { bankInfo } = this.state;
        const bankList = bankInfo.length > 0 ? bankInfo.map(item => {
            return (
                <div className='item' key={item.id}>
                    <div className='imgBox'>
                        <img src={require('../../assets/imgs/icon/bank.png').default} alt='' />
                    </div>
                    <div className='info'>
                        <div>银行 <b>{item.information.bank_name}</b> </div>
                        <div>姓名 <b>{item.information.name}</b></div>
                        <div>账号 <b>{this.setNum(item.information.card_number)}</b> </div>
                    </div>
                    <div className='delBtn' hidden={this.state.hideDelBtn} onClick={() => { this.delBank(item.id) }}>删除</div>
                </div>
            )
        }) : <div className='empty'>暂无银行卡</div>
        return (
            <div className='minePayWayPage'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                    rightContent={[
                        <div key="0" className='ft14 opacity7' hidden={this.state.bankInfo.length > 0} onClick={() => { Control.go('/mine_add_bank') }} >添加</div>,
                        <div key="1" className='ft14 opacity7' hidden={this.state.bankInfo.length <= 0} onClick={() => { this.manage() }} >管理</div>
                    ]}
                >银行卡</NavBar>
                <WingBlank size='lg'>
                    <WhiteSpace size='lg' />
                    <WhiteSpace size='lg' />
                    {bankList}
                    <div className='bz'>
                        {/* 注：支付宝(务必添加)、微信、银行卡，提交两种及以上收款方式才给予实名认证审核通过。 */}

                    </div>

                </WingBlank>

            </div>
        )
    }
}
export default MinePayWay;
