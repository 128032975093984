const OpenVip = (props) => {
    const { content, close, open } = props;
    console.log(content)

    return (
        <div className='publicNoticeBg'>
            <div className='publicNotice openVip'>
                <div className='box'>
                    <div className='openVipGo' onClick={() => { open() }}></div>
                </div>

                <div className='close' onClick={() => { close() }}>
                    <img src={require('./../assets/imgs/tab1/close.png').default} alt="" />
                </div>
            </div>
        </div>
    )
}
export default OpenVip;
