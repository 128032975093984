import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, Modal, Toast, Carousel } from 'antd-mobile';
import Api from '../../http/api';
import { Control } from 'react-keeper';




class MineRealHouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            manage: false,
            list: []
        }
    }
    componentDidMount() {
        let type = this.props.params.type;
        this.setState({ type }, () => {
            this.getList()
        })
        console.log('挂在完成')
    }

    getList = () => {
        const { type } = this.state;
        if (type == 1) {
            // 房屋列表
            Api.realHouseList({}).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.setState({
                        list: res.data
                    })
                }
            })
        } else if (type == 2) {
            // 车辆列表
            Api.realCarList({}).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.setState({
                        list: res.data
                    })
                }
            })
        } else if (type == 3) {
            // 视频列表
            Api.realVideo({}).then(res => {
                console.log(res)
                if (res.code == 200) {
                    if (res.data) {
                        this.setState({ list: [res.data] }, () => {
                            console.log(this.state.list)
                        })
                    }
                }
            })
        }


    }
    ok = () => {
        const { type } = this.state;
        if (type == 1) {
            Control.go('/mine_real_house')
        } else if (type == 2) {
            Control.go('/mine_real_car')
        } else if (type == 3) {
            Control.go('/mine_real3')
        }
    }
    del = (id) => {
        console.log(id)
        Modal.alert('提示', `确认删除？`, [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '删除', onPress: () => { this.delItem(id) } },
        ]);

    }
    delItem = (id) => {
        const { type } = this.state;
        if (type == 1) {
            // 删除房屋
            Api.realHouseDel({ id }).then(res => {
                if (res.code == 200) {
                    this.getList()
                }
            })
        } else if (type == 2) {
            // 删除车辆
            Api.realCarDel({ vehicle_id: id }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.getList()
                }
            })
        }


    }
    setType = (type) => {
        if (type == 0) {
            return '审核未通过'
        } else if (type == 1) {
            return '审核中'
        } if (type == 2) {
            return '已认证'
        }

    }
    imgList = (imgs) => imgs.map((val, index) => {
        return (
            <img key={val + index}
                src={val}
                alt=""
                style={{ width: '100%', verticalAlign: 'top' }}
                onLoad={() => {
                    // fire window resize event to change height
                    window.dispatchEvent(new Event('resize'));
                    this.setState({ imgHeight: 'auto' });
                }}
            />
        )
    })
    tishi = () => {
        const { type, list } = this.state;
        let content = ''
        if (list.length > 0) {
            return false
        }
        if (type == 1) {
            content = <span>认证后将在个人主页展示房产认证</span>
        } else if (type == 2) {
            content = <span>认证后将在个人主页展示车辆型号</span>
        } else if (type == 3) {
            content = <span>认证后将在个人主页展示人工视频认证</span>
        }

        return (
            <div className='tipssBox ft14 color255 les1' style={{}}>
                <div><i></i>  <span>真实度与实力的体现，在平台更受欢迎</span> </div>
                <div><i></i>   {content} </div>
                <div><i></i>  <span>认证后将会优先显示在首页</span> </div>
            </div>
        )
    }


    render() {
        const { type, list, manage } = this.state;
        const listItem = list.length > 0 ? list.map((item, index) => {
            return (
                <div className='imgsBox' key={index} style={{ marginBottom: 10 }}>
                    <div className='left' style={{ letterSpacing: 'normal' }}>
                        <div className='ft16 mb5'>{this.setType(item.state)}</div>
                        {(type == 2 && item.brand) ?
                            <div className='ft14 mb5'>{item.brand}</div>
                            : ''
                        }


                        <div className='ft14 color255 opacity7'>{item.date}</div>
                    </div>
                    <div className='right' style={{ overflow: 'hidden', background: '#222', borderRadius: '4px' }}>
                        <div style={{ dispaly: type != 3 ? 'block' : 'none' }}>
                            {
                                type != 3 ? <Carousel dots={false} autoplay={false} infinite>
                                    {this.imgList(type == 1 ? item.house_img : item.vehicle_img)}
                                </Carousel> : ''
                            }
                        </div>
                        <div style={{ dispaly: type == 3 ? 'block' : 'none', width: '100%', height: '100%', background: '#323238' }}>
                            {
                                type == 3 ? <video src={item.video_url} width='100%' height='100%' poster=""
                                    controls="controls" x5-playsinline="" playsInline="" webkit-playsinline="true" controlsList='nodownload' disablePictureInPicture preload="auto"></video> : ''
                            }

                        </div>
                    </div>
                    <div className='delBtn' style={{ display: manage && type != 3 ? 'block' : 'none' }} onClick={() => { this.del(item.id) }}>删除</div>
                </div>
            )
        }) : <div className='flex flex_center_center' style={{ marginTop: 100 }}>
            <img src={type == 3 ? require('./../../assets/imgs/real/QS_shipinrenz@2x.png').default : (type == 1 ? require('./../../assets/imgs/real/quesheng_house@2x.png').default : require('./../../assets/imgs/real/quesheng_car@2x.png').default)} alt="" width='60%' />

        </div>;
        return (
            <div className='login-page change_pwd_page mine_question_page mine_real_name_page'>
                <div className='navBoxBB'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                        rightContent={[
                            <div key="0" className='ft14 color255 opacity7' onClick={() => {
                                this.setState({ manage: !manage })
                            }} style={{ display: type != 3 ? 'block' : 'none' }} >管理</div>
                        ]}
                    >{type == 3 ? '视频认证' : (type == 1 ? '房产列表' : '车辆列表')}{list.length > 0 ? '(' + list.length + ')' : ''}</NavBar>
                </div>
                <WingBlank size='lg' className='login-content' style={{ marginTop: 55, paddingBottom: 150 }}>
                    {listItem}
                    {this.tishi()}

                    <div style={{
                        position: 'fixed', left: '15px',
                        right: '15px', bottom: '80px', zIndex: '10'
                    }}>
                        <Button className='login-btn change-btn' onClick={this.ok}>{type == 3 ? '视频认证' : (type == 1 ? '添加房产' : '添加车辆')}</Button>
                    </div>

                </WingBlank>
            </div >

        )
    }
}
export default MineRealHouse;

