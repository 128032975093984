import { Icon, NavBar, WingBlank } from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import '../../assets/style/mine_setting.scss'

class MineSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div>
        <div className="navBoxx">
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            用户协议
          </NavBar>
        </div>
        <div style={{ height: 45 }}></div>
        <div
          className="mine-setting mine-setting-state agreen color255 ft14 les1"
          style={{ userSelect: 'none' }}
        >
          <WingBlank size="lg">
            <h4 style={{ textIndent: 20 }}>
              欢迎使用简约，请您仔细阅读以下协议，如果您对本协议的任何条款有疑问或存在异议，您可以选择终止继续操作。当您进行注册、登录、使用等行为，我们默认您完全接受本协议项下的全部条款。
            </h4>

            <h4>1.服务须知</h4>
            <p>
              1.1
              注册成功简约账号后，因妥善保管账号及密码，并对其账号进行的所有活动和事件负法律责任。
            </p>
            <p>
              1.2
              用户可自行编辑个人信息中的账号名称、昵称、头像等，但应遵守相关法律法规，不得含有违法和不良信息，不得以他人或其他组织机构名义命名简约账号，亦不得使用引人误解的信息
            </p>
            <p>
              1.3
              简约官方对简约内容（即指简约用户在简约上已发布的信息，如冒泡、评论、破冰、组队等）享有使用权。
            </p>
            <p>
              1.4
              未经简约官方事先书面许可，用户不得抓取或协助任何第三方非法抓取简约内容，“非法抓取”是指采用程序或者非正常浏览等技术手段获取内容数据的行为，简约将移交有关司法执法机关处理。
            </p>
            <p>
              1.5
              用户不得以任何方式利用简约服务直接或间接从事违反中国法律以及社会公德的行为，简约有权对违反上述内容及用户予以删除并移交有关司法执法机关处理。
            </p>
            <p>1.6 用户不得利用简约服务发布、上传、复制、传播以下内容：</p>
            <p>1.6.1 违反中华人民共和国法律法规或监管政策</p>
            <p>1.6.2 发布或传播任何非法的信息</p>
            <p>
              1.6.3
              侵犯任何个人、企业事业单位或社会团体的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等
            </p>
            <p>
              1.7
              简约官方及授权账号有权对用户使用简约服务的行为及信息进行审核及处理，包括但不限于账号信息、个人信息、文字、图片、视频、冒泡投诉等范围。
            </p>
            <p>
              1.8
              如遇司法机构、监管机构要求或第三方合理请求，简约官方有权对用户账户及信息进行搜集、处置。
            </p>
            <p>
              1.9
              简约官方保留因业务发展需要，单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销服务的权利，用户需承担此风险。
            </p>
            <p>
              1.10
              简约官方提供的服务中可能包括广告，用户同意在使用过程中显示简约官方以及第三方合作者提供的广告。
            </p>

            <h4>2.知识产权</h4>
            <p>2.1 简约方是简约及简约产品的所有权及知识产权权利人。</p>
            <p>
              2.2
              用户在简约上发布的全部合法原创内容，著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到简约的同意。
            </p>
            <p>
              2.3
              简约有权但无义务对用户发布的内容进行审核或删除，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规对侵权信息进行处理。
            </p>

            <h4>3.隐私保护</h4>
            <p>
              {' '}
              为保护简约用户隐私权，提供更安全的互联网环境，简约将依据《中华人民共和国网络安全法》以及相关法规和政策对您的个人信息和其它数据进行严格保密，详情请参照《简约隐私条款》。
            </p>

            <h4>4.免责声明</h4>
            <p>
              4.1
              用户在简约发布的内容仅表明其个人的立场和观点，并不代表简约的立场或观点。作为内容的发布者，需自行对所发布内容负责，因所发布内容引发的一切纠纷，由该内容的发布者承担全部法律及连带责任，简约不承担任何法律及连带责任。
            </p>
            <p>
              4.2
              对于因不可抗力或简约不能控制的原因造成的网络服务中断或其它缺陷，简约不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
            </p>

            <h4>5.协议修改</h4>
            <p>
              根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，简约有权跟随国家法律法规及行业动态随时修订本规则总章，不作另行通知，并遵循板块管理规定追溯过往违规内容。
            </p>

            <h4>6.其他</h4>
            <p>
              6.1 本《用户协议》任何条款的部分或全部无效者，不影响其它条款的效力。
            </p>
            <p>
              6.2 本《用户协议》签订地为长沙。本《用户协议》的解释、效力及纠纷的解决，适用于中华人民共和国法律。用户和简约之间的任何争议，首先应友好协商解决，协商不成的，用户在此完全同意将交由简约住所地即长沙市有管辖权的人民法院管辖。
            </p>

            <p> 最终解释权归简约所有</p>
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default MineSetting
