import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, Button, InputItem, Picker, List, ImagePicker, Toast, TextareaItem } from 'antd-mobile';
import "../../assets/style/mine_setting.scss";
import Api from '../../http/api';
import Upload from '../../http/upload';
import { Control } from 'react-keeper';
import Positon from '../../components/Positon';

class MineChangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],//选择图片容器

            imgBox: [],//补充图片地址
            configList: [], //配置

            valueList: [], //选择的值
            item_id: '', //项目id

            location: '',
            showPosition: false,

            name: '',

            title: ''

        }

    }
    componentDidMount() {
        let id = this.props.params.id;
        this.setState({
            item_id: id
        })
        console.log(id)
        this.getBranchConfig(id);
    }
    getBranchConfig = (id) => {
        Api.getBranchConfig({ item_id: id }).then(res => {
            console.log('约会配置', res)
            if (res.code == 200) {
                let item = {
                    id: 0,
                    item_id: 0,
                    msg: "",
                    title: "约会说明"
                }
                res.data.push(item);
                let arr = {};

                res.data.forEach(item => {
                    arr[item.title] = '';

                    if (typeof (item.content) == 'object') {
                        let arr = []
                        item.content.forEach(it => {
                            arr.push({ label: it, value: it })
                        })
                        item.contentArr = arr;
                    } else {
                        item.contentArr = item.content;
                    }


                })
                this.setState({
                    configList: res.data,
                    valueList: arr
                })

            }
        })
    }
    handleChange = (name, e, type) => {
        console.log(name, e)
        let obj = '';
        obj = Object.assign({}, this.state.valueList, { [name]: type === 1 ? e : [e] });
        this.setState({ valueList: obj }, () => {
            console.log(this.state.valueList)
        })
    }
    onChange1 = (files, type, index) => {
        console.log(files, type, index);
        this.setState({
            files,
        }, () => {
            if (files.length > 0) {
                this.uploadImg();
            }

        });
    }
    // 上传图片到阿里
    uploadImg = () => {
        const { files } = this.state;
        console.log(files);
        let upLoad = new Upload({
            files: files,
            path: 'mineInfo/headImg/' + localStorage.mineId || '',
            onSuccess: (res) => {
                console.log('头像地址：', res)
                let arr = []
                res.forEach(item => {
                    arr.push(item.pathName)
                })
                this.setState({
                    imgBox: arr
                })
            },
            onFail: (err) => {
                Toast.fail('头像上传失败，请重试', 3)
                this.setState({
                    files: []
                })
                console.log(err)
            }

        });
        try {
            upLoad.open()
        } catch (e) {
            console.log('err', e)

        }

    }
    ok = () => {
        const { imgBox, valueList, item_id, title } = this.state;
        let flag = true;

        for (let item in valueList) {
            if (!valueList[item] || !valueList[item][0]) {
                flag = false;
                Toast.loading('请完善: ' + item)
                break;
            }
        }
        if (!title) {
            Toast.loading('请完善: 简介')
            return false;
        }
        if (flag) {
            let params = {
                branch_info: valueList,
                title: title,
                content_img: imgBox,
                item_id: item_id
            }
            console.log(imgBox, valueList, params)

            Api.sendData(params).then(res => {
                console.log(res)
                if (res.code == 200) {
                    Toast.success(res.msg)
                    setTimeout(() => {
                        Control.go(-1)
                    }, 2000);
                }
            })


        }
    }
    showPositionSet = (location) => {
        const { showPosition, name } = this.state;
        console.log(location)
        this.handleChange(name, location ? location.poiname : '', 2)
        this.setState({
            location,
            showPosition: !showPosition
        })
    }
    render() {
        const { configList, files, showPosition } = this.state;
        const itemList = configList.length > 0 ? configList.map((item, index) => {
            return (
                <div key={'item' + index}>
                    {typeof (item.content) == 'object' ?
                        <div className='itemBox'>
                            <Picker
                                data={item.contentArr}
                                cols={1}
                                extra='请选择'
                                value={this.state.valueList[item.title]}
                                onOk={v => { this.handleChange(item.title, v, 1) }}
                            >
                                <List.Item arrow="horizontal" > {item.title} </List.Item>
                            </Picker>
                        </div> :


                        // ['地址', '地点', '位置'].indexOf(item.title) !== -1 ?
                        //     <div className='item' onClick={() => { this.setState({ name: item.title, showPosition: true }) }}>
                        //         <InputItem
                        //             className='input-box'
                        //             value={this.state.valueList[item.title]}
                        //             type="text"
                        //             placeholder={'请选择'}
                        //         // extra={<Icon type='right' />}
                        //         >
                        //             {item.title}
                        //         </InputItem>
                        //     </div>
                        //     :
                        <div className='item'>
                            <InputItem
                                className='input-box'
                                value={this.state.valueList[item.title]}
                                type="text"
                                placeholder={'请填写'}
                                onChange={(e) => { this.handleChange(item.title, e, 2) }}
                            // extra={<Icon type='right' />}
                            >
                                {item.title}
                            </InputItem>
                        </div>


                    }
                </div>
            )
        }) : '';



        return (
            <div className='mineChangeInfoPage editPage sendData'>
                <div className='navBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >发布约会</NavBar>
                </div>
                <WhiteSpace />
                <div className='content'>
                    {itemList}
                    <div className='item ex'>
                        <div className='titlee'>约会简介</div>
                        <TextareaItem
                            placeholder="请填写约会简介"
                            ref={el => this.autoFocusInst = el}
                            rows='3'
                            onChange={(e) => { this.setState({ title: e }) }}
                        />
                    </div>
                    <div className='item ex'>
                        <div className='titlee'>图片说明 <small>(选填)</small> </div>
                        <ImagePicker
                            style={{ width: '100%' }}
                            files={files}
                            length={4}
                            onChange={this.onChange1}
                            onImageClick={(index, fs) => console.log(index, fs)}
                            selectable={files.length < 8}
                            multiple={true}
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                        />
                    </div>
                    <Button className='btn' onClick={this.ok}>确认发布</Button>
                </div>
                {showPosition ? <Positon showPosition={showPosition} showPositionSet={this.showPositionSet} /> : ''}


            </div >

        )
    }
}
export default MineChangeInfo;
