/* eslint-disable eqeqeq */
import Api from "./api";
import { Modal, Toast } from 'antd-mobile';


let appVersion = window.global_config.version;
let plus = window.plus;


/** 
 * post方法，对应post请求 
 * @param {Number} type [下载类型] 1  点击下载  2 检测更新下载
 */
export function CheckUpData(type, callBack = () => { }) {
    if (type == 2) {
        Toast.loading('检测更新中', 60 * 1000)
    }

    type = type || 1;
    console.log('下载操作类型 1直接下载 2检测更新下载 3直接下载 无下载地址时提示', type);
    Api.updata({ type: Number(localStorage.platform) }).then(res => {
        console.log('app版本', res);
        if (type == 2) {
            Toast.hide();
        }
        if (res.data.length == 0) {
            callBack(true)
            return false;
        }
        console.log('app下载更新网络版本', res.data[0].num);
        if (res.code == 200 && res.data[0].url) {
            //更新下载
            let url = '';
            let newVersion = res.data[0].num;
            if (localStorage.isApp == 1 && res.data[0].wgt) {
                url = res.data[0].wgt;
            } else {
                url = res.data[0].url;
            }
            if (type == 2) {
                if (Number(appVersion) * 10 < Number(newVersion) * 10) {
                    const alertInstance = Modal.alert('更新提示', '发现新版本,立即下载？', [
                        { text: '取消', onPress: () => { callBack(true) }, style: 'default' },
                        { text: '下载', onPress: () => { down(url); callBack(false) } },
                    ]);
                } else {
                    console.log('版本相同')
                    callBack(true)
                }
            } else {
                down(res.data[0].url)
            }
        } else {
            if (type == 3) {
                Toast.info('App开发中，敬请期待')
            }
            console.log('暂无安装包')
        }
    })

}
export function down(url) {
    if (localStorage.isApp == 1) {
        if (localStorage.platform == 1) { // 安卓
            downWgt(url)
        } else if (localStorage.platform == 2) { // ios
            window.plus.runtime.openURL(url);
        }
    } else {
        // window.open(url);
        window.location.href = url;
        console.log(window.plus)
        if (window.plus && window.plus.runtime) {
            window.plus.runtime.openURL(url);
        }

    }

}
/** 
 * post方法，对应post请求 
 * @param {String} wgtUrl [请求的url地址] 
 */
export function downWgt(wgtUrl) {
    window.plus.nativeUI.showWaiting("更新资源下载中");
    window.plus.downloader
        .createDownload(
            wgtUrl,
            {
                filename: "_doc/update/",
            },
            function (d, status) {
                if (status == 200) {
                    console.log("下载更新文件成功：" + d.filename);
                    installWgt(d.filename); //安装wgt包
                } else {
                    console.log("下载失败！");
                    window.plus.nativeUI.alert("下载失败！");
                    window.plus.nativeUI.alert(JSON.stringify(d));
                    window.plus.nativeUI.alert(status);
                }
                window.plus.nativeUI.closeWaiting();
            }
        )
        .start();
}
/** 
 * post方法，对应post请求 
 * @param {String} path [下载安装的url地址] 
 */
function installWgt(path) {
    window.plus.nativeUI.showWaiting("安装更新中");
    window.plus.runtime.install(
        path,
        { force: true },
        function () {
            window.plus.nativeUI.closeWaiting();
            console.log("安装更新文件成功！");
            window.plus.nativeUI.alert("更新完成！", function () {
                window.plus.runtime.restart();
            });
        },
        function (e) {
            window.plus.nativeUI.closeWaiting();
            console.log("安装更新文件失败[" + e.code + "]：" + e.message);
            window.plus.nativeUI.alert("安装更新文件失败[" + e.code + "]：" + e.message);
            if (e.code == 10) {
                alert("请清除临时目录");
            }
        }
    );
}
