/* eslint-disable eqeqeq */

import React from 'react'
import PublicNotice from './../components/PublicNotice'
import Tabbar from './../components/Tabbar'

import OpenVip from './../components/OpenVip'
import Tab1Item from './../components/Tab1_item'

import {
  ListView,
  NoticeBar,
  Picker,
  PullToRefresh,
  Tabs,
  Toast,
  WingBlank,
} from 'antd-mobile'
import { Control } from 'react-keeper'
import '../assets/style/tab1.scss'
import Api from './../http/api'
import { getAddress, getPosition } from './../http/common'

import copy from 'copy-to-clipboard'

class Tab1 extends React.Component {
  constructor(props) {
    super(props)
    const ds = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2,
    })
    this.state = {
      sort: 'distance',
      refreshing: true,
      page: 1, //页码
      limit: 20, // 每页条数
      dataSource: ds, //长列表加载数据源
      billData: [], //每页数据
      preBillData: [], //原始的订单列表
      allBillData: [], //原始的+每一页新的数据
      isLoading: false, //是否加载中
      isHasMore: true, //是否还有更多数据
      cols: 2,
      asyncValue: [],
      data: [],
      city_id: '',
      jtType: false,
      publicNotice: '',
      showOpenLuckPub: false,
      showTopNotice: true, //是否显示顶部通知
    }
  }
  // componentWillReceiveProps(props) {
  //     // console.log(props)
  //     if (props.pathname == '/tab1') {
  //         Api.changeCity({})
  //         this.checkReal();
  //     }
  // }
  componentDidUpdate() {
    if (this.props.pathname == '/tab1') {
      // Api.changeCity({})
      this.checkReal()
    }
  }
  checkReal = () => {
    if (!localStorage.real || JSON.parse(localStorage.real).face != 1) {
      this.checkRealState()
    }
  }
  // 检测是否进行真人认证
  checkRealState = () => {
    if (localStorage.sex == 2) {
      Api.realState({}).then(res => {
        if (res.code === 200) {
          // 真人认证检测
          localStorage.real = JSON.stringify(res.data)
          if (res.data.face != 1) {
            Toast.loading('请真人认证', 3)
            setTimeout(() => {
              Control.go(`/mine_real2`)
            }, 3000)
          }
        }
      })
    }
  }
  componentDidMount() {
    console.log('挂在完成')
    if (!localStorage.sex && localStorage.sex != 0) {
      this.changeSex()
    }
    getPosition(res => {
      this.getList()
      getAddress()
    })
    this.getProvince()

    this.checkReal()
    this.getPublicNotice()
    // setTimeout(() => {
    //   this.checkVip()
    // }, 3000)
  }
  // 检测是否是vip
  checkVip = () => {
    let isVip
    if (localStorage.userInfo) {
      isVip = JSON.parse(localStorage.userInfo).is_vip
      if (!isVip) {
        this.setState({
          showOpenLuckPub: true,
        })
      }
    }
  }
  closeOpenVip = () => {
    this.setState({
      showOpenLuckPub: false,
    })
  }

  onRefresh = () => {
    console.log('刷新')
    const { sort, limit, city_id, allBillData } = this.state
    let emptyList = allBillData.splice(0, allBillData.length)
    getPosition(res => {
      getAddress()
      this.setState(
        {
          refreshing: true,
          isLoading: true,
          page: 1,
          dataSource: this.state.dataSource.cloneWithRows(emptyList),
        },
        () => {
          Api.userList({
            sort: sort,
            limit: limit,
            page: 1,
            city_id: city_id,
            sex: localStorage.sex == 1 ? 2 : 1,
          }).then(res => {
            if (res.code == 200) {
              let allbi = this.state.preBillData.concat(res.data)
              this.setState(
                {
                  billData: res.data,
                  preBillData: allbi,
                  allBillData: allbi,
                  page: 2,
                  dataSource: this.state.dataSource.cloneWithRows(allbi),
                  isHasMore: res.data.length > 0 ? true : false,
                  isLoading: false,
                  refreshing: false,
                },
                () => {
                  // this.listView.scrollTo(0, -60);
                }
              )
            }
            window.scrollTo(0, 0)
          })
        }
      )
    })
  }

  componentWillUnmount() {
    localStorage.tab1State = this.state
  }
  changeSex = () => {
    Api.getInfoCast({ info_cast: 1 }).then(res => {
      console.log('性别', res)
      if (res.code == 200) {
        localStorage.sex = res.data.sex
        this.getList()
      }
    })
  }
  // 省
  getProvince = () => {
    Api.province({}).then(res => {
      // console.log('省列表：', res)
      if (res.code === 200) {
        let provinces = ''
        provinces = res.data
        provinces.forEach(item => {
          item.label = item.name
          item.value = item.id
        })
        this.setState({ data: provinces })
      }
    })
  }

  getList = () => {
    const { sort, limit, page, isHasMore, city_id } = this.state
    if (isHasMore) {
      Api.userList({
        sort: sort,
        limit: limit,
        page: page,
        city_id: city_id,
        sex: localStorage.sex == 1 ? 2 : 1,
      }).then(res => {
        // console.log(res)
        if (res.code === 200) {
          let allbi = this.state.preBillData.concat(res.data)
          if (this.state.page == 1) {
            window.scrollTo(0, 0)
          }
          this.setState({
            billData: res.data,
            preBillData: allbi,
            allBillData: allbi,
            page: Number(this.state.page) + 1,
            dataSource: this.state.dataSource.cloneWithRows(allbi),
            isHasMore: res.data.length > 0 ? true : false,
            isLoading: false,
          })
        }
      })
    }
  }
  goInfo = item => {
    Control.go(`/user_info/${item.id}/0`)
  }
  changeTab = tab => {
    // console.log(tab)
    // localStorage.tab1 = tab.sort;
    const { allBillData } = this.state
    let emptyList = allBillData.splice(0, allBillData.length)
    this.setState(
      {
        sort: tab.sort,
        page: 1, //页码变为1
        isHasMore: true,
        isLoading: false,
        dataSource: this.state.dataSource.cloneWithRows(emptyList), //传入一个空数组
      },
      () => {
        this.listView.scrollTo(0, -60)
        this.getList()
      }
    )
  }
  followss = item => {
    console.log(item)
    let params = {
      fid: item.id,
    }
    if (!item.is_follow) {
      Api.follow(params).then(res => {
        // console.log(res)
        if (res.code == 200) {
          //eslint-disable-line
          item.is_follow = 1
          console.log(this.state.dataSource)
        }
      })
    } else {
      Api.unFollow(params).then(res => {
        // console.log(res)
        if (res.code == 200) {
          //eslint-disable-line
          item.is_follow = 0
        }
      })
    }
  }
  onEndReached = () => {
    const { isHasMore } = this.state
    if (isHasMore) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.getList()
        }
      )
    } else {
      this.setState({
        isLoading: false,
        isHasMore: false,
      })
    }
  }
  row = (item, sectionID, rowID) => {
    return (
      <div key={item.id}>
        <Tab1Item
          item={item}
          goInfo={e => {
            this.goInfo(e)
          }}
          followss={e => {
            this.followss(e)
          }}
        />
      </div>
    )
  }
  // 选择城市每一项改变
  onPickerChange = val => {
    console.log(val)
    const { cols } = this.state
    let colNum = cols
    let d = [...this.state.data]
    let asyncValue = [...val]
    Api.city({ region_id: val }).then(citys => {
      console.log('市级列表：', citys)
      if (citys.code === 200) {
        let city2 = [...citys.data]
        city2.forEach(item => {
          item.label = item.name
          item.value = item.id
        })
        d.forEach((item, index) => {
          if (item.value === val[0]) {
            console.log(index)
            if (!item.children) {
              item.children = [...city2]
              colNum = 2
              asyncValue.push(item.children[0].value)
            }
          }
        })
        this.setState({
          data: d,
          cols: colNum,
          asyncValue,
        })
      }
    })
    // console.log('hh ', '2')
    this.setState({
      data: d,
      cols: colNum,
      asyncValue,
    })
  }
  // 城市改变
  onChange = v => {
    const { allBillData, asyncValue } = this.state
    console.log('onChange', v, asyncValue)
    let city_id = asyncValue[asyncValue.length - 1]
    let emptyList = allBillData.splice(0, allBillData.length)
    this.setState(
      {
        page: 1, //页码变为1
        isHasMore: true,
        isLoading: false,
        city_id: city_id,
        dataSource: this.state.dataSource.cloneWithRows(emptyList), //传入一个空数组
      },
      () => {
        this.listView.scrollTo(0, -60)
        this.getList()
        Api.changeCity({})
      }
    )
  }
  // 显示隐藏城市选择
  onVisibleChange = v => {
    console.log(v)
    this.setState({
      jtType: v,
    })
  }
  // 取消选择城市
  onDismiss = () => {
    this.setState({
      asyncValue: [],
    })
  }
  getPublicNotice = () => {
    Api.publicNotice({}).then(res => {
      console.log('公告：', res)
      if (res.code == 200) {
        this.setState({
          publicNotice: res.data,
        })
      }
    })
  }
  closePublicNotice = () => {
    console.log('关闭公告')
    this.setState({
      publicNotice: '',
    })
  }
  copyBtn = code => {
    if (code) {
      copy(code)
      Toast.success('复制成功')
    } else {
      Toast.info('无复制内容')
    }
  }

  render() {
    const {
      isHasMore,
      isLoading,
      billData,
      data,
      cols,
      jtType,
      publicNotice,
      showOpenLuckPub,
      showTopNotice,
    } = this.state
    const sex = localStorage.sex
    const tabs = [
      // { title: '全部', sort: 'distance' },
      { title: '附近优先', sort: 'distance' },
      { title: '在线优先', sort: 'online' },
      { title: '新人优先', sort: 'new_people' },
      { title: sex == 1 ? '关注度高' : '关注度高', sort: 'top' },
    ]
    const CustomChildren = props => (
      <div
        onClick={props.onClick}
        style={{ backgroundColor: '#222', fontSize: '12px' }}
      >
        <div
          className="test"
          style={{ display: 'flex', height: '42px', lineHeight: '42px' }}
        >
          {/* <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.children}
                    </div> */}
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              width: '100%',
              justifyContent: 'center',
              marginRight: 0,
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            <img
              src={require('../assets/imgs/icon/icon_dingwei.png').default}
              alt=""
              width="20px"
            />
            {props.extra}
            {/* <Icon type={jtType ? 'up' : 'down'} size='xxs' /> */}
            <img
              style={{ transform: `rotateZ( ${jtType ? '-180deg' : '0'})` }}
              src={require('./../assets/imgs/icon/down.png').default}
              width="22"
              alt=""
            />
          </div>
        </div>
      </div>
    )

    return (
      <div className="tab1">
        {showTopNotice && (
          <NoticeBar
            onClick={() => {
              this.setState({ showTopNotice: false })
            }}
            mode="closable"
            marqueeProps={{ loop: true, style: { padding: '0 5px' } }}
          >
            简约官网永久域名:"52jyo.com",防走丢,请保存
            <span
              style={{
                marginLeft: '5px',
                padding: '1px 5px',
                borderRadius: '4px',
                background: '#666',
              }}
              onClick={() => {
                this.copyBtn('52jyo.com')
              }}
            >
              复制
            </span>
          </NoticeBar>
        )}
        <div
          className="position"
          style={{ top: showTopNotice ? '35px' : '0px' }}
        >
          <Picker
            data={data}
            cols={cols}
            value={this.state.asyncValue}
            onPickerChange={this.onPickerChange}
            cascade={true}
            onChange={this.onChange}
            extra={localStorage.city ? localStorage.city : '附近'}
            onVisibleChange={this.onVisibleChange}
            onDismiss={this.onChange}
            dismissText=" "
          >
            <CustomChildren />
          </Picker>
        </div>
        <div className="tabs" style={{ top: showTopNotice ? '75px' : '40px' }}>
          <Tabs
            tabs={tabs}
            tabBarBackgroundColor="#222"
            tabBarActiveTextColor="#eccda4"
            tabBarInactiveTextColor="#8f8f8f"
            onTabClick={tab => {
              this.changeTab(tab)
            }}
            // initialPage={this.state.sort}
            // page={this.state.sort}
            renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4} />}
          />
        </div>
        <div className="tab1-content" style={{ position: 'relative' }}>
          {/* <WhiteSpace size='lg' /> */}
          <WingBlank size="lg">
            <ListView
              ref={el => (this.listView = el)}
              dataSource={this.state.dataSource}
              renderFooter={() => (
                <div
                  style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}
                >
                  {isHasMore &&
                    (isLoading ? (
                      <span className="list_loading">加载中...</span>
                    ) : billData.length === 0 ? (
                      '加载中...'
                    ) : (
                      '已加载'
                    ))}
                  {billData.length === 0 && !isHasMore ? '没有更多了' : ''}
                </div>
              )}
              renderRow={this.row} //每行数据渲染
              style={{
                minHeight: 'calc(100vh - 20px)', //高度需要定义
                overflow: 'auto',
                paddingTop: '15',
              }}
              // useBodyScroll
              pageSize={20} //一次渲染几条数据
              initialListSize={20}
              onEndReached={this.onEndReached} //加载新的数据
              onEndReachedThreshold={100} //距离底部40时候 加载数据
              pullToRefresh={
                <PullToRefresh
                  refreshing={this.state.refreshing}
                  onRefresh={this.onRefresh}
                />
              }
            />
          </WingBlank>
        </div>
        {publicNotice && publicNotice.content && (
          <PublicNotice
            content={publicNotice.content}
            closePublicNotice={this.closePublicNotice}
          />
        )}

        {showOpenLuckPub && (
          <OpenVip
            open={() => {
              Control.go('/mine_luck')
              this.closeOpenVip()
            }}
            close={this.closeOpenVip}
          />
        )}
      </div>
    )
  }
}

export default Tabbar(Tab1)
