/* eslint-disable eqeqeq */
import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, Modal, Toast } from 'antd-mobile';
import Api from '../../http/api';
import Upload from '../../http/upload';
import Step6 from "../../components/Step6";
import { upImg } from '../../http/common';
import { Control } from 'react-keeper';

class MineReal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // step1
            step: 0,
            renZhengInfo: '',//认证验证配置
            renZhengMsg: '等待识别结果',//认证结果信息
            renZhengMsg2: '面容识别中...',

            file1: [],//认证头像
            upLoadImg: '',
            realState: 1,

        }
    }
    componentDidMount() {
        console.log('挂在完成')
    }
    // 认证配置
    renZhen = () => {
        if (localStorage.platform == 2) {
            var winRef = window.open(" ", "_blank");
        }
        Api.renzhen({
            meta_info: localStorage.metaInfo
        }).then(res => {
            console.log('认证配置', res)
            if (res.code == 200) {
                this.setState({
                    renZhengInfo: res.data,
                    step: 2
                }, () => {
                    // setTimeout(() => {
                    //     this.renZhenResultF();
                    // }, 10 * 1000);
                    if (localStorage.isApp == 1) {
                        window.plus.runtime.openURL(res.data.src);
                    } else {
                        if (localStorage.platform == 2) {
                            // window.location = res.data.src  //本页打开
                            winRef.location = res.data.src;   //新窗口打开
                        } else {
                            window.open(res.data.src, '_blank')
                        }

                    }
                })
            }
        })
    }
    //认证结果
    renZhenResultF = () => {
        const { renZhengInfo } = this.state;
        if (renZhengInfo) {
            Api.renzhenResult({
                certify_id: renZhengInfo.certify_id
            }).then(res => {
                console.log('认证结果', res)
                if (res.code == 200) {
                    if (res.data.state == 1) {
                        this.setState({
                            renZhengMsg2: '认证发起中:  请进行面容识别',
                            renZhengMsg: res.msg,
                            realState: 1,
                        })
                    } else if (res.data.state == 2) {
                        this.setState({
                            renZhengMsg2: '真人检测中，请等待',
                            renZhengMsg: res.msg,
                            realState: 2
                        })
                    } else if (res.data.state == 3) {
                        this.setState({
                            renZhengMsg2: '认证失败:  真人检测失败',
                            renZhengMsg: res.msg,
                            realState: 3,
                            step: 3
                        })
                    } else if (res.data.state == 4) {
                        this.setState({
                            renZhengMsg2: '恭喜您,认证成功',
                            step: 3,
                            renZhengMsg: res.msg,
                            realState: 4
                        })
                    } else if (res.data.state == 5) {
                        this.setState({
                            renZhengMsg2: '认证失败： 不是同一人，请您重试',
                            renZhengMsg: res.msg,
                            realState: 5,
                            step: 3
                        })
                    }
                }
            })
        }

    }
    handleChange = (name, value) => {
        console.log(name, value);
        this.setState({
            [name]: value
        })
    }
    nextStep = () => {
        const { file1, step } = this.state;
        if (step === 0) {
            this.setState({
                step: 1
            })

        } else if (step === 1) {
            if (file1.length > 0) {
                upImg(file1[0], res => {
                    console.log(res)
                    if (res) {
                        Api.editInfo({ head_img: res[0] }).then(res => {
                            console.log('修改资料', res)
                            if (res.code === 200) {
                                Modal.alert('提示', '即将跳转面容识别，结束后请返回本页', [
                                    {
                                        text: '面容识别', onPress: () => {
                                            this.renZhen();
                                        }
                                    },
                                ]);
                            }
                        })
                    } else {

                    }
                })
                // this.uploadImg(file1, 'real', res => {
                //     
                // })
            } else {
                Toast.fail('请先上传照片')
            }

        }

    }

    // step1   人脸识别自动上传
    onChange1 = (file1, type, index) => {
        console.log('step1', file1, type, index);
        this.setState({ file1 });
    }
    // step1   上传照片
    uploadImg = (files, pathName, callback = () => { }) => {
        console.log(files, pathName)
        let upLoad = new Upload({
            files: files,
            path: pathName,
            onSuccess: (res) => {
                console.log(res)
                callback(res)
            },
            onFail: (err) => {
                console.log(err)
                callback(false)
            }
        })
        upLoad.open()
    }
    reSet = () => {
        this.setState({
            step: 1,
            renZhengInfo: '',//认证验证配置
            renZhengMsg: '等待识别结果',//认证结果信息
            renZhengMsg2: '面容识别中...',
            file1: [],//认证头像
            upLoadImg: '',
            realState: 1,
        })
    }



    render() {
        const { step, renZhengMsg2, renZhengMsg, realState } = this.state
        return (
            <div className='login-page  mine_real_name_page mine_real_page'>
                <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 'auto', height: 45, zIndex: 99 }}>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    > {step === 0 ? '认证须知' : '真人认证'} </NavBar>
                </div>
                <div style={{ height: 45 }}></div>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='md' className='login-content'>
                    <div className='rzInfo' style={{ display: step === 0 ? 'block' : 'none' }}>
                        <div className='itm'>
                            <div className='title'>
                                <img src={require('./../../assets/imgs/real/icon_title_1@2x.png').default} alt="" />
                            </div>
                            <p>真人认证是平台通过面容识别技术对相册照片进行本人真实性鉴定的功能，通过认证后将会对照片贴上“本人” 标签，个人主页则会“本人”标识。
                            </p>
                            <p>女性用户只有通过真人认证，才能出现在男性的交友列表上，以及才能主动跟男性用户发起私聊。</p>
                        </div>
                        <div className='itm'>
                            <div className='title'>
                                <img src={require('./../../assets/imgs/real/icon_title_2@2x.png').default} alt="" />
                            </div>
                            <p>面容识别照片模糊、黑暗、有遮挡、角度不对，遇到这 种情况可更换面容后再进行认证。
                            </p>
                            <p>选择的认证照片面部不清晰、有遮挡，或者属于远距离拍摄，无法辨别是否为本人。</p>
                            <p>美颜过度的照片也很容易认证失败，建议使用正脸清晰的高清生活照，通过率高达99%。</p>
                        </div>
                    </div>
                    <div className='stepBox' style={{ display: step != 0 ? 'flex' : 'none' }}>
                        <div className={step === 1 ? 'act' : ''}>上传照片</div>
                        <div className={step === 2 ? 'act' : ''}>面容识别</div>
                        <div className={step === 3 ? 'act' : ''}>认证结果</div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='stepContentBox'>
                        <div style={{ display: step === 1 ? 'block' : 'none' }}>
                            <Step6 state={this.state} handleChange={this.handleChange} onChange1={this.onChange1} />
                        </div>
                        <div className='rzResult' style={{ display: (step === 2 || step === 3) ? 'flex' : 'none' }}>
                            <img width='80' style={{ display: realState != 4 ? 'block' : 'none' }} src={require('./../../assets/imgs/real/icon_shibai@2x.png').default}></img>
                            <img width='80' style={{ display: realState == 4 ? 'block' : 'none' }} src={require('./../../assets/imgs/real/icon_chenggong@2x.png').default}></img>
                            <div className='ft16' style={{ color: '#fff', fontWeight: '500' }}>{renZhengMsg2}</div>
                            {/* <div className='ft14'>{renZhengMsg}</div> */}
                        </div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='btnsBox' style={{ display: (step === 1 || step === 0) ? 'block' : 'none' }} >
                        <Button className='login-btn' style={{ width: '100%' }} onClick={this.nextStep}>
                            {step === 0 ? '立即认证' : '下一步'}
                        </Button>
                    </div>
                    <div className='btnsBox' style={{ display: step === 2 ? 'block' : 'none' }} >
                        <Button className='login-btn' style={{ width: '100%' }} onClick={() => {
                            this.renZhenResultF()
                        }}>
                            查询认证结果
                        </Button>
                    </div>
                    <div className='btnsBox' style={{ display: step === 3 && realState != 4 ? 'block' : 'none' }} >
                        <Button className='login-btn' style={{ width: '100%' }} onClick={this.reSet}>
                            重新认证
                        </Button>
                    </div>
                    <div className='btnsBox' style={{ display: step === 3 && realState == 4 ? 'block' : 'none' }} >
                        <Button className='login-btn' style={{ width: '100%' }}>
                            认证成功
                        </Button>
                    </div>

                    <div style={{ height: 80 }}></div>
                </WingBlank >



            </div >

        )
    }
}
export default MineReal;

