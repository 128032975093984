import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/style/global.scss';
import App from './App';




ReactDOM.render(
  <App />, document.getElementById('root')
);
