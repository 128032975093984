import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, Button, DatePicker, InputItem, Picker, List, ImagePicker, Toast, TextareaItem, Tabs } from 'antd-mobile';
import "../../assets/style/mine_setting.scss";
import Api from '../../http/api';
import Upload from '../../http/upload';
import { Control } from 'react-keeper';

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
const minDate = new Date(nowTimeStamp - 60 * 60 * 24 * 365 * 70 * 1000);
const maxDate = new Date(nowTimeStamp - 60 * 60 * 24 * 365 * 18 * 1000);

class MineChangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: 2,
            asyncValue: [],
            data: [],

            type: 0,
            files: [],//头像
            date: '',//生日
            jobList: '',
            tipList: [],
            job: [],
            index: 0,
            about_me: [],
            head: '',


            info: {
                nickname: "",
                age: '',
                height: '',
                weight: '',
                education: '',

                about_me: [],
                birthday: '',

                emotion: '',
                head_img: "",
                occupation: '',

                region_name: '',
            },


        }

    }
    componentDidMount() {
        this.getMineInfo();
        let type = this.props.params.name;
        if (type == 8) {
            this.getProvince()
        } else if (type == 9) {
            this.getTips();
        } else if (type == 11) {
            this.getJobs();
        }

        this.setState({ type: Number(type) })
    }
    getMineInfo = () => {
        Api.getMineInfo({}).then(res => {
            console.log('我的资料详情', res)
            if (res.code == 200) {
                let date = ''
                if (res.data.birthday) {
                    date = new Date(res.data.birthday);
                }
                this.setState({
                    info: res.data,
                    date,
                    files: [{ url: res.data.head_img, netPath: '' }],
                    about_me: res.data.about_me,
                    asyncValue: [res.data.often_city],
                })

            }
            console.log(this.state)
        })

    }
    getJobs = () => {
        Api.job({}).then(res => {
            console.log('职业列表', res)
            if (res.code === 200) {
                this.setState({
                    jobList: res.data
                })
            }
        })
    }
    getTips = () => {
        Api.getInfoCast({ info_cast: 1 }).then(res => {
            if (res.code === 200) {
                Api.tip({ sex: res.data.sex }).then(res => {
                    console.log('个性列表', res)
                    if (res.code === 200) {
                        this.setState({
                            tipList: res.data
                        })
                    }
                })
            }
        })
    }
    // 获取省市
    getProvince = () => {
        Api.province({}).then(res => {
            console.log('省列表：', res)
            if (res.code === 200) {
                let provinces = '';
                provinces = res.data;
                provinces.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                })
                this.setState({ data: provinces })
            }
        })
    }
    // 选择城市每一项改变
    onPickerChange = (val) => {
        console.log(val);
        const { cols } = this.state;
        let colNum = cols;
        let d = [...this.state.data];

        let asyncValue = [...val];
        Api.city({ region_id: val }).then(citys => {
            console.log('市级列表：', citys)
            if (citys.code === 200) {
                let city2 = [...citys.data];
                city2.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                })
                d.forEach((item, index) => {
                    if (item.value === val[0]) {
                        console.log(index)
                        if (!item.children) {
                            item.children = [...city2]
                            colNum = 2;
                            asyncValue.push(item.children[0].value, item.children[0].label)
                        }
                    }
                })
                this.setState({
                    data: d,
                    cols: colNum,
                    asyncValue,
                });
            }
        })
        console.log('hh ', '2')
        // this.setState({
        //     data: d,
        //     cols: colNum,
        //     asyncValue,
        // });

    };
    // 城市改变
    // onChange = (v) => {
    //     const { asyncValue } = this.state;
    //     console.log('onChange', v, asyncValue)
    // }
    // 显示隐藏城市选择
    onVisibleChange = (v) => {
        console.log(v)
        const { data } = this.state;
        if (v && !this.state.asyncValue) {
            this.setState({
                asyncValue: [data[0].value]
            })
        }

    }
    // 取消选择城市
    onDismiss = () => {
        console.log('取消')
        this.setState({
            asyncValue: [this.state.info.often_city]
        })
    }

    handleChange = (name, e) => {
        console.log(name, e)
        let obj = '';
        if (name == 'date') {
            this.setState({ date: e })
            obj = Object.assign({}, this.state.info, { birthday: e });
        } else if (name == 'age' || name == 'height' || name == 'weight' || name == 'education') {
            obj = Object.assign({}, this.state.info, { [name]: e[0] });
        } else if (name == 'occupation') {
            obj = Object.assign({}, this.state.info, { occupation: e[1] });
            this.setState({ job: e[0] })
        } else {
            obj = Object.assign({}, this.state.info, { [name]: e });
        }

        this.setState({ info: obj }, () => {
            // console.log(this.state.info)
        })

    }
    onChange1 = (files, type, index) => {
        console.log(files, type, index);
        this.setState({
            files,
        }, () => {
            if (files.length > 0) {
                this.uploadImg();
            }

        });
    }
    // 上传图片到阿里
    uploadImg = () => {
        const { files } = this.state;
        console.log(files);
        let upLoad = new Upload({
            files: files,
            path: 'mineInfo/headImg/' + localStorage.mineId || '',
            onSuccess: (res) => {
                console.log('头像地址：', res)
                this.setState({
                    head: res[0].pathName
                })
            },
            onFail: (err) => {
                Toast.fail('头像上传失败，请重试', 3)
                this.setState({
                    files: []
                })
                console.log(err)
            }

        });
        try {
            upLoad.open()
        } catch (e) {
            console.log('err', e)

        }

    }
    toGo = (name, nameE, value) => {
        console.log(name, nameE, value)
    }
    addTips = (tip) => {
        const { about_me } = this.state;
        let tips = [...about_me];
        let index = tips.indexOf(tip);
        console.log(index)
        if (index == -1) {
            if (about_me.length > 9) {
                Toast.info('最多十个标签')
                return false;
            }
            tips.push(tip)
        } else {
            tips.splice(index, 1)
        }
        this.setState({
            about_me: tips
        })
    }
    ok = () => {

        const { info, about_me, date, files, asyncValue, head, type } = this.state;

        if (type == 1) {
            this.editHeadImg()
        } else {

            let parmas = info;
            parmas.about_me = about_me;
            console.log(asyncValue, info.often_city)
            if (asyncValue.length > 0) {
                parmas.often_city = asyncValue[asyncValue.length - 1]
            }
            console.log(info.often_city)

            if (date) {
                parmas.birthday = date;
            }
            // if (head) {
            //     parmas.head_img = head
            // }
            // console.log(parmas)

            delete parmas.head_img;

            Api.editInfo(parmas).then(res => {
                console.log('修改资料', res)
                if (res.code === 200) {
                    Toast.success(res.msg, 2)
                    setTimeout(() => {
                        Control.go(-1)
                    }, 2000);
                }
            })
        }

    }

    editHeadImg = () => {
        const { head } = this.state;
        console.log('修改头像', head)
        if (head) {
            Api.editHeadImg({ head_img: head }).then(res => {
                if(res.code==200){
                    Toast.success(res.msg, 2)
                    setTimeout(() => {
                        Control.go(-1)
                    }, 2000);
                }
            })
        } else {
            Toast.fail('请上传头像')
        }



    }


    render() {
        const { files, type, jobList, tipList, about_me, data, cols } = this.state;
        // 年龄
        let ageArr = []
        for (let i = 18; i < 60; i++) {
            ageArr.push({
                label: i + '岁',
                value: i
            })
        }
        // 身高
        let arr2 = [];
        for (let j = 150; j < 220; j++) {
            arr2.push({
                label: j + 'cm',
                value: j
            })
        }
        // 体重
        let arr3 = []
        for (let i = 35; i < 100; i++) {
            arr3.push({
                label: i + 'kg',
                value: i
            })
        }

        let arr4 = []
        for (let i = 0; i < jobList.length; i++) {
            let item = {
                label: jobList[i].title,
                value: jobList[i].title,
            }
            item.children = []
            jobList[i].info.forEach(it => {
                item.children.push({
                    label: it.title,
                    value: it.title
                })
            });
            arr4.push(item)
        }

        let arr5 = [
            { label: '初中', value: '初中' },
            { label: '高中', value: '高中' },
            { label: '大专', value: '大专' },
            { label: '本科', value: '本科' },
            { label: '硕士', value: '硕士' },
            { label: '博士', value: '博士' },
        ]


        return (
            <div className='mineChangeInfoPage editPage'>
                <div className='navBoxBB'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                        rightContent={
                            <div key='0' className='ft14 opacity7 color255' onClick={this.ok} >保存</div>
                        }
                    >修改资料</NavBar>
                </div>
                <div style={{ height: 55 }}></div>
                <WhiteSpace />
                <div className='itemBox' style={{ display: type == 1 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >头像  <small className='opacity7'>(真人认证用户,需本人照片设为头像)</small> </div>

                    <div className='item' style={{ padding: '5px 15px' }}>
                        <div className='left'> 头像 </div>
                        <div className='right'>
                            <div className='headBox'>
                                <ImagePicker
                                    style={{ width: '100%' }}
                                    files={files}
                                    length={1}
                                    onChange={this.onChange1}
                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    selectable={files.length < 1}
                                    multiple={false}
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='itemBox' style={{ display: type == 2 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >昵称</div>
                    <div className='item'>
                        <InputItem
                            className='input-box'
                            value={this.state.info.nickname}
                            type="text"
                            placeholder="昵称"
                            onChange={(e) => { this.handleChange('nickname', e) }} />
                    </div>
                </div>

                <div className='itemBox' style={{ display: type == 4 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >年龄</div>
                    <Picker
                        data={ageArr}
                        cols={1}
                        value={[this.state.info.age]}
                        extra='去完善'
                        onOk={v => { this.handleChange('age', v) }} >
                        <List.Item arrow="horizontal" > 年龄 </List.Item>
                    </Picker>
                </div>

                <div className='itemBox' style={{ display: type == 5 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' > 身高</div>
                    <Picker
                        data={arr2}
                        cols={1}
                        extra='去完善'
                        value={[this.state.info.height]}
                        onOk={v => { this.handleChange('height', v) }} >
                        <List.Item arrow="horizontal" > 身高 </List.Item>
                    </Picker>
                </div>

                <div className='itemBox' style={{ display: type == 6 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >体重</div>
                    <Picker
                        data={arr3}
                        cols={1}
                        extra='去完善'
                        value={[this.state.info.weight]}
                        onOk={v => { this.handleChange('weight', v) }} >
                        <List.Item arrow="horizontal" > 体重 </List.Item>
                    </Picker>
                </div>

                <div className='itemBox' style={{ display: type == 12 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >学历</div>
                    <Picker
                        data={arr5}
                        cols={1}
                        extra='去完善'
                        value={[this.state.info.education]}
                        onOk={v => { this.handleChange('education', v) }} >
                        <List.Item arrow="horizontal" > 学历 </List.Item>
                    </Picker>
                </div>

                <div className='itemBox' style={{ display: type == 7 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >生日</div>
                    <DatePicker
                        className='input-box'
                        mode="date"
                        extra="请选择"
                        value={this.state.date}
                        onChange={v => this.handleChange('date', v)}
                        maxDate={maxDate}
                        minDate={minDate}
                    >
                        <List.Item arrow="horizontal">生日</List.Item>
                    </DatePicker>
                </div>
                <div className='itemBox' style={{ display: type == 8 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >常出没</div>
                    <Picker
                        data={data}
                        cols={cols}
                        value={this.state.asyncValue}
                        onPickerChange={this.onPickerChange}
                        cascade={true}
                        onChange={this.onChange}
                        extra={this.state.info.often_city || '请选择'}
                        onVisibleChange={this.onVisibleChange}
                        onDismiss={this.onDismiss}
                    >
                        <List.Item arrow="horizontal" > 常出没 </List.Item>
                    </Picker>
                </div>
                <div className='itemBox' style={{ display: type == 11 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >职业</div>
                    <Picker
                        data={arr4}
                        cols={2}
                        value={[this.state.job, this.state.info.occupation]}
                        cascade={true}
                        extra='去完善'
                        onOk={v => { this.handleChange('occupation', v) }}>
                        <List.Item arrow="horizontal" > 职业 </List.Item>
                    </Picker>
                </div>
                <div className='itemBox' style={{ display: type == 9 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >个性标签</div>
                    <div className='item item2'>
                        {about_me.map((item, index) => {
                            return (
                                <span key={item + index}>{item} <b onClick={() => {
                                    this.addTips(item)
                                }}>×</b></span>
                            )
                        })}
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                    </div>
                </div>
                <div className='itemBox' style={{ display: type == 10 ? 'block' : 'none' }}>
                    <div className='title ft15 color255' >自我简介</div>
                    <div className='item' >
                        <TextareaItem
                            className='input-box'
                            value={this.state.info.brief}
                            type="text"
                            rows='4'
                            placeholder="自我简介"
                            onChange={(e) => { this.handleChange('brief', e) }} />
                    </div>
                </div>
                <div className='tipBoxBg' style={{ display: type == 9 ? 'block' : 'none' }}>
                    <Tabs
                        className='tipTabs'
                        tabs={tipList}
                        initialPage={0}
                        tabBarBackgroundColor='none'
                        tabBarActiveTextColor='#f6dfc1'
                        tabBarInactiveTextColor='#c7bfbf'
                        tabBarUnderlineStyle={{ border: '1px #f6dfc1 solid' }}
                        onTabClick={
                            (tab, index) => { this.setState({ index }) }}
                    />
                    <div className='tipBox'>{tipList.length > 0 ? tipList[this.state.index].info.map(item => {
                        return (
                            <span className={about_me.indexOf(item.title) != -1 ? 'act' : ''} onClick={() => { this.addTips(item.title) }} key={item.title}>{item.title}</span>
                        )
                    }) : ''}
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                    </div>

                </div>
            </div>
        )
    }
}
export default MineChangeInfo;
