/* eslint no-dupe-keys: 0, no-mixed-operators: 0 */
import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, ListView } from 'antd-mobile';
import Api from '../../http/api';
import { Control } from 'react-keeper';

import "../../assets/style/mine_like.scss";

class MineZan extends Component {
    constructor() {
        super();
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });

        this.state = {
            tab: localStorage.tabZan ? localStorage.tabZan : 0,

            page: 1, //页码
            limit: 10, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false
            })
        }
    }


    changeTab = (tab) => {
        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            tab: tab,
            page: 1, //页码变为1
            isHasMore: true,
            isLoading: false,
            dataSource: this.state.dataSource.cloneWithRows(emptyList) //传入一个空数组
        }, () => {
            localStorage.tabZan = tab;
            this.listView.scrollTo(0, 0);
            this.getList();
        })
    }
    componentDidMount() {
        this.getList();

    }
    getList = () => {
        const { tab, page, limit } = this.state;
        let params = {
            type: tab,
            page: page,
            limit: limit
        }
        Api.dongTaiLikeList(params).then(res => {
            console.log('点赞列表', tab, res)
            if (res.code === 200) {
                let allbi = this.state.preBillData.concat(res.data);
                this.setState({
                    billData: res.data,
                    preBillData: allbi,
                    allBillData: allbi,
                    page: Number(this.state.page) + 1,
                    dataSource: this.state.dataSource.cloneWithRows(allbi),
                    isHasMore: res.data.length > 0 ? true : false,
                    isLoading: false
                })
            }
        })

    }
    goInfo = (id, id2) => {
        const { tab } = this.state;
        console.log(tab, id, id2)
        if (tab === 0) {
            Control.go('/user_info/' + id+'/0')
        } else {
            Control.go('/user_info/' + id2+'/0')
        }

    }
    row = (item, sectionID, rowID) => {
        return (
            <div className='item' key={rowID}>
                <div className='item-left' onClick={() => { this.goInfo(item.uid, item.author_id) }} style={{ backgroundImage: `url(${item.head_img}),url(${require('./../../assets/imgs/icon/bgImg.jpg').default}`, backgroundSize: 'cover' }}>
                    {/* <img src={item.head_img} alt='' /> */}
                </div>
                <div className='item-right'>
                    <div className='flex felx_left_center name-box'>
                        <div>{item.nickname}</div>
                    </div>
                    <div className='ft12'>{item.ctime}</div>
                </div>
                <div className='item-img item-img2 color999' style={{ display: (item.img || item.video) ? 'flex' : 'none', borderRadius: '4px', background: 'none' }} >
                    <img src={item.img} alt='' style={{ display: item.video ? 'none' : 'flex' }} />
                    <video width='100%' height='100%' src={item.video} style={{ display: item.video ? 'block' : 'none' }}></video>
                </div>
                <div className='color255 ft12 ft_line2 opacity7' style={{ display: (!item.img && !item.video) && item.content ? '-webkit-box' : 'none', width: '30%', height: '42px', WebkitLineClamp: 2, padding: '3px 6px', borderRadius: 4, background: '#333', lineHeight: '20px', overflow: 'hidden', position: 'absolute', right: 0, left: 'auto' }}>
                    {(!item.img && !item.video) ? item.content : ''}
                </div>
            </div>
        )
    }
    render() {
        const { isHasMore, isLoading, billData } = this.state;
        return (
            <div className='likePage'>
                <div style={{
                    position: 'fixed', top: '0', left: 0,
                    right: 0, bottom: 'auto', zIndex: 999
                }}>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >
                        <div className='tabs'>
                            <div className={this.state.tab == 0 ? 'active' : ''} onClick={() => { this.changeTab(0) }}>
                                Ta赞了我
                            </div>
                            <div className={this.state.tab == 1 ? 'active' : ''} onClick={() => { this.changeTab(1) }}>
                                我赞了Ta
                            </div>
                        </div>
                    </NavBar>
                </div>
                <WingBlank size='lg'>
                    <WhiteSpace size='lg' />
                    <div style={{ height: 25 }}></div>
                    <div>
                        <ListView
                            ref={el => this.listView = el}
                            dataSource={this.state.dataSource}
                            renderFooter={() => (
                                <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                    {
                                        isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                    }
                                    {
                                        (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                    }

                                </div>
                            )}
                            renderRow={this.row} //每行数据渲染
                            style={{
                                minHeight: 'calc(100vh - 20px)', //高度需要定义
                                overflow: 'auto',
                                paddingTop: '15px'
                            }}
                            pageSize={10}  //一次渲染几条数据
                            initialListSize={10}
                            onEndReached={this.onEndReached}//加载新的数据
                            onEndReachedThreshold={500}//距离底部40时候 加载数据
                        />

                        {/* {listItem} */}
                    </div>
                </WingBlank>

            </div>
        )
    }
}
export default MineZan;
