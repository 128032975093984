/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank } from 'antd-mobile';
import '../../assets/style/user_info.scss';
import Photos from '../../components/Photos';
import Api from '../../http/api';
import { Control } from 'react-keeper';

class Data_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgList: [],//用户相册
            friendInfo: [],//初始本资料
            friendInfoNew: {},//基本详细资料

            itemType: 'userInfo',

            dataInfo: [],


        }
    }
    componentDidMount() {
        // console.log(this.props.params.id);
        console.log(this.props.params.id)
        window.scrollTo(0, 0)
        this.setState({
            friendInfo: { id: this.props.params.id }
        }, () => {
            this.getUserInfo()
        })
        if (sessionStorage.dataInfo) {
            console.log(JSON.parse(sessionStorage.dataInfo))
            let dataInfo = JSON.parse(sessionStorage.dataInfo)
            let arr = [];
            if (dataInfo.content_img.length > 0) {
                (dataInfo.content_img).forEach(it => {
                    let img = {
                        img: it,
                    }
                    arr.push(img)
                })
            }

            dataInfo.imgArr = arr;

            let arr2 = [{ title: '简介', value: dataInfo.title }];
            for (let it in dataInfo.branch_info) {
                console.log(it)
                arr2.push({ title: it, value: dataInfo.branch_info[it][0] })
            }

            this.setState({
                imgList: dataInfo.imgArr,
                dataInfo: arr2
            })
        }

    }
    getUserInfo = (type = 0) => {
        console.log(type)
        let params = { uid: this.state.friendInfo.id }
        Api.userInfo(params).then(res => {
            console.log('用户信息', res)
            if (res.code == 200) { //eslint-disable-line 
                this.setState({
                    friendInfoNew: res.data,
                    // imgList: res.data.album
                })
            }
        })

    }


    setJuli = (item) => {
        if (!item && item !== 0) {
            return '';
        } else if (item === 0) {
            return '0m';

        } else {
            item = Number(item)
            if (item > 1000) {
                return (item / 1000).toFixed(2) + 'km'
            } else {
                return item + 'm'
            }
        }
    }

    render() {
        const { friendInfoNew, dataInfo } = this.state;
        return (
            <div className='user-info-page u-i dataInfo'>
                {/* navbar */}
                <div style={{ height: 'auto', zIndex: '888', overflow: 'hidden', backgroundColor: '#222', width: '100%' }} id='head'>
                    <div className='navBox'>
                        <NavBar
                            mode="dark"
                            icon={<Icon type="left" />}
                            onLeftClick={() => {
                                // Control.go(-1)
                                Control.go(-1);
                            }}
                            className='nav'
                        />
                    </div>
                    {/* 头部 */}
                    <div className='head' style={{ backgroundImage: `url(${friendInfoNew.head_img})` }}>
                        <div className='topBox'>
                            <WingBlank size='lg' className='userName'>
                                <div className='rz flex flex_column flex_column_left_center'>
                                    {friendInfoNew.brand ?
                                        <div style={{ marginBottom: '5px', display: 'flex' }} className='rzItem'>
                                            <div className='tip' style={{ background: '#4f4ee0' }}>车辆认证</div>
                                            <div>人工车辆审核为{friendInfoNew.brand}</div>
                                        </div>
                                        : ''
                                    }
                                    <div className='rzItem' style={{ marginBottom: '5px', display: (friendInfoNew.face_album === 1 || friendInfoNew.video == 1) ? 'flex' : 'none' }}>
                                        <div className='tip tip2'>相册认证</div>
                                        <div>Ai面容识别照片为本人</div>
                                    </div>
                                    <div style={{ marginBottom: '5px', display: (friendInfoNew.real_head == 1 || friendInfoNew.video == 1) ? 'flex' : 'none' }} className='rzItem'>
                                        <div className='tip'>头像认证</div>
                                        <div>
                                            {friendInfoNew.real_head == 1 ? 'Ai面容识别' : ''}
                                            {(friendInfoNew.real_head == 1 && friendInfoNew.video == 1) ? '+' : ''}
                                            {friendInfoNew.video == 1 ? '人工视频审核' : '头像'}
                                            为本人
                                        </div>
                                    </div>

                                </div>
                            </WingBlank>
                        </div>
                    </div>
                    {/* 资料概括 */}
                    <WingBlank size='lg'>
                        <div className='user-info'>
                            <div className='name flex flex_left_center' style={{ color: friendInfoNew.is_vip === 1 ? '#eccda4' : '' }}>
                                {friendInfoNew.nickname}
                                <img src={require('../../assets/imgs/icon/vip2.png').default} alt=''
                                    style={{ display: friendInfoNew.is_vip === 1 ? 'flex' : 'none' }} width='26px' />
                                <span className='flex_center_center'>
                                    <img src={require('../../assets/imgs/icon/icon_dingwei.png').default} width='15px' alt='' /> {this.setJuli(friendInfoNew.distance)}
                                </span>

                            </div>
                            <WhiteSpace />
                            <div className='rz2'>
                                <span hidden={!friendInfoNew.age}>{friendInfoNew.age}岁</span>
                                <span hidden={!friendInfoNew.occupation}>{friendInfoNew.occupation}</span>
                                <span hidden={!friendInfoNew.region_name}>{friendInfoNew.region_name}</span>
                                <span hidden={!friendInfoNew.off_line}>{friendInfoNew.off_line}</span>
                            </div>
                        </div>
                    </WingBlank>
                </div>
                {/* tabs 内容 */}
                <WingBlank size='lg'>
                    <div className='userInfoBox'>
                        <div className='ft16 ft_bold  mb10 ' style={{ color: '#efcdaf', marginTop: 40, fontWeight: 600 }}>
                            约会信息</div>
                        <div className='userInfoList'>
                            {dataInfo.map(item => {
                                return (
                                    <div key={item.title}>
                                        <b>{item.title}</b>
                                        <span>{item.value}</span>
                                    </div>
                                )
                            })}
                        </div>
                        {this.state.imgList.length > 0 && <div>
                            <div className='ft15  mb10  les1' style={{ color: '#efcdaf', marginTop: 12, fontWeight: 500 }}>
                                补充说明</div>
                            <Photos list={this.state.imgList} photoType={'abc'} />
                        </div>
                        }

                        <WhiteSpace size='lg' />
                    </div>
                </WingBlank>
            </div >

        )
    }
}
export default Data_info;
