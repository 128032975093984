/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { TextareaItem, ImagePicker } from 'antd-mobile';
import Emotion from "./Emotion/index";

import Record from "./Record";

import { AudioOutline } from 'antd-mobile-icons'


class SendKey extends Component {
    constructor(porps) {
        super(porps)
        this.state = {
            showRecordBtn: false,   //是否显示录音
        }
    }
    // componentDidMount() {

    // }
    handleEmotion = (e) => {
        this.props.handleEmotion(e)
    }


    checkEnter = (e) => {
        var et = e || window.event;
        var keycode = et.charCode || et.keyCode;
        if (keycode == 13) {
            if (window.event) {
                window.event.returnValue = false;
            } else {
                e.preventDefault(); //for firefox
            }
            this.props.sendMsg()
        }
    }
    showRecordBtn = () => {
        const { showRecordBtn } = this.state;
        this.setState({
            showRecordBtn: !showRecordBtn
        }, () => {
            if (!this.state.showRecordBtn) {
                this.inputRef.focus()
            }
        })
    }

    render() {
        const { showRecordBtn } = this.state; //键盘切换
        const { canRecordInput } = this.props; //是否可以语音输入
        return (
            <div>
                {showRecordBtn ? <Record showRecordBtn={this.showRecordBtn} sendMsg3={this.props.sendMsg3} /> :
                    <div className='inputBoxBoxx'>
                        <div className='inputBox'>
                            {/* 语音输入 */}
                            {canRecordInput &&
                                <div className='inputBtn' onClick={this.showRecordBtn}>
                                    <AudioOutline color='#dcca9e' fontSize={27} />
                                </div>
                            }
                            {/* 表情输入 */}
                            <div className='inputBtn' onClick={this.props.changeShowInputImg}>
                                <img src={require('./../assets/imgs/chat/img.jpg').default} alt='' />
                            </div>
                            {/* 图片输入 */}
                            <div className='inputBtn'>
                                <img src={require('./../assets/imgs/chat/add.jpg').default} alt='' />
                                <div className='imgpic'>
                                    <ImagePicker
                                        style={{ width: '100%', opacity: 0 }}
                                        files={this.props.file1}
                                        length={1}
                                        onChange={this.props.onChange1}
                                        onImageClick={(index, fs) => console.log(index, fs)}
                                        selectable={this.props.file1.length < 1}
                                        multiple={false}
                                        accept="image/gif,image/jpeg,image/jpg,image/png"
                                    />
                                </div>

                            </div>
                            {/* 文字输入 */}
                            <div className='inputBoxBox'>
                                <TextareaItem
                                    className='input'
                                    value={this.props.text}
                                    type="text"
                                    placeholder=""
                                    name='text'
                                    onChange={this.props.setText}
                                    autoHeight
                                    onKeyDown={e => { this.checkEnter(e) }}
                                    enterkeyhint='send'
                                    ref={el => this.inputRef = el}
                                />
                            </div>
                            <div className='sendBtn' onClick={this.props.sendMsg}>发送</div>
                        </div>

                        <div className='inputImgsBox' style={{ height: this.props.showInputImg ? '200px' : '0px' }}>
                            <Emotion handleEmotion={this.handleEmotion} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default SendKey;
