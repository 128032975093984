import React, { Component } from "react";
import { NavBar, Icon, WhiteSpace, WingBlank, Toast } from "antd-mobile";
import "../../assets/style/user_info.scss";
import Api from "../../http/api";
import { Control } from "react-keeper";
import copy from "copy-to-clipboard";

class MineVip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moneyList: [], //充值金额类别
      moneyNow: null, //选中的金额
      moneySelf: 0, //自定义的金额
      payList: [
        // { name: '支付宝', id: 1, img: require('../../assets/imgs/vip/icon_alipay1.png').default },
        // { name: '微  信', id: 2, img: require('../../assets/imgs/vip/icon_wechat1.png').default },
        // { name: '客服代充', id: 3, img: require('../../assets/imgs/vip/icon_kefu1.png').default },
        // { name: '后台充值', id: 4, img: require('../../assets/imgs/vip/icon_kefu1.png').default },
        // { name: '银联充值', id: 5, img: require('../../assets/imgs/vip/icon_unionpay.png').default },
      ], //支付方式
      payWay: [], //选择的支付方式
      payUrl: "", // 微信支付跳转地址
    };
  }
  componentDidMount() {
    this.getPayWayInfo();
  }
  getPayWayInfo = () => {
    Api.payWayInfo({}).then((res) => {
      console.log("支付方式配置", res);
      if (res.code === 200) {
        let payW = res.data.filter((item) => item.status === 1);
        this.setState(
          {
            payList: payW,
          },
          () => {
            this.setPayWay(this.state.payList[0]);
          }
        );
      }
    });
  };
  goRecharge = (type, money) => {
    console.log(type, money);
    if (money === "自定义") {
      money = this.state.moneySelf;
    }
    if (money <= 0) {
      Toast.fail("请您输入金额");
      return false;
    }

    let params = {
      title: this.state.payWay.title,
      pay_id: Number(type),
      type: Number(type),
      money: Number(money),
      ip: JSON.parse(localStorage.ip),
    };
    console.log(params);
    // return false;
    Api.payWay(params).then((res) => {
      console.log("支付地址", decodeURIComponent(res.data));

      if (localStorage.platform == 2) {
        // ios
        var winRef = window.open(" ", "_blank");
      }
      if (res.code == 200 && res.data) {
        let url = decodeURIComponent(res.data);
        if (localStorage.isApp == 1) {
          window.plus.runtime.openURL(url);
        } else {
          if (localStorage.isWx && (type == 2 || type == 1)) {
            //微信浏览器里
            console.log("微信浏览器里");
            this.setState({
              payUrl: url,
            });
          } else {
            // 非微信浏览器
            if (localStorage.platform == 2) {
              // window.location = res.data.src  //本页打开
              winRef.location = url; //新窗口打开
            } else {
              window.open(url, "_blank");
            }
          }
        }
      }
    });
  };
  recharge = () => {
    const { payWay, moneyNow } = this.state;
    if (payWay.id == 5) {
      Control.go("/chat_serve");
    } else {
      this.goRecharge(payWay.id, moneyNow);
    }
  };
  setPayWay = (item) => {
    console.log(item);
    this.setState({
      payWay: item,
      // moneyList: item.money_type === 1 ? [...item.money_arr.fixed] : ['自定义']
      // moneyList: [...item.money_arr.fixed, "自定义"],
      moneyList: [...item.money_arr.fixed],
    });
  };
  copyBtn = (code) => {
    if (code) {
      copy(code);
      Toast.success("复制成功");
    } else {
      Toast.info("无复制内容");
    }
  };
  render() {
    const { moneyList, moneyNow, payWay, payList, payUrl } = this.state;
    const vipLists = moneyList.map((item, index) => {
      return (
        <div
          className={item === moneyNow ? "item  active" : "item"}
          style={{ display: payWay.id == 5 ? "none" : "flex" }}
          key={index}
          onClick={() => {
            this.setState({
              moneyNow: item,
            });
          }}
        >
          <div></div>
          <div hidden={item == "自定义"}>¥{item}</div>
          <div hidden={item != "自定义"}>
            <input
              placeholder="输入金额"
              onChange={(e) => {
                this.setState({
                  moneySelf: e.target.value,
                });
              }}
            />
          </div>
          <div></div>
        </div>
      );
    });
    const payLists = payList.map((item, index) => {
      return (
        <div
          className={item.id === payWay.id ? "item  active2" : "item"}
          key={index}
          onClick={() => {
            this.setPayWay(item);
          }}
        >
          <div>
            <img src={item.img} alt="" />
          </div>
          <div>{item.title}</div>
        </div>
      );
    });

    return (
      <div className="mine-vip-page mineRechargePage">
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            Control.go(-1);
          }}
          rightContent={
            <div
              className="ft14 opacity7"
              onClick={() => {
                Control.go("/mine_recharge_record");
              }}
            >
              充值记录
            </div>
          }
        >
          充值
        </NavBar>
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <div className="title">
            充值金额
            {
              <div
                className="ft14 ft_normal opacity7 mt5"
                style={{ marginLeft: 5 }}
              >
                {payWay.money_arr && payWay.money_arr.range
                  ? "充值范围: [" + payWay.money_arr.range + "]"
                  : ""}
              </div>
            }
          </div>
          <WhiteSpace size="lg" />
          <div className="top">
            {vipLists}
            <div className="item" style={{ height: 0, opacity: 0 }}></div>
          </div>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <div className="title">充值方式</div>
          <WhiteSpace size="lg" />
          <div className="mid">{payLists}</div>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <div
            className="btn"
            onClick={() => {
              this.recharge();
            }}
          >
            立即充值
          </div>

          <div className="wxBox" style={{ display: payUrl ? "block" : "none" }}>
            <div className="wxBoxBg color255">
              <div
                className="close"
                onClick={() => {
                  this.setState({ payUrl: "" });
                }}
              >
                <Icon type="cross-circle-o" color="#db5353" />
              </div>
              <div className="item ft20 ft_bold les2">支付地址</div>
              <div className="item  les1 ft_line2 flex flex_center_center opacity7">
                {payUrl}
              </div>
              <div className="item  les1  flex_center_center opacity7">
                请复制地址,在默认浏览器打开
              </div>
              <div
                className="copy"
                onClick={() => {
                  this.copyBtn(payUrl);
                }}
              >
                复制地址
              </div>
            </div>
          </div>
          <div
            className="mask"
            style={{ display: payUrl ? "block" : "none" }}
          ></div>
        </WingBlank>
      </div>
    );
  }
}
export default MineVip;
