import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank } from 'antd-mobile';
import MoneyRecordList from "../../components/MoneyRecordList";
import "../../assets/style/mine.scss";
import { Control } from 'react-keeper';

class MineWalletRecord extends Component {
    render() {
        return (
            <div className='mineWalletPage MineWalletRecordPage'>
                <div className='navBoxx'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >流水记录</NavBar>
                </div>
                <div style={{height:45}}></div>
                <WingBlank size='lg'>
                    <div className='recordListBox'>
                        <WingBlank size='md'>
                            <MoneyRecordList />
                        </WingBlank>
                    </div>
                </WingBlank>




            </div>
        )
    }
}
export default MineWalletRecord;
