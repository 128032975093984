import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, ImagePicker } from 'antd-mobile';
import Api from './../../http/api';
import { Control } from 'react-keeper';



class MineRealName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: '',
            pwd: '',
            files: [],
            files2: [],

        }
        this.setTel = this.setTel.bind(this);
        this.setPwd = this.setPwd.bind(this);
        console.log(props)
    }
    componentDidMount() {
        console.log('挂在完成')
        this.renZhen();
    }
    renZhen = () => {
        Api.renzhen({
            meta_info: localStorage.metaInfo
        }).then(res => {
            console.log(res)
        })
    }
    
    setTel(tel) {
        this.setState({ tel })
    }
    setPwd(pwd) {
        this.setState({ pwd })
    }
    handleChange = (e) => {
        console.log(e);
        // this.setState({
        //     [e.target.name]: e.target.value
        // })
    }
    goRegist = () => {
        console.log('regist')
    }
    goHome = () => {
        console.log('123')
        Control.go('/tab1')
    }
    onChange = (files, type, index) => {
        console.log(files, type, index);
        if (this.state.files.length > 0) {
            this.setState({
                files: []
            });
        }
        this.setState({
            files,
        });
    }
    onChange2 = (files2, type, index) => {
        console.log(files2, type, index);

        this.setState({
            files2,
        });
    }
    reChange = () => {
        if (this.state.files2.length > 0) {
            this.setState({
                files2: []
            });
        }
    }
    render() {
        const bg1 = this.state.files.length > 0 ? this.state.files[0].url : require('./../../assets/imgs/icon/zhengmian@2x.png').default;
        const bg2 = this.state.files2.length > 0 ? this.state.files2[0].url : require('./../../assets/imgs/icon/fanmian@2x.png').default;




        return (
            <div className='login-page change_pwd_page mine_question_page mine_real_name_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >实名认证</NavBar>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='md' className='login-content'>
                    <div className='imgsBox'>
                        <div className='left'>
                            <div className='ft16 mb5 color255'>头像面</div>
                            <div className='ft14 info'>上传您身份证头像面</div>
                        </div>
                        <div className='right' style={{ backgroundImage: `url(${bg1})` }} >
                            <ImagePicker
                                length="1"
                                files={this.state.files}
                                onChange={this.onChange}
                                onImageClick={(index, fs) => this.reChange}
                                selectable={this.state.files.length < 1}
                            />
                        </div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='imgsBox'>
                        <div className='left'>
                            <div className='ft16 mb5 color255'>国徽面</div>
                            <div className='ft14 info'>上传您身份证国徽面</div>
                        </div>
                        <div className='right' style={{ backgroundImage: `url(${bg2})` }}>
                            <ImagePicker
                                length="1"
                                files={this.state.files2}
                                onChange={this.onChange2}
                                onImageClick={(index, fs) => console.log(index, fs)}
                                selectable={this.state.files2.length < 1}
                            />
                        </div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='label'>注： 仅用于实名认证中心核实身份</div>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn'>确认提交</Button>
                </WingBlank>
            </div >

        )
    }
}
export default MineRealName;

