/* eslint-disable eqeqeq */

import React from 'react';
import Tabbar from './../components/Tabbar';
import { Flex, WingBlank, ListView, Modal, Toast, Carousel } from 'antd-mobile';
import '../assets/style/tab3.scss';
import Api from './../http/api';
import { Link, Control } from 'react-keeper';

import { LockOutline } from 'antd-mobile-icons'





class Tab3 extends React.Component {
    constructor(props) {
        super(props)
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            page: 1, //页码
            limit: 10, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
            unlock_cost: '',
            unlock_end_time_hour: '',
            hasNewNotice: localStorage.hasNewNotice ? localStorage.hasNewNotice : 0,
            banner: [
                { title: '社交', people: '405', info: '一起聊一聊吧1', imgSrc: require('../assets/imgs/tab3/b1@2x.png').default },
                { title: '旅游', people: '203', info: '一起聊一聊吧2', imgSrc: require('../assets/imgs/tab3/b2@2x.png').default },
                { title: '游戏', people: '535', info: '一起聊一聊吧3', imgSrc: require('../assets/imgs/tab3/b3@2x.png').default },
                { title: '美食', people: '385', info: '一起聊一聊吧4', imgSrc: require('../assets/imgs/tab3/b4@2x.png').default },
            ],
            chatRoomList: [], //聊天室列表
            chatToken: '',//聊天token
            chatRoomName: ''
        }
    }
    //获取聊天室列表

    getChatRoomList = () => {
        Api.chatRoomList({}).then(res => {
            console.log('聊天室列表', res)
            if (res.code == 200) {
                this.setState({
                    chatRoomList: res.data
                })
            }
        })

    }
    //去聊天室

    goChatRoom = (item) => {
        console.log(item)
        if (Number(item.now_num) < Number(item.max)) {
            if (item.unlock == 1) {
                //已解锁
                Api.chatRoomPwd({ room_id: item.id }).then(res => {
                    console.log('加入聊天室获得令牌', res)
                    if (res.code == 200) {
                        this.setState({
                            chatToken: res.data,
                            chatRoomName: item.name
                        }, () => {
                            let params = {
                                type: "rJoin",
                                class: 3,
                                data: {
                                    token: res.data
                                }
                            }
                            window.sendMsg(params);
                        })
                    }
                })

            } else {
                //聊天室未解锁 弹出解锁输入框
                Modal.prompt(
                    '解锁聊天室',
                    '请输入聊天室密码',
                    [
                        { text: '取消' },
                        {
                            text: '提交', onPress: password => {
                                if (password) {
                                    this.chatRoomUnlook(item.id, password)
                                }

                            }
                        },
                    ],
                    'secure-text',
                )

            }

        } else {
            Toast.info("当前聊天室人数已满")
        }
    }



    //聊天室解锁

    chatRoomUnlook = (roomId, pwd) => {
        Api.chatRoomUnlook({ room_id: roomId, password: pwd }).then(res => {
            console.log('解锁聊天室', res)
            if (res.code == 200) {
                //解锁成功
                Toast.success('解锁成功!')
            }
        })

    }
    goInfo = (item) => {
        // console.log(item)
        if (item.auth == 1) {
            Control.go(encodeURI('/chat/' + item.id + '/' + item.nickname))
        } else if (item.auth == 0) {
            this.getFriendInfo(item.id, item)
        }
    }
    goChat = (id, name) => {
        Control.go(encodeURI('/chat/' + id + '/' + name))
    }
    // 获取好友信息
    getFriendInfo = (id, item) => {
        let params = { uid: id }
        Api.userInfo(params).then(res => {
            console.log('用户信息', res)
            if (res.code == 200) { //eslint-disable-line 
                this.unlockLimit(res.data, item)
            }
        })
    }
    // 解锁
    unlockLimit = (friendInfoNew, item) => {
        const { unlock_end_time_hour } = this.state;
        console.log(friendInfoNew.unlock);
        if (friendInfoNew.unlock == 0) {
            console.log('有权限聊天')
        } else if (friendInfoNew.unlock == 1) {
            // 用户申请聊天 且无权限
            // console.log('会员次数解锁');
            Modal.alert('提示', `您与 ${item.nickname} 解锁已超过${unlock_end_time_hour}日，需重新解锁使。用VIP特权 申请解锁 (今日剩${friendInfoNew.vip_unlock_count}次)？`, [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                { text: `免费解锁`, onPress: () => this.openChatLimitVip(friendInfoNew) },
            ]);
        } else if (friendInfoNew.unlock == 2) {
            // console.log('付费解锁');
            Modal.alert('提示', `您与 ${item.nickname} 解锁已超过${unlock_end_time_hour}日，需重新解锁使。消费 ${friendInfoNew.unlock_price}元 申请解锁？`, [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                { text: '解锁', onPress: () => this.openChatLimit(friendInfoNew) },
            ]);

        } else if (friendInfoNew.unlock == 4) {
            Toast.loading('等待对方同意', 3)
        } else if (friendInfoNew.unlock == 5) {
            Modal.alert('提示', `开通VIP 获得申请解锁特权？`, [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                {
                    text: '去开通', onPress: () => {
                        Control.go('/mine_vip');
                    }
                },
            ]);
        }
    }
    //花费解锁
    openChatLimit = (friendInfoNew) => {
        let params = {
            uid: friendInfoNew.id,
        }
        Api.openChatLimit(params).then(res => {
            console.log('解锁聊天权限：', res)
            if (res.code == 200) { //eslint-disable-line 
                if (friendInfoNew.unlock_state == 1) {
                    Toast.success('解锁申请已发送', 3)
                } else {
                    this.goChat(friendInfoNew.id, friendInfoNew.nickname)
                }
            }
        })
    }
    // vip 次数解锁
    openChatLimitVip = (friendInfoNew) => {
        let params = {
            uid: friendInfoNew.id,
        }
        Api.openChatLimitVip(params).then(res => {
            console.log('解锁聊天权限：', res)
            if (res.code == 200) { //eslint-disable-line 
                console.log('解锁成功：', res)
                if (friendInfoNew.unlock_state == 1) {
                    Toast.success('解锁申请已发送', 3)
                } else {
                    this.goChat(friendInfoNew.id, friendInfoNew.nickname)
                }
            }
        })



    }

    componentDidMount() {
        console.log('tab3页面');
        this.getList();
        // this.getChatRoomList();
        window.scrollTo(0, 0);
        window.addEventListener('message', this.onMessage, false);
        localStorage.hasNewMsg = 0;

    }
    componentWillUnmount() {
        window.removeEventListener('message', this.onMessage)
        window.scrollTo(0, 0)
        this.setState = () => {
            return
        }
    }

    onMessage = (res) => {
        const { allBillData } = this.state;
        console.log(typeof (res))
         // if (typeof (res) == 'string') {
        //     res = JSON.parse(res)
        // }
        let msg = res.data;
          // if (res.data && typeof (res.data) == 'string') {
        //     msg = JSON.parse(res.data)
        // }
        console.log('我在tab3里：', msg)


        if (msg.type == 'newNotice') {
            localStorage.hasNewNotice = 1;
            this.setState({
                hasNewNotice: 1
            })
        } else if (msg.type == 'messageCallback') {
            let index = allBillData.findIndex(item => item.id == msg.data.from.id);
            // console.log(index)
            let newItem = {
                auth: 1,
                content: msg.data.message.content,
                head_img: msg.data.from.head_img,
                id: msg.data.from.id,
                last_time: msg.data.message.time,
                nickname: msg.data.from.nickname,
                no_read: 0,
                type: msg.data.message.type,

            }
            if (index != -1) {
                newItem.no_read = allBillData[index].no_read + 1;
                this.setState({
                    allBillData: allBillData.map((item) => item.id == msg.data.from.id ? newItem : item),
                }, () => {
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.state.allBillData),
                    })
                })

            } else {
                let emptyList = allBillData.splice(0, allBillData.length);
                this.setState({
                    page: 1,
                    isHasMore: true,
                    dataSource: this.state.dataSource.cloneWithRows(emptyList),
                    isLoading: true,
                }, () => {
                    this.getList()
                })

            }

        } else if (msg.type == 'roomJoinReceipt') {
            // console.log('聊天室进入申请结果', msg.data.status, this.state.chatToken)
            // 聊天室进入申请结果
            if (msg.data.status == 0) {
                Toast.fail(msg.data.msg)
            } else if (msg.data.status == 1) {
                if (this.state.chatToken) {
                    Modal.alert('聊天室公告', msg.data.notice, [
                        {
                            text: '进入聊天', onPress: () => {
                                Control.go('/chat_room/', {
                                    token: this.state.chatToken,
                                    lock: msg.data.muted,
                                    chatName: this.state.chatRoomName
                                })
                            }
                        },
                    ]);

                } else {
                    Toast.info('进入房间失败')
                }


            }

        }

    }
    getList = () => {
        const { limit, page, isHasMore } = this.state;
        if (isHasMore) {
            Api.chatList({
                size: limit,
                page: page
            }).then(res => {
                // console.log(res)
                if (res.code === 200) {
                    let allbi = this.state.preBillData.concat(res.data.members);
                    this.setState({
                        billData: res.data.members,
                        preBillData: allbi,
                        allBillData: allbi,
                        unlock_cost: res.data.unlock_cost,
                        unlock_end_time_hour: res.data.unlock_end_time_hour,
                        page: Number(this.state.page) + 1,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.members.length > 0 ? true : false,
                        isLoading: false
                    })
                }
            })
        }

    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }

    goInfo2 = (id) => {
        Control.go(`/user_info/${id}/0`);
    }


    render(h) {
        const { isHasMore, isLoading, billData, hasNewNotice, banner, chatRoomList } = this.state;
        const tabs = [
            { title: '喜欢', path: '/mine_like', img: require('../assets/imgs/icon/icon_xihuan2.png').default },
            { title: '点赞', path: "/mine_zan", img: require('../assets/imgs/icon/icon_dianzan.png').default },
            { title: '看过我', path: "/mine_view", img: require('../assets/imgs/icon/icon_kanguowo.png').default },
            { title: '通知', path: "/mine_msg", img: require('../assets/imgs/icon/icon_tongzhi.png').default },
        ]
        const tabsList = tabs.map((tab, index) => {
            return (
                <Flex.Item key={index} className='top-tab-item'>
                    <Link to={tab.path}>
                        <img src={tab.img} alt='' />
                        <div>{tab.title}</div>
                        <div className={index == 3 ? 'noticeState' : ''} style={{ display: hasNewNotice == 1 ? 'block' : 'none' }} ></div>
                    </Link>
                </Flex.Item>
            )
        })
        const row = (item, sectionID, rowID) => {
            return (
                <div key={item.id} className='item' >
                    <div className='item-left' onClick={() => { this.goInfo2(item.id) }}>
                        <div className='imBox'>
                            <img src={item.head_img} alt='head' onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} />
                        </div>
                        <div className='msgNoRead' style={{ display: item.no_read > 0 ? 'block' : 'none' }}>{item.no_read}</div>
                    </div>
                    <div onClick={() => { this.goInfo(item) }} className={item.auth === 1 ? 'item-right' : 'item-right noLimit'}>
                        <div className='name mb2 flex flex_left_center ft_line1'>{item.nickname ? item.nickname : '简约用户'}</div>
                        <div className='dt'>
                            <div className='dt-text ft_line1 opacity7' style={{ color: '#fff' }}>{item.type === 1 ? item.content : '[图片]'}</div>
                        </div>
                        <div className='state' style={{ top: 'auto', bottom: '3px' }}>{item.last_time}</div>
                    </div>
                </div>

            )
        }
        return (
            <div className='tab3'>
                <div className='top-tab'>
                    <WingBlank size='lg'>
                        <Flex>
                            {tabsList}
                        </Flex>
                    </WingBlank>
                </div>
                <div className='tab3-content'>
                    <div className='bannerBox' style={{display:chatRoomList.length>0?'block':'none'}}>
                        <Carousel
                            autoplay={true}
                            dots={false}
                            infinite
                            autoplayInterval={5000}
                        >
                            {chatRoomList.map((item, index) => (
                                <div className='banner-item' key={index} onClick={() => {
                                    this.goChatRoom(item)
                                }}>
                                    <img
                                        src={require(`../assets/imgs/tab3/b${(index + 1) % 4}@2x.png`).default}
                                        alt=""
                                        style={{ width: '100%', verticalAlign: 'top' }}
                                        onLoad={() => {
                                            window.dispatchEvent(new Event('resize'));
                                        }}
                                    />
                                    <div className='banner-text'>
                                        <div className='ft16 ft_bold color255 les1 ft_line1'>
                                            {!item.unlock ? <LockOutline /> : ''}
                                            {item.name}</div>
                                        <div className='info ft_line1'>{item.introduce}({item.now_num}人)</div>

                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <ListView
                        ref={el => this.listView = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                {
                                    isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                }
                                {
                                    (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                }

                            </div>
                        )}
                        renderRow={row} //每行数据渲染
                        style={{
                            minHeight: 'calc(100vh - 20px)', //高度需要定义
                            overflow: 'auto'
                        }}
                        pageSize={10}  //一次渲染几条数据
                        initialListSize={10}
                        onEndReached={this.onEndReached}//加载新的数据
                        onEndReachedThreshold={200}//距离底部40时候 加载数据
                    />

                </div>

            </div>
        )

    }
}
export default Tabbar(Tab3);