/* eslint-disable eqeqeq */

import lrz from 'lrz'
import Api from './../http/api'

// import { Modal } from 'antd-mobile';

let BMap = window.myPosition ? window.myPosition : ''

let geolocation2 = BMap ? new BMap.Geolocation() : ''

export async function getPosition(callback = () => {}) {
  // let a = { "coordsType": "gcj02", "address": { "country": "中国", "province": "四川省", "city": "成都市", "district": "成华区", "street": "杉板桥路", "streetNum": "23号", "cityCode": "75" }, "addresses": "中国四川省成都市成华区杉板桥路23号", "coords": { "latitude": 30.663166, "longitude": 104.114091, "accuracy": 40, "altitude": 5e-324, "heading": null, "speed": 0, "altitudeAccuracy": 0 }, "timestamp": 1638782956000 }
  // console.log(a)
  if (!localStorage.token) return
  await setBaiduApi()
  let plus = window.plus
  if (localStorage.isApp == 1 && plus && plus.geolocation) {
    // console.log(window.plus)
    plus.geolocation.getCurrentPosition(
      function (position) {
        console.log('原生定位：成功', JSON.stringify(position))
        localStorage.lon = position.coords.longitude //经度
        localStorage.lat = position.coords.latitude //纬度
        localStorage.city = position.address.city + position.address.district //地区
        callback(true)
      },
      function (e) {
        console.log(e)
        getLocByBaiDuMap(res3 => {
          if (!res3) {
            getLocation()
          }
          callback(true)
        })
      },
      {
        provider: 'baidu',
        geocode: 'true',
      }
    )
  } else {
    // h5定位
    getLocByH5(res => {
      if (res) {
        callback(res)
      } else {
        // 百度 地图定位
        getLocByBaiDuMap(res3 => {
          if (!res3) {
            getLocation()
          }
          callback(true)
        })
      }
    })
  }
}
export function getAddress() {
  let BMap = window.myPosition
  if (BMap && localStorage.lat && localStorage.lon) {
    let gc = new BMap.Geocoder()
    gc.getLocation(
      new BMap.Point(localStorage.lon, localStorage.lat),
      function (rs) {
        let addComp = rs.addressComponents
        // let address = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;//获取地址
        // console.log(rs, address);
        localStorage.city = addComp.city + addComp.district
      }
    )
  }
}
//百度定位
function getLocByBaiDuMap(callback = () => {}) {
  let BMap = window.myPosition
  let map = document.getElementById('baiduIframe')
  if (map) {
    map.remove()
  }
  if (BMap) {
    geolocation2.getCurrentPosition(function (r) {
      console.log('百度地图')
      if (this.getStatus() === 0) {
        localStorage.lat = r.latitude
        localStorage.lon = r.longitude
        localStorage.city = r.address.city
        callback(true)
        let iframes = document.getElementsByTagName('iframe')
        iframes.forEach(element => {
          if (element.src.indexOf('baidu.com')) {
            element.id = 'baiduIframe'
            element.name = 'baiduIframe'
          }
        })
      } else {
        console.log('failed' + this.getStatus())
        callback(false)
      }
    }) //获取地址信息，设置地址label
  } else {
    console.log('BMap:', BMap)
    callback(false)
  }
}

// H5+  定位
function getLocByH5(callback = () => {}) {
  let params = {
    enableHighAccuracy: true, //高精度
    timeout: 2000, //超时时间
    maximumAge: 60000, //位置缓存时间
  }
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(onSuccess, onError, params)
  } else {
    alert('您的浏览器不支持使用HTML 5来获取地理位置服务')
    callback(false)
  }

  //定位数据获取成功响应
  function onSuccess(position) {
    console.log('H5+定位：成功')
    localStorage.lon = position.coords.longitude //经度
    localStorage.lat = position.coords.latitude //纬度
    callback(true)
  }
  //定位数据获取失败响应
  function onError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log('您拒绝对获取地理位置的请求')
        break
      case error.POSITION_UNAVAILABLE:
        console.log('位置信息是不可用的')
        break
      case error.TIMEOUT:
        console.log('请求您的地理位置超时')
        break
      case error.UNKNOWN_ERROR:
        console.log('未知错误')
        break
      default: {
      }
    }
    console.log('H5+定位：失败')
    callback(false)
  }
}

// 百度 ip定位
function getLocation(callback = () => {}) {
  let map = document.getElementById('baidumap')
  if (map) {
    map.remove()
  }
  var script = document.createElement('script')
  script.id = 'baidumap'
  script.type = 'text/javascript'
  script.src =
    'https://api.map.baidu.com/location/ip?ak=KhuoWi7KrYgIQwYocRN7Z5oWNHfubZde&coor=bd09ll&callback=getLo'
  document.body.appendChild(script)
}

// 百度 api
async function setBaiduApi(callback = () => {}) {
  let map = document.getElementById('baidumapapi')
  if (map) {
    return
  }
  var script = document.createElement('script')
  script.id = 'baidumapapi'
  script.type = 'text/javascript'
  script.src =
    'https://api.map.baidu.com/api?v=2.0&ak=KhuoWi7KrYgIQwYocRN7Z5oWNHfubZde&callback=getLo2'
  document.body.appendChild(script)

  return new Promise((resove, reject) => {
    setTimeout(() => {
      BMap = window.myPosition
      geolocation2 = BMap ? new BMap.Geolocation() : ''
      resove(true)
    }, 1000)
  })
}

//上传图片
export function upImg(file, call = () => {}) {
  // console.log(file, file.length);
  if (file.length != undefined) {
    for (let i = 0; i < file.length; i++) {
      let arr = []
      upload(file[i], res => {
        file[i].src = res
        arr.push(res)
        if (arr.length == file.length) {
          call(arr)
        }
      })
    }
  } else {
    let arr = []
    upload(file, res => {
      file.src = res
      arr.push(res)
      call(arr)
    })
  }
}

/**
 * 文件上传
 * @param file  //上传的文件 可以是数组也可以是单个对象
 */

function upload(file, callback) {
  // console.log(file);
  file.status = 'uploading'
  file.message = '上传中...'
  lrz(file.file, {
    width: 1000,
    height: 1200,
    fieldName: 'file',
    quality: 1, //自定义使用压缩方式
  })
    .then(function (rst) {
      //成功时执行
      // console.log(rst);
      rst.formData.append('token', localStorage.token)
      Api.upload(rst.formData).then(res => {
        // console.log('图片上传', res);
        if (res.code == 200) {
          file.status = ''
          file.message = ''
          callback(res.data)
        } else {
          file.status = 'failed'
          file.message = '上传失败'
          callback('')
        }
      })
    })
    .catch(function (error) {
      //失败时执行
      console.log(error)
      file.status = 'failed'
      file.message = '上传失败'
      callback('')
    })
    .always(function () {
      //不管成功或失败，都会执行
    })
}
export default {
  getLocation, //获取定位
  getPosition,
  getAddress,
  upImg, //上传图片
}
