const PublicNotice = (props) => {
    const { content,closePublicNotice } = props;
    console.log(content)

    return (
        <div className='publicNoticeBg'>
            <div className='publicNotice'>
                <div className='box'>
                    {content}
                </div>
                <div className='close' onClick={()=>{closePublicNotice()}}> <img src={require('./../assets/imgs/tab1/close.png').default} alt="" /></div>
            </div>
        </div>
    )
}
export default PublicNotice;
