import React, { useState, useEffect } from 'react'
import style from './Photos.module.scss';
import { Icon } from 'antd-mobile';
import WxImageViewer from 'react-wx-images-viewer';
import VideoPlay from "./Video";


const Photos = (props) => {
    const [indexs, setIndexs] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [imgsBox, setImgsBox] = useState('');

    const { list, pageType, showManage, photoType } = props;
    useEffect(() => {
        let types = ['mp4', 'avi', 'mov', '3gp', 'mpeg', 'nAVI', 'asf', 'wmv', 'mpeg-4'];
        for (let i in list) {
            let index1 = list[i].img.lastIndexOf(".");
            let index2 = list[i].img.length;
            let suffix = (list[i].img.substring(index1 + 1, index2)).toLowerCase();//后缀名
            // console.log(suffix)
            let type = types.indexOf(suffix)
            // console.log(type)
            if (type !== -1) {
                list[i].type = 2
            } else {
                list[i].type = 1
            }
        }
        setImgsBox(list)
    }, [props])


    const onClose = () => {
        setIsOpen(false)
    }

    const openViewer = (index) => {
        console.log(index)
        if (!showManage) {
            showManageSet();
            setIndexs(index);
            setIsOpen(true);
        }

    }
    const play = (id) => {
        console.log(id)
        let video = document.getElementById(id)
        if (video.requestFullscreen) {
            video.requestFullscreen()
            video.play()
        } else if (video.mozRequestFullscreen) {
            //firefox
            video.mozRequestFullscreen()
            video.play()
        } else if (video.webKitRequestFullscreen) {
            // chrome
            video.webKitRequestFullscreen()
            video.play()
        } else if (video.msRequestFullscreen) {
            //ie11
            video.msRequestFullscreen()
            video.play()
        }

    }

    const imgs = (imgs) => {
        let arr = []
        imgs.forEach(img => {
            arr.push(img.img)
        });
        return arr;
    }
    // console.log(pageType, photoType)

    const delPhoto = props.delPhoto;
    const showManageSet = props.manage || function () { };
    const imgList = imgsBox.length > 0 ? imgsBox.map((item, index) => {
        if (item.type === 1) {
            return (
                <div className={photoType === 'abc' ? style.photoItem2 : style.photoItem} key={index}
                    style={{ display: photoType === 'abc' ? (index < 8 ? 'block' : 'none') : 'block' }}>
                    <div className={style.stateBr} style={{ display: item.state == 1 ? 'block' : 'none' }}>本人</div>
                    <img src={item.img} alt='' onError={(e) => { e.target.onerror = null; e.target.src = require('./../assets/imgs/icon/tupian@2x.png').default }} referrer="no-referrer" onClick={openViewer.bind(this, index)} />
                    {
                        isOpen ? <WxImageViewer onClose={onClose} urls={imgs(imgsBox)} index={indexs} /> : ""
                    }
                    <div className={style.delBox} style={{ display: pageType === 'minePhoto' && showManage ? 'flex' : 'none' }} onClick={() => {
                        delPhoto(item.id, index)
                    }}>
                        <Icon type='cross-circle' color='#d2b793' />
                    </div>
                </div>
            )
        } else {
            return (
                <div className={photoType === 'abc' ? style.photoItem2 : style.photoItem} style={{ display: photoType === 'abc' ? (index < 8 ? 'block' : 'none') : 'block' }} key={index}>
                    <VideoPlay src={item.img} fullScreen={true} />
                    <div className={style.delBox} style={{ display: pageType === 'minePhoto' && showManage ? 'flex' : 'none' }} onClick={() => {
                        delPhoto(item.id, index)
                    }}>
                        <Icon type='cross-circle' color='#d2b793' />
                    </div>
                </div>
            )
        }


    }) : (<div className={style.msg + '  flex flex_column flex_center_center ft16 les1 color999'}>

        <img style={{ marginTop: '20px' }} width='40%' src={require('./../assets/imgs/icon/noImg.png').default} alt='' />

    </div>);
    return (
        <div className={photoType === 'abc' ? style.photo2 : style.photo}>
            {imgList}
            <div className={photoType === 'abc' ? style.itemm2 : style.itemm}></div>
        </div>
    )
}
export default Photos;
