/* eslint-disable eqeqeq */

import React from 'react';
import Tabbar from './../components/Tabbar';

import Tab2Item from './../components/Tab2_item';
import { Tabs, Popover, ListView, Toast, PullToRefresh } from 'antd-mobile';
import '../assets/style/tab1.scss';
import '../assets/style/tab2.scss';
import Api from './../http/api';
import { Control } from 'react-keeper';


const Item = Popover.Item;
class Tab2 extends React.Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            topic_id: 0,//话题id
            like: 0,//热度排序
            itemType: 'tab2',
            visible: false,//性别切换按钮
            nearby: 1,// 是否看附近
            showBtns: false, //显示发布
            refreshing: true,
            sex: 0,



            page: 1, //页码
            limit: 20, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    componentDidMount() {
        console.log('挂在完成')
        this.getList();
    }
    onSelect = (opt) => {
        console.log(opt);
        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            visible: false,
            sex: opt.props.value,
            page: 1, //页码变为1
            isHasMore: true,
            isLoading: false,
            dataSource: this.state.dataSource.cloneWithRows(emptyList) //传入一个空数组
        }, () => {
            this.listView2.scrollTo(0, -60);
            this.getList();
        })

    };
    handleVisibleChange = (visible) => {
        this.setState({
            visible,
        });
    };

    getList = () => {
        const { topic_id, like, nearby, page, limit, isHasMore } = this.state;
        let params = {
            page: page,
            sex: localStorage.sex == 1 ? 2 : 1,
            topic_id: topic_id,
            like: like,
            nearby: nearby,
            limit: limit
        }
        if (isHasMore) {
            Api.dongTai(params).then(res => {
                // console.log('动态列表', res)
                if (res.code == 200) {
                    if (this.state.page == 1) {
                        window.scrollTo(0, 0)
                    }
                    if (res.data.length > 0) {
                        let allbi = this.state.preBillData.concat(res.data);
                        this.setState({
                            billData: res.data,
                            preBillData: allbi,
                            allBillData: allbi,
                            page: Number(this.state.page) + 1,
                            dataSource: this.state.dataSource.cloneWithRows(allbi),
                            isHasMore: res.data.length > 0 ? true : false,
                        })
                    } else {
                        this.setState({
                            isHasMore: res.data.length > 0 ? true : false,
                        })
                    }

                }
            })
        }

    }

    setTab = (tab, index) => {
        console.log(tab, index)
        let type = index == 0 ? 1 : 0;
        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            sort: tab.sort,
            page: 1, //页码变为1
            isHasMore: true,
            nearby: type,
            isLoading: false,
            dataSource: this.state.dataSource.cloneWithRows(emptyList) //传入一个空数组
        }, () => {
            this.listView2.scrollTo(0, -60);
            this.getList();
        })
    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            })
            this.getList()
        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    onRefresh = () => {
        const { topic_id, like, nearby, page, limit, allBillData } = this.state;

        console.log('刷新', nearby)

        let params = {
            page: 1,
            sex: localStorage.sex == 1 ? 2 : 1,
            topic_id: topic_id,
            like: like,
            nearby: nearby,
            limit: limit
        }
        let emptyList = allBillData.splice(0, allBillData.length);

        this.setState({
            refreshing: true,
            isLoading: true,
            page: 1,
            dataSource: this.state.dataSource.cloneWithRows(emptyList)
        }, () => {

            Api.dongTai(params).then(res => {
                console.log('动态列表', res)
                if (res.code == 200) {
                    if (res.data.length > 0) {
                        let allbi = this.state.preBillData.concat(res.data);
                        this.setState({
                            billData: res.data,
                            preBillData: allbi,
                            allBillData: allbi,
                            page: 2,
                            dataSource: this.state.dataSource.cloneWithRows(allbi),
                            isHasMore: res.data.length > 0 ? true : false,
                            isLoading: false,
                            refreshing: false,
                        })
                    } else {
                        this.setState({
                            isHasMore: res.data.length > 0 ? true : false,
                            isLoading: false,
                            refreshing: false,
                        })
                    }
                    window.scrollTo(0, 0)

                }
            })

        });
    };
    row = (item, sectionID, rowID) => {
        return (
            <div key={item.id}>
                <Tab2Item item={item} itemType={this.state.itemType} showPLBox={this.showPLBox} />
            </div>
        )
    }
    handleChange = (name, text) => {
        console.log(text.length)
        if (text.length > 100) {
            Toast.info('最多100字符')
            this.setState({
                text: text.substring(0, 100)
            })
        } else {
            this.setState({ [name]: text })
        }
    }
    showFB = () => {
        const { showBtns } = this.state;
        this.setState({
            showBtns: !showBtns
        })
        setTimeout(() => {
            this.setState({
                showBtns: false
            })

        }, 3000);
    }

    render() {
        const tabs = [
            { title: '附近' },
            { title: '全部' }
        ];
        // console.log(this.props);
        const { isHasMore, isLoading, billData, showBtns, sex } = this.state;


        return (
            <div className='tab1 tab2'>
                <div className='tabs'>
                    <Tabs tabs={tabs}
                        tabBarBackgroundColor='#222'
                        tabBarActiveTextColor='#eccda4'
                        tabBarInactiveTextColor='#8f8f8f'
                        onTabClick={(tab, index) => { this.setTab(tab, index) }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={5} />}
                    />
                </div>
                <div className='topP'>
                    <img type="ellipsis"
                        // onClick={this.showFB}
                        src={require('../assets/imgs/icon/icon_fabu.png').default}
                        width='22'
                        alt=''
                        onClick={() => {
                            Control.go('/mine_send_dynamic')
                        }}
                    />
                </div>

                <div className='position' style={{ display: 'none' }}>
                    <Popover mask
                        overlayClassName="fortest"
                        overlayStyle={{ color: 'currentColor' }}
                        visible={this.state.visible}
                        overlay={[
                            (<Item key="0" value="0" style={{ color: this.state.sex == 0 ? '#eccda4' : '' }} >全部</Item>),
                            (<Item key="1" value="1" style={{ color: this.state.sex == 1 ? '#eccda4' : '' }} >只看男生</Item>),
                            (<Item key="2" value="2" style={{ color: this.state.sex == 2 ? '#eccda4' : '' }}>只看女生</Item>),
                        ]}
                        align={{
                            overflow: { adjustY: 0, adjustX: 0 },
                            offset: [-10, 0],
                        }}
                        onVisibleChange={this.handleVisibleChange}
                        onSelect={this.onSelect}
                    >
                        <div style={{
                            height: '100%',
                            padding: '0 15px',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            right: 0,
                            left: 'auto',
                            top: 'auto',
                            bottom: 'auto'
                        }}
                        >
                            <img src={require('../assets/imgs/icon/icon_menu.png').default} alt='' width='22px' />
                        </div>
                    </Popover>

                </div>
                <div className='dtType' style={{ display: showBtns ? 'block' : 'none' }}>
                    <div onClick={() => {
                        Control.go('/mine_send_dynamic')

                    }} >发布动态</div>
                </div>
                <div className='tab1-content'>
                    <ListView
                        ref={el => this.listView2 = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                {
                                    isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                }
                                {
                                    !isHasMore ? '没有更多了' : ''
                                }

                            </div>
                        )}
                        renderRow={this.row} //每行数据渲染
                        style={{
                            minHeight: 'calc(100vh - 20px)', //高度需要定义
                            overflow: 'auto',
                            paddingTop: '15px',
                            width: '100%'
                        }}
                        pageSize={20}  //一次渲染几条数据
                        initialListSize={20}
                        onEndReached={this.onEndReached}//加载新的数据
                        onEndReachedThreshold={100}//距离底部40时候 加载数据
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                    />
                </div>
            </div>
        )

    }
}

export default Tabbar(Tab2);
