import { Icon, Modal, NavBar, Toast, WhiteSpace, WingBlank } from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import '../../assets/style/user_info.scss'
import Api from './../../http/api'

class MineVip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVip: 0,
      money: 0,
      payUrl: '', //支付跳转地址
      vipList: [], //vip 类别
      vipType: [], //选中的vip类别
    }
  }
  componentDidMount() {
    this.getMineInfo()
    this.getInfo()
  }
  getInfo = () => {
    Api.mineVip({}).then(res => {
      console.log('vip配置', res)
      if (res.code === 200) {
        this.setState({
          vipList: res.data,
          vipType: res.data[0],
        })
      }
    })
  }
  getMineInfo = () => {
    Api.mineInfo({}).then(res => {
      console.log('我的信息', res)
      if (res.code == 200) {
        this.setState({
          money: res.data.money,
          isVip: res.data.is_vip,
        })
        localStorage.userInfo = JSON.stringify(res.data)
      }
    })
  }
  openVip = () => {
    const { vipType, money } = this.state
    console.log(vipType)
    if (Number(money) < Number(vipType.price)) {
      const alertInstance1 = Modal.alert('提示', '余额不足，立即充值？', [
        // { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
        {
          text: '立即充值',
          onPress: () => {
            Control.go('mine_recharge')
          },
        },
      ])
      return false
    }
    let params = {
      price_id: vipType.id,
    }
    // console.log(params)
    Api.openVip(params).then(res => {
      console.log('支付地址', res)
      if (res.code === 200) {
        Toast.success(res.msg, 3)
        setTimeout(() => {
          this.getMineInfo()
          // if (res.data.is_luck_draw) {
          //     Modal.alert('提示', '恭喜您，可以开幸运盲盒啦！', [
          //         {
          //             text: '取消', onPress: () => {
          //                 Control.go(-1)
          //             }, style: 'default'
          //         },
          //         {
          //             text: '去开启', onPress: () => {
          //                 Control.go('/mine_luck')
          //             }
          //         },
          //     ]);
          // } else {
          Control.go(-1)
          // }
        }, 3000)
      }
    })
  }
  render() {
    const { vipList, vipType, isVip } = this.state
    const infoList = [
      {
        name: '无限用户查看',
        img: require('../../assets/imgs/vip/Vip_icon_1.png').default,
        info: '不限次数查看',
      },
      {
        name: '每日10次免费',
        img: require('../../assets/imgs/vip/Vip_icon_2.png').default,
        info: '社交账号/私聊',
      },
      {
        name: '阅后即焚延时',
        img: require('../../assets/imgs/vip/Vip_icon_3.png').default,
        info: '从2秒提升至6秒',
      },
      {
        name: '每日10次重看',
        img: require('../../assets/imgs/vip/Vip_icon_4.png').default,
        info: '查看已焚照片',
      },

      {
        name: '每日动态',
        img: require('../../assets/imgs/vip/Vip_icon_5.png').default,
        info: '免费发布动态',
      },
      {
        name: '隐身模式',
        img: require('../../assets/imgs/vip/Vip_icon_6.png').default,
        info: '防止被打扰',
      },
    ]
    const vipLists = vipList.map((item, index) => {
      return (
        <div
          className={item.id === vipType.id ? 'item  active' : 'item'}
          key={index}
          onClick={() => {
            this.setState({
              vipType: item,
            })
          }}
        >
          <div className="ft_bold">{item.member_title}</div>
          <div>¥{item.price}</div>
          <div>{item.msg}</div>
        </div>
      )
    })
    const infoLists = infoList.map((item, index) => {
      return (
        <div className="item" key={index}>
          <div>
            <img src={item.img} alt="" />
          </div>
          <div className="info">
            <div className="name">{item.name}</div>
            <div style={{ opacity: '0.4' }}>{item.info}</div>
          </div>
        </div>
      )
    })
    return (
      <div className="mine-vip-page">
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 'auto',
            zIndex: 999,
          }}
        >
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            开通会员
          </NavBar>
        </div>
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <div className="ft16 ft_bold" style={{ color: '#efcfa8' }}>
            选择套餐
          </div>
          <WhiteSpace size="lg" />
          <div className="top">{vipLists}</div>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <div
            className="btn ft_bold les1"
            onClick={() => {
              this.openVip()
            }}
          >
            {' '}
            {isVip == 1 ? '立即续期' : '立即开通'}
          </div>
          <WhiteSpace size="lg" />
          <div className="bz">
            注:点击开通视为您已阅读并同意用户协议、隐私协议，本产品为虚拟内容服务，开通后将无法退款，请您理解
          </div>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <div className="title ft_bold les1">VIP权益</div>
          <WhiteSpace size="lg" />
          <div className="btm">{infoLists}</div>
        </WingBlank>
      </div>
    )
  }
}
export default MineVip
