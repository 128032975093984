import { Icon, NavBar, Toast, WhiteSpace, WingBlank } from 'antd-mobile'
import React from 'react'

import copy from 'copy-to-clipboard'
import html2canvas from 'html2canvas'
import { Control } from 'react-keeper'
import Api from '../../http/api'
import './../../assets/style/mine.scss'

//推广说明
class MineInvite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myInvite: {
        share_code: '',
        qr_code: '',
      },
      imgSrc: '',
      rank: [],
    }
  }
  componentDidMount() {
    this.getInviteCode()
  }
  canvasImg = () => {
    let canvasEle = document.getElementById('html2canvasTest') //获取元素
    html2canvas(canvasEle, {
      width: canvasEle.offsetWidth, //设置canvas的宽度
      height: canvasEle.offsetHeight,
      scale: window.devicePixelRatio, //缩放
      useCORS: true,
      allowTaint: false,
    }).then(canvas => {
      this.setState({
        imgSrc: canvas.toDataURL(),
      })
    })
  }
  getInviteCode = () => {
    Api.invite({}).then(res => {
      console.log('邀请码', res)
      console.log('邀请码', res.data)
      if (res.code === 200) {
        this.setState(
          {
            myInvite: res.data,
          },
          () => {
            this.canvasImg()
          }
        )
      }
    })
    // 获取团队收益排行
    Api.teamRank({}).then(res => {
      console.log('排行榜', res)
      if (res.code === 200) {
        this.setState({
          rank: res.data,
        })
      }
    })
  }
  copyBtn = code => {
    copy(code)
    Toast.success('复制成功')
  }
  imageDown = (base64, success, error) => {
    let that = this
    var bitmap = new window.plus.nativeObj.Bitmap('image')
    bitmap.loadBase64Data(
      base64,
      function () {
        that.bigmapToSave(bitmap, success, error)
      },
      function (err) {
        window.plus.nativeUI(err.message)
      }
    )
  }
  bigmapToSave = (bitmap, success, error) => {
    let that = this
    let name = '邀请码_' + this.randomString(5)
    bitmap.save(
      `_doc/${name}.png`,
      { overwrite: false },
      function (event) {
        console.log(event.target)
        that.imageSaveByGallery(event.target, success, error)
      },
      function (err) {
        window.plus.nativeUI(err.message)
      }
    )
  }
  imageSaveByGallery = (url, success, error) => {
    window.plus.gallery.save(
      url,
      function () {
        Toast.success('已保存至相册')
        const tarbitmap = window.plus.nativeObj.Bitmap.getBitmapById('image')
        tarbitmap.clear()
        success()
      },
      function (err) {
        window.plus.nativeUI(err.message)
        error()
      }
    )
  }
  randomString = e => {
    e = e || 32
    var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    var a = t.length
    var n = ''
    for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
    return n
  }
  downloadCodeImg = () => {
    const { imgSrc } = this.state
    console.log(imgSrc)
    if (!imgSrc) {
      Toast.loading('获取中...')
      return false
    }
    if (localStorage.isApp == 1) {
      // app内
      this.imageDown(imgSrc)
    } else {
      // 非app
      if (imgSrc) {
        var a = document.createElement('a')
        a.download = '邀请码' // 设置图片地址
        a.href = imgSrc
        a.click()
      }
    }
  }
  setNick = nick => {
    if (nick) {
      return (
        nick.substring(0, 1) +
        '*****' +
        nick.substring(nick.length - 1, nick.length)
      )
    }
  }
  go = index => {
    if (index == 2) {
      Control.go('/mine_invite_ad')
    }
  }
  render() {
    const { rank, imgSrc, myInvite } = this.state
    return (
      <div className="mineInvitePage" style={{ paddingBottom: '40px' }}>
        <div
          className="navBarBox"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: 'auto',
            zIndex: '999',
          }}
        >
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            推广说明
          </NavBar>
        </div>
        <div>
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            {[1, 2, 3, 4].map(item => {
              return (
                <div
                  className="itemm"
                  key={item}
                  onClick={() => {
                    this.go(item)
                  }}
                >
                  <img
                    src={
                      require(`./../../assets/imgs/invite/Activity_0${item}.jpg`)
                        .default
                    }
                    alt="123"
                    width="100%"
                  />
                </div>
              )
            })}

            <div className="itemm">
              <img
                src={
                  require('./../../assets/imgs/invite/Activity_05.jpg').default
                }
                alt="123"
                width="100%"
              />
              <div className="codeBox" id="html2canvasTest">
                <img src={myInvite.qr_code} alt=""></img>
              </div>
              <div
                className="code code2"
                onClick={this.downloadCodeImg}
                style={{
                  background: imgSrc
                    ? 'linear-gradient(to right, #f5dbbf, #c89e78)'
                    : '#dcbcbc',
                }}
              >
                保存二维码
              </div>
              <div className="code">
                <div className="codeCopyBox">
                  <span className="inviteUrl ft_line1">
                    {myInvite.share_url}
                  </span>
                  <span
                    className="copyBtn"
                    onClick={() => {
                      this.copyBtn(myInvite.share_url)
                    }}
                  >
                    复 制
                  </span>
                </div>
              </div>
            </div>
            <div className="itemm">
              <img
                src={
                  require(`./../../assets/imgs/invite/Activity_06.jpg`).default
                }
                alt="123"
                width="100%"
              />
              <div className="rankBox">
                {rank.slice(0, 10).map((item, index) => {
                  return (
                    <div className="rankBoxItem" key={index + 'item'}>
                      <div className="left">
                        <div className="headBox">
                          <img src={item.head_img} alt="" />
                        </div>
                      </div>
                      <div className="center"> {this.setNick(item.nick)}</div>
                      <div className="right">{item.profit} </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="itemm">
              <img
                src={
                  require(`./../../assets/imgs/invite/Activity_07.jpg`).default
                }
                alt="123"
                width="100%"
              />
            </div>
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default MineInvite
