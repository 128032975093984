import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, ImagePicker, TextareaItem, Toast } from 'antd-mobile';
import '../../assets/style/mine.scss';
import Api from '../../http/api';
import Upload from '../../http/upload';
import { Control } from 'react-keeper';
import Positon from '../../components/Positon';
import Topic from '../../components/Topic';


class SendDongTai extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            filesUrlBox: [],
            text: '',
            showPosition: false, //显示隐藏地址
            location: '', //当前位置
            showTopic: false,//显示话题
            topic: [],//话题
            multiple: false,

            dtType: 1,// 1 图片  2 视频


        }
    }
    onChange = (files, type, index) => {
        console.log(files)

        this.setState({
            files,
        }, () => {
            if (this.state.files.length == 0) {
                this.setState({
                    multiple: false,
                })
            } else if (this.state.files.length == 1) {
                let type = files[0].file.type;
                if (type.indexOf('video') != -1) {
                    let videoBox = document.getElementsByClassName('am-image-picker-item-content')
                    console.log(videoBox[0])

                    videoBox[0].style.cssText = "background-image:none"
                    let video = document.createElement('video');

                    video.src = files[0].url;
                    video.controls = true;
                    video.muted = 'muted';
                    video.className = 'newVideo';
                    videoBox[0].append(video);

                    this.setState({
                        multiple: false,
                        dtType: 2
                    })
                } else {
                    this.setState({
                        multiple: true,
                        dtType: 1
                    })
                }

            } else if (this.state.files.length > 9) {
                Toast.info('最多选择9张')
                this.setState({
                    files: this.state.files.slice(0, 9)
                })
            }

        })




    }
    onTextChange = (text) => {
        if (text) {
            console.log(text.length)
            if (text.length > 100) {
                Toast.info('最多100字符')
                this.setState({
                    text: text.substring(0, 100)
                })
            } else {
                this.setState({ text })
            }

        } else {
            this.setState({ text: '' })
        }
    }
    uploadImg = () => {
        const { files } = this.state;
        console.log(files);
        if (files.length < 1) {
            this.sendDT();
            return false;
        }
        let upLoad = new Upload({
            files: files,
            path: 'dongTai',
            onSuccess: (imgs) => {
                console.log('imgBoxs', imgs)
                let imgsBox = [];
                for (let i in imgs) {
                    imgsBox.push('/' + imgs[i].path + '/' + imgs[i].name)
                    if (imgsBox.length === imgs.length) {
                        this.setState({
                            filesUrlBox: imgsBox
                        }, () => {
                            this.sendDT()
                        })

                    }
                }
            },
            onFail: (err) => {
                console.log(err)
            }

        });
        try {
            upLoad.open()
        } catch (e) {
            console.log('err', e)

        }

    }
    ok = () => {
        this.uploadImg();
    }
    sendDT = () => {
        console.log('发布')
        const { text, filesUrlBox, location, topic, dtType } = this.state;
        if (!text && filesUrlBox.length < 1) {
            Toast.fail('请填写动态')
            return false
        }
        let topicArr = []
        if (topic.length > 0) {
            topic.forEach(item => {
                topicArr.push(item.id)
            })
        }
        console.log('话题id', topicArr)

        let params = {
            content: text,
            video: (dtType == 2 && filesUrlBox.length > 0) ? filesUrlBox[0] : '',
            img: dtType == 1 ? filesUrlBox : '',
            topic_id: topicArr,//话题
            location: location ? location.poiname : '',
        }
        console.log(params)
        Api.sendDongTai(params).then(res => {
            console.log(res)
            if (res.code === 200) {
                Toast.success(res.msg, 1, () => {
                    Control.go(-1)
                });
            }
        })

    }
    showPositionSet = (location) => {
        const { showPosition } = this.state;
        console.log(location)
        this.setState({
            location,
            showPosition: !showPosition
        })
    }
    showTopicSet = (topics) => {
        const { showTopic, topic } = this.state;
        console.log(showTopic, topics)
        this.setState({
            topic: topics ? topics : topic,
            showTopic: !showTopic
        })
    }
    render() {
        const { files, showPosition, location, showTopic, topic, multiple } = this.state;
        return (
            <div className='sendDongTaiPage'>
                <div className='navBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                        rightContent={[
                            <div onClick={this.ok} key='sendBtn' className='sendBtn'>发布</div>
                        ]}
                        className='nav'
                    >发布动态</NavBar>
                </div>
                <WhiteSpace size='lg' />
                <WhiteSpace size='lg' />
                <WhiteSpace size='lg' />
                <WingBlank size='lg'>
                    <TextareaItem
                        placeholder="记录生活，分享此刻..."
                        value={this.state.text}
                        onChange={this.onTextChange}
                        ref={el => this.autoFocusInst = el}
                        autoHeight
                    // row={5}
                    // count={100}
                    />
                    {/* <WhiteSpace size='lg' /> */}
                    {/* <WingBlank size='lg'></WingBlank> */}

                    <ImagePicker
                        files={files}
                        onChange={this.onChange}
                        length='3'
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files.length == 0 ? true : (
                            (multiple == false && files.length == 1) ? false : (
                                files.length < 9
                            )
                        )}
                        multiple={multiple}
                        accept={files.length == 0 ? 'video/*,image/*' : (multiple == false && files.length == 1) ? 'video/*' : 'image/*'}
                    />

                    <div className='topicBox' onClick={() => { this.setState({ showTopic: true }) }}>
                        {topic.length > 0 ? topic.map((item, index) => {
                            return (
                                <span key={item.id}>{'#' + item.title}</span>
                            )

                        }) : '#话题'}
                    </div>
                    <div className='positionAd' onClick={() => { this.setState({ showPosition: true }) }}>
                        <div className='flex flex_left_center'>
                            {/* <img width='18' src={require('./../../assets/imgs/icon/dw2.png').default} alt="" /> */}
                            <span>所在位置</span>
                        </div>
                        <div className='flex flex_right_center addrBox'>
                            <span>{location ? location.poiname : ''}</span>
                            <Icon type='right' color='#fff' />
                        </div>
                    </div>
                    <WhiteSpace />
                    <div className='positionAd'>
                        <div className='flex flex_left_center'>
                            {/* <img width='18' src={require('./../../assets/imgs/icon/dw2.png').default} alt="" /> */}
                            <span>私密评论</span>
                        </div>
                        <div className='flex flex_right_center addrBox opacity7'>
                            评论仅我可见&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>

                </WingBlank>

                {showTopic ? <Topic showTopic={showTopic} showTopicSet={this.showTopicSet} topic={topic} /> : ''}
                {showPosition ? <Positon showPosition={showPosition} showPositionSet={this.showPositionSet} /> : ''}


            </div>
        )
    }
}

export default SendDongTai;
