import { Component } from 'react'

import { Icon, NavBar, Toast, WhiteSpace, WingBlank } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import { Control } from 'react-keeper'
import Api from './../../http/api'
import { CheckUpData } from './../../http/updata'

import '../../assets/style/mine_setting.scss'

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share: {
        share_code: '',
        share_url: '',
      },
      down_url: '',
    }
  }
  copyBtn = code => {
    if (code) {
      copy(code)
      Toast.success('复制成功')
    } else {
      Toast.info('无复制内容')
    }
  }
  getCode = () => {
    Api.invite({}).then(res => {
      console.log('邀请码', res)
      if (res.code === 200) {
        let downs = res.data.download_array
        let type = Number(localStorage.platform) - 1
        this.setState({
          share: res.data,
          down_url: (downs && downs.length) > 0 ? downs[type].url : '',
        })
      }
    })
  }
  componentDidMount() {
    this.getCode()
  }
  down = src => {
    CheckUpData()
  }

  render() {
    const { share, down_url } = this.state
    const funcList = [
      {
        name: '邀请码',
        code: share.share_code,
        path: '',
        src: require('./../../assets/imgs/set/User_icon_yonghuxieyi@2x.png')
          .default,
      },
      {
        name: '邀请链接',
        code: share.share_url,
        path: '',
        src: require('./../../assets/imgs/set/User_icon_yonghuxieyi@2x.png')
          .default,
      },
      {
        name: 'APP下载',
        code: down_url,
        path: '',
        src: require('./../../assets/imgs/set/User_icon_yonghuxieyi@2x.png')
          .default,
      },
      {
        name: '推广说明',
        path: '/mine_invite',
        src: require('./../../assets/imgs/set/User_icon_yonghuxieyi@2x.png')
          .default,
      },
      {
        name: '推广素材',
        path: '/mine_invite_ad',
        src: require('./../../assets/imgs/set/User_icon_yaoqingma@2x.png')
          .default,
      },
      {
        name: '推广数据',
        path: '/team_info',
        src: require('./../../assets/imgs/tab4/icon_tuandui.png').default,
      },
    ]

    const funcListMap = funcList.map((item, index) => {
      return (
        <div
          className="item"
          key={item.name}
          onClick={() => {
            item.path && Control.go(item.path)
          }}
          style={{ display: index != 2 ? '' : item.code ? '' : 'none' }}
        >
          <div className="left">
            <img src={item.src} alt="" />
            <span>{item.name}</span>
            <span
              className="ft_line1 ft12"
              style={{ marginLeft: 5, color: '#e5c59d', maxWidth: 80 }}
            >
              {item.code || ''}
            </span>
          </div>
          <div className="right">
            {index == 2 && item.code && (
              <div
                onClick={() => {
                  this.down(item.code)
                }}
              >
                下载
              </div>
            )}
            {item.code && (
              <div
                onClick={() => {
                  this.copyBtn(item.code)
                }}
              >
                复制
              </div>
            )}
            <Icon
              type="right"
              style={{ display: index > 2 ? 'block' : 'none' }}
            />
          </div>
        </div>
      )
    })
    return (
      <div>
        <div className="navBoxBB">
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            团队中心
          </NavBar>
        </div>
        <div>
          <img
            src={require('./../../assets/imgs/team/Team_banner@2x.png').default}
            alt=""
            width="100%"
          />
        </div>
        <div className="mine-setting mine-team">
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            {funcListMap}
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default Team
