/* eslint-disable eqeqeq */
import Api from './api';
import OSS from 'ali-oss';
import { Toast } from 'antd-mobile';
import lrz from 'lrz';


export default class upload {
    constructor(param = {}) {
        this.param = param;
        this.filesBox = [];
        this.info = '';

    }
    open = () => {
        localStorage.debug = 'ali-oss';
        this.getParams()
    }
    getParams = () => {
        const { files } = this.param;
        console.log('将要上传的文件', files)
        Api.getFileUploadInfo({}).then(res => {
            // console.log('上传配置', res)
            if (res.code === 200) {
                this.info = res.data;
                this.info['secure'] = true;
                this.info['refreshSTSToken'] = async () => {
                    const info = await fetch(window.global_config.BASE_URL + '/oss/conf');
                    return {
                        accessKeyId: info.data.accessKeyId,
                        accessKeySecret: info.data.accessKeySecret,
                        stsToken: info.data.stsToken
                    }
                }
                this.info['refreshSTSTokenInterval'] = 3000000;
                console.log('上传配置：', this.info)

                if (files.length <= 0) {
                    console.log('没有文件');
                    Toast.fail('请您先选择文件', 2)
                    return false;
                }
                for (let i in files) {
                    try {
                        this.zipImg(files[i], i)
                    } catch (err) {
                        Toast.fail('上传失败，请稍后重试')
                        console.log(err)
                    }
                }
            }
        })
    }
    zipImg = (file, index) => {
        // console.log(file, file.file.type.indexOf('video'))
        console.log(file, file.file.type, file.file.type.indexOf('audio'))
        let that = this;
        if (file.file.type.indexOf('video') == -1 && file.file.type.indexOf('audio') == -1) {
            console.log("是图片")
            lrz(file.file, {
                width: 1200,
                height: 1600,
                fieldName: "file",
                quality: 1, //自定义使用压缩方式
            })
                .then(function (rst) {
                    //成功时执行
                    // console.log(rst)
                    // 上传图片
                    that.upLoad(rst, index, 1)
                })
                .catch(function (e) {
                    //失败时执行
                    console.log(e);
                })
                .always(function () {
                    //不管成功或失败，都会执行
                });

        } else {
            // 上传视频
            console.log("不是图片")
            let type = ''
            if (file.file.type.indexOf('video') != -1) {
                type = 2
            } else if (file.file.type.indexOf('audio') != -1) {
                type = 3
            }
            that.upLoad(file, index, type)
        }


    }
    upLoad = (file, index, type) => {
        // type=1  图片  2视频   3录音
        console.log('上传类型： 1图片 2视频 3录音    :', type)
        const { files, path } = this.param;
        let name = '';
        console.log(file, type)
        if (type == 1) {
            name = file.origin.name;
        } else if (type == 2) {
            name = file.file.name;
        } else if (type == 3) {
            name = (new Date()).getTime() + '.mp3'
        }
        console.log(name)
        console.log('ok', this.info, file, path);
        if (this.info.accessKeyId) {
            const client = new OSS(this.info);
            // 从输入框获取file对象，例如<input type="file" id="file" />。
            const data = file.file;
            file.progress = 0;
            // 创建并填写Blob数据。
            //const data = new Blob('Hello OSS');
            // 创建并填写OSS Buffer内容。
            //const data = new OSS.Buffer('Hello OSS');

            Toast.loading(`第${Number(index) + 1}个,${file.progress}`)

            client.multipartUpload(`/${path}/${name}`, data, {
                progress: e => {
                    // this.videoPercentage = Number((e * 100).toFixed(2))
                    console.log(Number(Math.round(e * 100)))
                    file.progress = Number(Number(Math.round(e * 100))) + '%';
                    Toast.loading(`第${Number(index) + 1}个,${file.progress}`)
                }
            })
                .then(res => {
                    Toast.hide()

                    let data1 = {
                        path: path,
                        name: name,
                        pathName: '/' + path + '/' + name
                    }
                    file.netPath = data1;
                    this.filesBox.push(data1);
                    if (this.filesBox.length === files.length) {
                        this.onSuccess(this.filesBox)
                    }

                })
                .catch((err) => {
                    Toast.fail('上传失败')
                    console.log('上传失败', err)
                    this.onFail(err)
                })


        } else {
            console.log('获取上传参数失败，暂时无法使用此功能')

        }

    }
    onSuccess = (data) => {
        let { onSuccess } = this.param;
        onSuccess && onSuccess(data);
    };
    onFail = (err) => {
        let { onFail } = this.param;
        onFail && onFail(err);
    };

}


