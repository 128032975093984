import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, Modal, List, Switch } from 'antd-mobile';
import "../../assets/style/mine_setting.scss";
import Api from '../../http/api';
import { Control } from 'react-keeper';

class MineSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invisible: false
        }
    }
    componentDidMount() {
        this.getMineInfo()
    }

    getMineInfo = () => {
        Api.getMineInfo({}).then(res => {
            console.log('我的资料详情', res)
            if (res.code == 200) {
                this.setState({ invisible: res.data.invisible })
            }
        })
    }
    ok = () => {
        const { invisible } = this.state;

        let parmas = { invisible: invisible ? 0 : 1 }
        Api.editInfo(parmas).then(res => {
            if (res.code === 200) {
                this.setState({
                    invisible: !invisible
                })
            }
        })
    }

    render() {

        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >隐身功能</NavBar>
                <div className='mine-setting mine-setting-state'>
                    <WingBlank size='lg'>
                        <WhiteSpace size='lg' />
                        <List.Item
                            className='itemm'
                            extra={<Switch
                                checked={this.state.invisible}
                                color='#d2b793'
                                onChange={() => { this.ok() }}
                            />}
                        >在首页列表隐藏我</List.Item>
                    </WingBlank>
                </div>

            </div>
        )
    }
}
export default MineSetting;
