/* eslint-disable eqeqeq */
import { Toast } from 'antd-mobile'
import React from 'react'
import { Control } from 'react-keeper'
import Websocket from 'react-websocket'
// import { getPosition } from './../http/common'

let timer1 = '' //心跳  setHeart
let timer2 = '' //位置  setPosition
let timer3 = '' //用户绑定
const baseSocketUrl = window.global_config.BASE_SOCKET_URL

class ReactWebsocket extends React.Component {
  constructor(props) {
    super(props)
    window.sendMsg = this.sendMessage
    window.handleClose = this.handleClose
    window.reStartSocket = this.reStartSocket

    this.state = {
      isServeType: localStorage.isServeType,
      isBinding: false,
      openSocket: true, //重新加载新的socket
    }
  }
  // 收到消息
  handleData = e => {
    // console.log(JSON.parse(e))
    let res = JSON.parse(e)
    let messageEvent = MessageEvent || window.MessageEvent
    if (messageEvent) {
      let msg = new messageEvent('message', { data: res })
      window.dispatchEvent(msg)
    } else {
      window.postMessage(res)
    }
    this.msgType(res)
  }

  msgType = res => {
    if (res.type === 'bindStatus') {
      if (res.data.status == 1) {
        window.isOnline = true
        console.log('用户socket绑定成功')
        this.sendMessage({
          type: 'setUserPosition',
          class: 1,
          data: {
            time: new Date().getTime(),
            token: localStorage.token,
            position: `${localStorage.lon || ''},${localStorage.lat || ''}`,
          },
        })
        this.setPosition()
      } else {
        window.isOnline = ''
      }
    } else if (res.type === 'cBindStatus') {
      if (res.data.status == 1) {
        window.isOnline = true
        console.log('客服socket绑定成功')
      } else {
        window.isOnline = ''
      }
    } else if (res.type === 'sendError') {
      console.log('发送错误')
      Toast.fail(res.data.message)
    } else if (res.type === 'tokenError') {
      // Toast.fail('token错误,请您重新登陆')
      this.goLogin()
    }
  }
  handleOpen = e => {
    console.log('连接建立成功', this.refWebSocket)
    let rand = Math.floor(Math.random() * 100)
    timer3 && clearInterval(timer3)
    console.log('timer3:', timer3)
    timer3 = ''
    console.log('timer3:', timer3)
    if (!timer3) {
      timer3 = setInterval(() => {
        if (this.refWebSocket) {
          if (this.refWebSocket.state.ws.readyState == 1) {
            //readyState == 1 socket 已经链接
            if (!window.isOnline) {
              //未绑定用户时
              this.setState(
                {
                  isServeType: localStorage.isServeType,
                },
                () => {
                  if (this.state.isServeType == 2) {
                    console.log('推 不需绑定')
                  } else {
                    console.log('去绑定')
                    this.bindUser()
                  }
                }
              )
            } else {
              //绑定用户时
              // console.log('已绑定', rand)
            }
          } else if (this.refWebSocket.state.ws.readyState == 2) {
            console.log('webscoket正在链接', rand)
          } else if (this.refWebSocket.state.ws.readyState == 3) {
            console.log('webscoket已经关闭连接', rand)
            this.reStartSocket()
          }
        } else {
          window.isOnline = ''
          console.log('暂未连接', rand)
        }
      }, 2000)
    }

    this.setHeart()
  }
  // 连接断开
  handleClose = e => {
    console.log('连接断开', e)
    window.isOnline = ''
  }
  goLogin = () => {
    Toast.info('登陆失效,请重新登陆')
    let ip = localStorage.ip || ''
    let metaInfo = localStorage.metaInfo || ''
    let platform = localStorage.platform || ''
    let isApp = localStorage.isApp || ''

    let agree = localStorage.agree || ''

    let isFirstOpenApp = localStorage.isFirstOpenApp
    localStorage.clear()
    localStorage.isFirstOpenApp = isFirstOpenApp

    localStorage.agree = agree

    window.isOnline = ''
    this.handleClose()

    localStorage.ip = ip
    localStorage.metaInfo = metaInfo
    localStorage.platform = platform
    localStorage.isApp = isApp
    setTimeout(() => {
      Control.replace('/')
    }, 3000)
  }

  // 发送消息
  sendMessage = e => {
    // console.log(e)
    if (!localStorage.token) {
      this.goLogin()
      return false
    }
    if (e.type != 'pong' && e.type != 'setUserPosition') {
      // console.log(e)
    }

    if (this.refWebSocket && this.refWebSocket.state.ws.readyState == 1) {
      this.refWebSocket.sendMessage(JSON.stringify(e))
    } else {
      console.log('连接已断开')
      console.log(this.refWebSocket)
    }
  }
  // 心跳
  setHeart = () => {
    timer1 && clearInterval(timer1)
    timer1 = ''
    if (!timer1) {
      timer1 = setInterval(() => {
        if (this.state.isServeType == 2) {
          // console.log('推 不需socket')
        } else if (window.isOnline && localStorage.token) {
          this.sendMessage({
            type: 'pong',
            class: this.state.isServeType ? 2 : 1,
            data: {
              time: new Date().getTime(),
            },
          })
        }
      }, 30000)
    }
  }
  //位置
  setPosition = () => {
    timer2 && clearInterval(timer2)
    timer2 = ''
    if (!timer2) {
      timer2 = setInterval(() => {
        if (!localStorage.isServe && window.isOnline && localStorage.token) {
          this.sendMessage({
            type: 'setUserPosition',
            class: 1,
            data: {
              time: new Date().getTime(),
              token: localStorage.token,
              position: `${localStorage.lon || ''},${localStorage.lat || ''}`,
            },
          })
          // getPosition()
        }
      }, 60000)
    }
  }

  // 用户绑定
  bindUser = () => {
    if (this.state.isBinding) {
      return false
    } else {
      this.setState(
        {
          isBinding: true,
        },
        () => {
          if (localStorage.token) {
            console.log('isServeType', this.state.isServeType)
            this.sendMessage({
              type: this.state.isServeType == 1 ? 'cBind' : 'bind',
              class: this.state.isServeType == 1 ? 2 : 1,
              data: {
                token: localStorage.token,
              },
            })
          } else {
            console.log('未登录')
          }
          setTimeout(() => {
            this.setState({
              isBinding: false,
            })
          }, 2000)
        }
      )
    }
  }
  reStartSocket = () => {
    window.isOnline = ''
    this.setState({
      openSocket: false,
    })
    setTimeout(() => {
      this.setState({
        openSocket: true,
      })
    }, 500)
  }
  render() {
    return (
      <div>
        {/* {this.state.openSocket && */}
        <Websocket
          url={baseSocketUrl}
          onMessage={this.handleData} //接受信息的回调
          onOpen={this.handleOpen} //websocket打开
          onClose={this.handleClose} //websocket关闭
          reconnect={true}
          debug={true}
          ref={Websocket => {
            this.refWebSocket = Websocket
          }}
        />
        {/* } */}
      </div>
    )
  }
}

export default ReactWebsocket
