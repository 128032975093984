import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, ImagePicker, Toast } from 'antd-mobile';
import Api from '../../http/api';
import Upload from '../../http/upload';
import { Control } from 'react-keeper';



class MineRealCar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            files2: [],

        }
    }
    componentDidMount() {
        console.log('挂在完成')
    }

    onChange = (files, type, index) => {
        console.log(files, type, index);
        if (this.state.files.length > 0) {
            this.setState({
                files: []
            });
        }
        this.setState({
            files,
        });
    }
    onChange2 = (files2, type, index) => {
        console.log(files2, type, index);
        if (this.state.files2.length > 0) {
            this.setState({
                files2: []
            });
        }
        this.setState({
            files2,
        });
    }
    ok = () => {
        const { files, files2 } = this.state;
        // console.log(files, files2)
        let imgsBox = [...files, ...files2];
        console.log(imgsBox)
        if (imgsBox.length > 0) {
            this.uploadImg(imgsBox, 'realHouse', res => {
                let imgs = []
                res.forEach(img => {
                    imgs.push(img.pathName)
                });
                Api.realCar({ vehicle_img: imgs }).then(res2 => {
                    console.log(res2)
                    if (res2.code == 200) {
                        Toast.success(res2.msg, 3)
                        setTimeout(() => {
                            Control.go(-1)
                        }, 3000);
                    }
                })

            })
        } else {
            Toast.fail('请添加证件照')
        }
    }

    // step1   上传照片
    uploadImg = (files, pathName, callback = () => { }) => {
        console.log(files, pathName)
        let upLoad = new Upload({
            files: files,
            path: pathName,
            onSuccess: (res) => {
                // console.log('照片上传完毕：', res)
                callback(res)
            },
            onFail: (err) => {
                // console.log('照片上传失败：', err)
                callback(false)
            }
        })
        upLoad.open()
    }
    render() {
        const bg1 = this.state.files.length > 0 ? this.state.files[0].url : require('./../../assets/imgs/icon/quesheng_car2@2x.png').default;
        const bg2 = this.state.files2.length > 0 ? this.state.files2[0].url : require('./../../assets/imgs/icon/car_2@2x.png').default;
        return (
            <div className='login-page change_pwd_page mine_question_page mine_real_name_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >车辆认证</NavBar>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>
                    <div className='imgsBox'>
                        <div className='left'>
                            <div className='ft16 mb5 color255'>证件页一</div>
                            <div className='ft14 info'>请上传清晰行驶证页</div>
                        </div>
                        <div className='right' style={{ backgroundImage: `url(${bg1})` }} >
                            <ImagePicker
                                length="1"
                                files={this.state.files}
                                onChange={this.onChange}
                                selectable={this.state.files.length < 1}
                            />
                        </div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='imgsBox'>
                        <div className='left'>
                            <div className='ft16 mb5 color255'>证件页二</div>
                            <div className='ft14 info'>请上传清晰车辆照片</div>
                        </div>
                        <div className='right' style={{ backgroundImage: `url(${bg2})` }}>
                            <ImagePicker
                                length="1"
                                files={this.state.files2}
                                onChange={this.onChange2}
                                selectable={this.state.files2.length < 1}
                            />
                        </div>
                    </div>
                    <WhiteSpace size="lg" />
                    {/* <div className='label'>注： </div> */}
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' onClick={this.ok}>确认提交</Button>
                </WingBlank>
            </div >

        )
    }
}
export default MineRealCar;

