import React from "react";
import "../assets/style/tabbar.scss";

const Conn = (WrappedComponent) => class Conn extends React.Component {
  render() {
    return (
      <div>
        <WrappedComponent {...this.props} />
      </div>
    );
  }
}

export default Conn;

