
const Tab5Item = (props) => {
    // console.log(props);
    const { item, goInfo } = props;

    const setJuli = (item) => {
        if (!item && item !== 0) {
            return '';
        } else if (item === 0) {
            return '附近';
        } else {
            item = Number(item)
            if (item > 1000) {
                return (item / 1000).toFixed(2) + 'km'
            } else {
                return item + 'm'
            }
        }
    }
    // console.log(item, goInfo)
    const ListItem = <div className='item' onClick={() => { goInfo(item) }}>

        <div className='item-left'>
            <img src={item.head_img} onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} alt='head' />
            <div className='is_video' style={{ display: item.video == 1 ? 'flex' : 'none' }}>
                <div></div>
            </div>
        </div>

        <div className='item-right'>
            <div className='name  flex flex_left_center'>
                {item.nickname || ' '}
                <img src={require('../assets/imgs/icon/vip2.png').default} alt='' style={{ opacity: item.is_vip === 1 ? '1' : '0' }} />
            </div>
            <div className='addr flex flex_left_center'>
                {item.region_name}·{setJuli(item.distance)}
                {/* ·{item.ctime} */}
            </div>
            <div className='info ft_line1' >
                {item.title}
            </div>
        </div >
        {
            item.content_img.length > 0 && <div className='imgBox'>
                <img
                    src={(item.content_img)[0]}
                    alt=""
                    onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }}
                />
            </div>
        }

    </div >
    return (
        <div>
            {ListItem}
        </div>
    );

}
export default Tab5Item;