/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, Modal, Toast, InputItem } from 'antd-mobile';
import '../../assets/style/user_info.scss';
import Api from '../../http/api';
import { Control } from 'react-keeper';

import OpenLuck from "./../../components/OpenLuck";


class MineLuck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: 0,
            giftList: [], //盲盒列表
            realGift: [], //实物
            nRealGift: [], //虚拟
            giftLuck: '', //中奖物品

            giftNum: 0, //可用次数
            giftPrice: 0,//拆盲盒次数价格
            money: 0, //余额
            buyNum: 1,//购买次数
            buyAllMoney: 0,//总额
            showBuyGiftNumBox: false
        }
    }
    componentDidMount() {
        this.getGiftList()
        this.getGiftNum()
    }
    getGiftList = () => {
        Api.giftList({}).then(res => {
            console.log('盲盒列表', res)
            if (res.code === 200) {
                this.setState({
                    giftList: [...res.data.real, ...res.data.fictitious],
                    realGift: res.data.real,
                    nRealGift: res.data.fictitious
                })
            }
        })
    }
    // 打开盲盒
    openGift = () => {
        const { giftNum } = this.state
        if (giftNum > 0) {
            Api.giftLuck({}).then(res => {
                console.log('抽取盲盒结果', res)
                if (res.code === 200) {
                    this.setState({
                        giftLuck: res.data,
                        giftNum: giftNum - 1
                    })
                }
            })
        } else {
            this.setState({
                showBuyGiftNumBox: true
            }, () => {
                this.num.focus();
            })
        }

    }
    buyLuckNum = () => {
        const { money, buyNum, buyAllMoney } = this.state;
        console.log(buyNum)
        if (!buyNum || buyNum == 0 || buyNum < 0) {
            Toast.info('购买次数最小为1')
            this.setState({
                buyNum: 1,
                buyAllMoney: 1 * this.state.giftPrice
            })
            this.num.focus();
            return false
        }

        if (!(money < buyAllMoney)) {
            Api.giftLuckBuyNum({ count: buyNum }).then(res => {
                console.log('购买结果', res)
                if (res.code === 200) {
                    this.getGiftNum()
                    Toast.success(res.msg, 2)
                    setTimeout(() => {
                        this.setState({
                            showBuyGiftNumBox: false,
                            buyNum: 1,
                            buyAllMoney: this.state.giftPrice
                        })
                    }, 2000);
                }
            })
        } else {
            this.setState({
                showBuyGiftNumBox: false
            })
            Toast.fail('余额不足', 3)
            setTimeout(() => {
                Control.go('/mine_recharge')
            }, 3000);
        }


    }
    changeTab = (tab) => {
        if (tab != this.state.tabActive) {
            this.setState({
                tabActive: tab,
            }, () => {
                let arr = '';
                if (tab == 0) {
                    arr = [...this.state.realGift, ...this.state.nRealGift]
                } else if (tab == 1) {
                    arr = this.state.realGift
                } else if (tab == 2) {
                    arr = this.state.nRealGift
                }
                this.setState({
                    giftList: arr
                })
            })
        }
    }
    // 获取次数
    getGiftNum = () => {
        Api.giftNum({}).then(res => {
            console.log('抽取可用次数', res)
            if (res.code === 200) {
                this.setState({
                    giftNum: res.data.luck_draw_count,
                    money: res.data.money == '0.00' ? '0' : Number(res.data.money),
                    giftPrice: Number(res.data.gift_price),
                    buyAllMoney: Number(res.data.gift_price) * this.state.buyNum
                })
            }
        })
    }
    getNum = () => {
        let num = {
            left: Math.floor(Math.random() * 100) + '%',
            // bottom: Math.floor(Math.random() * 100) + '%',
            '--index2': 'rgba(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + 1 + ')',
            animation: `run3 6s ${Math.floor(Math.random() * 6000) + 'ms'} linear infinite`,
            '--index': Math.floor(Math.random() * 100) + '%'
        };
        return num
    }
    handleChange = (name, e) => {
        console.log(e)
        e = e.split('.')[0];
        this.setState({
            buyNum: e,
            buyAllMoney: e * this.state.giftPrice
        })

    }

    render() {
        const { tabActive, giftList, giftLuck } = this.state;
        const tabs = ['盲盒产品', '实物盲盒', '虚拟盲盒',];
        const info = ['63.9', '30', '5', '1', '0.1']

        const tabsList = tabs.map((tab, index) => {
            return (
                <div key={tab} className={tabActive == index ? 'active' : ''} onClick={() => {
                    this.changeTab(index)
                }}>{tab}</div>
            )
        })
        const popList = [...this.state.realGift, ...this.state.nRealGift].map((item, index) => {
            return (
                <div className='luckPopItem action1' style={this.getNum()} key={'pop' + index}>
                    <img src={item.img} alt="" />
                </div>
            )
        })
        const giftLists = giftList.map((item, index) => {
            return (
                <div className='giftItem' key={'gift' + index}>
                    <div className='left'>
                        <img src={item.img} alt="" />
                    </div>
                    <div className='right'>
                        <div className='title ft_line1'>
                            {item.label && <img src={require(`./../../assets/imgs/gift/icon_${item.label}.png`).default} alt="" />}
                            {item.gift_name}
                        </div>
                        <div className='price ft_line1'>市场参考价 <span>{item.gift_price}</span> </div>
                    </div>
                </div>
            )
        })
        return (
            <div className='mine-luck-page'>
                <div className='navBoxBB'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                        rightContent={[
                            <div key='0' className='ft12 opacity7' onClick={() => {
                                Control.go('mine_luck_record')
                            }}>盲盒记录 </div>
                        ]}
                    />
                </div>
                <WingBlank size='lg'>
                    <WhiteSpace size='lg' />
                    <WhiteSpace size='lg' />
                    <div className='luck-box'>
                        <img src={require('./../../assets/imgs/gift/gift.png').default} alt="" />
                        <img className='point' src={require('./../../assets/imgs/gift/point.png').default} alt="" />
                    </div>
                    <div className='luck-pop'>
                        {popList}
                    </div>
                    <div className='btn' onClick={() => { this.openGift() }}>
                        开启盲盒  <span className='opacity7 ft14'>{'   ( ' + this.state.giftNum + ' 次)'}</span>
                    </div>
                    <div className='lbBox'>
                        <div className='titlee'>
                            商品产出概率
                        </div>
                        <div className='luck-num'>
                            {info.map((item, index) => {
                                return (
                                    <div key={item}>
                                        <img src={require(`./../../assets/imgs/gift/icon_${index + 1}.png`).default} alt="" />
                                        <span>{item + '%'}</span>
                                    </div>
                                )
                            })}

                        </div>
                        <div className='ft12 titlee opacity7' style={{ color: '#d3b894' }} >开盒必出以下宝贝之一</div>
                    </div>
                    <div className='tabsBox'>
                        {tabsList}
                    </div>
                    <div className='giftList'>
                        {giftLists}
                    </div>
                </WingBlank>

                {this.state.showBuyGiftNumBox &&
                    <div>
                        <div className='infoPop'>
                            <div className='ft16  title'>购买开盲盒次数</div>
                            {/* <div className='priceBox'>
                            <img src={nowItem.gift_info.img} alt="" />
                            <span>{nowItem.gift_info.gift_name}  <small className='opacity7'>价值：{nowItem.gift_info.gift_price}</small> </span>

                        </div> */}
                            <div>
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <InputItem
                                    className='input-box'
                                    value={this.state.money}
                                    type="text"
                                    placeholder="账户余额"
                                    editable={false}
                                >
                                    账户余额
                                </InputItem>
                                <WhiteSpace size="md" />
                                <InputItem
                                    className='input-box'
                                    value={this.state.giftPrice}
                                    type="text"
                                    placeholder="单次价格"
                                    editable={false}
                                >
                                    单次价格
                                </InputItem>

                                <WhiteSpace size="md" />
                                {/* <InputItem
                                    className='input-box'
                                    value={this.state.buyAllMoney}
                                    type="text"
                                    placeholder="0"
                                    editable={false}
                                >
                                    消费总额
                                </InputItem> */}

                                <InputItem
                                    className='input-box'
                                    value={this.state.buyNum}
                                    type="number"
                                    placeholder=" 购买次数"
                                    onChange={(v) => { this.handleChange('buyNum', v) }}
                                    ref={e => this.num = e}
                                >
                                    购买次数
                                </InputItem>
                                {/* <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" /> */}
                                {/* <WhiteSpace size="lg" /> */}
                                <WhiteSpace size="lg" />
                            </div>
                            <div className='btns'>
                                <div onClick={e => {
                                    this.setState({ showBuyGiftNumBox: false })
                                }}>取消</div>
                                <div onClick={this.buyLuckNum}>立即购买</div>
                            </div>
                        </div>
                        <div className='fik'></div>
                    </div>
                }

                {giftLuck && <OpenLuck gift={giftLuck} open={() => {
                    Control.go('mine_luck_record')
                }} close={() => { this.setState({ giftLuck: '' }) }} />}

            </div>
        )
    }
}
export default MineLuck;
