import { Icon, NavBar, WhiteSpace, WingBlank } from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import Api from '../../http/api'

import '../../assets/style/team.scss'

class TeamList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
  }
  componentDidMount() {
    localStorage.isServeType = 2
    this.getList()
  }
  getList = () => {
    Api.pushInfo({}).then(res => {
      console.log(res)
      let data = res.data
      if (res.code == 200) {
        let valueList = [
          data.today.promotion_number,
          data.today.recharge_number,

          data.yesterday.promotion_number,
          data.yesterday.recharge_number,

          data.thisMonth.promotion_number,
          data.thisMonth.recharge_number,

          data.lastMonth.promotion_number,
          data.lastMonth.recharge_number,

          data.total.promotion_number,
          data.total.recharge_number,
        ]
        this.setState({ valueList })
      }
    })
  }
  out = () => {
    let ip = localStorage.ip || ''
    let metaInfo = localStorage.metaInfo || ''
    let platform = localStorage.platform || ''
    let isApp = localStorage.isApp || ''
    let isWx = localStorage.isWx || ''

    let agree = localStorage.agree || ''
    let isFirstOpenApp = localStorage.isFirstOpenApp
    localStorage.clear()
    localStorage.isFirstOpenApp = isFirstOpenApp
    localStorage.agree = agree

    window.isOnline = ''
    window.handleClose('关闭聊天')

    localStorage.ip = ip
    localStorage.metaInfo = metaInfo
    localStorage.platform = platform
    localStorage.isApp = isApp
    localStorage.isWx = isWx

    Control.replace('/')
  }

  render() {
    const { valueList } = this.state
    const funcList = [
      {
        name: '今日新增注册',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_jinrixinzeng@2x.png')
          .default,
      },
      {
        name: '今日充值人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_tuanduizong@2x.png')
          .default,
      },
      {
        name: '昨日推广注册',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_jinrixinzeng@2x.png')
          .default,
      },

      {
        name: '昨日充值人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_tuanduizong@2x.png')
          .default,
      },

      {
        name: '本月推广注册',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_benyuetui@2x.png')
          .default,
      },
      {
        name: '本月充值人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_benyuetui@2x.png')
          .default,
      },

      {
        name: '上月推广注册',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_benyuetui@2x.png')
          .default,
      },
      {
        name: '上月充值人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_benyuetui@2x.png')
          .default,
      },
      {
        name: '推广总人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_tuanduizong@2x.png')
          .default,
      },
      {
        name: '充值总人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_tuanduizong@2x.png')
          .default,
      },
    ]
    const funcListMap = funcList.map((item, index) => {
      return (
        <div className="item" key={item.name}>
          <div className="left">
            <img src={item.src} alt="" />
          </div>
          <div className="right">
            <span> {valueList[index]}</span>
            <span> {item.name}</span>
          </div>
        </div>
      )
    })
    return (
      <div>
        <div className="navBoxBB">
          <NavBar
            mode="dark"
            // icon={<Icon type="left" />}
            onLeftClick={this.out}
            leftContent={[
              <div
                className="flex flex_left_center ft12 opacity7"
                key="0"
                onClick={() => {
                  localStorage.loginAuto = ''
                }}
              >
                <Icon type="left" /> 退出
              </div>,
            ]}
            rightContent={[
              <div
                className="ft12 opacity7"
                key="0"
                onClick={() => {
                  Control.go('mine_invite_ad2')
                }}
              >
                推广素材
              </div>,
            ]}
          >
            推广数据
          </NavBar>
        </div>
        <div className="teamPage" style={{ marginTop: 55 }}>
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            <div className="teamBox">{funcListMap}</div>
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default TeamList
