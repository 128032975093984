import React from 'react';
import './../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button } from 'antd-mobile';

const SetInfo4 = (props) => {
    const handleChange = props.handleChange;
    const { wx, qq } = props.state;


    return (
        <div >
            <WingBlank size='lg' className='set-info4'>
                <InputItem
                    className='input-box'
                    value={wx}
                    type="text"
                    placeholder="微信账号"
                    onChange={(v) => {handleChange('wx', v) }}
                >
                    <div style={{ backgroundImage: `url(${require('./../assets/imgs/icon/Icon_WeChat.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                </InputItem>
                <InputItem
                    className='input-box'
                    value={qq}
                    type="text"
                    placeholder="QQ账号"
                    onChange={(v) => {handleChange('qq', v) }}
                >
                    <div style={{ backgroundImage: `url(${require('./../assets/imgs/icon/Icon_QQ.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                </InputItem>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button className='login-btn change-btn' onClick={props.nextStep}>进入主页</Button>
            </WingBlank>

        </div >

    )

}
export default SetInfo4;

