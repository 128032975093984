
import React from 'react';
import Conn from '../components/Conn';
import { Flex, WingBlank, ListView, Modal, Toast, NavBar, Icon, PullToRefresh, SearchBar, SwipeAction } from 'antd-mobile';
import '../assets/style/tab3.scss';
import Api from '../http/api';
import { Control } from 'react-keeper';





class serveHome extends React.Component {
    constructor(props) {
        super(props)
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
            // rowHasChanged: (row1, row2) => true
        });
        this.state = {
            page: 1, //页码
            limit: 50, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
            search: '',

            refreshing: false,
        }

    }
    goInfo = (item) => {
        console.log(item)
        Control.go(encodeURI('/chat_serve2/' + item.uid + '/' + item.nickname))
    }

    // 获取好友信息

    componentDidMount() {
        // console.log('serveHome页面')
        localStorage.isServeType = 1;
        this.getList();
        window.scrollTo(0, 0);
        window.addEventListener('message', this.onMessage, false)
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
        window.removeEventListener('message', this.onMessage)
        this.setState = () => {
            return
        }
    }
    onMessage = (res) => {
        const { allBillData } = this.state;
         // if (typeof (res) == 'string') {
        //     res = JSON.parse(res)
        // }
        let msg = res.data;
          // if (res.data && typeof (res.data) == 'string') {
        //     msg = JSON.parse(res.data)
        // }
        // console.log('我在serveHome里：', msg);
        if (msg.type === 'serviceMessageCallback') {
            let index = allBillData.findIndex(item => item.uid == msg.data.from.id);
            console.log(index)
            let newItem = {
                content: msg.data.message.content,
                head_img: msg.data.from.head_img,
                id: '',
                last_time: msg.data.message.time,
                nickname: msg.data.from.nickname,
                no_read: 0,
                type: msg.data.message.type,
                uid: msg.data.from.id,
            }
            if (index != -1) {
                newItem.no_read = allBillData[index].no_read + 1;
                this.setState({
                    allBillData: allBillData.map((item) => item.uid == msg.data.from.id ? newItem : item),
                }, () => {
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.state.allBillData),
                    })
                })

            } else {
                newItem.no_read = 1;
                this.setState({
                    allBillData: [...this.state.allBillData, newItem],
                }, () => {
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.state.allBillData),
                    })
                })
            }
            this.playMusic()
        }

    }

    onRefresh = () => {
        console.log('刷新')

        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            refreshing: true,
            isLoading: true,
            isHasMore: true,
            page: 1,
            dataSource: this.state.dataSource.cloneWithRows(emptyList)
        }, () => {
            this.getList();
        });
    };

    getList = () => {
        const { limit, page, isHasMore } = this.state;
        if (isHasMore) {
            Api.serveChatList({
                size: limit,
                page: page
            }).then(res => {
                console.log(res)
                if (res.code === 200) {
                    let allbi = this.state.preBillData.concat(res.data);
                    this.setState({
                        billData: res.data,
                        preBillData: allbi,
                        allBillData: allbi,

                        page: Number(this.state.page) + 1,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.length > 0 ? true : false,
                        isLoading: false,
                        refreshing: false
                    })
                }
                window.scrollTo(0, 0)
            })
        }

    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    onChange = (search) => {
        console.log(search)
        const { allBillData } = this.state;
        let arr = allBillData.filter(item => {
            return ((item.nickname).indexOf(search) != -1 || String(item.uid).indexOf(search) != -1)
        })
        this.setState({
            search,
            dataSource: this.state.dataSource.cloneWithRows(arr)
        });
        console.log(arr)

    };
    goOut = () => {
        const alertInstance = Modal.alert('退出', '清理登录信息,并退出？', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '退出', onPress: () => {
                    localStorage.token = '';
                    localStorage.serveInfo = '';
                    localStorage.loginAuto = '';
                    window.isOnline = '';
                    window.handleClose('关闭聊天')
                    Control.replace('/')
                }
            },
        ]);
    }
    //删除
    del = (uid) => {
        console.log('删除', uid)
        Toast.info('开发中', 2)
    }
    playMusic = () => {
        let audio = document.getElementById("music");
        if (audio) {
            let playPromise = audio.play();
            if (playPromise) {
                playPromise
                    .then(() => {
                        // console.log("audio played auto");
                    })
                    .catch(() => {
                        // console.log("playback prevented");
                    });
            }
        }
    }

    render(h) {
        const { isHasMore, isLoading, billData, search } = this.state;

        const row = (item, sectionID, rowID) => {
            return (
                <SwipeAction
                    key={item.id}
                    autoClose
                    right={[
                        {
                            text: '删除',
                            onPress: () => { this.del(item.uid) },
                            style: { backgroundColor: '#F4333C', color: 'white', width: '80px' },
                        },
                    ]}

                >
                    <div className='item' style={{ background: item.no_read > 0 ? '#333' : '' }} onClick={() => { this.goInfo(item) }}>
                        <div className='item-left' >
                            <div className='imBox imBox2'>
                                <img src={item.head_img} alt='head' onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} />
                            </div>
                            <div className='msgNoRead' style={{ display: item.no_read > 0 ? 'block' : 'none' }}>{item.no_read > 99 ? 99 : item.no_read}</div>
                        </div>
                        <div className='item-right' >
                            <div className='name mb2 flex flex_left_center ft_line1' style={{ color: '#fff' }}>
                                <span className='id'>[{item.uid}]</span>
                                <span>{item.nickname ? item.nickname : '简约用户'}</span>
                            </div>
                            <div className='dt'>
                                <div className='dt-text ft_line1 opacity7' style={{ color: '#fff' }}>{item.type === 1 ? item.content : '[图片]'}</div>
                            </div>
                            <div className='state' style={{ top: 'auto', bottom: '3px' }}>{item.last_time}</div>
                        </div>
                    </div>
                </SwipeAction>
            )
        }
        return (
            <div className='tab3 serveHome'>
                <div className='navBoxx'>
                    <NavBar
                        mode="dark"
                        rightContent={[
                            <div key='0' onClick={this.goOut} className='ft12 opacity7' >退出</div>,
                        ]}
                    >消息列表</NavBar>
                    <div className='searchBox'>
                        <SearchBar value={search} placeholder="搜索" onChange={(e) => { this.onChange(e) }} />
                    </div>
                </div>
                <div style={{ height: 90 }}></div>
                <div className='tab3-content' style={{ marginTop: '20px' }}>
                    <ListView
                        ref={el => this.listView = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                {
                                    isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                }
                                {
                                    (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                }

                            </div>
                        )}
                        renderRow={row} //每行数据渲染
                        style={{
                            minHeight: 'calc(100vh - 20px)', //高度需要定义
                            overflow: 'auto'
                        }}
                        pageSize={10}  //一次渲染几条数据
                        initialListSize={10}
                        onEndReached={this.onEndReached}//加载新的数据
                        onEndReachedThreshold={200}//距离底部40时候 加载数据

                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                    />

                </div>
            </div>
        )

    }
}


export default Conn(serveHome);