/* eslint-disable eqeqeq */
import { Carousel, Flex, Icon, WhiteSpace, WingBlank } from 'antd-mobile'
import React from 'react'
import { Control, Link } from 'react-keeper'
import '../assets/style/tab4.scss'
import Tabbar from './../components/Tabbar'
import Api from './../http/api'
import { CheckUpData } from './../http/updata'

class Tab4 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mineInfo: {},
      banner: [
        {
          path: '/mine_invite',
          imgSrc: require('../assets/imgs/tab4/User_banner@2x.png').default,
        },
        // {
        //   path: '',
        //   imgSrc: require('../assets/imgs/tab4/User_banner1@2x.png').default,
        // },
        // {
        //   path: '/mine_luck',
        //   imgSrc: require('../assets/imgs/tab4/luck.png').default,
        // }, // 开盲盒福利
      ],
      showSetInfo: false, // 完善资料弹窗
    }
  }
  componentDidMount() {
    this.getMineInfo()
    this.getMineInfo2()
  }
  getMineInfo = () => {
    Api.mineInfo({}).then(res => {
      // console.log('我的信息', res)
      if (res.code == 200) {
        this.setState({
          mineInfo: res.data,
        })
        localStorage.userInfo = JSON.stringify(res.data)
      }
    })
  }
  setMoney = money => {
    console.log(money)
    if (money) {
      if (Number(money) > 10000) {
        const mon = parseFloat(Number(money))
        const b = Math.floor((mon / 10000) * 100) / 100 + 'w'
        return b
      }
    } else {
      return money
    }
  }
  goTo = path => {
    console.log(path)
    if (path) {
      Control.go(path)
    }
  }
  getServe = () => {
    Api.getServe({}).then(res => {
      console.log(res)
      if (res.code == 200) {
        if (res.data.id) {
          sessionStorage.serveInfo = JSON.stringify(res.data)
          setTimeout(() => {
            Control.go('/chat_serve')
          }, 500)
        }
      }
    })
  }
  showImg = () => {}

  getMineInfo2 = () => {
    console.log('getMineInfo2')

    let oldTime = localStorage.checkInfoAlert
      ? Number(localStorage.checkInfoAlert)
      : ''
    let newTime = new Date().getTime()

    console.log(oldTime, newTime, newTime - oldTime)

    if (!oldTime || newTime - oldTime > 1 * 24 * 60 * 60 * 1000) {
      console.log('需要检测资料完整度')

      Api.getMineInfo({}).then(res => {
        console.log('我的资料详情', res)
        if (res.code == 200) {
          let info = res.data
          if (
            !info.about_me ||
            info.about_me.length == 0 ||
            !info.age ||
            !info.brief ||
            !info.education ||
            !info.height ||
            !info.nickname ||
            !info.occupation ||
            !info.weight ||
            !info.nickname
          ) {
            setTimeout(() => {
              this.setState({
                showSetInfo: true,
              })
              localStorage.checkInfoAlert = newTime
            }, 1000)
          }
        }
      })
    }
  }
  render(h) {
    const { mineInfo, banner } = this.state

    const tabs = [
      // { title: '我的邀约', img: require('../assets/imgs/tab4/icon_yuehui.png').default, path: '/mine_data' },
      {
        title: '我的动态',
        img: require('../assets/imgs/tab4/icon_dongtai.png').default,
        path: '/mine_info',
      },
      {
        title: '我的相册',
        img: require('../assets/imgs/tab4/icon_xiangce.png').default,
        path: '/mine_photos',
      },
      {
        title: '认证中心',
        img: require('../assets/imgs/tab4/icon_shimingrenzheng.png').default,
        path: '/mine_ac',
      },
      // { title: '银行卡', img: require('../assets/imgs/tab4/icon_yinhangka.png').default, path: '/mine_payway' },
      {
        title: '我的钱包',
        img: require('../assets/imgs/tab4/icon_yinhangka.png').default,
        path: '/mine_wallet',
      },
      {
        title: '团队中心',
        img: require('../assets/imgs/tab4/icon_tuandui.png').default,
        path: '/team',
      },

      {
        title: '意见反馈',
        img: require('../assets/imgs/tab4/icon_wentifankui.png').default,
        path: '/mine_question',
      },
      {
        title: '账户设置',
        img: require('../assets/imgs/tab4/icon_shezhi.png').default,
        path: '/mine_setting',
      },
      {
        title: '联系客服',
        img: require('../assets/imgs/tab4/icon_zaixiankefu.png').default,
        path: '/chat_serve',
      },
    ]
    const tabsList = tabs.map((tab, index) => {
      return (
        <div
          onClick={() => {
            this.goTo(tab.path)
          }}
          key={index}
          className="tabs-item"
        >
          <img src={tab.img} alt="" />
          <div>{tab.title}</div>
        </div>
      )
    })
    return (
      <div className="tab4">
        <div className="head-box">
          <WingBlank size="lg">
            {/* 头部 */}
            <WhiteSpace size="lg" />
            <div className="changeInfo">
              <Link to="/mine_change_info">
                <div>
                  <img
                    src={
                      require('./../assets/imgs/tab4/icon_bianji.png').default
                    }
                    alt=""
                    width="24"
                  />
                  编辑资料
                </div>
              </Link>
            </div>
            <div className="head" style={{ marginTop: '20px' }}>
              <Link to={'/user_info/' + localStorage.mineId + '/0'}>
                <div className="head-left">
                  <img
                    src={mineInfo.head_img}
                    alt=""
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        require('./../assets/imgs/icon/touxiang@2x.png').default
                    }}
                  />
                </div>
              </Link>
              <div className="head-right">
                <div className="name mb2 flex flex_left_center">
                  {mineInfo.nickname || '简约用户'}
                  <img
                    src={require('../assets/imgs/icon/icon_vip.png').default}
                    alt=""
                    hidden={mineInfo.is_vip !== 1}
                  />
                </div>
                <div className="dt">
                  <div className="dt-text ft_line1">
                    <span hidden={!mineInfo.age}>{mineInfo.age}岁</span>
                    <span hidden={!mineInfo.occupation}>
                      {mineInfo.occupation}
                    </span>
                    <span hidden={!mineInfo.region_name}>
                      {mineInfo.region_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            {/* 钱包 */}
            <Flex className="like">
              <Flex.Item>
                <Link to="/mine_like">
                  <div className="ft20 ft_bold">{mineInfo.follow || 0}</div>
                  <div className="ft12 name">我喜欢</div>
                </Link>
              </Flex.Item>
              <Flex.Item>
                <Link to={'/mine_agree'}>
                  <div className="ft20 ft_bold">
                    {mineInfo.unlock_count || 0}
                  </div>
                  <div className="ft12 name">请求</div>
                </Link>
              </Flex.Item>
              <Flex.Item>
                <Link to="/mine_wallet">
                  <div className="ft20 ft_bold">{mineInfo.money || 0}</div>
                  <div className="ft12 name">钱包</div>
                </Link>
              </Flex.Item>
            </Flex>
          </WingBlank>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          {/* 续费会员 */}
          <div className="date">
            <div className="flex flex_center_center left ft14">
              <img
                src={require('../assets/imgs/icon/icon_vip2.png').default}
                alt=""
              />
              {mineInfo.is_vip === 1
                ? '到期时间 : '
                : '开通会员,开启浪漫约会之旅'}
              {mineInfo.is_vip === 1 ? mineInfo.vip_over_time : ''}
            </div>
            <Link className="right ft12 les1" to="/mine_vip">
              {mineInfo.is_vip === 1 ? '续费会员' : '开通会员'}
            </Link>
          </div>
        </div>
        <WhiteSpace size="lg" />
        <WingBlank size="lg">
          <div className="bannerBox">
            <Carousel
              autoplay={true}
              dots={false}
              infinite
              autoplayInterval={5000}
            >
              {banner.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    item.path ? Control.go(item.path) : CheckUpData(3)
                  }}
                >
                  <img
                    src={item.imgSrc}
                    alt=""
                    style={{ width: '100%', verticalAlign: 'top' }}
                    onLoad={() => {
                      window.dispatchEvent(new Event('resize'))
                      this.setState({ imgHeight: 'auto' })
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <WhiteSpace size="xs" />
          <WhiteSpace size="lg" />
          <div className="tabs flex flex_center_center">{tabsList}</div>

          {/* <div onClick={window.reStartSocket}> 重新开始</div> */}
          {/* <Record /> */}
          {/* 闪图组件 */}
          {/* <ImgRead img={''} /> */}
        </WingBlank>
        {this.state.showSetInfo && (
          <div className="wxBox" style={{ height: '35%' }}>
            <div className="wxBoxBg">
              <div
                className="close"
                onClick={() => {
                  this.setState({ showSetInfo: false })
                }}
              >
                <Icon type="cross-circle-o" color="#f1d4b1" />
              </div>
              <div className="item  les1">
                个人魅力资料还未完善,完善后成功搭讪几率高达90%哦 ～
              </div>

              <div
                className="copy copy2"
                onClick={() => {
                  Control.go('/mine_change_info')
                }}
              >
                前去完善
              </div>
              <div
                className="copy"
                onClick={() => {
                  this.setState({ showSetInfo: false })
                }}
              >
                取消
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default Tabbar(Tab4)
