import React from 'react'
import { Link } from 'react-keeper'
import '../assets/style/tabbar.scss'

const tabbars = [
  {
    img1: require('./../assets/imgs/tabbar/Tab_icon_home.png').default,
    img2: require('./../assets/imgs/tabbar/Tab_icon_home1.png').default,
    name: '首页',
    path: '/tab1',
  },
  {
    img1: require('./../assets/imgs/tabbar/Tab_icon_dynamic.png').default,
    img2: require('./../assets/imgs/tabbar/Tab_icon_dynamic1.png').default,
    name: '动态',
    path: '/tab2',
  },
  // {
  //   img1: require('./../assets/imgs/tabbar/Tab_icon_yuehui1.png').default,
  //   img2: require('./../assets/imgs/tabbar/Tab_icon_yuehui.png').default,
  //   name: '约会',
  //   path: '/tab5',
  // },
  // {
  //     img1: require("./../assets/imgs/tabbar/fuli.png").default,
  //     img2: require("./../assets/imgs/tabbar/fuli.png").default,
  //     name: '领福利',
  //     path: '/mine_luck'
  // },
  {
    img1: require('./../assets/imgs/tabbar/Tab_icon_message.png').default,
    img2: require('./../assets/imgs/tabbar/Tab_icon_message1.png').default,
    name: '消息',
    path: '/tab3',
  },
  {
    img1: require('./../assets/imgs/tabbar/Tab_icon_user.png').default,
    img2: require('./../assets/imgs/tabbar/Tab_icon_user1.png').default,
    name: '我的',
    path: '/tab4',
  },
]

const Tabbar = WrappedComponent =>
  class Tabbar extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
      super(props)
      this.state = {
        hasNewMsg: localStorage.hasNewMsg,
      }
    }
    componentDidMount() {
      window.addEventListener('message', this.onMessage, false)
    }

    onMessage = () => {
      let path = window.location.href

      if (path.indexOf('/tab3') > 0) {
        localStorage.hasNewMsg = 0
        this.setState({
          hasNewMsg: 0,
        })
      } else {
        setTimeout(() => {
          this.setState({
            hasNewMsg: localStorage.hasNewMsg,
          })
        }, 300)
      }
    }
    componentWillUnmount() {
      window.removeEventListener('message', this.onMessage)
      this.setState = () => {
        return
      }
    }
    render() {
      const { hasNewMsg } = this.state
      const url = window.location.href
      const tabbar = tabbars.map((tabbar, index) => {
        return (
          <Link
            to={tabbar.path}
            key={tabbar.path}
            className={[
              'tabbar-item',
              url.indexOf(tabbar.path) > -1 ? 'active' : '',
            ].join(' ')}
          >
            <img
              src={url.indexOf(tabbar.path) > -1 ? tabbar.img2 : tabbar.img1}
              alt=""
              //   className={index === 2 ? 'fuliicon' : ''} // 福利图标特效
            />
            <div> {tabbar.name} </div>
            {tabbar.path === '/tab3' && hasNewMsg == 1 && (
              <div className="dot"></div>
            )}
          </Link>
        )
      })
      return (
        <div className="tabbar-container">
          <div className="tabbar-child">
            <WrappedComponent {...this.props} />
          </div>
          <div className="tabbar tabbarIt"> {tabbar} </div>
        </div>
      )
    }
  }

export default Tabbar
