/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, Modal, Toast, InputItem } from 'antd-mobile';
import '../../assets/style/user_info.scss';
import Api from '../../http/api';
import { Control } from 'react-keeper';


class MineLuckRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            giftList: [], //盲盒列表
            nowItem: null,
            addrDefault: '',//默认地址
            isDefaultAddr: false,

            name: '',
            phone: '',
            addr: '',
        }
    }
    componentDidMount() {
        this.getList()
        this.getAddr()
    }
    getAddr = () => {
        Api.addrGet({}).then(res => {
            console.log('默认地址', res)
            if (res.code == 200 && res.data) {
                this.setState({
                    addrDefault: res.data
                })

            }
        })
    }
    getList = () => {
        Api.giftRecord({}).then(res => {
            console.log('盲盒记录', res)
            if (res.code === 200) {
                this.setState({
                    giftList: res.data
                })
            }
        })
    }
    stateS = (state, phone) => {
        if (state == 0) {
            return '未开启'
        } else if (state == 1) {
            if (phone) {
                return '查看信息'
            } else {
                return '确认订单'
            }

        } else if (state == 2) {
            return '配送中'
        } else if (state == 3) {
            return '已完成'
        }
    }
    giftGet = (item) => {
        console.log(item)
        const { addrDefault } = this.state;
        if (item.phone) {
            this.setState({
                nowItem: item,
                name: item.name,
                phone: item.phone,
                addr: item.addr,
                isDefaultAddr: false
            })
        } else if (addrDefault) {
            this.setState({
                nowItem: item,
                name: addrDefault.name,
                phone: addrDefault.phone,
                addr: addrDefault.addr,
                isDefaultAddr: true
            })
        } else {
            this.setState({
                nowItem: item,
                isDefaultAddr: false
            })
        }

    }
    sendGift = () => {
        const { nowItem, name, phone, addr } = this.state;

        if (!name) {
            Toast.info("请填写姓名")
            return false;
        } else if (!phone || phone.length != 11) {
            Toast.info("请填写正确电话号")
            return false;
        } else if (!addr) {
            Toast.info("请填写地址")
            return false;
        }

        let params = {
            luck_draw_id: nowItem.id,
            phone,
            addr,
            name
        }
        console.log(params)
        Api.giftGet(params).then(res => {
            console.log('领取奖励', res)
            if (res.code == 200) {
                Toast.success(res.msg, 3)
                this.cancel()
                setTimeout(() => {
                    this.getList()
                }, 3000);

            }
        })

    }
    //初始化选项
    cancel = () => {
        this.setState({
            nowItem: null,
            isDefaultAddr: false,
            name: '',
            phone: '',
            addr: '',
        })
    }
    handleChange = (name, e) => {
        this.setState({
            [name]: e,
            isDefaultAddr: false
        })
    }



    render() {
        const { giftList, nowItem } = this.state;
        const giftLists = giftList.map((item, index) => {
            return (
                <div className='giftItem' key={'giftt' + index}>
                    <div className='left'>
                        <img src={item.gift_info.img} alt="" />
                    </div>
                    <div className='right'>
                        <div className='title ft_line1'>
                            {item.gift_info.label && <img src={require(`./../../assets/imgs/gift/icon_${item.gift_info.label}.png`).default} alt="" />}
                            {item.gift_info.gift_name}
                        </div>
                        <div className='price ft_line1'>市场参考价
                            <span>{item.gift_info.gift_price}</span>
                        </div>
                        <div className='ft13 opacity4' style={{ marginTop: '5px' }}>{item.ctime}</div>
                    </div>
                    <div className={(item.state == 1 ? 'state1' : '') + ' state'} onClick={() => { this.giftGet(item) }} >
                        {this.stateS(item.state, item.phone)}
                    </div>
                </div>
            )
        })
        return (
            <div className='mine-luck-page mine-luck-record-page' style={{ backgroundPositionY: '-1vw' }}>
                <div className='navBoxBB' style={{ background: '#222' }}>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                        rightContent={[
                            <div key='0' className='ft14 opacity7' onClick={() => {
                                Control.go('/set_addr')
                            }}> 收货地址 </div>,
                        ]}
                    >盲盒记录</NavBar>
                </div>
                <WingBlank size='lg'>
                    {/* <WhiteSpace size='lg' /> */}
                    {/* <WhiteSpace size='lg' /> */}
                    <div className='giftList'>
                        {giftList.length > 0 ?
                            giftLists
                            : <div className='empty'>暂无记录</div>
                        }
                    </div>
                </WingBlank>

                {nowItem &&

                    <div className='infoPop'>
                        <div className='ft16  title'>确认信息</div>
                        <div className='priceBox'>
                            <img src={nowItem.gift_info.img} alt="" />
                            <span>{nowItem.gift_info.gift_name}  <small className='opacity7'>价值：{nowItem.gift_info.gift_price}</small> </span>

                        </div>
                        <div>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <InputItem
                                className='input-box'
                                value={this.state.name}
                                type="text"
                                placeholder="收货人姓名"
                                onChange={(v) => { this.handleChange('name', v) }}
                            >
                                姓名
                            </InputItem>
                            <WhiteSpace size="md" />
                            <InputItem
                                className='input-box'
                                value={this.state.phone}
                                type="text"
                                placeholder="收货人手机"
                                onChange={(v) => { this.handleChange('phone', v) }}
                            >
                                手机
                            </InputItem>
                            <WhiteSpace size="md" />
                            <InputItem
                                className='input-box'
                                value={this.state.addr}
                                type="text"
                                placeholder="收货人地址"
                                editable={this.state.edit}
                                onChange={(v) => { this.handleChange('addr', v) }}
                            >
                                地址
                            </InputItem>
                            <WhiteSpace size="md" />
                            {this.state.isDefaultAddr &&
                                <div className='ft14 opacity7 color255 mb10 flex mrdz'>(当前为默认地址,可直接修改)</div>
                            }
                        </div>
                        <div className='btns'>
                            <div onClick={this.cancel}>取消操作</div>
                            <div onClick={this.sendGift}>确认信息</div>
                        </div>

                    </div>

                }

            </div>
        )
    }
}
export default MineLuckRecord;
