import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, Button, InputItem, Picker, List, ImagePicker, Toast } from 'antd-mobile';
import "../../assets/style/mine_setting.scss";
import Api from './../../http/api';
import Upload from '../../http/upload';
import { file } from '@babel/types';
import { Control } from 'react-keeper';

class MineChangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                id: '',
                age: '',
                about_me: [],
                birthday: '',
                constellation: '',
                education: '',
                emotion: '',
                head_img: "",
                height: '',
                nickname: "",
                region_name: '',
                sex: 0,
                weight: '',
            },
            files: [],

        }

    }
    componentDidMount() {
        this.getMineInfo();
    }
    getMineInfo = () => {
        Api.getMineInfo({}).then(res => {
            console.log('我的资料详情', res)
            if (res.code == 200) {
                this.setState({
                    info: res.data
                })

            }
            console.log(this.state)
        })

    }

    handleChange = (name, e) => {
        const obj = Object.assign({}, this.state.info, { [name]: e });
    }

    toGo = (name) => {
        Control.go('/mine_change_info_edit/' + name)
    }
    render() {
        const { info, sex, files } = this.state;
        return (
            <div className='mineChangeInfoPage'>
                <div className='navBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >修改资料</NavBar>
                </div>
                <WhiteSpace />
                <div className='content'>
                    <div className='item' onClick={() => { this.toGo(1) }}>
                        <div className='left'>
                            头像
                        </div>
                        <div className='right'>
                            <div className='headBox' style={{ backgroundImage: `url(${info.head_img})`, backgroundSize: 'cover' }} >

                            </div>

                        </div>
                    </div>
                    <div className='item' onClick={() => { this.toGo(2) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.nickname}
                            type="text"
                            placeholder="昵称"
                            disabled
                            extra={<Icon type='right' />}
                        >
                            昵称
                        </InputItem>
                    </div>
                    <div className='item'>
                        <InputItem
                            className='input-box'
                            value={this.state.info.sex == 1 ? '男' : '女'}
                            type="text"
                            disabled
                            placeholder="性别"
                            extra={<Icon type='right' />}
                        >
                            性别
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(4) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.age + ' 岁'}
                            type="text"
                            disabled
                            placeholder="年龄"
                            extra={<Icon type='right' />}
                        >
                            年龄
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(5) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.height + ' cm'}
                            type="text"
                            disabled
                            placeholder="身高"
                            extra={<Icon type='right' />}
                        >
                            身高
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(6) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.weight + ' kg'}
                            type="text"
                            disabled
                            placeholder="体重"
                            extra={<Icon type='right' />}
                        >
                            体重
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(11) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.occupation}
                            type="text"
                            disabled
                            placeholder="职业"
                            extra={<Icon type='right' />}
                        >
                            职业
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(12) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.education}
                            type="text"
                            disabled
                            placeholder="学历"
                            extra={<Icon type='right' />}
                        >
                            学历
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(7) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.birthday}
                            type="text"
                            disabled
                            placeholder="生日"
                            extra={<Icon type='right' />}
                        >
                            生日
                        </InputItem>
                    </div>

                    <div className='item' onClick={() => { this.toGo(8) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.often_city}
                            type="text"
                            disabled
                            placeholder="常出没"
                            extra={<Icon type='right' />}
                        >
                            常出没
                        </InputItem>

                    </div>
                    <div className='item' onClick={() => { this.toGo(9) }}>
                        <InputItem
                            className='input-box'
                            value={this.state.info.about_me}
                            type="text"
                            disabled
                            placeholder="个性标签"
                            extra={<Icon type='right' />}
                        >
                            个性标签
                        </InputItem>
                    </div>
                    <div className='item' onClick={() => { this.toGo(10) }} >
                        <InputItem
                            className='input-box'
                            value={this.state.info.brief}
                            type="text"
                            disabled
                            placeholder="自我简介"
                            extra={<Icon type='right' />}
                            onChange={(e) => { this.handleChange('brief', e) }}
                        >
                            自我简介
                        </InputItem>
                    </div>
                </div>


            </div>

        )
    }
}
export default MineChangeInfo;
