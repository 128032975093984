import React from 'react';
import { NavBar, Icon, WingBlank, WhiteSpace, Flex } from 'antd-mobile';
import { Control } from 'react-keeper';

const list = [1, 2, 3, 4, 5, 6, 7, 8];
class MineAboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className='aboutUsPage' style={{ paddingBottom: '40px' }}>
                <div className='navBarBox' style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: 'auto', zIndex: '999' }}>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1);
                        }}
                    >关于我们</NavBar>
                </div>
                <div>
                    <WingBlank size='lg'>
                        <WhiteSpace size='lg' />
                        {list.map((item, index) => {
                            return (
                                <div className='color255' key={index} style={{ marginTop: '-2px' }}>
                                    <img src={require('./../../assets/imgs/about/about' + item + '.png').default} alt='123' width='100%' />
                                </div>
                            )

                        })}

                    </WingBlank>

                </div>

            </div>
        )
    }
}
export default MineAboutUs;
