import React from 'react';
import './../../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, NavBar, Icon, Toast } from 'antd-mobile';
import Api from '../../http/api';
import { Control } from 'react-keeper';


class MineChangePwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wx: '',
            qq: ''
        }
    }
    componentDidMount() {
        this.getMineInfo()
    }
    handleChange = (name, e) => {
        this.setState({
            [name]: e
        })
    }
    getMineInfo = () => {
        Api.getMineInfo({}).then(res => {
            console.log('我的资料详情', res)
            if (res.code == 200) {
                this.setState({
                    wx: res.data.wx,
                    qq: res.data.qq
                })

            }
            console.log(this.state)
        })
    }

    ok = () => {
        const { wx, qq } = this.state;
        if (!wx && !qq) {
            Toast.fail('请填写社交账号');
            return false;
        } else {
            let parmas = { wx, qq }
            Api.editInfo(parmas).then(res => {
                console.log('修改资料', res)
                if (res.code === 200) {
                    Toast.success(res.msg, 2)
                    setTimeout(() => {
                        Control.go(-1)
                    }, 2000);
                }
            })
        }


    }
    render() {
        return (
            <div className='login-page change_pwd_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >社交账号</NavBar>

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>

                    <InputItem
                        className='input-box'
                        value={this.state.wx}
                        type="text"
                        placeholder="微信账号"
                        onChange={(v) => { this.handleChange('wx', v) }}
                    >
                        <div style={{ backgroundImage: `url(${require('./../../assets/imgs/icon/Icon_WeChat.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                    </InputItem>
                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.qq}
                        type="text"
                        placeholder="QQ账号"
                        onChange={(v) => { this.handleChange('qq', v) }}
                    >
                        <div style={{ backgroundImage: `url(${require('./../../assets/imgs/icon/Icon_QQ.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                    </InputItem>

                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' onClick={this.ok}>提交</Button>



                </WingBlank>



            </div >

        )
    }
}
export default MineChangePwd;

