/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import style from './MoneyRecordList.module.scss';
import { ListView } from 'antd-mobile';
import Api from './../http/api';

class MoneyRecordList extends Component {
    constructor() {
        super();
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            page: 1, //页码
            limit: 50, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {
        const { page, limit } = this.state;
        let params = {
            page: page,
            limit: limit
        }
        Api.billList(params).then(res => {
            console.log(res)
            if (res.code == 200) {
                let allbi = this.state.preBillData.concat(res.data);
                this.setState({
                    billData: res.data,
                    preBillData: allbi,
                    allBillData: allbi,
                    page: Number(this.state.page) + 1,
                    dataSource: this.state.dataSource.cloneWithRows(allbi),
                    isHasMore: res.data.length > 0 ? true : false,
                    isLoading: false
                })
            }

        })
    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    row = (item, sectionID, rowID) => {
        return (
            <div className={style.item} key={rowID}>
                <div className={style.left}>
                    <div>{item.msg}</div>
                    <div>{item.time}</div>
                </div>
                <div className={style.right}>
                    {item.type == 1 ? '+' : '-'} {item.amount}
                </div>
            </div>
        )
    }
    render() {
        const { isHasMore, isLoading, billData } = this.state;
        return (
            <div>
                <ListView
                    ref={el => this.listView = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (
                        <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                            {
                                isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                            }
                            {
                                (billData.length == 0 && !isHasMore) ? '没有更多了' : ''
                            }

                        </div>
                    )}
                    renderRow={this.row} //每行数据渲染
                    style={{
                        minHeight: 'calc(100vh - 20px)', //高度需要定义
                        overflow: 'auto',
                        paddingTop: '0'
                    }}
                    pageSize={50}  //一次渲染几条数据
                    initialListSize={50}
                    onEndReached={this.onEndReached}//加载新的数据
                    onEndReachedThreshold={100}//距离底部40时候 加载数据
                />
            </div>
        )
    }
}
export default MoneyRecordList;
