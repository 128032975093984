/* eslint-disable eqeqeq */
import React from 'react';

import { Toast } from 'antd-mobile';

import Upload from './../http/upload';

// import Recorder from 'recorder-core'
// import 'recorder-core/src/engine/mp3'
// import 'recorder-core/src/engine/mp3-engine'
import Recorder from 'recorder-core/recorder.mp3.min';

import { AudioOutline, PlayOutline } from 'antd-mobile-icons'


let rec;
let audioURL = '';
let startY;
let startX;
class Record extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blobURL: null, //录音文件的播放地址
            audioTime: 0,//录音时长
            audiofile: '', //录音文件
            audioIsPlay: false, // 是否正在播放 录音文件

            isRecording: false, //是否正在录音
            recordText: '初始化...',

            longClick: 0,  // 长按标志
            timeOutEvent: 0,  // 计时器
            canRecord: false, //录音权限


        }
    }
    componentDidMount() {
        this.recOpen()
    }
    componentWillUnmount() {
        if (rec) {
            rec.close()
            rec = null;
        }
    }
    recOpen = () => {
        // 打开权限
        let that = this;
        rec = Recorder({
            type: "mp3",
            sampleRate: 16000,
            bitRate: 16
        });
        rec.open(() => {
            console.log('授权成功,可以开始录音');
            that.setState({ recordText: '按住 说话', canRecord: true })
        }, (msg, isUserNotAllow) => {
            //用户拒绝未授权或不支持
            //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
            console.log(msg, isUserNotAllow);
            Toast.fail(msg)
            that.setState({
                isRecording: false,
                canRecord: false,
                recordText: '录音未授权',
                blobURL: null,
                audioTime: 0,
                audiofile: '',
            })
        });


    }
    // 打开录音
    recStart = () => {
        const { canRecord } = this.state
        //开始录音
        let that = this;
        if (audioURL) {
            window.URL.revokeObjectURL(audioURL)
            audioURL = '';
        }
        if (canRecord) {
            rec.start()
            that.setState({
                blobURL: null,
                audioTime: 0,
                audiofile: '',
                isRecording: true,
                recordText: '松开 结束'
            })
        } else {
            Toast.fail('录音未授权')
            that.setState({
                blobURL: null,
                audioTime: 0,
                audiofile: '',
                isRecording: false,
                recordText: '录音未授权'
            })
            this.recOpen();
        }

    }


    /**结束录音**/
    recStop = () => {
        const { canRecord, isRecording } = this.state
        let that = this;
        if (rec && canRecord && isRecording) {
            rec.stop(function (blob, duration) {
                //录音成功
                console.log('blob:', blob, '时长', duration, (duration / 1000).toFixed(1));
                if (duration < 2000) {
                    Toast.info('说话时长太短')
                    that.setState({
                        isRecording: false,
                        recordText: '按住 说话',
                        audioTime: 0,
                        blobURL: '',
                        audiofile: '',
                    })
                } else {
                    // 创建指向音频文件的URL
                    audioURL = window.URL.createObjectURL(blob);
                    console.log(audioURL)
                    that.setState({
                        blobURL: audioURL,
                        audiofile: [{ file: blob }],
                        isRecording: false,
                        recordText: '按住 说话',
                        audioTime: (duration / 1000).toFixed(1)
                    })
                }
            }, function (msg) {
                console.log("录音失败:" + msg);
                rec.close();//可以通过stop方法的第3个参数来自动调用close
                // rec = null;
                that.setState({
                    isRecording: false,
                    canRecord: false,
                    recordText: '录音 失败',
                    blobURL: null,
                    audioTime: 0,
                    audiofile: '',
                })
            });
        }

    };

    //长按
    start = (e) => {
        e.preventDefault();  // 阻止其他点击事件
        console.log("----start----")
        this.setState({
            timeOutEvent: setTimeout(() => {
                this.setState({ longClick: 1, audioIsPlay: false });  //  长按标志位
                // 此处为长按事件
                console.log("长按事件")
                this.recStart();
            }, 600)
        })
        // this.touchSatrtFunc(e);
    }

    move = (e) => {
        e.preventDefault();  // 阻止其他点击事件
        console.log("----move----", this.state.timeOutEvent, this.state.longClick)
        clearTimeout(this.state.timeOutEvent)
        this.setState({ longClick: 0 });  // 清除标志位

        // this.touchMoveFunc(e)
    }
    end = (e) => {
        e.preventDefault();  // 阻止其他点击事件
        this.recStop();
        clearTimeout(this.state.timeOutEvent)// 清除标志位
        console.log("----end----", this.state.timeOutEvent, this.state.longClick)
        this.setState({ longClick: 0 }, () => {
            if (this.state.timeOutEvent != 0 && this.state.longClick == 0) {  // 判断是否非长按事件
                //此处为点击事件
                console.log('点击事件')
            }
        });
        // this.touchEndFunc(e)
        return false;
    }
    play = () => {
        if (this.audioSource) {
            if (this.audioSource.paused) {
                this.audioSource.play();// 这个就是播放  
            } else {
                this.audioSource.pause();// 这个就是暂停
            }
        }
    }

    uploadAudio = () => {
        const { audiofile } = this.state;
        if (audiofile) {
            if (this.audioSource) {
                this.audioSource.pause();
                this.setState({
                    audioIsPlay: false
                })
            }

            let upLoad = new Upload({
                files: audiofile,
                path: 'audio/' + localStorage.mineId,
                onSuccess: (res) => {
                    console.log('上传录音成功：', res)
                    res[0].time = this.state.audioTime;
                    this.props.sendMsg3(res)

                    this.setState({
                        audiofile: '',
                        blobURL: '',
                        audioTime: 0,
                    }, () => {
                        if (audioURL) {
                            window.URL.revokeObjectURL(audioURL)
                            audioURL = '';
                        }

                    })

                },
                onFail: (err) => {
                    console.log(err)
                }

            }).open()
        }

    }

    //touchstart事件
    touchSatrtFunc = (evt) => {
        try {
            evt.preventDefault(); //阻止触摸时浏览器的缩放、滚动条滚动等

            let touch = evt.touches[0]; //获取第一个触点
            let x = Number(touch.pageX); //页面触点X坐标
            let y = Number(touch.pageY); //页面触点Y坐标
            //记录触点初始位置
            startX = x;
            startY = y;
        } catch (e) {
            console.log('touchSatrtFunc：' + e.message);
        }
    }

    //touchmove事件，这个事件无法获取坐标
    touchMoveFunc = (evt) => {
        try {
            evt.preventDefault(); //阻止触摸时浏览器的缩放、滚动条滚动等
            let touch = evt.touches[0]; //获取第一个触点
            let x = Number(touch.pageX); //页面触点X坐标
            let y = Number(touch.pageY); //页面触点Y坐标
            console.log(`原xy (${startX},${startY})    现XY:(${x},${y})`)

            //判断滑动方向 上下

            if (y - startY > 100) {
                //你自己的方法 我是用来翻页的一样的
                console.log('向下')
            } else if (y - startY < -100) {
                //你自己的方法
                console.log('向上')
            }
        } catch (e) {
            console.log('touchMoveFunc：' + e.message);
        }
    }

    //touchend事件
    touchEndFunc = (evt) => {
        try {
            evt.preventDefault(); //阻止触摸时浏览器的缩放、滚动条滚动等
        } catch (e) {
            console.log('touchEndFunc：' + e.message);
        }
        console.log(startX, startY)
    }

    render() {

        return (
            <div className="record-mic">
                <div className='audioBg'>
                    <div className='cancel' onClick={this.props.showRecordBtn}>
                        <img width={30} src={require('./../assets/imgs/chat/keyBorder.png').default} alt='' />
                    </div>
                    <div className='audioBox' onClick={this.play}>
                        <div className={(this.state.audioIsPlay ? 'bgggg1' : '') + '  bgggg'} style={{ opacity: this.state.audiofile ? '1' : '0.4' }} >
                            {this.state.blobURL &&
                                <audio
                                    ref={c => {
                                        this.audioSource = c;
                                    }}
                                    src={this.state.blobURL}
                                    onPlay={() => {
                                        console.log('播放')
                                        this.setState({
                                            audioIsPlay: true
                                        })
                                    }}
                                    onPause={() => {
                                        console.log('暂停')
                                        this.setState({
                                            audioIsPlay: false
                                        })
                                    }}
                                    onEnded={() => {
                                        console.log('播放完毕')
                                        this.setState({
                                            audioIsPlay: false
                                        })
                                    }}
                                >
                                </audio>
                            }
                            {this.state.audioTime + '″'}
                            <PlayOutline fontSize={20} color='#a18560' style={{ marginLeft: '5px' }} />
                        </div>
                    </div>
                    <div className='send' onClick={this.uploadAudio} style={{ opacity: this.state.audiofile ? '1' : '0.4' }}>
                        发送
                    </div>

                </div>
                {this.state.isRecording && <div className='fik'>
                    <img src={require('./../assets/imgs/chat/record.gif').default} alt='' />
                </div>}
                <div className='recordBtnBox' style={{ position: 'relative', zIndex: 1001 }} >
                    <div onTouchStart={this.start} onTouchMove={this.move} onTouchEnd={this.end}>
                        <AudioOutline fontSize={20} color={this.state.isRecording ? '#a18560' : '#fff'} />
                        {this.state.recordText}
                    </div>
                </div>

            </div>
        );
    }
}
export default Record;