/* eslint-disable eqeqeq */
import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, Modal, Toast } from 'antd-mobile';
import Api from '../../http/api';
import Upload from '../../http/upload';
import Step7 from "../../components/Step7";
import { Control } from 'react-keeper';

class MineReal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file1: [],//认证头像
            upLoadImg: '',
        }
    }
    componentDidMount() {
        console.log('挂在完成')
    }
    handleChange = (name, value) => {
        console.log(name, value);
        this.setState({
            [name]: value
        })
    }
    nextStep = () => {
        const { file1 } = this.state;
        if (file1.length > 0) {
            this.uploadImg(file1, 'real/video', res => {
                if (res) {
                    Api.realVideoUp({ video_url: res[0].pathName }).then(ress => {
                        Toast.success(ress.msg, 3)
                        setTimeout(() => {
                           Control.replace('/mine_ac')
                        }, 3000);
                    })
                }


            })
        } else {
            Toast.fail('请先选择视频')
        }
    }

    // step1   人脸识别自动上传
    onChange1 = (file1, type, index) => {
        console.log('step1', file1, type, index);
        this.setState({ file1 });
    }
    // step1   上传照片
    uploadImg = (files, pathName, callback = () => { }) => {
        console.log(files, pathName)
        let upLoad = new Upload({
            files: files,
            path: pathName,
            onSuccess: (res) => {
                console.log(res)
                callback(res)
            },
            onFail: (err) => {
                console.log(err)
                callback(false)
            }
        })
        upLoad.open()
    }

    render() {
        return (
            <div className='login-page  mine_real_name_page mine_real_page'>
                <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 'auto', height: 45, zIndex: 99 }}>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >  视频认证 </NavBar>
                </div>
                <div style={{ height: 45 }}></div>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='md' className='login-content'>
                    <WhiteSpace size="lg" />
                    <div className='stepContentBox'>
                        <div>
                            <Step7 state={this.state} handleChange={this.handleChange} onChange1={this.onChange1} />
                        </div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='btnsBox'>
                        <Button className='login-btn' style={{ width: '100%' }} onClick={this.nextStep}>
                            立即上传
                        </Button>
                    </div>
                   
                    <div style={{ height: 80 }}></div>
                </WingBlank >
            </div >

        )
    }
}
export default MineReal;

