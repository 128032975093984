import { Icon, NavBar, WhiteSpace, WingBlank } from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import Api from './../../http/api'

import '../../assets/style/team.scss'

class TeamList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueList: [0, 0, 0, 0, 0, 0, 0],
    }
  }
  componentDidMount() {
    this.getList()
  }
  getList = () => {
    Api.teamInfo({}).then(res => {
      console.log(res)
      let data = res.data
      if (res.code == 200) {
        let valueList = [
          data.new_today,
          data.team_total_number,
          data.today_profit,
          data.yesterday_profit,
          data.month_profit,
          data.total_profit,
          data.total_bonus,
        ]
        this.setState({ valueList })
      }
    })
  }

  render() {
    const { valueList } = this.state
    const funcList = [
      {
        name: '今日新增人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_jinrixinzeng@2x.png')
          .default,
      },
      {
        name: '团队总人数',
        value: 0,
        src: require('./../../assets/imgs/team/User_team_icon_tuanduizong@2x.png')
          .default,
      },
      {
        name: '今日推广收益',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_jinritui@2x.png')
          .default,
      },

      {
        name: '昨日推广收益',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_zuoritui@2x.png')
          .default,
      },

      {
        name: '本月推广收益',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_benyuetui@2x.png')
          .default,
      },
      {
        name: '推广总收益',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_tuiguangzong@2x.png')
          .default,
      },

      {
        name: '分红总收益',
        value: 0,
        src: require('./../../assets/imgs/team/User_tema_icon_fenhongzong@2x.png')
          .default,
      },
      {
        name: '推广详细列表>',
        value: 0,
        path: '/team_invite_info',
        src: require('./../../assets/imgs/team/User_tema_icon_tuiguangliebiao@2x.png')
          .default,
      },
    ]
    const funcListMap = funcList.map((item, index) => {
      return (
        <div
          className="item"
          key={item.name}
          onClick={() => {
            item.path ? Control.go(item.path) : console.log(item.name)
          }}
        >
          <div className="left">
            <img src={item.src} alt="" />
          </div>
          <div className="right">
            <span hidden={index == 7}> {valueList[index]}</span>
            <span> {item.name}</span>
          </div>
        </div>
      )
    })
    return (
      <div>
        <div className="navBoxBB">
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            推广数据
          </NavBar>
        </div>
        <div className="teamPage" style={{ marginTop: 55 }}>
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            <div className="teamBox">{funcListMap}</div>
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default TeamList
