/* eslint-disable eqeqeq */
import { Toast } from 'antd-mobile'
import axios from 'axios'
import qs from 'qs'
import { Control } from 'react-keeper'
import { getPosition } from './common'

let baseUrl = window.global_config.BASE_URL
// let baseUrl = '/api';

/**
 * 使用Vuex的store之前需要先引入，
 * 1.通过store.state.tokenStr获取属性值
 * 2.通过store.commit('mutations中的方法名',tokenStr)设置属性值
 */

function initAxios() {
  // 初始化配置
  // 设置api访问基路径
  if (!localStorage.lon || !localStorage.lat) {
    getPosition()
  }
  axios.defaults.baseURL = baseUrl
  axios.defaults.timeout = 10 * 1000 //超时
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded;charset=UTF-8'
  axios.interceptors.request.use(
    config => {
      //请求拦截
      // let token = window.localStorage.token || localStorage.token;
      return config
    },
    error => {
      console.log(error, error.response)
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    response => {
      // 该处为后端返回整个内容
      // console.log(response);
      if (typeof response.data == 'object') {
        response.data = JSON.stringify(response.data)
      }
      const res = JSON.parse(response.data) // 该处可将后端数据取出，提前做一个处理
      if (res.code == 200) {
        return Promise.resolve(response) // 该处将结果返回，下一步可用于前端页面渲染用
      } else if (res.code == 400) {
        Toast.fail(res.msg)
        return Promise.reject(response)
      } else if (res.code == 100) {
        Toast.fail(res.msg, 3)
        setTimeout(() => {
          let ip = localStorage.ip || ''
          let metaInfo = localStorage.metaInfo || ''
          let platform = localStorage.platform || ''
          let isApp = localStorage.isApp || ''

          let agree = localStorage.agree || ''
          let isFirstOpenApp=localStorage.isFirstOpenApp
          localStorage.clear()
          localStorage.isFirstOpenApp=isFirstOpenApp
          localStorage.agree = agree

          window.isOnline = ''
          window.handleClose('关闭当前聊天')

          localStorage.ip = ip
          localStorage.metaInfo = metaInfo
          localStorage.platform = platform
          localStorage.isApp = isApp

          Control.replace('/')

          // window.location.reload();
        }, 3000)

        return Promise.reject(response)
      }
    },
    error => {
      // console.log(error)
      setTimeout(() => {
        Toast.hide()
      }, 3000)
      if (error == 'Error: Network Error') {
        Toast.fail('网络错误')
      }
      let originalRequest = error.config
      if (
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') !== -1 &&
        !originalRequest._retry
      ) {
        // eslint-disable-next-line
        Toast.fail('网络超时')
        return Promise.reject('网络超时')
      }
      return Promise.reject(error)
    }
  )
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  axios.defaults.baseURL = '/api'
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * POST 方式请求
 * @param hasParamUrl 请求url
 * @param params  (如下的param)
 * @param isShowLoad （是否显示加载动画）
 */

export function post(requestUrl, params, isShowLoad = true) {
  initAxios()
  if (isShowLoad) {
    Toast.loading('', 0)
  }
  params.token = params.token ? params.token : localStorage.token
  if (localStorage.isServe) {
    params.lon = '' //精度
    params.lat = '' //纬度
  } else {
    params.lon = params.lon ? params.lon : localStorage.lon //精度
    params.lat = params.lat ? params.lat : localStorage.lat //纬度
  }

  // if (
  //   localStorage.userInfo &&
  //   JSON.parse(localStorage.userInfo).nickname == '小可爱哦哦' &&
  //   JSON.parse(localStorage.userInfo).id == 10
  // ) {
  //   console.log('小可爱哦哦')
  //   params.sex = 0
  // }

  // console.log(requestUrl, params);
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios
      .post(requestUrl, qs.stringify(params))
      .then(res => {
        resolve(JSON.parse(res.data))
        if (isShowLoad) {
          // eslint-disable-next-line eqeqeq
          if (JSON.parse(res.data).code == 200) {
            Toast.hide()
          } else {
            setTimeout(() => {
              Toast.hide()
            }, 3000)
          }
        }
      })
      .catch(error => {
        // reject(error);
        if (isShowLoad) {
          setTimeout(() => {
            Toast.hide()
          }, 3000)
        }
      })
  })
}

/**
 * 上传文件——可以同时上传多个
 * @param uploadFileUrl
 * @param formData
 *      let formData = new FormData(); //创建form对象
 *      param.append('file',file1,fileName1);//通过append向form对象添加数据
 *      param.append('file',file2,fileName2);//通过append向form对象添加数据
 */
export function postFile(uploadFileUrl, formData) {
  initAxios()
  // axios.defaults.maxContentLength = 100 * 1024 * 1024;
  // axios.defaults.maxBodyLength = 100 * 1024 * 1024;
  axios.defaults.timeout = 50000 //超时

  let config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios
      .post(uploadFileUrl, formData, config)
      .then(res => {
        resolve(JSON.parse(res.data))
      })
      .catch(err => {
        // reject(err);
        console.log(err)
      })
  })
}
