import React from 'react';
import './../../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, NavBar, Icon, Toast } from 'antd-mobile';
import Api from '../../http/api';
import { Control } from 'react-keeper';


class MineChangePwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            disable: false
        }
    }
    componentDidMount() {
        this.getMineInviter()
    }
    getMineInviter = () => {
        Api.getMyInvite({ addf: 12323 }).then(res => {
            console.log(res)
            if (res.code == 200) {
                this.setState({
                    code: res.data.inviter_code,
                    disable: res.data.inviter_code ? true : false
                })
            }
        })
    }
    handleChange = (name, e) => {
        this.setState({
            [name]: e
        })
    }

    ok = () => {
        const { code } = this.state;
        if (!code) {
            Toast.fail('请填写验证码');
            return false;
        }
        Api.inviteCode({ share_code: code }).then(res => {
            console.log(res)
            if (res.code === 200) {
                Toast.success(res.msg)
                setTimeout(() => {
                    this.getMineInviter()
                }, 3000);
            }
        })

    }
    render() {
        const { disable } = this.state;
        return (
            <div className='login-page change_pwd_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >填写邀请码{disable ? '(已填写)' : ''} </NavBar>

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>
                    <InputItem
                        className='input-box'
                        value={this.state.code}
                        type="text"
                        placeholder="请填写邀请码"
                        onChange={(v) => { this.handleChange('code', v) }}
                        disabled={disable}
                    >
                        <div style={{ backgroundImage: `url(${require('./../../assets/imgs/login/icon_yanzhengma.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                    </InputItem>

                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' style={{ display: disable ? 'none' : 'block' }} onClick={this.ok}>提交</Button>



                </WingBlank>



            </div >

        )
    }
}
export default MineChangePwd;

