import React, { useState, useEffect } from 'react';
import './../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, List, Picker, Tabs } from 'antd-mobile';




const SetInfo3 = (props) => {
    const {about_info, jobList, tipList, gexingtip, education, data, cols, asyncValue } = props.state;
    const handleChange = props.handleChange;
    const addTips = props.addTips;
    
    const [tipLists, settips] = useState({ info: [] });
    const [showTip, setShowTip] = useState(false);
    const [first, setfirst] = useState(0);

    // useEffect(() => {
    //     let arr = []
    //     for (let i = 18; i < 60; i++) {
    //         arr.push({
    //             label: i + '岁',
    //             value: i
    //         })
    //     }
    //     setage(arr);

    //     let arr2 = [];
    //     for (let j = 150; j < 220; j++) {
    //         arr2.push({
    //             label: j + 'cm',
    //             value: j
    //         })
    //     }
    //     setheight(arr2);

    //     let arr3 = []
    //     for (let i = 35; i < 100; i++) {
    //         arr3.push({
    //             label: i + 'kg',
    //             value: i
    //         })
    //     }
    //     setweight(arr3)

    //     let arr4 = []
    //     for (let i = 0; i < jobList.length; i++) {
    //         let item = {
    //             label: jobList[i].title,
    //             value: jobList[i].title,
    //         }
    //         item.children = []
    //         jobList[i].info.forEach(it => {
    //             item.children.push({
    //                 label: it.title,
    //                 value: it.title
    //             })
    //         });
    //         arr4.push(item)
    //     }
    //     setjobs(arr4);

    // }, [props])


    const setTip = (item, index) => {
        setfirst(index)
        settips(tipList[index])
    }
    const setSetShowTip = () => {
        setShowTip(!showTip)
        settips(tipList[first])
    }
    const educationList = [
        { label: '初中', value: '初中' },
        { label: '高中', value: '高中' },
        { label: '大专', value: '大专' },
        { label: '本科', value: '本科' },
        { label: '硕士', value: '硕士' },
        { label: '博士', value: '博士' },
    ]

    return (
        <div>
            <WingBlank size='lg' className='set-info2 set-info3' >
               
                <Picker
                    data={educationList}
                    cols={1}
                    extra='去完善'
                    className="forss"
                    value={education}
                    onOk={v => { handleChange('education', v) }} >
                    <List.Item arrow="horizontal" > 学历 </List.Item>
                </Picker>

             
                <div onClick={(v) => { setSetShowTip() }}>
                    <Picker
                        disabled={true}
                        data={[]}
                        cols={2}

                        value={gexingtip}
                        cascade={true}
                        extra={gexingtip.length > 0 ? gexingtip.map((item, index) => {
                            return (
                                <span key={item + index} >{index === gexingtip.length - 1 ? item + ' ' : item + ' ,'}</span>
                            )
                        }) : '去完善'}
                    >
                        <List.Item arrow="horizontal" > 个性标签 </List.Item>
                    </Picker>
                </div>
                <Picker
                    data={data}
                    cols={cols}
                    value={asyncValue}
                    onPickerChange={props.onPickerChange}
                    cascade={true}
                    extra='去完善'
                    onVisibleChange={props.onVisibleChange}
                    onDismiss={props.onDismiss}
                >
                    <List.Item arrow="horizontal" > 常出没 </List.Item>
                </Picker>

                <InputItem
                    className='input-box'
                    value={about_info}
                    type="text"
                    placeholder="请填写简介"
                    onChange={
                        (v) => { handleChange('about_info', v) }} >
                    <div > 自我介绍 </div>
                </InputItem>
                <div style={{ display: showTip ? 'block' : 'none' }} className='tipBoxBg'>
                    <Tabs
                        className='tipTabs'
                        tabs={tipList}
                        initialPage={first}
                        tabBarBackgroundColor='none'
                        tabBarActiveTextColor='#f6dfc1'
                        tabBarInactiveTextColor='#c7bfbf'
                        tabBarUnderlineStyle={{ border: '1px #f6dfc1 solid' }}
                        onTabClick={
                            (tab, index) => { setTip(tab, index) }}
                    />
                    <div className='tipBox'>{(tipLists && tipLists.info) ? tipLists.info.map(item => {
                        return (
                            <span className={gexingtip.indexOf(item.title) != -1 ? 'act' : ''} onClick={() => { addTips(item.title) }} key={item.title}>{item.title}</span>

                        )
                    }) : ''}
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>
                        <span style={{ height: 0, width: 0, padding: 0 }}></span>

                    </div>
                    <Button className='login-btn change-btn ok' style={{ marginTop: 30 }} onClick={(v) => { setShowTip(false) }} > 选好了({gexingtip.length}个)</Button>


                </div>


                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button className='login-btn change-btn'
                    onClick={props.nextStep} > 下一步 </Button>

            </WingBlank>
        </div>
    )
}

export default SetInfo3;