import { post, postFile } from '../http/http'

const api = {
  // 首页
  register(a) {
    return post('/member/register', a)
  },
  login(a) {
    return post('/member/login', a)
  },
  // code(a) { return post('/sms/juhe', a) },//获取手机验证码
  code(a) {
    return post('/sms/163_send_sms', a)
  }, //获取手机验证码

  forgetPwd(a) {
    return post('/member/forget_password', a)
  }, //忘记密码

  province(a) {
    return post('/member/province_region', a, false)
  }, //获取省
  city(a) {
    return post('/member/city_region', a, false)
  }, //获取市

  editInfoCast(a) {
    return post('/member/edit_info_cast', a, false)
  }, //按步骤 编辑用户资料
  getInfoCast(a) {
    return post('/member/get_info_cast', a, false)
  }, //获取步骤 编辑的资料

  //tab1
  userList(a) {
    return post('/member/list', a, false)
  }, //用户列表
  follow(a) {
    return post('/member/add_follow', a)
  }, //关注
  unFollow(a) {
    return post('/member/cancel_follow', a, false)
  }, //取消关注
  openChatLimit(a) {
    return post('/member/pay_unlock', a, false)
  }, //付费解锁聊天权限
  openChatLimitVip(a) {
    return post('/member/unlock_index', a, false)
  }, //会员次数解锁聊天权限

  // tab1->userInfo
  userInfo(a) {
    return post('/member/show_info', a, false)
  }, //用户资料
  userInfoMore(a) {
    return post('/member/show_member_info', a)
  }, //用户详细资料
  userInfoAlbm(a) {
    return post('/member/show_album', a)
  }, //用户相册
  userDongTai(a) {
    return post('/member/show_dynamic', a, false)
  }, //用户动态
  addBlack(a) {
    return post('/black/add', a, false)
  }, //将用户加入黑名单
  delBlack(a) {
    return post('/black/del', a, false)
  }, //将用户移除黑名单
  blacklist(a) {
    return post('/black/list', a)
  }, //黑名单

  //tab2 动态
  dongTai(a) {
    return post('/dynamic/dynamic_list', a, false)
  }, //平台用户的动态
  dongTaiChat(a) {
    return post('/dynamic/get_topic', a)
  }, //话题列表

  dongTailike(a) {
    return post('/dynamic/click_like', a, false)
  },
  dongTaiComment(a) {
    return post('/dynamic/comment', a, false)
  }, //发布评论

  // tab3
  chatList(a) {
    return post('/chat/get_contacts', a)
  }, //聊天列表
  msgRecordList(a) {
    return post('/chat/get_friend_record', a, false)
  }, //消息记录
  followList(a) {
    return post('/member/list_follow', a)
  }, //我关注和被关注列表

  agreeList(a) {
    return post('/member/unlock_lists', a)
  }, //解锁申请列表
  agree(a) {
    return post('/member/unlock_result', a)
  }, //解锁

  dongTaiLikeList(a) {
    return post('/dynamic/likes_dynamic_list', a)
  }, //动态赞和被赞
  notice(a) {
    return post('/member/notice', a)
  }, //系统通知
  viewList(a) {
    return post('/member/visit', a)
  }, //访问记录

  // tab4
  mineInfo(a) {
    return post('/member/my_index', a)
  }, //我的主页信息
  //tab4  mine_change_info
  getMineInfo(a) {
    return post('/member/get_info', a)
  }, //修改资料前获取默认资料
  editInfo(a) {
    return post('/member/edit_info', a)
  }, //修改资料
  editHeadImg(a) {
    return post('/member/faceVerify', a)
  }, //修改自己的头像
  rechargeRecord(a) {
    return post('/pay/recharge_log', a)
  }, //充值记录

  mineDongTai(a) {
    return post('/dynamic/my_dynamic', a)
  }, //我的动态
  sendDongTai(a) {
    return post('/dynamic/add_dynamic', a)
  }, //发布动态
  delDongTai(a) {
    return post('/dynamic/del_dynamic', a, false)
  }, //删除动态

  question(a) {
    return post('/member/feedback_submit', a)
  }, //问题反馈
  report(a) {
    return post('/member/add_report', a)
  }, //匿名举报

  mineVip(a) {
    return post('/pay/vip_price_list', a)
  }, //vip类型
  payWayInfo(a) {
    return post('/pay/get_pay_config', a)
  }, //支付配置接口
  payWay(a) {
    return post('/pay/pay', a)
  }, //支付方式接口

  invite(a) {
    return post('/member/show_share_qr', a)
  }, //我的邀请码
  inviteCode(a) {
    return post('/member/add_inviter', a)
  }, //我的邀请人
  inviteImgs(a) {
    return post('/member/share_img', a)
  }, //邀请图
  profit(a) {
    return post('/member/share_profit', a, false)
  }, //我的收益

  openVip(a) {
    return post('/pay/open_vip', a)
  }, //开通vip

  bankList(a) {
    return post('/member/back_card_list', a)
  }, //银行类别列表
  minePayInfo(a) {
    return post('/member/pay_info_list', a)
  }, //获取我的银行卡信息
  bindBank(a) {
    return post('/member/bind_pay_info', a)
  }, //绑定银行卡
  delBank(a) {
    return post('/member/del_pay_info', a, false)
  }, //绑定银行卡
  cash(a) {
    return post('/pay/submit', a)
  }, //提现
  cashRecord(a) {
    return post('/pay/withdrawa_log', a)
  }, //提现记录

  billList(a) {
    return post('/pay/bill_list', a)
  }, //账单列表

  mineAlbm(a) {
    return post('/member/list_album', a)
  }, //我的相册
  mineAlbmAdd(a) {
    return post('/member/add_album', a)
  }, //我的相册上传
  mineAlbmDel(a) {
    return post('/member/del_album', a, false)
  }, //我的相册删除
  mineWeChatSet(a) {
    return post('/member/bind_wechat', a, false)
  }, //绑定微信

  // qita
  getFileUploadInfo(a) {
    return post('/oss/conf', a, false)
  }, //阿里云文件上传参数

  renzhen(a) {
    return post('/ali/InitFaceVerify', a, false)
  }, //阿里云获取活体检测地址
  renzhenResult(a) {
    return post('/ali/DescribeFaceVerify', a, false)
  }, //阿里云获取活体检结果查询

  realHouse(a) {
    return post('/member/add_house', a)
  }, //房屋认证
  realCar(a) {
    return post('/member/add_vehicle', a)
  }, //车辆认证
  realVideoUp(a) {
    return post('/member/video', a)
  }, //视频认证

  realState(a) {
    return post('/member/my_authentication', a)
  }, //认证状态

  realVideo(a) {
    return post('/member/my_video', a)
  }, //视频认证列表

  realHouseList(a) {
    return post('/member/house', a)
  }, //房屋列表
  realHouseDel(a) {
    return post('/member/house_del', a, false)
  }, //删除房屋

  realCarList(a) {
    return post('/member/vehicle', a)
  }, //车辆列表
  realCarDel(a) {
    return post('/member/vehicle_del', a, false)
  }, //删除车辆

  changeCity(a) {
    return post('/member/edit_city', a, false)
  }, //更新用户城市

  updata(a) {
    return post('/member/ver', a, false)
  }, //检查升级

  // Iliao
  job(a) {
    return post('/member/get_occupation', a, false)
  }, //获取职业
  tip(a) {
    return post('/member/get_label_list', a, false)
  }, //获取个性标签

  // 团队
  teamInfo(a) {
    return post('/member/team_info', a)
  }, //团队详情
  teamRank(a) {
    return post('/member/rangking_lists', a)
  }, //团队收益排行

  // 客服
  getServe(a) {
    return post('/customer_service/distribution', a)
  }, //分配客服

  upload(a) {
    return postFile('/ali/upload', a)
  }, //真人认证 头像上传

  sendNotice(a) {
    return post('/member/send_notice', a)
  }, //真人认证 头像上传

  getMyInvite(a) {
    return post('/member/my_inviter', a)
  }, //我的邀请人

  // 客服

  serveLogin(a) {
    return post('/customer_service/login', a)
  }, //客服登陆
  serveChatList(a) {
    return post('/customer_service/contacts_list', a)
  }, //客服联系列表
  serveMsgRecordList(a) {
    return post('/customer_service/record_list', a)
  }, //客服联系列表

  pushInfo(a) {
    return post('/customer_service/extension', a)
  }, //推广专员 推广详情
  pushImg(a) {
    return post('/customer_service/show_service_share_qr', a)
  }, //推广专员 邀请码

  //约会
  getBranch(a) {
    return post('/item/config', a)
  }, //发布约会的类型

  getBranchConfig(a) {
    return post('/item/getBranch', a)
  }, //发布约会的配置

  sendData(a) {
    return post('/item/addItem', a)
  }, //发布约会

  dataList(a) {
    return post('/item/itemLists', a)
  }, //约会列表

  dataMine(a) {
    return post('/item/myItemLists', a)
  }, //我发布的约会

  dataDel(a) {
    return post('/item/delList', a)
  }, //删除我发布的约会

  publicNotice(a) {
    return post('/member/get_home_notice', a)
  }, //首页公告

  collect(a) {
    return post('/item/addItemCollection', a)
  }, //收藏约会

  unCollect(a) {
    return post('/item/delItemCollection', a)
  }, //取消收藏约会

  collectList(a) {
    return post('/item/itemCollection', a)
  }, //收藏约会列表

  // 聊天室有关
  chatRoomList(a) {
    return post('/room/get_rooms', a)
  }, //聊天室列表

  chatRoomUnlook(a) {
    return post('/room/unlock', a)
  }, //解锁聊天室

  chatRoomPwd(a) {
    return post('/room/join', a)
  }, //加入聊天室前获取令牌

  //盲盒

  giftList(a) {
    return post('/member/gift_lists', a)
  }, //盲盒列表
  giftRecord(a) {
    return post('/member/luck_draw_log', a)
  }, //记录
  giftLuck(a) {
    return post('/member/luck_draw', a)
  }, //抽盲盒
  giftNum(a) {
    return post('/member/luck_draw_count', a, false)
  }, //可用次数
  giftGet(a) {
    return post('/member/edit_addr', a)
  }, //领取奖励

  giftLuckBuyNum(a) {
    return post('/member/buy_luck_draw', a)
  }, //购买次数

  //收货人地址
  addrGet(a) {
    return post('/member/get_addr_info', a)
  }, //获取默认地址
  addrEdit(a) {
    return post('/member/edit_addr_info', a)
  }, //修改默认地址
  closeAccount(a) {
    return post('/member/cancellation', a)
  }, //账户注销
}

export default api
