/* eslint-disable eqeqeq */
import React from 'react';

class NewMsg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            msg: ''
        }
    }
    componentDidMount() {
        window.addEventListener('message', res => {
            // console.log(res)
            let msg = res.data;
            // if (res.data && typeof (res.data) == 'string') {
            //     msg = JSON.parse(res.data)
            // }
            let mineId = localStorage.mineId
            let path = window.location.href;
            // console.log(path)

            let showMsg = true;
            if (path.indexOf('chat') > 0) {
                showMsg = false
            }
            if (msg.type == 'messageCallback' && msg.data.from.id != mineId && showMsg) {
                localStorage.hasNewMsg = 1;
                this.setState({ msg: msg.data })
            }
            setTimeout(() => {
                this.setState({
                    msg: ''
                })
            }, 5000);
        }, false);

    }

    render(h) {
        const { msg } = this.state;

        const content = msg ? <div id='msgTop'>
            <div className='imgBox'><img src={msg.from.head_img} alt='' /></div>
            <div className='ft_line1 content'> {msg.from.nickname}:  {msg.message.content}</div>
        </div> : '';

        return (
            <div className='ft_line1'> {content}</div>
        )

    }

}
export default NewMsg;
