/* eslint-disable eqeqeq */
import {
  Checkbox,
  Flex,
  InputItem,
  Modal,
  Switch,
  Toast,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile'
import { Component } from 'react'
import '../assets/style/login.scss'
import Api from './../http/api'
import { CheckUpData } from './../http/updata'

import { Control, Link } from 'react-keeper'
import Conn from './../components/Conn'
// import { getPosition } from './../http/common'

import Init from './../components/Init'

const AgreeItem = Checkbox.AgreeItem

let timer = ''
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: 60,
      timeSet: 60,

      tel: '', //用户账号
      serveNum: '', //客服账号
      pwd: '',
      pwd1: '',
      pwd2: '',
      code: '',
      checked: localStorage.loginAuto == 'false' ? false : true, //自动登录
      isHideLoginPage: false,
      inviteCode: '',
      isServe: localStorage.isServe ? JSON.parse(localStorage.isServe) : '',
      num: 5,
      showInitPage: true,
      agree: localStorage.agree ? localStorage.agree == 'true' : false,
      isFirstOpenApp: false, //是否第一次打开App
    }
  }
  componentDidMount() {
    const { isServe } = this.state
    if (localStorage.loginAuto == 'false') {
      localStorage.token = ''
    }
    window.isOnline = ''
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1000)
    if (!isServe) {
      this.checkInviteCode()
    }
    this.initPage()
  }
  argee = type => {
    // type 1 用户协议   2隐私协议
    let text
    if (type == 1) {
      text = <span>《用户协议》</span>
    } else {
      text = <span>《隐私政策》</span>
    }
    return text
  }

  initPage = () => {
    console.log('initPage')
    this.setState({
      showInitPage: false,
    })
    if (localStorage.isApp == 1) {
      CheckUpData(2, res => {
        if (res) {
          Toast.hide()
          this.autoLogin()
        }
      })
    } else {
      this.autoLogin()
    }
  }
  autoLogin = () => {
    const { isServe, agree } = this.state
    if (!agree) return
    if (!isServe) {
      // 客户
      // getPosition()
      this.checkInviteCode()
    } else {
      // 客服
      if (localStorage.token && localStorage.serveInfo && this.state.checked) {
        Toast.loading('自动登录中')
        let type = localStorage.isServeType
        setTimeout(() => {
          if (type == 2) {
            Control.go('/invite_info')
          } else {
            Control.go('/serveHome')
          }
        }, 2000)
      }
    }
  }
  componentWillUnmount() {
    Toast.hide()
    if (timer) {
      clearInterval(timer)
      timer = ''
    }
    this.setState = () => {
      return
    }
  }
  // 检测 邀请码注册 和自动登录
  checkInviteCode = () => {
    let path = window.location.href
    // let path = 'http://localhost:3000/#/?share_code=serve';
    let inviteCode = path.split('?share_code=')[1]
    console.log('地址：', path, 'inviteCode:', inviteCode)

    if (inviteCode && inviteCode == 'serve') {
      console.log('我是客服')
      localStorage.isServe = true
      localStorage.userInfo = ''
      this.setState({
        isHideLoginPage: false,
        isServe: true,
      })
    } else if (inviteCode) {
      console.log('我是用户')
      localStorage.isServe = ''
      localStorage.serveInfo = ''
      this.setState({
        inviteCode,
        isHideLoginPage: true,
        isServe: false,
      })
    } else {
      if (!this.state.checked) {
        return false
      } else if (localStorage.token) {
        Toast.loading('自动登录中')
        if (localStorage.info_cast == 0) {
          this.goAppInit()
        } else {
          this.checkInfoCast(localStorage.info_cast)
        }
      }
    }
  }

  handleChange = (name, e) => {
    this.setState({
      [name]: e,
    })
  }
  goRegist = () => {
    console.log('regist')
  }
  getCode = () => {
    const { tel, timeSet } = this.state
    let tell = tel.replace(/\s*/g, '')
    if (tell.length < 11) {
      Toast.fail('手机号不正确')
    } else {
      if (this.state.time != timeSet) {
        Toast.fail(`请${this.state.time}秒后再试`)
        return false
      }
      Api.code({ phone: tell }).then(res => {
        console.log(res)
        Toast.success(res.msg)
        timer = setInterval(() => {
          console.log(this.state.time)
          if (this.state.time > 0) {
            this.setState({
              time: this.state.time - 1,
            })
          } else {
            this.setState({ time: timeSet })
            clearInterval(timer)
            timer = ''
          }
        }, 1000)
      })
    }
  }
  goAppInit() {
    if (localStorage.token) {
      Toast.success('登陆成功', 3)
      setTimeout(() => {
        Control.go('/tab1')
        this.getMineInfo()
      }, 3000)
    }
  }
  getMineInfo = () => {
    Api.mineInfo({}).then(res => {
      if (res.code == 200) {
        localStorage.userInfo = JSON.stringify(res.data)
        localStorage.sex = res.data.sex
        localStorage.mineId = res.data.id || ''
      }
    })
  }
  onSubmit = () => {
    const {
      isHideLoginPage,
      tel,
      code,
      pwd1,
      pwd2,
      pwd,
      inviteCode,
      isServe,
      serveNum,
      agree,
    } = this.state
    if (!agree) {
      return Toast.info('请勾选已阅读并同意《用户协议》和《隐私政策》')
    }
    if (isServe) {
      // 客服登陆
      let type = localStorage.isServeType || 1 //1客服登陆  2推广登陆

      let path = type == 1 ? '/serveHome' : '/invite_info'

      if (!serveNum) {
        Toast.fail('账号不正确')
        return false
      }
      if (!pwd) {
        Toast.fail('请填写登陆密码')
        return false
      }
      let parmas = {
        account: serveNum,
        password: pwd,
        ip:
          localStorage.ip || window.localStorage.ip
            ? JSON.parse(localStorage.ip) || JSON.parse(window.localStorage.ip)
            : '',
        type: type,
      }
      Api.serveLogin(parmas).then(res => {
        console.log(res)
        if (res.code == 200) {
          sessionStorage.isAgree = ''
          localStorage.token = res.data.token
          localStorage.serveInfo = JSON.stringify(res.data)
          localStorage.mineId = res.data.id
          Toast.success('登陆成功', 2)

          setTimeout(() => {
            if (type == 2) {
              Control.go('/invite_info')
            } else {
              Control.go('/serveHome')
            }
          }, 2000)
        }
      })
    } else {
      // 客户登陆
      const tell = tel.replace(/\s*/g, '')

      if (!isHideLoginPage) {
        console.log('登陆')
        if (tell.length < 11) {
          Toast.fail('手机号不正确')
          return false
        }
        if (!pwd) {
          Toast.fail('请填写登陆密码')
          return false
        }
        let parmas = {
          phone: tell,
          password: pwd,
          ip:
            localStorage.ip || window.localStorage.ip
              ? JSON.parse(localStorage.ip) ||
                JSON.parse(window.localStorage.ip)
              : '',
        }
        Api.login(parmas).then(res => {
          console.log(res)
          if (res.code == 200) {
            Toast.success('登陆成功', 2)
            localStorage.token = res.data.token
            localStorage.info_cast = res.data.info_cast
            localStorage.mineId = res.data.uid
            this.checkInfoCast(res.data.info_cast)
          }
        })
      } else {
        console.log('注册')
        if (tell.length < 11) {
          Toast.fail('手机号不正确')
          return false
        }
        if (!code) {
          Toast.fail('请填写验证码')
          return false
        }
        if (!pwd1 || pwd1.length < 8) {
          Toast.fail('请设置大于8位的密码')
          return false
        }
        if (pwd2 !== pwd1) {
          Toast.fail('两次密码不一致')
          return false
        }
        let parmas = {
          phone: tell,
          password: pwd1,
          repassword: pwd2,
          code: code,
          inviteCode: inviteCode,
          ip:
            localStorage.ip || window.localStorage.ip
              ? JSON.parse(localStorage.ip) ||
                JSON.parse(window.localStorage.ip)
              : '',
        }
        Api.register(parmas).then(res => {
          console.log(res)
          if (res.code == 200) {
            localStorage.token = res.data.token
            localStorage.info_cast = res.data.info_cast
            localStorage.mineId = res.data.uid
            this.checkInfoCast(res.data.info_cast)
          }
        })
      }
    }
  }

  checkInfoCast = info_cast => {
    info_cast = Number(info_cast)
    console.log('info_cast:', info_cast)
    if (info_cast === 0) {
      this.goAppInit()
    } else {
      Toast.hide()
      Toast.loading('请您完善资料', 3)
      localStorage.loginAuto = 'false'
      setTimeout(() => {
        Control.go('/set_info/' + info_cast)
      }, 3000)
    }
  }
  onMessage = data => {
    console.log(data)
  }
  okok = () => {
    console.log('登录页的ok', '')
    this.checkInfoCast(localStorage.info_cast)
  }
  // 用户还是客服版本
  changeKf = type => {
    const { num, isServe } = this.state
    if (num > 0) {
      this.setState({
        num: num - 1,
      })
    } else {
      localStorage.serveInfo = ''
      localStorage.userInfo = ''
      localStorage.loginAuto = 'false'
      localStorage.mineId = ''
      localStorage.token = ''
      if (isServe) {
        this.setState({
          isServe: '',
          num: 5,
        })
        localStorage.isServe = ''
        localStorage.isServeType = ''
      } else {
        this.setState({
          isServe: true,
          num: 5,
        })
        localStorage.isServe = true
        localStorage.isServeType = type
      }

      console.log(isServe, localStorage.isServeType)
    }
    console.log(num)
  }
  // 下载app
  downApp = () => {
    CheckUpData(3)
  }

  render() {
    const {
      pwd,
      pwd1,
      pwd2,
      code,
      tel,
      serveNum,
      isHideLoginPage,
      inviteCode,
      isServe,
    } = this.state
    return (
      <div className="login-page loginBg">
        <div>
          {localStorage.isApp == 0 && (
            <div className="downAp" onClick={this.downApp}>
              下载APP
            </div>
          )}
          <Flex>
            <img
              onClick={() => {
                this.changeKf(2)
              }}
              src={
                isServe
                  ? localStorage.isServeType == 1
                    ? require('../assets/imgs/login/logo_kefu@2x.png').default
                    : require('../assets/imgs/login/logo_kefu2@2x.png').default
                  : require('../assets/imgs/login/logo@2x.png').default
              }
              alt="logo"
              className="logo"
            />
          </Flex>
          <WhiteSpace size="lg" />
          <WingBlank size="md" className="login-content">
            <div hidden={isServe} style={{ width: '100%' }}>
              <InputItem
                className="input-box"
                value={tel}
                type="phone"
                placeholder="请输入手机号"
                name="tel"
                onChange={e => {
                  this.handleChange('tel', e)
                }}
                ref={el => (this.inputRef = el)}
                onClick={() => {
                  this.inputRef.focus()
                }}
                clear={true}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      require('./../assets/imgs/login/icon_zhanghao.png')
                        .default
                    })`,
                    backgroundSize: 'cover',
                    height: '22px',
                    width: '22px',
                  }}
                />
              </InputItem>
            </div>
            <div hidden={!isServe} style={{ width: '100%' }}>
              <InputItem
                className="input-box"
                value={serveNum}
                type="text"
                placeholder="请输入账号"
                name="serveNum"
                onChange={e => {
                  this.handleChange('serveNum', e)
                }}
                ref={el => (this.inputRef2 = el)}
                onClick={() => {
                  this.inputRef2.focus()
                }}
                clear={true}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      require('./../assets/imgs/login/icon_zhanghao.png')
                        .default
                    })`,
                    backgroundSize: 'cover',
                    height: '22px',
                    width: '22px',
                  }}
                />
              </InputItem>
            </div>

            <WhiteSpace size="lg" />
            <div className="login" hidden={isHideLoginPage}>
              <InputItem
                className="input-box"
                value={pwd}
                type="password"
                name="pwd"
                placeholder="请输入密码"
                onChange={e => {
                  this.handleChange('pwd', e)
                }}
                ref={el => (this.inputRef3 = el)}
                clear={true}
                onClick={() => {
                  this.inputRef3.focus()
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      require('../assets/imgs/login/icon_mima.png').default
                    })`,
                    backgroundSize: 'cover',
                    height: '22px',
                    width: '22px',
                  }}
                />
              </InputItem>
              <WhiteSpace size="lg" />
              <Flex>
                <Flex.Item>
                  <Link
                    to="/mine_change_pwd"
                    style={{ display: isServe ? 'none' : 'flex' }}
                  >
                    忘记密码
                  </Link>
                </Flex.Item>
                <Flex.Item className="kg">
                  <span style={{ marginRight: 5 }}>自动登录</span>
                  <Switch
                    checked={this.state.checked}
                    onChange={() => {
                      this.setState(
                        {
                          checked: !this.state.checked,
                        },
                        () => {
                          localStorage.loginAuto = this.state.checked
                        }
                      )
                    }}
                    color="#523f2d"
                  />
                </Flex.Item>
              </Flex>
              <WhiteSpace size="lg" />
            </div>

            <div className="regist" hidden={!isHideLoginPage}>
              <div className="code_box">
                <div className="code_box_input">
                  <InputItem
                    className="input-box"
                    value={code}
                    type="text"
                    name="code"
                    placeholder="请输入验证码"
                    onChange={e => {
                      this.handleChange('code', e)
                    }}
                    ref={el => (this.inputRef4 = el)}
                    onClick={() => {
                      this.inputRef4.focus()
                    }}
                    clear={true}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${
                          require('./../assets/imgs/login/icon_yanzhengma2.png')
                            .default
                        })`,
                        backgroundSize: 'cover',
                        height: '22px',
                        width: '22px',
                      }}
                    />
                  </InputItem>
                </div>
                <div
                  className="code_box_code"
                  style={{
                    background:
                      this.state.time == this.state.timeSet
                        ? 'linear-gradient(to right, #efd0a9, #f9e7ce)'
                        : '#a09d9d',
                  }}
                  onClick={() => {
                    this.getCode()
                  }}
                >
                  获取验证码({this.state.time})
                </div>
              </div>

              <WhiteSpace size="lg" />
              <InputItem
                className="input-box"
                value={pwd1}
                type="text"
                name="pwd1"
                placeholder="请设置登陆密码"
                onChange={e => {
                  this.handleChange('pwd1', e)
                }}
                ref={el => (this.inputRef5 = el)}
                onClick={() => {
                  this.inputRef5.focus()
                }}
                clear={true}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      require('./../assets/imgs/login/icon_mima.png').default
                    })`,
                    backgroundSize: 'cover',
                    height: '22px',
                    width: '22px',
                  }}
                />
              </InputItem>

              <WhiteSpace size="lg" />
              <InputItem
                className="input-box"
                value={pwd2}
                type="text"
                name="pwd2"
                placeholder="请确认登陆密码"
                onChange={e => {
                  this.handleChange('pwd2', e)
                }}
                ref={el => (this.inputRef6 = el)}
                clear={true}
                onClick={() => {
                  this.inputRef6.focus()
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      require('./../assets/imgs/login/icon_mima.png').default
                    })`,
                    backgroundSize: 'cover',
                    height: '22px',
                    width: '22px',
                  }}
                />
              </InputItem>
              <WhiteSpace size="lg" />
              {false && (
                <InputItem
                  className="input-box"
                  value={inviteCode}
                  type="text"
                  name="inviteCode"
                  placeholder="请输入邀请码"
                  onChange={e => {
                    this.handleChange('inviteCode', e)
                  }}
                  ref={el => (this.inputRef7 = el)}
                  onClick={() => {
                    this.inputRef7.focus()
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        require('./../assets/imgs/login/icon_yanzhengma.png')
                          .default
                      })`,
                      backgroundSize: 'cover',
                      height: '22px',
                      width: '22px',
                    }}
                  />
                </InputItem>
              )}
            </div>
            <Flex>
              <AgreeItem
                defaultChecked={
                  localStorage.agree ? localStorage.agree === 'true' : false
                }
                onChange={e => {
                  console.log(e.target.checked)
                  this.setState(
                    {
                      agree: e.target.checked,
                    },
                    () => {
                      localStorage.agree = e.target.checked
                    }
                  )
                }}
                style={{ marginLeft: 0, color: '#efd0a9' }}
              >
                <span className=" ft14 color999">
                  我已阅读并同意
                  <span
                    onClick={() => {
                      sessionStorage.isAgree = 1
                      Control.go('/set_agreement')
                    }}
                    style={{ color: '#f9e6cdb3' }}
                  >
                    《用户协议》
                  </span>
                  和
                  <span
                    onClick={() => {
                      sessionStorage.isAgree = 1
                      Control.go('/set_privacy_agreement')
                    }}
                    style={{ color: '#f9e6cdb3' }}
                  >
                    《隐私政策》
                  </span>
                </span>
              </AgreeItem>
            </Flex>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <div
              className="login-btn"
              onClick={() => {
                this.onSubmit()
              }}
            >
              {isServe
                ? localStorage.isServeType == 1
                  ? '客服登陆'
                  : '推广登陆'
                : !isHideLoginPage
                ? '立即登陆'
                : '立即注册'}
            </div>

            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <div
              className="login-btn2"
              style={{ display: isServe ? 'none' : 'block' }}
              onClick={() => {
                this.setState({
                  isHideLoginPage: !isHideLoginPage,
                })
              }}
            >
              {!isHideLoginPage ? '没有账号？去注册' : '已有账号,去登录'}
            </div>

            <div
              className="slogo"
              onClick={() => {
                this.changeKf(1)
              }}
              style={{ display: isHideLoginPage ? 'none' : 'block' }}
            >
              <img
                src={require('./../assets/imgs/login/slogan@2x.png').default}
                alt=""
              />{' '}
            </div>
          </WingBlank>
        </div>
        {/* <div className='changeKf' onClick={this.changeKf} style={{ top: '15px', right: '15px', position: 'fixed', display: isHideLoginPage ? 'none' : 'block' }} >{isServe ? '用户版' : '客服版'}</div> */}
        {this.state.showInitPage && <Init initPage={this.initPage} />}

        <Modal
          visible={this.state.isFirstOpenApp && false}
          transparent
          maskClosable={false}
          onClose={() => {}}
          title="用户协议和隐私政策"
          className="isFirstOpenApp"
          style={{
            position: 'relative',
            width: '85%',
            height: '80vh',
            overflow: 'hidden',
            textAlign: 'left',
            borderRadius: '10px',
          }}
          footer={[
            {
              text: '不同意且退出',
              onPress: () => {
                this.setState({
                  isFirstOpenApp: true,
                })
                localStorage.isFirstOpenApp = true
                if (localStorage.isApp == 1 && window.plus) {
                  if (localStorage.platform == 1) {
                    // 安卓
                    window.plus.runtime.quit()
                  } else if (localStorage.platform == 2) {
                    // ios
                    window.plus.ios
                      .import('UIApplication')
                      .sharedApplication()
                      .performSelector('exit')
                  }
                }
              },
            },
            {
              text: '同意',
              onPress: () => {
                this.setState({
                  isFirstOpenApp: false,
                  showInitPage: true,
                })
                localStorage.isFirstOpenApp = false
              },
            },
          ]}
          afterClose={() => {}}
        >
          <div
            style={{
              overflow: 'scroll',
              textAlign: 'left',
              fontSize: '13px',
              color: '#666',
            }}
          >
            <WingBlank size="lg">
              <h4 style={{ color: '#efd0a9', textAlign: 'center' }}>
                《用户协议》
              </h4>
              <h4 style={{ textIndent: 20 }}>
                欢迎使用简约，请您仔细阅读以下协议，如果您对本协议的任何条款有疑问或存在异议，您可以选择终止继续操作。当您进行注册、登录、使用等行为，我们默认您完全接受本协议项下的全部条款。
              </h4>

              <h4>1.服务须知</h4>
              <p>
                1.1
                注册成功简约账号后，因妥善保管账号及密码，并对其账号进行的所有活动和事件负法律责任。
              </p>
              <p>
                1.2
                用户可自行编辑个人信息中的账号名称、昵称、头像等，但应遵守相关法律法规，不得含有违法和不良信息，不得以他人或其他组织机构名义命名简约账号，亦不得使用引人误解的信息
              </p>
              <p>
                1.3
                简约官方对简约内容（即指简约用户在简约上已发布的信息，如冒泡、评论、破冰、组队等）享有使用权。
              </p>
              <p>
                1.4
                未经简约官方事先书面许可，用户不得抓取或协助任何第三方非法抓取简约内容，“非法抓取”是指采用程序或者非正常浏览等技术手段获取内容数据的行为，简约将移交有关司法执法机关处理。
              </p>
              <p>
                1.5
                用户不得以任何方式利用简约服务直接或间接从事违反中国法律以及社会公德的行为，简约有权对违反上述内容及用户予以删除并移交有关司法执法机关处理。
              </p>
              <p>1.6 用户不得利用简约服务发布、上传、复制、传播以下内容：</p>
              <p>1.6.1 违反中华人民共和国法律法规或监管政策</p>
              <p>1.6.2 发布或传播任何非法的信息</p>
              <p>
                1.6.3
                侵犯任何个人、企业事业单位或社会团体的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等
              </p>
              <p>
                1.7
                简约官方及授权账号有权对用户使用简约服务的行为及信息进行审核及处理，包括但不限于账号信息、个人信息、文字、图片、视频、冒泡投诉等范围。
              </p>
              <p>
                1.8
                如遇司法机构、监管机构要求或第三方合理请求，简约官方有权对用户账户及信息进行搜集、处置。
              </p>
              <p>
                1.9
                简约官方保留因业务发展需要，单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销服务的权利，用户需承担此风险。
              </p>
              <p>
                1.10
                简约官方提供的服务中可能包括广告，用户同意在使用过程中显示简约官方以及第三方合作者提供的广告。
              </p>

              <h4>2.知识产权</h4>
              <p>2.1 简约方是简约及简约产品的所有权及知识产权权利人。</p>
              <p>
                2.2
                用户在简约上发布的全部合法原创内容，著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到简约的同意。
              </p>
              <p>
                2.3
                简约有权但无义务对用户发布的内容进行审核或删除，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规对侵权信息进行处理。
              </p>

              <h4>3.隐私保护</h4>
              <p>
                {' '}
                为保护简约用户隐私权，提供更安全的互联网环境，简约将依据《中华人民共和国网络安全法》以及相关法规和政策对您的个人信息和其它数据进行严格保密，详情请参照《简约隐私条款》。
              </p>

              <h4>4.免责声明</h4>
              <p>
                4.1
                用户在简约发布的内容仅表明其个人的立场和观点，并不代表简约的立场或观点。作为内容的发布者，需自行对所发布内容负责，因所发布内容引发的一切纠纷，由该内容的发布者承担全部法律及连带责任，简约不承担任何法律及连带责任。
              </p>
              <p>
                4.2
                对于因不可抗力或简约不能控制的原因造成的网络服务中断或其它缺陷，简约不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
              </p>

              <h4>5.协议修改</h4>
              <p>
                根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，简约有权跟随国家法律法规及行业动态随时修订本规则总章，不作另行通知，并遵循板块管理规定追溯过往违规内容。
              </p>

              <h4>6.其他</h4>
              <p>
                6.1 本《用户协议》任何条款的部分或全部无效者，不影响其它条款的效力。
              </p>
              <p>
                6.2 本《用户协议》签订地为长沙。本《用户协议》的解释、效力及纠纷的解决，适用于中华人民共和国法律。用户和简约之间的任何争议，首先应友好协商解决，协商不成的，用户在此完全同意将交由简约住所地即长沙市有管辖权的人民法院管辖。
              </p>

              <p> 最终解释权归简约所有</p>
            </WingBlank>
            <WingBlank size="lg">
              <h4 style={{ color: '#efd0a9', textAlign: 'center' }}>
                《隐私政策》
              </h4>
              <h4 style={{ textIndent: 20 }}>
                长沙朔克网络科技有限公司（简称“我们”）作为简约App（以下简称“简约”）的运营者，深知个人信息对您的重要性，我们将按照《中华人民共和国网络安全法》以及相关法规和政策，制定《简约隐私政策》（以下简称“本政策”），保护您的个人信息及隐私安全。我们制定本政策并特别提示：希望您在使用简约及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。 本隐私政策将帮助您了解： 我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式收集个人信息。 当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。我们将在隐私政策中逐项说明哪些是必要信息。 我们仅会在您提供授权且在为实现特定功能或服务时使用本协议所搜集的个人信息及授权，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。 本隐私政策适用于您通过简约应用程序、简约官方网站、供第三方网站和应用程序使用的简约软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。 下文将帮您详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；帮您了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。
              </h4>

              <h4>1.个人信息收集及处理方式</h4>
              <p>
                我们的部分服务需要您提供特定的个人信息来实现特定功能。若您选择不提供该类信息，则无法正常使用服务中的特定功能：
              </p>
              <p>
                1.1
                当您注册简约时，您需要至少向我们提供您准备使用的您本人的手机号码，我们将通过发送短信验证码来验证您的身份是否有效。手机号码属于个人敏感信息，如果拒绝提供将无法使用简约。此外，我们可能会根据您的授权从第三方处获取您的第三方帐户信息（QQ登录，QQ会采集设备标识信息；微信登录：微信开放平台SDK会采集设备标识信息），并与您的简约帐户进行绑定，使您可通过第三方帐户直接登录和使用简约。我们将在您授权同意的范围内使用您的相关信息。在注册与使用服务过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供更好的服务和体验：您的本人照片、身高、体重、标签、经常出没的地方、职业等信息。但如果您不提供这些信息，将不会影响使用简约的基本功能。
              </p>
              <p>
                1.2 （1）人脸信息的采集和使用：
                当您进行性别认证、头像认证、相册认证等操作时，我们会在征得您的同意后采集您的人脸信息（静态或动态的面部特征）用于性别认证、人脸信息与照片对比，以实现相应的认证功能。我们在您授权同意后，获取您的本机摄像头、相册、蓝牙以及麦克风权限。该功能由百度人脸识别SDK提供服务并收集相应数据（包括：您的人脸照片、硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件地址MAC、以及设备摄像头状态），如不授予该权限，您将无法通过认证。
                （2）面部信息的披露、共享与保留、删除：
                ①我们对您的人脸信息严格保密，不会对外披露或者将其用于该功能以外的其他用途。
                ②由于人脸采集和识别业务借助了“百度人脸识别”服务，所以人脸数据将与百度共享，存储于百度人脸库以及简约数据库中。
                ③当您想取消头像、相册认证时，您可以联系简约客服协助完成，并且删除存储在百度人脸库和简约数据库中的人脸信息。
              </p>
              <p>
                1.3
                当您使用冒泡、组队、破冰、聊天等其他信息发布功能公开发布信息，包括发布图文、音视频、发布评论时，我们会在征得您的同意后相应地开启相册、相机、麦克风等权限来拍摄照片或视频或语音交互。请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发布冒泡时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用简约时共享甚至公开分享相关信息。您上传的照片、视频、评论、点赞、聊天等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求，我们不会对外提供上述信息，或者将其用于该功能以外的其他用途。
              </p>
              <p>
                1.4
                当您浏览查看附近的人、聊天发送定位、发布携带位置的冒泡时，我们会在征得您的同意后开启位置权限，记录您的地理位置信息。该信息属于敏感信息，拒绝提供该信息会使您无法使用上述功能，但不影响您正常使用简约的其他功能。此外，您也可以随时关闭相关功能。
              </p>
              <p>
                1.5
                当您使用简约各项服务时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：①设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设置参数、设备配置、设备标识、设备网络信息、设备环境等软硬件特征信息，如：IMEI / android ID / IDFA / OPENUDID / GUID / SIM卡IMSI信息等）。②服务日志信息：当您使用简约时，我们会自动收集您对我们服务的详细使用情况，作为有关服务日志保存。包括您的浏览、点击查看和搜索查询内容、关注分享、发布、点赞、评论等信息，以及位置信息、IP地址、浏览器的类型、运营商信息、使用的语言、访问日期和时间。 ③软件安装列表：为了预防恶意程序及安全运营所必需，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
                请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
              </p>
              <p>
                1.6
                我们的产品服务集成第三方合作伙伴友盟+SDK（友盟同欣（北京）科技有限公司），友盟+SDK采集设备标识符（如：IMEI / android ID / IDFA / OPENUDID / GUID / SIM卡IMSI信息 / MAC地址 / 软件安装列表等）以及手机号码，用于提供手机号码一键登录服务；通过采集地理位置提供反作弊服务，剔除作弊设备。已做好SDK延迟初始化配置，确保用户同意《隐私政策》后再初始化SDK采数。隐私权政策链接：https://www.umeng.com/page/policy
              </p>

              <p>
                1.7
                我们的推送服务由第三方合作伙伴深圳市和讯华谷信息技术有限公司【极光】提供，在提供该等服务的过程中，第三方合作伙伴需要收集您的相关个人信息，包括设备信息[ 设备信息包括：设备标识符（IMEI、IDFA、Android ID、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）、网络信息（网络信息包括：IP地址，WiFi信息，基站信息等相关信息）、地理位置信息。为向您提供更好的服务、改善技术和提升用户体验，您理解并同意，我们有权在必要范围内向第三方合作伙伴提供您的个人信息，且第三方合作伙伴亦可以基于前述目的自行收集和使用您的个人信息，并在保护您个人信息和隐私权以及符合适用法律法规的前提下，将收集的信息和数据经去标识化或匿名化处理后用于其他服务和用途。当我们要将个人信息用于本政策未载明的其它用途时，会事先通知您并征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，也会事先通知您并并征求您的同意。
              </p>
              <p>
                1.8
                当您进行收益提现时，我们会收集您提供的支付宝帐户信息。您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求，我们不会对外提供上述信息，或者将其用于该功能以外的其他用途。
              </p>
              <p>
                1.9
                当您参与简约官方组织的线上或线下活动时，为了便于联系和寄送奖品，我们需要您提供个人信息，如邮箱、手机号、姓名、收货地址等，并在该特定目的范围内处理及利用您的个人信息，非经您同意，我们不会将个人资料用于其他用途。
              </p>
              <p>
                1.10
                当涉及国家安全与利益、社会公共利益、与犯罪侦查有关的相关活动、你或他人生命财产安全但在特殊情况下无法获得你的及时授权、能够从其他合法公开的渠道、法律法规规定的其他情形下，简约可能在不经过你的同意或授权的前提下，收集你的个人信息。
              </p>

              <h4>2. 个人信息的使用及对外提供</h4>
              <p>
                {' '}
                我们从上述渠道收集的您的个人信息，会根据您的授权用作下列用途：
              </p>
              <p>
                2.1 用户注册
                当您注册成为简约新用户时，应用可通过手机号+接收到的验证码来注册和登录。
              </p>
              <p>
                2.2 信息展示和搜索
                为了让您快速地找到您周边的用户，同时正常地为您提供信息展示和搜索服务，我们会在检查当前用户是否有网络使用到获取粗略（精准）位置、访问网络、改变网络状态、读取系统日志、获取粗略（精准）位置、访问网络、改变配置、用户唤醒机器这几项权限 。
              </p>
              <p>
                2.3 客户服务及争议处理当您需要我们提供客户服务时，我们的客服会使用您的相关信息，账户信息和订单信息。为保证您的账户安全，我们的客服会使用您的账户信息对您的身份进行核验。待您的身份核验后，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更联系人或联系电话。为保障您的合法权益，保证我们的客户服务合法合规，并持续改进我们的服务能力，我们会对您与客服沟通的过程进行文字留存，该留存信息仅会在法律所允许的期限和范围内予以留存和处理，并仅在基于您的维权需要、政府部门及司法部门的调查指令需要提供时，我们会进行必要披露。
              </p>
              <p>
                {' '}
                2.4 对外提供未经您本人允许，简约不会向任何第三方公开（包括共享、转让、公开披露等方式）您的个人信息下列情形除外：
              </p>

              <p>2.4.1 简约已经取得您的授权或同意时。</p>

              <p>2.4.2司法或行政机关基于法定程序要求简约提供时。</p>

              <p>2.4.3简约为维护自身合法权益而向用户提起诉讼或仲裁时。</p>

              <p>2.4.4根据你与简约相关服务条款、应用许可使用协议的约定。</p>

              <p>
                2.4.5在法律允许的范围内，为保障简约、简约用户以及社会公共利益免受损害时。
              </p>

              <h4>3. 个人信息的保护</h4>
              <p>
                我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
              </p>
              <p>
                3.1我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
              </p>

              <p>
                3.2我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。
              </p>

              <p>
                3.3尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
              </p>

              <p>
                3.4您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。
              </p>

              <p>
                3.5我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
              </p>

              <p>
                3.6您一旦离开简约及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在简约及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于简约的链接或引导。
              </p>

              <h4>4. 个人信息的管理</h4>
              <p>
                4.1
                您有权合法的自行或联系客服访问和更改您的个人信息，信息更改前我们会验证您的身份。
              </p>
              <p>
                4.2
                如您的个人信息触犯相关法律法规及相关规定（如头像或内容涉嫌色情或不文明用语），我们有权在为得到本人同意的情况下对其进行删除或更改处理。
              </p>
              <p>
                4.3
                请您妥善保管个人账户、密码等信息，不要提供给第三人，否则由此所造成的损失完全由您个人承担。
              </p>

              <h4>5. 账号的注销</h4>
              <p>5.1 您可以随时注销此前注册的账号。</p>

              <p>
                5.2
                注销路径：您可以在“简约”点击【我的】、点击【账户设置】、点击【注销账号】，注销您的账号。
              </p>

              <h4>6.Cookie 和同类技术的使用</h4>
              <p>
                Cookie可以帮助网站辨认注册用户，计算用户数量，通常被各网站用来判定完成注册的用户是否已经实现登录。为提升服务/产品质量及维持核心功能的正常运行，简约会在您的计算机中放置并调用我们的Cookie。同时，如不希望个人信息保留在cookie中，您可在您所使用的浏览器功能项中设定隐私权等级为高，即可拒绝Cookie的写入，但可能会导致网站部分核心功能无法正常运行。
              </p>
              <h4>7.未成年人个人信息的处理</h4>
              <p>
                简约十分重视对未成年人个人信息的保护。若你是18周岁以下的未成年人，在使用简约的服务前，应确保已经取得监护人的同意，如你在简约上申请注册账号，简约将默认为你已得到上述同意。简约将根据国家相关法律法规及本《简约隐私条款》的规定着重保护未成年人的个人信息。如您的监护人不同意您按照本指引使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们。
              </p>
              <h4>8.本隐私政策如何修改</h4>
              <p>
                《简约隐私政策》将根据需求随时进行修正，修正后的《简约隐私政策》将会在公示在简约网站上。
              </p>
              <h4>9.争议解决</h4>
              <p>
                当你因为对本政策有任何疑问或认为损害了您的合法利益时，请联系简约客服，客服将为您解答及协商解决，若不能协商解决，双方有权依照注册协议向有管辖权的法院提起诉讼。
              </p>
              <h4>10.运营主体基本信息</h4>
              <p>
                我们的运营主体基本信息如下： <br />
                公司名称：长沙朔克网络科技有限公司
                <br />
                注册地址: 湖南省长沙市天心区劳动西路348号贺龙体育场5043房
                <br />
                办公地址：湖南省长沙市天心区湘江中路二段178号汇景发展环球中心A塔609号
                <br />
                成立时间：2022年3月4日
              </p>

              <p> 最终解释权归简约所有</p>
            </WingBlank>
          </div>
        </Modal>
      </div>
    )
  }
}
export default Conn(Login)
