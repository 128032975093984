import {
  Button,
  Icon,
  Modal,
  NavBar,
  Toast,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import '../../assets/style/mine_setting.scss'
import Api from '../../http/api'

class MineSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  closeAccount = () => {
    // 注销账户
    Api.closeAccount({}).then(res => {
      if (res.code === 200) {
        Toast.info(res.msg || '账号已注销')
        setTimeout(() => {
          let ip = localStorage.ip || ''
          let metaInfo = localStorage.metaInfo || ''
          let platform = localStorage.platform || ''
          let isApp = localStorage.isApp || ''
          let isWx = localStorage.isWx || ''

          let isFirstOpenApp = localStorage.isFirstOpenApp
          localStorage.clear()
          localStorage.isFirstOpenApp = isFirstOpenApp

          window.isOnline = ''
          window.handleClose('关闭聊天')
          localStorage.ip = ip
          localStorage.metaInfo = metaInfo
          localStorage.platform = platform
          localStorage.isApp = isApp
          localStorage.isWx = isWx

          Control.replace('/')
        }, 2000)
      }
    })
  }
  outLogin = () => {
    Modal.alert('提示', `清除登陆信息？`, [
      { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
      {
        text: '退出',
        onPress: () => {
          let ip = localStorage.ip || ''
          let metaInfo = localStorage.metaInfo || ''
          let platform = localStorage.platform || ''
          let isApp = localStorage.isApp || ''
          let isWx = localStorage.isWx || ''

          let agree = localStorage.agree || ''
          let isFirstOpenApp = localStorage.isFirstOpenApp
          localStorage.clear()
          localStorage.isFirstOpenApp = isFirstOpenApp
          localStorage.agree = agree

          window.isOnline = ''
          window.handleClose('关闭聊天')
          localStorage.ip = ip
          localStorage.metaInfo = metaInfo
          localStorage.platform = platform
          localStorage.isApp = isApp
          localStorage.isWx = isWx

          Control.replace('/')
        },
      },
    ])
  }
  render() {
    const funcList = [
      {
        name: '黑名单',
        path: '/mine_dislike',
        src: require('./../../assets/imgs/set/User_icon_heimingdan@2x.png')
          .default,
      },
      {
        name: '邀请码',
        path: '/set_invite',
        src: require('./../../assets/imgs/set/User_icon_yaoqingma@2x.png')
          .default,
      },
      {
        name: '社交账号',
        path: '/set_wx',
        src: require('./../../assets/imgs/set/User_icon_lianxifangshi@2x.png')
          .default,
      },
      // { name: '收货地址', path: '/set_addr', src: require('./../../assets/imgs/set/User_icon_lianxifangshi@2x.png').default },
      {
        name: '隐私设置',
        path: '/set_privacy',
        src: require('./../../assets/imgs/set/User_icon_yinsi@2x.png').default,
      },
      {
        name: '隐身功能',
        path: '/set_state',
        src: require('./../../assets/imgs/set/User_icon_yinshen@2x.png')
          .default,
      },
      {
        name: '修改密码',
        path: '/mine_change_pwd',
        src: require('./../../assets/imgs/set/User_icon_xiugaimima@2x.png')
          .default,
      },
      {
        name: '注销账号',
        path: '',
        src: require('./../../assets/imgs/set/zx.png').default,
      },

      {
        name: '用户协议',
        path: '/set_agreement',
        src: require('./../../assets/imgs/set/User_icon_yonghuxieyi@2x.png')
          .default,
      },
      {
        name: '隐私政策',
        path: '/set_privacy_agreement',
        src: require('./../../assets/imgs/set/User_icon_zhengce@2x.png')
          .default,
      },
      {
        name: '版本号',
        path: '',
        src: require('./../../assets/imgs/set/User_icon_banbenhao@2x.png')
          .default,
      },
    ]
    const funcListMap = funcList.map((item, index) => {
      return (
        <div
          className="item"
          key={index}
          onClick={() => {
            if (item.name === '注销账号') {
              Modal.alert('注销账户', '你确定要注销账号吗？', [
                {
                  text: '取消',
                  onPress: () => console.log('cancel'),
                  style: 'default',
                },
                { text: '注销账号', onPress: () => this.closeAccount() },
              ])
            }
            item.path ? Control.go(item.path) : console.log(item.name)
          }}
        >
          <div className="left">
            <img src={item.src} alt="" /> {item.name}
          </div>
          <div className="right">
            <Icon
              type="right"
              style={{ display: item.name !== '版本号' ? 'block' : 'none' }}
            />
            {item.name === '版本号' ? window.global_config.version : ''}
          </div>
        </div>
      )
    })
    return (
      <div>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            Control.go(-1)
          }}
        >
          账户设置
        </NavBar>
        <div className="mine-setting">
          <WingBlank size="lg">
            <WhiteSpace size="lg" />
            {/* <WhiteSpace size='lg' /> */}
            {funcListMap}
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <Button className="btn" onClick={this.outLogin}>
              退出登录
            </Button>
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default MineSetting
