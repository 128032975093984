import React from "react";
import Routers from "./router/Router";
import NewMsg from './components/NewMsg';
import ReactWebsocket from './components/ReactWebsocket';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 10
    };
  }
  componentDidMount() {
    window.isNet = true;

    let EventUtil = {
      addHandler: function (element, type, handler) {
        if (element.addEventListener) {
          element.addEventListener(type, handler, false);
        } else if (element.attachEvent) {
          element.attachEvent("on" + type, handler);
        } else {
          element["on" + type] = handler;
        }
      }
    };
    EventUtil.addHandler(window, "online", function () {
      window.isNet = true;
    });

    EventUtil.addHandler(window, "offline", function () {
      window.isNet = '';
    });
  }



  render() {
    return (
      <div className='app' id='app'>
        <NewMsg></NewMsg>
        <ReactWebsocket ref={Websocket => { this.refWebSocket = Websocket }} />
        {/* <Router > */}
        <Routers />
        {/* </Router> */}
        <div>
          <audio controls id="music" hidden style={{ display: 'none' }} >
            <source src={require('./assets/music/suc.mp3').default} type="audio/mp3" />
          </audio>
        </div>


      </div>


    )
  }
}

export default App;

