const OpenLuck = (props) => {
    const { close, open, gift } = props;
    console.log(gift)
    return (
        <div className='publicNoticeBg'>
            <div className='publicNotice openVip openLuck'>
                <div className='box'>
                    <div className='imgBox'>
                        <img src={gift.img} alt="" />
                    </div>
                    <div className='name'>
                        {gift.gift_name}
                    </div>
                    <div className='openVipGo' onClick={() => { open() }}>立即领奖</div>
                </div>

                <div className='close' onClick={() => { close() }}>
                    <img src={require('./../assets/imgs/tab1/close.png').default} alt="" />
                </div>
            </div>
        </div>
    )
}
export default OpenLuck;
