
import React from 'react';
import { ListView, NavBar, Icon } from 'antd-mobile';
import './../../assets/style/tab3.scss';
import Api from './../../http/api';
import { Control } from 'react-keeper';


class MineMsg extends React.Component {
    constructor(props) {
        super(props)
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            page: 1, //页码
            limit: 20, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }


    componentDidMount() {
        this.getList();
    }
    getList = () => {
        const { limit, page, isHasMore } = this.state;
        if (isHasMore) {
            Api.notice({
                limit: limit,
                page: page
            }).then(res => {
                console.log(res)
                if (res.code === 200) {
                    localStorage.hasNewNotice = 0;
                    let allbi = this.state.preBillData.concat(res.data);
                    this.setState({
                        billData: res.data,
                        preBillData: allbi,
                        allBillData: allbi,
                        page: Number(this.state.page) + 1,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.length > 0 ? true : false,
                    })
                }
            })
        }

    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }

    render(h) {
        const { isHasMore, isLoading, billData } = this.state;
        const row = (item, sectionID, rowID) => {
            return (
                <div key={rowID} className='item' style={{height:'auto'}}>
                    <div className='item-left'>
                        <div className='imBox'>
                            <img src={require('../../assets/imgs/icon/msg.jpg').default} alt='head' />
                        </div>
                    </div>
                    <div className='item-right' style={{ paddingBottom: '22px' }}>
                        {/* <div className='name mb2 flex flex_left_center ft_line1'>{item.nickname}</div> */}
                        <div className='dt' style={{ width: '100%' }}>
                            <div className='dt-text'
                                dangerouslySetInnerHTML={{
                                    __html: item.content

                                }}
                            >
                                {/* {item.content} */}
                            </div>

                        </div>
                        <div className='state' style={{ top: 'auto', bottom: 0 }}>{item.ctime}</div>
                    </div>
                </div>

            )
        }
        return (
            <div className='tab3'>
                <div className='navBoxx'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >系统通知</NavBar>
                </div>
                <div style={{ height: 45 }}></div>
                <div className='tab3-content' style={{ marginTop: 0 }}>
                    <ListView
                        ref={el => this.listView = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                {
                                    isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                }
                                {
                                    (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                }

                            </div>
                        )}
                        renderRow={row} //每行数据渲染
                        style={{
                            minHeight: 'calc(100vh - 20px)', //高度需要定义
                            overflow: 'auto'
                        }}
                        pageSize={20}  //一次渲染几条数据
                        initialListSize={20}
                        onEndReached={this.onEndReached}//加载新的数据
                        onEndReachedThreshold={200}//距离底部40时候 加载数据
                    />

                </div>
            </div>
        )

    }
}
export default MineMsg;