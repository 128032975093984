import { Icon, NavBar, WhiteSpace, WingBlank } from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import '../../assets/style/mine.scss'
import Api from '../../http/api'

class MineWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      money: 0,
    }
  }
  componentDidMount() {
    this.getMineInfo()
  }
  getMineInfo = () => {
    Api.mineInfo({}).then(res => {
      console.log('我的信息', res)
      if (res.code == 200) {
        this.setState({
          money: res.data.money,
        })
        localStorage.userInfo = JSON.stringify(res.data)
      }
    })
  }

  render() {
    const { money } = this.state
    return (
      <div className="mineWalletPage">
        <div className="navBoxx">
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
            rightContent={
              <div
                className="ft14 opacity7"
                onClick={() => {
                  Control.go('/mine_wallet_record')
                }}
              >
                流水记录
              </div>
            }
          >
            钱包
          </NavBar>
        </div>
        <div style={{ height: 45 }}></div>
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <div className="walletBox">
            <div>余额(元)</div>
            <div className="money">{money || 0}</div>
          </div>
          <WhiteSpace size="sm" />
          <WhiteSpace size="lg" />
          <div className="btnBox">
            <div
              onClick={() => {
                Control.go('/mine_recharge')
              }}
            >
              <img
                src={
                  require('../../assets/imgs/icon/icon_chongzhi1.png').default
                }
                alt=""
              />
              充值
            </div>

            <div
              onClick={() => {
                Control.go('/mine_cash')
              }}
            >
              <img
                src={require('../../assets/imgs/icon/icon_tixian1.png').default}
                alt=""
              />
              提现
            </div>
          </div>
          <WhiteSpace size="lg" />
          {/* <div className='recordListBox'>
                        <WingBlank size='md'>
                            <MoneyRecordList />
                        </WingBlank>

                    </div> */}
        </WingBlank>
      </div>
    )
  }
}
export default MineWallet
