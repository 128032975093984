import React from 'react';
import './../../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, NavBar, Icon, Picker, List, Toast } from 'antd-mobile';
import Api from './../../http/api';
import { Control } from 'react-keeper';


class MineAddBank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            bankNum: '',
            bank: '',
            bankList: [],
        }
    }
    componentDidMount() {
        console.log('挂在完成')
        this.getBankList();
    }
    getBankList = () => {
        Api.bankList({}).then(res => {
            console.log('银行信息：', res)
            if (res.code === 200) {
                let arr = [...res.data]
                for (let i in arr) {
                    arr[i].label = arr[i].bank_name;
                    arr[i].value = arr[i].bank_name;
                }
                this.setState({
                    bankList: arr
                })

            }
        })
    }

    handleChange = (name, e) => {
        // console.log(e);
        this.setState({
            [name]: e
        })
    }
    ok = () => {
        const { name, bankNum, bank } = this.state;
        console.log(name, bankNum, bank)
        if (!name) {
            Toast.fail('请输入收款人');
            return false;
        }
        if (!bank) {
            Toast.fail('请选择开户银行');
            return false;
        }
        let bank_number = '';

        if (!bankNum || String(bankNum.replace(/\s+/g, "")).length < 16) {
            Toast.fail('请核对银行卡号');
            return false;
        } else {
            bank_number = String(bankNum.replace(/\s+/g, ""));
        }
        let params = {
            type: 1,
            bank_name: bank[0],
            name: name,
            card_number: bank_number
        }
        console.log(params)
        Api.bindBank(params).then(res => {
            console.log('绑定信息：', res)
            if (res.code === 200) {
                Toast.success(res.msg, 3, () => {
                      Control.go(-1)
                })
            }
        })
    }


    render() {
        const { bankList } = this.state;
        return (
            <div className='login-page change_pwd_page mine_question_page mine_add_bank_page' style={{ backgroundImage: 'none' }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >添加银行卡</NavBar>


                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='md' className='login-content'>
                    <InputItem
                        className='input-box'
                        value='银行卡'
                        type="text"
                        placeholder="银行卡"
                        editable={false}
                    >
                        收款方式
                    </InputItem>
                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.name}
                        type="text"
                        placeholder="请输入收款人"
                        onChange={(v) => { this.handleChange('name', v) }}
                    >
                        收款人
                    </InputItem>
                    <WhiteSpace size="lg" />
                    <Picker data={bankList} cols={1} className="forss" value={this.state.bank} onChange={v => this.setState({ bank: v })} >
                        <List.Item arrow="horizontal">开户银行</List.Item>
                    </Picker>
                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.bankNum}
                        type="bankCard"
                        placeholder="请输入银行卡号"
                        name='bankNum'
                        onChange={(v) => { this.handleChange('bankNum', v) }}
                    >
                        银行卡号
                    </InputItem>
                    {/* <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.pwd}
                        type="password"
                        placeholder="请输入支付密码"
                        name='pwd'
                        onChange={this.setTel}
                    >
                        支付密码
                    </InputItem> */}

                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' onClick={this.ok}>确认添加</Button>



                </WingBlank>



            </div >

        )
    }
}
export default MineAddBank;

