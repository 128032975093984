import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank } from 'antd-mobile';
import MoneyRecordList2 from "../../components/MoneyRecordList2";
import "../../assets/style/mine.scss";
import { Control } from 'react-keeper';

class MineCashRecord extends Component {
    render() {
        return (
            <div className='mineWalletPage MineWalletRecordPage'>
                <div className='navBoxx'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >提现记录</NavBar>
                </div>
                <div style={{ height: 45 }}></div>
                <WingBlank>
                    <div className='recordListBox'>
                        <WingBlank size='md'>
                            <MoneyRecordList2 />
                        </WingBlank>
                    </div>
                </WingBlank>
            </div>
        )
    }
}
export default MineCashRecord;
