/* eslint-disable eqeqeq */

import React from 'react';
import Tabbar from '../components/Tabbar';
import Tab6Item from '../components/Tab6_item';

import { WingBlank, ListView, PullToRefresh, NavBar, Icon, Modal } from 'antd-mobile';
import '../assets/style/tab1.scss';
import Api from '../http/api';
import { Control } from 'react-keeper';



class Tab6 extends React.Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            refreshing: true,
            page: 1, //页码
            limit: 20, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据

            type: 1 //1我的邀约 2 邀约收藏
        }
    }



    componentDidMount() {
        console.log(this.props)
        this.getList();
    }
    onRefresh = () => {
        console.log('刷新')
        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            refreshing: true,
            isLoading: true,
            page: 1,
            dataSource: this.state.dataSource.cloneWithRows(emptyList)
        }, () => {
            this.getList()
        });
    };

    getList = () => {
        const { limit, page, isHasMore, type } = this.state;
        if (isHasMore) {
            if (type === 1) {
                Api.dataMine({
                    limit: limit,
                    page: page,
                }).then(res => { this.setList(res) })
            } else if (type === 2) {
                Api.collectList({
                    limit: limit,
                    page: page,
                }).then(res => { this.setList(res) })
            }
        }
    }

    setList = (res) => {

        if (res.code === 200) {
            let allbi = this.state.preBillData.concat(res.data);
            if (this.state.page == 1) {
                window.scrollTo(0, 0)
            }
            this.setState({
                refreshing: false,
                billData: res.data,
                preBillData: allbi,
                allBillData: allbi,
                page: Number(this.state.page) + 1,
                dataSource: this.state.dataSource.cloneWithRows(allbi),
                isHasMore: res.data.length > 0 ? true : false,
                isLoading: false
            })
        }

    }

    goInfo = (item) => {
        console.log(item)
        sessionStorage.dataInfo = JSON.stringify(item);
        Control.go(`/data_info/${item.uid}`);
    }
    delData = (id) => {
        console.log(id)
        const mo = Modal.alert('提示', `删除本邀约？`, [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '删除', onPress: () => {
                    Api.dataDel({ list_id: id }).then(res => {
                        console.log(res)
                        if (res.code == 200) {
                            this.onRefresh()
                        }
                    })


                }
            },
        ]);
    }

    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    row = (item, sectionID, rowID) => {
        return (
            <div key={item.id} >
                <Tab6Item item={item} type={this.state.type} goInfo={(e) => { this.goInfo(e) }} delData={this.delData} unCollect={this.unCollect} />
            </div>
        )
    }
    changeTab = (type) => {
        console.log(type)
        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            page: 1, //页码变为1
            isHasMore: true,
            isLoading: false,
            dataSource: this.state.dataSource.cloneWithRows(emptyList), //传入一个空数组
            type: type
        }, () => {
            this.listView.scrollTo(0, 0);

            this.getList();
        })
    }
    // 取消收藏的约会
    unCollect = (id) => {

        Api.unCollect({ collection_id: id }).then(res => {
            if (res.code === 200) {
                this.changeTab(2)
            }
        })
    }

    render() {
        const { isHasMore, isLoading, billData, } = this.state;

        return (
            <div className='tab6'>
                <div className='navBoxBB'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >
                        <div className='tabs'>
                            <div className={this.state.type == 1 ? 'active' : ''} onClick={() => { this.changeTab(1) }}>
                                我的邀约
                            </div>
                            <div className={this.state.type == 2 ? 'active' : ''} onClick={() => { this.changeTab(2) }}>
                                邀约收藏
                            </div>
                        </div>


                    </NavBar>

                </div>

                <div className='tab1 tab5' >
                    <div className='tab1-content' style={{ position: 'relative' }}>
                        {/* <WhiteSpace size='lg' /> */}
                        <WingBlank size='lg' >
                            <ListView
                                ref={el => this.listView = el}
                                dataSource={this.state.dataSource}
                                renderFooter={() => (
                                    <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                        {
                                            isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                        }
                                        {
                                            (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                        }

                                    </div>
                                )}
                                renderRow={this.row} //每行数据渲染
                                style={{
                                    minHeight: 'calc(100vh - 20px)', //高度需要定义
                                    overflow: 'auto',
                                    paddingTop: '15',

                                }}
                                // useBodyScroll
                                pageSize={20}  //一次渲染几条数据
                                initialListSize={20}
                                onEndReached={this.onEndReached}//加载新的数据
                                onEndReachedThreshold={100}//距离底部40时候 加载数据
                                pullToRefresh={<PullToRefresh
                                    refreshing={this.state.refreshing}
                                    onRefresh={this.onRefresh}
                                />}
                            />
                        </WingBlank>
                    </div>
                </div>
            </div>
        )

    }
}

export default Tabbar(Tab6)