/* eslint-disable eqeqeq */
import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, Modal, Toast } from 'antd-mobile';
import Api from '../../http/api';
import Upload from '../../http/upload';
import Step6 from "../../components/Step6";
import { upImg } from '../../http/common';
import { Control } from 'react-keeper';

class MineReal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


        }
    }
    componentDidMount() {
        console.log('挂在完成')
    }


    render() {

        return (
            <div className='login-page  mine_real_name_page mine_real_page'>
                <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 'auto', height: 45, zIndex: 99 }}>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    > 真人认证 </NavBar>
                </div>
                <div style={{ height: 45 }}></div>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='md' className='login-content'>
                    <div className='stepBox'>
                        <div >上传照片</div>
                        <div >面容识别</div>
                        <div className={['act']}>认证完成</div>
                    </div>
                    <WhiteSpace size="lg" />
                    <div className='stepContentBox'>
                        <div className='rzResult'>
                            <img width='80' src={require('./../../assets/imgs/real/certification_icon_people@2x.png').default} />
                            <div className='ft16' style={{ color: '#fff', fontWeight: '500' }}>认证成功</div>
                            <div className='ft12' style={{ color: '#f1cfa2', marginTop: 10 }}>
                                您已通过真人认证
                            </div>

                        </div>
                    </div>
                </WingBlank >

            </div >

        )
    }
}
export default MineReal;

