import React, { Component } from 'react'

let timer = ''
export default class Video extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPlay: false
        }

    }
    play = () => {
        let video = this.videoElment;
        console.log(video.pause, video.paused);
        let videos = document.getElementsByTagName('video');
        videos.forEach(element => {
            if (element != video) {
                element.pause()
            }
        });
        if (video.paused) {
            console.log('fullScreen:', this.props.fullScreen)
            video.play()
            timer = setTimeout(() => {
                if (this.props.fullScreen) {
                    this.fullScreen()
                }
            }, 1000);

        } else {
            timer && clearTimeout(timer);
            this.exitFullscreen();
            video.pause();
        }
    }

    //进入全屏
    fullScreen = () => {
        let ele = this.videoElment;
        if (ele.requestFullscreen) {
            ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
            ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
            ele.webkitRequestFullScreen();
        }
    }
    //退出全屏
    exitFullscreen = () => {
        let de = this.videoElment;
        if (de.exitFullscreen) {
            de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
            de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
            de.webkitCancelFullScreen();
        }
    }

    render() {
        return (
            <div className='videoPlayEl'
                style={{ backgroundImage: `url(${this.props.src + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,ar_auto'})`, backgroundSize: 'cover' }}
            >
                <video
                    style={{ display: this.state.isPlay ? 'inline-block' : 'none', opacity: this.state.isPlay ? 1 : 0 }}
                    poster={this.props.src + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,ar_auto'}

                    src={this.props.src}
                    controls="controls"

                    // x5-playsinline=""
                    // x5-video-orientation="portraint"
                    // x5-video-player-fullscreen="true"
                    // webkit-playsinline='true'
                    
                    playsInline={this.props.fullScreen ? '' : true}
                    webkitplaysinline={this.props.fullScreen ? '' : 'true'}

                    controlsList='nodownload'
                    disablePictureInPicture //禁用画中画
                    preload="none"  //页面加载即加载视频
                    onClick={this.play}
                    ref={el => {
                        this.videoElment = el;
                    }}
                    onPlay={(e) => { this.setState({ isPlay: true }) }}
                    onPause={(e) => { this.setState({ isPlay: false }) }}
                    onEnded={(e) => { this.setState({ isPlay: false }) }}
                />
                <div className='bggImg' onClick={this.play} style={{ display: this.state.isPlay ? 'none' : 'block' }}>
                    {/* 视频海报 */}
                    <img className='playBtn' src={require('./../assets/imgs/icon/play2.png').default}
                        alt="" />
                </div>

            </div>
        )
    }
}
