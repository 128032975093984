import React from 'react';
import './../../assets/style/login.scss';
import { WhiteSpace, WingBlank, Button, NavBar, Icon, TextareaItem, ImagePicker, Toast } from 'antd-mobile';
import Api from './../../http/api';
import Upload from '../../http/upload';
import { Control } from 'react-keeper';


class MineQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: '',
            question: '',
            files: [],
            multiple: true,
        }

        console.log(props)
    }
    componentDidMount() {
        console.log('挂在完成')
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e
        })
    }
    // step1   人脸识别自动上传
    onChange = (files, type, index) => {
        console.log('step1', files, type, index);
        this.setState({
            files,
        }, () => {
            this.uploadImg(files, 'chat', res => {
                if (res) {
                    console.log(res)
                }
            })
        });
    }
    // step1   上传照片
    uploadImg = (files, pathName, callback = () => { }) => {
        console.log(files, pathName)
        let upLoad = new Upload({
            files: files,
            path: pathName,
            onSuccess: (res) => {
                console.log(res)
                callback(res)
            },
            onFail: (err) => {
                console.log(err)
                callback(false)
            }

        }).open()
    }

    send = () => {
        const { tel, question, files } = this.state;
        console.log('ok')
        console.log(tel, question, files)
        let imgbox = [];
        if (files.length > 0) {
            files.forEach(file => {
                if (file.netPath) {
                    imgbox.push(file.netPath.pathName)
                }

            })
        }
        console.log('图片路径集', imgbox)

        if (!question) {
            Toast.fail('请填写问题描述')
            return false;
        }
        let params = {
            phone: tel,
            content: question,
            img: imgbox
        }
        console.log(params)
        Api.question(params).then(res => {
            console.log('问题反馈', res)
            if (res.code == 200) {
                Toast.success(res.msg, 3, () => {
                    Control.go(-1)
                })
            }

        })
    }
    render() {
        const { files } = this.state;
        return (
            <div className='login-page change_pwd_page mine_question_page' style={{padding: 0}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >意见反馈</NavBar>


                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>
                    {/* <InputItem
                        className='input-box'
                        value={this.state.tel}
                        type="phone"
                        placeholder="请填写您注册的手机号"
                        name='tel'
                        onChange={(e) => { this.handleChange('tel', e) }}
                    >
                        联系手机
                    </InputItem> */}
                    <WhiteSpace size="lg" />
                    <TextareaItem
                        className='input-box'
                        value={this.state.question}
                        type="text"
                        name='question'
                        placeholder="请描述您遇到的问题"
                        onChange={(e) => { this.handleChange('question', e) }}
                        autoHeight
                        // title="问题描述"
                        rows='5'
                    >

                    </TextareaItem>

                    <WhiteSpace size="lg" />
                    <div className='imgsBox'>
                        <div className='title mb5'>问题截图</div>
                        <ImagePicker
                            length="4"
                            files={files}
                            onChange={this.onChange}
                            onImageClick={(index, fs) => console.log(index, fs)}
                            selectable={files.length < 9}
                            multiple={this.state.multiple}
                        />
                        <div className='test'></div>



                    </div>

                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' onClick={this.send}>确认提交</Button>



                </WingBlank>



            </div >

        )
    }
}
export default MineQuestion;

