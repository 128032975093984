/* eslint-disable eqeqeq */

import React from 'react';
import Tabbar from '../components/Tabbar';
import Tab5Item from '../components/Tab5_item';

import { Tabs, WingBlank, ListView, PullToRefresh } from 'antd-mobile';
import '../assets/style/tab1.scss';
import Api from '../http/api';
import { getPosition, getAddress } from '../http/common';
import { Control } from 'react-keeper';



class Tab5 extends React.Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {


            refreshing: true,
            page: 1, //页码
            limit: 20, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据



            //约会
            branchList: [],//约会类型
            showDataList: false,
            tabs: [],
            sort: 0,//项目id

        }
    }



    componentDidMount() {
        console.log(this.props)
        this.getList();
        this.getBranch();
    }
    onRefresh = () => {
        console.log('刷新')
        this.getBranch();
        getPosition(res => {
            getAddress();
        });
        const { sort, limit, allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            refreshing: true,
            isLoading: true,
            page: 1,
            dataSource: this.state.dataSource.cloneWithRows(emptyList)
        }, () => {
            Api.dataList({
                item_id: sort,
                limit: limit,
                page: 1,
            }).then(res => {
                if (res.code == 200) {
                    let allbi = this.state.preBillData.concat(res.data);
                    this.setState({
                        billData: res.data,
                        preBillData: allbi,
                        allBillData: allbi,
                        page: 2,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.length > 0 ? true : false,
                        isLoading: false,
                        refreshing: false,
                    }, () => {
                        // this.listView.scrollTo(0, -60);

                    })
                }
                window.scrollTo(0, 0)
            })
        });
    };

    getList = () => {
        const { sort, limit, page, isHasMore } = this.state;
        if (isHasMore) {
            Api.dataList({
                item_id: sort,
                limit: limit,
                page: page,
            }).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    let allbi = this.state.preBillData.concat(res.data);
                    if (this.state.page == 1) {
                        window.scrollTo(0, 0)
                    }
                    this.setState({
                        billData: res.data,
                        preBillData: allbi,
                        allBillData: allbi,
                        page: Number(this.state.page) + 1,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.length > 0 ? true : false,
                        isLoading: false
                    })
                }
            })
        }
    }
    getBranch() {
        Api.getBranch({}).then(res => {
            console.log('约会类型', res)
            if (res.code == 200) {
                let tabs = [{ title: '全部', sort: 0 }];
                res.data.forEach(item => {
                    tabs.push({ title: item.item_title, sort: item.id })
                })
                this.setState({
                    branchList: res.data,
                    tabs
                })

            }
        })
    }


    goInfo = (item) => {
        console.log(item)
        sessionStorage.dataInfo = JSON.stringify(item);
        Control.go(`/user_info/${item.uid}/${item.uid}`);
    }
    changeTab = (tab) => {
        console.log(tab)

        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            sort: tab.sort,
            page: 1, //页码变为1
            isHasMore: true,
            isLoading: false,
            dataSource: this.state.dataSource.cloneWithRows(emptyList) //传入一个空数组
        }, () => {
            this.listView.scrollTo(0, -60);
            this.getList();
        })
    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    row = (item, sectionID, rowID) => {
        return (
            <div key={item.id} >
                <Tab5Item item={item} goInfo={(e) => { this.goInfo(e) }} />
            </div>
        )
    }
    sendData = (id) => {
        console.log(id)
        Control.go(`/mine_send_data/${id}`);
    }



    render() {
        const { isHasMore, isLoading, billData, branchList, showDataList, tabs } = this.state;

        return (
            <div className='tab1 tab5' >
                <div className='tabs'>
                    <Tabs tabs={tabs}
                        tabBarBackgroundColor='#222'
                        tabBarActiveTextColor='#eccda4'
                        tabBarInactiveTextColor='#8f8f8f'
                        onTabClick={(tab) => { this.changeTab(tab) }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={5} />}
                    />
                </div>
                <div className='topBg'></div>
                <div className='topP'>
                    <img type="ellipsis"
                        src={require('../assets/imgs/icon/icon_fabu.png').default}
                        width='22'
                        alt=''
                        onClick={() => {
                            this.setState({
                                showDataList: true
                            })
                        }}
                    />
                </div>

                <div className='topBox' style={{ display: showDataList ? 'flex' : 'none' }}>
                    <div className='title'>
                        想约人做什么
                    </div>
                    {
                        branchList.map((item) => {
                            return (
                                <div className='topBoxItem' key={item.id} onClick={() => {
                                    this.sendData(item.id)
                                }} >
                                    <img src={item.img || require('../assets/imgs/tab5/icon_lvxing@2x.png').default} alt="" />
                                    <span>{item.item_title}</span>
                                </div >
                            )
                        })
                    }
                </div>
                <div className='fik' style={{ display: showDataList ? 'flex' : 'none' }}
                    onClick={() => {
                        this.setState({
                            showDataList: false
                        })
                    }}
                >
                </div>
                <div className='tab1-content' style={{ position: 'relative' }}>
                    {/* <WhiteSpace size='lg' /> */}
                    <WingBlank size='lg' >
                        <ListView
                            ref={el => this.listView = el}
                            dataSource={this.state.dataSource}
                            renderFooter={() => (
                                <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                    {
                                        isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                    }
                                    {
                                        (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                    }

                                </div>
                            )}
                            renderRow={this.row} //每行数据渲染
                            style={{
                                minHeight: 'calc(100vh - 20px)', //高度需要定义
                                overflow: 'auto',
                                paddingTop: '15',

                            }}
                            // useBodyScroll
                            pageSize={20}  //一次渲染几条数据
                            initialListSize={20}
                            onEndReached={this.onEndReached}//加载新的数据
                            onEndReachedThreshold={100}//距离底部40时候 加载数据
                            pullToRefresh={<PullToRefresh
                                refreshing={this.state.refreshing}
                                onRefresh={this.onRefresh}
                            />}
                        />
                    </WingBlank>
                </div>
            </div>
        )

    }
}

export default Tabbar(Tab5)