import { WhiteSpace, ImagePicker } from 'antd-mobile';

const Step1 = (props) => {
    const { file1 } = props.state;
    const onChange1 = props.onChange1;
    const videoUrl = file1.length > 0 ? URL.createObjectURL(file1[0].file) : '';

    return (
        <div>
            <div className='step1'>
                <div className='step1_top flex_column flex flex_s_a flex_center_center'>
                    <div className='it flex flex_column flex_column_left_center' style={{ lineHeight: 'normal', width: '100%' }}>
                        <span className='ft16 ft_bold color255 mb5'>上传视频</span>
                        <span style={{ color: '#f1cfa2' }}>请上传本人清晰正面五官视频</span>
                    </div>
                    <div className='it2' style={{ width: 180, height: 180, marginTop: 20 }}>
                        <div className='videoBox' style={{ display: file1.length > 0 ? 'block' : 'none', textAlign: 'left', width: '100%', height: '100%', background: '#000' }}>
                            <video width='100%' height='100%' poster="" src={videoUrl}
                                controls="controls" x5-playsinline="" playsInline="" webkit-playsinline="true" controlsList='nodownload' disablePictureInPicture preload="auto">
                            </video>
                        </div>
                        <div style={{ display: file1.length == 0 ? 'block' : 'none' }}>
                            <ImagePicker
                                style={{ width: '100%', opacity: 0.9 }}
                                files={file1}
                                length={1}
                                onChange={onChange1}
                                onImageClick={(index, fs) => console.log(index, fs)}
                                selectable={file1.length < 1}
                                multiple={false}
                                accept="video/*"
                            />
                        </div>

                    </div>
                </div>
                <WhiteSpace size="md" />
                <div className='step1_mid'>
                    <div className='it'>
                        <div className='imgBox'>
                            <img src={require('./../assets/imgs/real/tx1@2x.png').default} alt='' />
                        </div>

                        <div className='state dui'>五官清晰</div>
                    </div>
                    <div className='it'>
                        <div className='imgBox'>
                            <img src={require('./../assets/imgs/real/tx2@2x.png').default} alt='' />
                        </div>
                        <div className='state'>五官遮挡</div>
                    </div>
                    <div className='it'>
                        <div className='imgBox'>
                            <img src={require('./../assets/imgs/real/tx3@2x.png').default} alt='' />
                        </div>
                        <div className='state'>五官模糊</div>
                    </div>
                    <div className='it'>
                        <div className='imgBox'>
                            <img src={require('./../assets/imgs/real/tx4@2x.png').default} alt='' />
                        </div>
                        <div className='state'>不是本人</div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Step1
