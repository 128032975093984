/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import Api from './../http/api';



const Tab1Item = (props) => {
    // console.log(props);
    const { item, goInfo } = props;
    const [follows, setFollows] = useState(item.is_follow);

    const setJuli = (item) => {
        if (!item && item !== 0) {
            return ' ';
        } else if (item === 0) {
            return '  0m';
        } else {
            item = Number(item)
            if (item > 1000) {
                return '  ' + (item / 1000).toFixed(2) + 'km'
            } else {
                return '  ' + item + 'm'
            }
        }
    }
    const follow = () => {
        // followss(item)
        let params = {
            fid: item.id,
        }
        if (!follows) {
            Api.follow(params).then(res => {
                // console.log(res)
                if (res.code == 200) { //eslint-disable-line 
                    item.is_follow = 1;
                    setFollows(1)
                }
            })
        } else {
            Api.unFollow(params).then(res => {
                // console.log(res)
                if (res.code == 200) { //eslint-disable-line 
                    item.is_follow = 0;
                    setFollows(0)
                }
            })
        }

    }
    const setAlbum = (list) => {
        let albumList = [];
        let types = ['mp4', 'avi', 'mov', '3gp', 'mpeg', 'nAVI', 'asf', 'wmv', 'mpeg-4'];
        for (let i in list) {
            let index1 = list[i].lastIndexOf(".");
            let index2 = list[i].length;
            let suffix = (list[i].substring(index1 + 1, index2)).toLowerCase();//后缀名
            let type = types.indexOf(suffix)
            if (type == -1) {
                albumList.push(list[i])
            }
        }
        return albumList.slice(0, 3);
    }
    // console.log(item, goInfo)
    const ListItem = <div className='item' onClick={() => { goInfo(item) }}>

        <div className='item-left'>
            <img src={item.head_img} onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} alt='head' />
            {(item.real_head === 1 || item.sex == 2) &&
                <div className={item.real_head === 1 ? 'rzState' : 'rzState rzState2'}>
                    {item.real_head === 1 ? '真人认证' : '未认证'}
                </div>
            }

            <div className='is_video' style={{ display: item.video == 1 ? 'flex' : 'none' }}>
                <div></div>
            </div>
        </div>

        <div className='item-right'>
            <div className='name  flex flex_left_center' style={{ color: item.is_vip == 1 ? '#e4d0a4' : '#fff' }}>{item.nickname || ' '}
                <img src={require('../assets/imgs/icon/vip2.png').default} alt='' style={{ opacity: item.is_vip === 1 ? '1' : '0' }} />
            </div>
            <div className='info' >
                <span hidden={!item.age}>{item.age}岁</span>
                <span hidden={!item.height}>{item.height}cm</span>
                <span hidden={!item.occupation}>{item.occupation}</span>
            </div>
            <div className='addr flex flex_left_center'>
                <img style={{ opacity: 0.2 }} src={require('../assets/imgs/icon/icon_dingwei.png').default} alt='' hidden={!item.region_name || item.region_name == null} />
                {item.region_name}{item.distance_show ? setJuli(item.distance) : ''}
            </div>
            <div className='imgsBox flex  flex_left_center' style={{ minHeight: item.album.length > 0 ? '0' : '30px' }}>

                {setAlbum(item.album).map((item, index) => {
                    return (
                        <div key={index} style={{ display: index < 3 ? 'block' : 'none' }}>
                            <img src={item} alt='' />
                        </div>
                    )
                })}
            </div>
            <span className='state state2' style={{ width: 25, height: 25 }} onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                follow()
            }}>
                <img src={require('../assets/imgs/icon/icon_xihuan1.png').default} alt='' style={{ opacity: follows === 1 ? '1' : '0.2' }} width='24px' />
            </span>
            <span className='state'>{item.off_line}</span>
        </div >
    </div >
    return (
        <div>
            {ListItem}
        </div>
    );

}
export default Tab1Item;