/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { WhiteSpace, Modal, Toast, TextareaItem } from 'antd-mobile';
import WxImageViewer from 'react-wx-images-viewer';
import style from './Tab2_item.module.scss';
import Api from './../http/api';
import { Control } from 'react-keeper';
import VideoPlay from "./Video";
// import LazyLoad from 'react-lazyload';




const Tab2Item = (props) => {

    const [indexs, setIndexs] = useState(0); //放大图片
    const [isOpen, setIsOpen] = useState(false);// 放大图片

    const [item, setNowItem] = useState(props.item);
    const [comments, setComments] = useState(props.item.comments);//评论
    const [commentsShow, setCommentsShow] = useState(true);//显示评论列表

    const [showPingLunBox, setShowPingLunBox] = useState(false);//显示评论框
    const [pingLun, setPingLun] = useState('');//评论内容

    const mineNick = localStorage.userInfo ? JSON.parse(localStorage.userInfo).nickname : '';



    const onClose = () => {
        setIsOpen(false)
    }
    const openViewer = (index) => {
        console.log(index)
        setIndexs(index);
        setIsOpen(true);
    }
    const like = (id, num) => {
        console.log('动态Id', id)
        if (id) {
            Api.dongTailike({ dynamic_id: id }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    const obj = Object.assign({}, item, { like: item.like == 1 ? 0 : 1, lick_count: item.like == 1 ? (num - 1) : (num + 1) });
                    setNowItem(obj)
                }
            })

        }
    }
    const delDongTai = (id) => {
        console.log('删除动态', id)
        Modal.alert('提示', '删除该动态？', [
            { text: '取消', onPress: () => console.log('取消'), style: 'default' },
            { text: '删除', onPress: () => del(id), style: { color: 'red' } },
        ]);
    }
    const del = (id) => {
        console.log(id)
        let params = {
            dynamic_id: id
        }
        Api.delDongTai(params).then(res => {
            console.log(res)
            if (res.code == 200) {
                const obj = Object.assign({}, item, { del: 1 });
                setNowItem(obj);
            }
        })

    }
    const goInfo = () => {
        console.log(itemType)
        if (itemType == 'tab2') {
            Control.go('/user_info/' + item.uid + '/0')
        }
    }

    const { itemType } = props;
    // console.log('itemType', itemType)

    const closePLBox = () => {
        console.log('关闭评论框')
        setShowPingLunBox(false)
    }

    const showPLBox = () => {
        console.log('打开评论框')
        setShowPingLunBox(true)
    }
    const sendPingLun = () => {
        console.log(pingLun, item.id)
        if (!pingLun) {
            Toast.fail('评论不能为空')
            return false
        } else {
            let params = {
                content: pingLun,
                dynamic_id: item.id
            }
            Api.dongTaiComment(params).then(res => {
                console.log('发布结果', res)
                if (res.code == 200) {
                    setComments([...comments, {
                        content: res.data.content,
                        ctime: res.data.ctime
                    }]);
                    setPingLun('');
                    closePLBox();
                }
            })


        }

    }

    const dtList = (videoSrc, imgss) => {
        // console.log(type)
        let list;
        let type;
        if (videoSrc) {
            type = 1
        } else if (!videoSrc && imgss) {
            type = 0
        }
        if (type == 0) {
            list = imgss.map((item, index) => {
                // console.log(imgss.length)
                return (
                    <div key={index} className={imgss.length == 1 ? style.imgBoxOne : style.imgBox}
                        style={{ backgroundImage: imgss.length == 1 ? '' : `url(${item})`, backgroundSize: 'cover' }}
                        onClick={openViewer.bind(this, index)}>
                        {/* <LazyLoad> */}
                            <img src={item} alt='' style={{ display: imgss.length == 1 ? 'block' : 'none' }} />
                        {/* </LazyLoad> */}
                        {
                            isOpen ? <WxImageViewer onClose={onClose} urls={imgss} index={indexs} /> : ""
                        }
                    </div >
                )
            });
        } else if (type == 1) {
            list =
                <div className={style.videoBox}>
                    <VideoPlay src={videoSrc} />
                </div >
        }
        return list;

    }
    const setJuli = (item) => {
        if (!item && item != 0) {
            return '';
        } else if (item == 0) {
            return ' · 0m';

        } else {
            item = Number(item)
            if (item > 1000) {
                return ' · ' + (item / 1000).toFixed(2) + 'km'
            } else {
                return ' · ' + item + 'm'
            }
        }
    }
    const keyUp = (e) => {
        console.log(e)
        if (e.keyCode == 13) {
            sendPingLun();
        }
    }

    const userInfo = item.userInfo ? item.userInfo : [];

    const ListItem = item.del ? '' : <div className={style.item} >
        <div className={style.itemLeft} onClick={() => { goInfo() }}>
            {/* <LazyLoad> */}
                <img src={userInfo.head_img} alt='head' onError={(e) => { e.target.onerror = null; e.target.src = `${require('../assets/imgs/icon/bgImg.jpg').default}` }} />
            {/* </LazyLoad> */}

        </div>
        <div className={style.itemRight}>
            <div className={style.name + ' flex flex_left_center'}>
                {userInfo.nickname}
                <span className={style.man} style={{ display: userInfo.sex == 1 ? 'flex' : 'none' }} ><img src={require('./../assets/imgs/icon/Icon_man.png').default} alt='男' width='16' /></span>
                <span className={style.woman} style={{ display: userInfo.sex == 2 ? 'flex' : 'none' }}><img src={require('./../assets/imgs/icon/Icon_women.png').default} alt='女' width='16' /></span>
            </div>
            <div className={style.info} style={{ display: itemType == 'tab2' ? 'block' : 'none' }}>
                <span hidden={!userInfo.age}>{userInfo.age}岁</span>
                <span hidden={!userInfo.height}>{userInfo.height}cm</span>
                <span hidden={!userInfo.occupation}>{userInfo.occupation}</span>
            </div>
            {/* 动态 */}
            <div className={style.dt}>
                <div className={style.dtText}>
                    {item.content}
                </div>
                <div className={style.info} style={{ marginBottom: '10px', display: (itemType == 'userInfo' || itemType == 'mineInfo') ? 'block' : 'none' }}>
                    <i style={{ fontStyle: 'normal' }} className='ft12'>{item.ctime}</i>
                </div>
                <div className={style.dtImgBox}>
                    {dtList(item.video, item.img)}
                </div>
            </div>
            {/* 地址 */}
            <div className={style.location + '   ft12 flex_left_center '} style={{ display: item.location ? 'block' : 'none' }}>
                <span>{item.location}</span>

            </div>
            {/* 话题 */}
            <div className={style.topic} style={{ display: item.topic_id && item.topic_id.length > 0 ? 'block' : 'none' }}>
                {(item.topic_id && item.topic_id.length > 0) ? item.topic_id.map((itemm, index) => {
                    return (
                        <span key={index}>{'#' + itemm.title}</span>
                    )
                }) : ''}
            </div>


            <div className={style.addr + ' flex flex_left_center flex_s_b'}>
                {/* 没赞 */}
                <div style={{ display: item.like == 0 ? 'flex' : 'none' }}
                    onClick={() => { like(item.id, item.lick_count) }}
                >
                    <img src={require('../assets/imgs/icon/icon_zan.png').default} alt='' />
                    {item.lick_count ? item.lick_count : ''}
                </div>
                {/* 赞了 */}

                <div style={{ display: item.like == 1 ? 'flex' : 'none', color: '#ecceae' }}
                    onClick={() => { like(item.id, item.lick_count) }}
                >
                    <img src={require('../assets/imgs/icon/icon_zan1.png').default} alt='' />
                    {item.lick_count ? item.lick_count : ''}
                </div>
                {/* 我的动态点赞数 */}

                <div style={{ display: (!item.like && item.like != 0) ? 'flex' : 'none', color: '#ecceae' }}>
                    <img src={require('../assets/imgs/icon/icon_zan1.png').default} alt='' />
                    {item.lick_count ? item.lick_count : ''}
                </div>

                {/* 评论 */}
                <div style={{ display: (itemType == 'tab2' || itemType == 'userInfo') ? 'flex' : 'none', fontSize: 12 }} onClick={() => { showPLBox() }} >
                    <img style={{ width: 23, height: 23 }} src={require('../assets/imgs/icon/liaotian.png').default} alt='' />
                    {itemType == 'tab2' ? '评论' : ''}
                </div>

                <div style={{ display: itemType == 'mineInfo' && comments && comments.length > 0 ? 'flex' : 'none', fontSize: 12 }} onClick={() => { setCommentsShow(!commentsShow) }} >
                    {/* <img src={require('../assets/imgs/icon/icon_zan1.png').default} alt='' /> */}
                    <img style={{ width: 23, height: 23 }} src={require('../assets/imgs/icon/liaotian.png').default} alt='' />
                    {comments && comments.length > 0 ? ` ${comments && comments.length} ` : ''}
                </div>
                {/* 时间 */}

                <div style={{ display: (itemType != 'userInfo' && itemType != 'mineInfo') ? 'block' : 'none' }}>
                    {item.ctime}
                </div>

                {/* 删除 */}
                <div style={{ justifyContent: 'flex-end', display: itemType == 'mineInfo' ? 'flex' : 'none', fontSize: 12 }} onClick={() => { delDongTai(item.id) }}>
                    删除
                </div>
            </div>
            <div className={style.commontBox} style={{ display: commentsShow && comments && comments.length > 0 ? 'block' : 'none' }}>
                {comments && comments.length > 0 ? comments.map((itemm, index) => {
                    return (
                        <div className={style.commontItem} style={{ border: 'none' }} key={'comment' + index}>
                            <div style={{ wordBreak: 'break-word' }}>
                                <span style={{ color: '#eccda4', marginRight: 5 }}>
                                    {(itemType == 'mineInfo' ? itemm.user_info.nickname + ' : ' : (mineNick ? mineNick + ' : ' : ''))}
                                </span>
                                {itemm.content}
                            </div>

                        </div>
                    )
                }) : ''}
            </div>
        </div>
        <WhiteSpace size='md' />
    </div>

    return (
        <div className='test' style={{ display: item.del ? 'none' : 'block' }}>
            {ListItem}
            <div className='fik' style={{ display: showPingLunBox ? 'flex' : 'none' }} onClick={closePLBox}>
            </div>

            {showPingLunBox ?
                <div className='pingLunBox' style={{ display: 'flex' }}>
                    <div className='inputBox'>
                        <TextareaItem
                            className='input-box'
                            value={pingLun}
                            type="text"
                            placeholder="评论(100字内)"
                            onChange={(e) => { setPingLun(e) }}
                            autoFocus
                            rows={1}

                        />
                    </div>
                    <div className='send' onKeyUp={keyUp} onClick={sendPingLun}>发布</div>
                </div>
                : ''
            }

        </div>
    )
}

export default Tab2Item;