import React from 'react';
import { Carousel } from 'antd-mobile';
import './../assets/style/mine.scss';


let timer = '';
class Init extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexImg: 0
        }

    }
    componentDidMount() {
        timer = setInterval(() => {
            const { indexImg } = this.state;
            if (indexImg < 1) {
                this.setState({
                    indexImg: indexImg + 1
                })
            } else {
                // this.setState({
                //     indexImg: 0
                // })
                clearInterval(timer)
                this.props.initPage()
            }
        }, 2 * 1000);

    }

    componentWillUnmount() {
        clearInterval(timer)
        this.setState = (state, callback) => {
            return
        }
    }

    render() {
        const { indexImg } = this.state;
        return (
            <div className='mineInvitePage mineInviteAdPage InitPage'>
                <div className='inviteImgBox'>
                    <Carousel
                        autoplay={false}
                        selectedIndex={indexImg}
                        easeInOutQuad
                        dots={false}

                    >
                        {[0].map((val, index) => (
                            <img
                                src={require(`./../assets/imgs/login/${val}.jpg`).default}
                                key={val}
                                style={{ display: 'inline-block', width: '100%', height: 'auto', verticalAlign: 'top', '--i': index * 2 + 's' }}

                                alt=""
                                onLoad={() => { window.dispatchEvent(new Event('resize')) }}
                            />

                        ))}
                    </Carousel>
                </div>
                <div className='closeImgBox3 ft12' onClick={this.props.initPage}>跳过</div>
            </div>
        )
    }
}
export default Init;
