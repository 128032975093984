/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { WhiteSpace, WingBlank, NavBar, Icon, Toast } from 'antd-mobile';
import './../../assets/style/chat.scss';
import Api from './../../http/api';

import Upload from '../../http/upload';
import WxImageViewer from 'react-wx-images-viewer';
import { Control } from 'react-keeper';
import Conn from './../../components/Conn';


import SendKey from "./../../components/SendKey";


let timer = '';
let audio_now = '';
class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInputImg: false,//是否显示表情框
            text: '',//输入框的内容
            friendInfo: {},
            chatRecordList: [],
            page: 1,
            size: 50,
            refreshing: false,
            down: true,
            isMore: true,
            mineId: localStorage.mineId || '',

            // 图片聊天
            file1: [],
            showBtns: false,//顶部功能键

            // 图片放大查看
            isOpen: false,
            imgs: [],
            isOnline: window.isOnline || '',

            audioNowPlayId: ''//当前语音消息播放的id
        }
    }
    setText = (text) => {
        this.setState({ text })
    }
    //  初始化
    componentDidMount() {
        console.log(this.props.params.id, decodeURIComponent(this.props.params.nickname))
        this.setState({
            friendInfo: { id: this.props.params.id, nickname: decodeURIComponent(this.props.params.nickname) }
        }, () => {
            this.onMessage();
            this.getMsgRecordList();
        })
        window.addEventListener('message', this.onNewMsg, false)

        timer = setInterval(() => {
            this.setState({
                isOnline: (window.isOnline || '') && (window.isNet || '')
            })
        }, 500);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onNewMsg)
        timer && clearInterval(timer);
        this.initAndOutRoom();
        this.setState = () => {
            return
        }
    }

    onNewMsg = (res) => {
        const { friendInfo } = this.state;
        // if (typeof (res) == 'string') {
        //     res = JSON.parse(res)
        // }
        let msg = res.data;
        // if (res.data && typeof (res.data) == 'string') {
        //     msg = JSON.parse(res.data)
        // }
        console.log('我在chat面：', msg)
        if (msg.type === 'messageCallback' && (msg.data.from.id == friendInfo.id || msg.data.to.id == friendInfo.id)) {
            let newList = [...this.state.chatRecordList, msg.data];
            this.setState({
                chatRecordList: newList
            }, () => {
                this.tig.scrollIntoView(false);
                // console.log('新纪录', this.state.chatRecordList)
            })

        }
    }

    onMessage = () => {
        if (this.state.isOnline) {
            this.initAndOutRoom()
        } else {
            setTimeout(() => {
                this.onMessage()
            }, 1000);
        }
    }
    getMsgRecordList = () => {
        const { size, friendInfo, page, isMore } = this.state;
        let params = {
            fid: friendInfo.id,
            size: size,
            page: page
        }
        if (!isMore) {
            this.setState({
                refreshing: false
            })
        } else {
            Api.msgRecordList(params).then(res => {
                console.log(res)
                if (res.code == 200) {
                    if (page === 1) {
                        this.setState({
                            chatRecordList: res.data.reverse(),
                            refreshing: false
                        }, () => {
                            // this.chatListView.scrollIntoView(false);
                            this.tig.scrollIntoView(false);
                        })
                    } else {
                        let newList = res.data.reverse();
                        let arr = [...newList, ...this.state.chatRecordList];
                        this.setState({
                            chatRecordList: arr,
                            page: this.state.page + 1,
                            isMore: res.data.length > 0 ? true : false,
                            refreshing: false
                        }, () => {
                            // this.tig.scrollIntoView(true);
                        })
                    }

                }
            })
        }
    }
    initAndOutRoom = () => {
        const params = {
            type: "read",
            data: {
                fid: this.state.friendInfo.id,
                token: localStorage.token
            }
        }
        window.sendMsg(params);
    }
    onClose = () => {
        this.setState({
            isOpen: false
        })
    }

    openViewer(src) {
        this.setState({
            imgs: [src],
            isOpen: true
        })
    }


    sendMsg = () => {
        const { text } = this.state;
        let text1 = text.replace(/\s+/g, "");

        if (!text || !text1) {
            Toast.info('请输入内容')
            return false;
        }


        const params = {
            type: "messages",
            data: {
                fid: this.state.friendInfo.id,
                message: text,
                type: 1,
                token: localStorage.token
            }

        }
        if (this.state.isOnline) {
            window.sendMsg(params);
            this.setState({
                text: ''
            })

        } else {
            Toast.info('连接中,请稍后')
        }

    }
    sendMsg2 = (imgSrc) => {
        console.log('发布图片', imgSrc)
        let params = {
            type: "messages",
            data: {
                fid: this.state.friendInfo.id,
                message: imgSrc[0].pathName,
                type: 2,
                token: localStorage.token
            }

        }
        console.log('发布图片参数', params)
        if (this.state.isOnline) {
            window.sendMsg(params);
            this.setState({
                file1: []
            })
        } else {
            Toast.info('连接中,请稍后')
        }
    }

    sendMsg3 = (recordMsg) => {
        console.log('发布语音', recordMsg)
        let params = {
            type: "messages",
            data: {
                fid: this.state.friendInfo.id,
                message: recordMsg[0].pathName,
                type: 3,
                token: localStorage.token
            }

        }
        console.log('发布语音参数', params)
        if (this.state.isOnline) {
            window.sendMsg(params);
        } else {
            Toast.info('连接中,请稍后')
        }

    }
    // 输入框里内容加上表情
    handleEmotion = (i) => {
        console.log(i)
        const msg = this.state.text + i
        this.setState({ text: msg })

    }
    // 将匹配结果替换表情图片
    emotion = (res) => {
        // console.log(res);
        let word = res.replace(/\#|\;/gi, "");
        // console.log(res, word);
        const list = [
            "微笑",
            "撇嘴",
            "色",
            "发呆",
            "得意",
            "流泪",
            "害羞",
            "闭嘴",
            "睡",
            "大哭",
            "尴尬",
            "发怒",
            "调皮",
            "呲牙",
            "惊讶",
            "难过",
            "酷",
            "冷汗",
            "抓狂",
            "吐",
            "偷笑",
            "可爱",
            "白眼",
            "傲慢",
            "饥饿",
            "困",
            "惊恐",
            "流汗",
            "憨笑",
            "大兵",
            "奋斗",
            "咒骂",
            "疑问",
            "嘘",
            "晕",
            "折磨",
            "衰",
            "骷髅",
            "敲打",
            "再见",
            "擦汗",
            "抠鼻",
            "鼓掌",
            "糗大了",
            "坏笑",
            "左哼哼",
            "右哼哼",
            "哈欠",
            "鄙视",
            "委屈",
            "快哭了",
            "阴险",
            "亲亲",
            "吓",
            "可怜",
            "菜刀",
            "西瓜",
            "啤酒",
            "篮球",
            "乒乓",
            "咖啡",
            "饭",
            "猪头",
            "玫瑰",
            "凋谢",
            "示爱",
            "爱心",
            "心碎",
            "蛋糕",
            "闪电",
            "炸弹",
            "刀",
            "足球",
            "瓢虫",
            "便便",
            "月亮",
            "太阳",
            "礼物",
            "拥抱",
            "强",
            "弱",
            "握手",
            "胜利",
            "抱拳",
            "勾引",
            "拳头",
            "差劲",
            "爱你",
            "NO",
            "OK",
            "爱情",
            "飞吻",
            "跳跳",
            "发抖",
            "怄火",
            "转圈",
            "磕头",
            "回头",
            "跳绳",
            "挥手",
            "激动",
            "街舞",
            "献吻",
            "左太极",
            "右太极",
        ];
        let index = list.indexOf(word);
        return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" style="
      vertical-align: middle;
  ">`;
    }
    goInfo = () => {
        Control.go('/user_info/' + this.state.friendInfo.id + '/0')
    }
    goInfo2 = (type) => {
        console.log('type', type)
        let id = '';
        if (type === 1) {
            id = this.state.friendInfo.id
        } else {
            id = this.state.mineId
        }
        if (id) {
            Control.go('/user_info/' + id + '/0')
        }

    }

    // step1   人脸识别自动上传
    onChange1 = (file1, type, index) => {
        console.log('step1', file1, type, index);
        this.setState({
            file1,
        }, () => {
            this.uploadImg(file1, 'chat', res => {
                if (res) {
                    console.log(res)
                    this.sendMsg2(res)
                }
            })
        });
    }
    // step1   上传照片
    uploadImg = (files, pathName, callback = () => { }) => {
        console.log(files, pathName)
        let upLoad = new Upload({
            files: files,
            path: pathName,
            onSuccess: (res) => {
                console.log(res)
                callback(res)
            },
            onFail: (err) => {
                console.log(err)
                callback(false)
            }

        }).open()
    }

    checkEnter = (e) => {
        var et = e || window.event;
        var keycode = et.charCode || et.keyCode;
        if (keycode == 13) {
            if (window.event) {
                window.event.returnValue = false;
            } else {
                e.preventDefault(); //for firefox
            }
            this.sendMsg()
        }
    }
    playAudio = (id) => {
        const { audioNowPlayId } = this.state;
        if (audio_now && audioNowPlayId && id == audioNowPlayId) {
            if (audio_now.paused) {
                audio_now.play();
                this.setState({
                    audioNowPlayId: id
                })
            } else {
                audio_now.pause();
                this.setState({
                    audioNowPlayId: ''
                })
            }
            return false
        }
        if (audio_now) {
            console.log('停止播放上一个')
            audio_now.pause();
            this.setState({
                audioNowPlayId: ''
            })
            audio_now = '';
        }
        audio_now = document.getElementById(id);
        if (audio_now !== null) {
            audio_now.load();
            audio_now.play();
            this.setState({
                audioNowPlayId: id
            })
        }
    }
    //语音播放结束
    onAudioPlayEnd = (id) => {
        const { audioNowPlayId } = this.state
        if (id == audioNowPlayId) {
            console.log('播放完毕', audioNowPlayId)
            this.setState({
                audioNowPlayId: ''
            })
        }

    }
    getLong = (id) => {
        let audio_now = document.getElementById(id);
        if (audio_now !== null) {
            let long = Math.ceil(audio_now.duration) * 3;
            if (long > 50) {
                long = 50
            }
            let num = {
                width: long + 'vw',

            }
            return num
        }
    }
    changeShowInputImg = () => {
        this.setState({
            showInputImg: !this.state.showInputImg
        }, () => {
            this.tig.scrollIntoView(false);
        })

    }



    render() {
        const { friendInfo, chatRecordList, showBtns, isOpen } = this.state;
        const chatRecordListItems = chatRecordList.length > 0 ? chatRecordList.map((item, index) => {
            let type;
            if (item.from.id == friendInfo.id) {
                type = 1;//好友发来的消息
            } else {
                type = 2;//我发出的消息
            }
            return (
                <div className={type === 1 ? 'item' : 'item mine'} key={index}>
                    <div className='imgBox' onClick={() => { this.goInfo2(type) }}>
                        <img src={item.from.head_img} alt='' />
                    </div>
                    <div className='chatBox'>
                        <div className='text'
                            style={{ display: item.message.type === 1 ? 'block' : 'none' }}
                            dangerouslySetInnerHTML={{
                                __html: item.message.content.replace(
                                    /#[\u4E00-\u9FA5]{1,3};/gi,
                                    this.emotion
                                )
                            }}>
                        </div>
                        <div className='text2' style={{ display: item.message.type === 2 ? 'block' : 'none' }}>
                            {item.message.type === 2 ? <img className='imgggg' src={item.message.content} alt='' onClick={this.openViewer.bind(this, item.message.content)} /> : ''}
                            {
                                isOpen ? <WxImageViewer onClose={this.onClose} index={0} urls={this.state.imgs} /> : ""
                            }
                        </div>

                        {item.message.type === 3 &&
                            <div className={(this.state.audioNowPlayId == ('audio' + index) ? 'isPlay' : '') + '   text text3'} onClick={() => { this.playAudio('audio' + index) }}  >
                                <audio
                                    src={item.message.content}
                                    controls='controls'
                                    id={'audio' + index}
                                    onEnded={() => { this.onAudioPlayEnd('audio' + index) }}
                                    style={this.getLong('audio' + index)}
                                />
                            </div>
                        }
                    </div>
                    <div className='state'>{item.message.time}</div>
                </div>
            )
        }) : <div className='empty'>暂无记录</div>
        return (
            <div className='chatPage'>
                <div className='navBarBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}

                        rightContent={[
                            <Icon key="0" type="ellipsis" onClick={() => {
                                this.setState({
                                    showBtns: !showBtns
                                })
                            }} />
                        ]}
                    >{friendInfo.nickname}{this.state.isOnline ? '' : <Icon size='xxs' style={{ marginLeft: 5 }} type='loading' />}</NavBar>
                    <div className='dtType' style={{ display: showBtns ? 'block' : 'none' }}>
                        <div onClick={this.goInfo} >好友主页</div>
                        <div onClick={() => { Control.go('/msg_record/' + friendInfo.id) }} >聊天记录</div>
                    </div>
                </div>

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg'>
                    <div className='chatContent' style={{ marginTop: 50 }}
                        ref={el => this.chatListView = el}>
                        {chatRecordListItems}
                    </div>
                    <div style={{ transition: 'all 300ms', height: this.state.showInputImg ? '200px' : '0px' }} ref={(tig) => { this.tig = tig }}>
                    </div>
                </WingBlank>

                <SendKey
                    file1={this.state.file1} //图片输入
                    text={this.state.text} //文字输入
                    showInputImg={this.state.showInputImg}
                    canRecordInput={true} //是否可以语音输入
                    sendMsg={this.sendMsg} //发送文字消息
                    sendMsg3={this.sendMsg3} //发送语音消息
                    setText={this.setText} //设置文字
                    changeShowInputImg={this.changeShowInputImg} //切换图片输入框
                    handleEmotion={this.handleEmotion}  //表情
                    onChange1={this.onChange1} //图片改变
                />

            </div >
        )
    }
}


export default Conn(Chat);


