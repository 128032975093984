import React from 'react';
import './../../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, NavBar, Icon, Toast } from 'antd-mobile';
import Api from './../../http/api';
import { Control } from 'react-keeper';

let timer = '';
class MineChangePwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 60,
            timeSet: 60,

            telD: localStorage.userInfo ? JSON.parse(localStorage.userInfo).phone : '',
            telPlace: '',
            tel: '',
            pwd1: '',
            pwd2: '',
            code: '',
        }
    }
    componentDidMount() {
        const { telD } = this.state;
        if (telD) {
            let tel = telD.substring(0, 3) + ' **** ' + telD.substring(telD.length - 4, telD.length);
            console.log(tel)
            this.setState({ telPlace: tel })
        }
        console.log('挂在完成')
    }
    componentWillUnmount() {
        if (timer) {
            clearInterval(timer)
            timer = ''
        }
        this.setState = () => {
            return
        }


    }
    handleChange = (name, e) => {
        // console.log(e);
        this.setState({
            [name]: e
        })
    }

    ok = () => {
        const { tel, code, pwd1, pwd2, telD } = this.state;
        console.log(tel, code, pwd1, pwd2, telD)
        let tell = '';
        if (!tel && telD) {
            tell = telD
        } else {
            tell = tel.replace(/\s*/g, "");
        }

        if (tell.length < 11) {
            Toast.fail('手机号不正确');
            return false;
        }
        if (!code) {
            Toast.fail('请填写验证码');
            return false;
        }
        if (!pwd1 || pwd1.length < 8) {
            Toast.fail('请设置大于8位的密码');
            return false;
        }
        if (pwd2 !== pwd1) {
            Toast.fail('两次密码不一致');
            return false;
        }
        let parmas = {
            phone: tell,
            password: pwd1,
            repassword: pwd2,
            code: code,
            ip: (localStorage.ip || window.localStorage.ip) ? (JSON.parse(localStorage.ip) || JSON.parse(window.localStorage.ip)) : ''
        }
        Api.forgetPwd(parmas).then(res => {
            console.log(res)
            if (res.code === 200) {
                localStorage.token = res.data.token;
                localStorage.sex = res.data.sex;
                localStorage.loginAuto = true;
                Toast.success(res.msg);

                setTimeout(() => {
                    Control.replace('/')
                }, 3000);

            }
        })

    }

    getCode = () => {
        const { tel, timeSet, telD } = this.state;
        let tell = '';
        console.log(telD)
        if (!tel && telD) {
            tell = telD
        } else {
            tell = tel.replace(/\s*/g, "");
        }




        if (tell.length < 11) {
            Toast.fail('手机号不正确');
        } else {
            if (this.state.time != timeSet) {
                Toast.fail(`请${this.state.time}秒后再试`)
                return false
            }
            Api.code({ phone: tell }).then(res => {
                console.log(res)
                Toast.success(res.msg)
                timer = setInterval(() => {
                    console.log(this.state.time)
                    if (this.state.time > 0) {
                        this.setState({
                            time: this.state.time - 1
                        })
                    } else {
                        this.setState({ time: timeSet })
                        clearInterval(timer)
                        timer = '';
                    }
                }, 1000)
            })
        }
    }
    render() {
        return (
            <div className='login-page change_pwd_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        Control.go(-1)
                    }}
                >修改登陆密码</NavBar>


                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank size='lg' className='login-content'>
                    <InputItem
                        className='input-box'
                        value={this.state.tel}
                        type="phone"
                        disabled={this.state.telPlace ? true : false}

                        placeholder={this.state.telPlace || "账户手机号"}
                        onChange={(v) => { this.handleChange('tel', v) }}
                    >
                        <div style={{ backgroundImage: `url(${require('./../../assets/imgs/login/icon_zhanghao.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                    </InputItem>
                    <WhiteSpace size="lg" />

                    <div className='code_box'>
                        <div className='code_box_input'>
                            <InputItem
                                className='input-box'
                                value={this.state.code}
                                type="text"
                                placeholder="请输入验证码"
                                onChange={(v) => { this.handleChange('code', v) }}
                            >
                                <div style={{ backgroundImage: `url(${require('./../../assets/imgs/login/icon_yanzhengma.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                            </InputItem>

                        </div>
                        <div className='code_box_code' style={{ background: this.state.time == this.state.timeSet ? 'linear-gradient(to right, #efd0a9, #f9e7ce)' : '#a09d9d' }} onClick={() => { this.getCode() }}>获取验证码({this.state.time})</div>
                    </div>

                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.pwd1}
                        type="password"
                        placeholder="请设置新密码"
                        onChange={(v) => { this.handleChange('pwd1', v) }}
                    >
                        <div style={{ backgroundImage: `url(${require('./../../assets/imgs/login/icon_mima.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                    </InputItem>

                    <WhiteSpace size="lg" />
                    <InputItem
                        className='input-box'
                        value={this.state.pwd2}
                        type="password"
                        placeholder="请确认新密码"
                        onChange={(v) => { this.handleChange('pwd2', v) }}
                    >
                        <div style={{ backgroundImage: `url(${require('./../../assets/imgs/login/icon_mima.png').default})`, backgroundSize: 'cover', height: '22px', width: '22px' }} />
                    </InputItem>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Button className='login-btn change-btn' onClick={this.ok}>确认修改</Button>



                </WingBlank>



            </div >

        )
    }
}
export default MineChangePwd;

