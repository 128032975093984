import { Icon, NavBar, WhiteSpace, WingBlank } from 'antd-mobile'
import { Component } from 'react'
import { Control } from 'react-keeper'
import Api from '../../http/api'

import '../../assets/style/team.scss'

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'direct', //direct 直接  //indirect 间接
      list: {
        direct: [],
        indirect: [],
        tertiary: [],
      },
    }
  }
  componentDidMount() {
    this.getList()
  }
  getList = () => {
    Api.teamInfo({}).then(res => {
      console.log(res)
      let arr4 = []
      if (res.code == 200) {
        let list = res.data.info
        list.indirect.forEach(item => {
          item.type = '二级'
          arr4.push(item)
        })
        list.tertiary.forEach(item => {
          item.type = '三级'
          arr4.push(item)
        })
        list.second = arr4
        // for (let i = 0; i < 99; i++) {
        //     list.second.push(list.direct[0])
        // }
        this.setState({
          list,
        })
      }
    })
  }
  setTab = tab => {
    this.setState({ tab })
  }
  setTime = time => {
    if (time) {
      if (time < 1000000000000) {
        time = time * 1000
      }
      let date = new Date(time)
      let ti =
        (date.getHours() > 10 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 10 ? date.getMinutes() : '0' + date.getMinutes())
      // + ':' +(date.getSeconds() > 10 ? date.getSeconds() : '0' + date.getSeconds())

      return date.toLocaleDateString() + '  ' + ti
    }
  }

  render() {
    const { list, tab } = this.state
    const listArr =
      list[tab].length > 0 ? (
        list[tab].map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="right">
                <div className="left">
                  <img src={item.userInfo?.head_img} alt=""></img>
                </div>
                {/* <span>ID：{item.uid}</span> */}
                <span>{item.userInfo?.phone}</span>
                <span
                  className="tig"
                  style={{
                    background: !item.type
                      ? ''
                      : item.type == '二级'
                      ? '#2196f3'
                      : '#e91e63',
                  }}
                >
                  {item.type ? item.type : '一级'}
                </span>
              </div>

              {item.userInfo?.is_vip == 1 && (
                <div>
                  <img
                    src={require('./../../assets/imgs/icon/vip2.png').default}
                    alt=""
                    width="30"
                  />
                </div>
              )}

              <div className="ft10 opacity7">{this.setTime(item.ctime)}</div>
            </div>
          )
        })
      ) : (
        <div className="empty" style={{ marginTop: 20 }}>
          暂无数据
        </div>
      )

    return (
      <div className="teamInfoPage">
        <div className="navBoxBB">
          <NavBar
            mode="dark"
            icon={<Icon type="left" />}
            onLeftClick={() => {
              Control.go(-1)
            }}
          >
            推广详情
          </NavBar>
        </div>

        <div className="teamPage" style={{ marginTop: 70 }}>
          <WingBlank size="lg">
            <div className="tabs">
              <div
                className={tab == 'direct' ? 'act' : ''}
                onClick={() => {
                  this.setTab('direct')
                }}
              >
                直接列表
              </div>
              <div
                className={tab == 'second' ? 'act' : ''}
                onClick={() => {
                  this.setTab('second')
                }}
              >
                间接列表
              </div>
            </div>
            <WhiteSpace size="lg" />
            <div>{listArr}</div>
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default Team
