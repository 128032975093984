import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, Modal, ListView } from 'antd-mobile';
import "../../assets/style/mine_like.scss";
import Api from '../../http/api';
import { Control } from 'react-keeper';



class MineLike extends Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });

        this.state = {
            type: 1,

            page: 1, //页码
            limit: 10, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {
        const { type, page, limit } = this.state;
        let params = {
            type: type,
            page: page,
            limit: limit
        }
        Api.agreeList(params).then(res => {
            console.log(res)
            if (res.code === 200) {
                let allbi = this.state.preBillData.concat(res.data);
                this.setState({
                    billData: res.data,
                    preBillData: allbi,
                    allBillData: allbi,
                    page: Number(this.state.page) + 1,
                    dataSource: this.state.dataSource.cloneWithRows(allbi),
                    isHasMore: res.data.length > 0 ? true : false,
                    isLoading: false
                })
            }
        })
    }


    changeTab = (type) => {
        console.log(type)
        const { allBillData } = this.state;
        let emptyList = allBillData.splice(0, allBillData.length);
        this.setState({
            type: type,
            page: 1, //页码变为1
            isHasMore: true,
            isLoading: false,
            dataSource: this.state.dataSource.cloneWithRows(emptyList) //传入一个空数组
        }, () => {
            this.listView.scrollTo(0, 0);
            this.getList();
        })
    }


    agreeSet = (id, type) => {
        console.log(id, type)
        const params = {
            unlock_id: id,
            unlock_state: type
        }
        Api.agree(params).then(res => {
            console.log(res)
            if (res.code === 200) {
                const { allBillData } = this.state;
                let emptyList = allBillData.splice(0, allBillData.length);
                this.setState({
                    page: 1, //页码变为1
                    isHasMore: true,
                    isLoading: false,
                    dataSource: this.state.dataSource.cloneWithRows(emptyList) //传入一个空数组
                }, () => {
                    this.listView.scrollTo(0, 0);
                    this.getList();
                })
            }
        })

    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    goInfo = (id) => {
        Control.go('/user_info/' + id+'/0')
    }
    row = (item, sectionID, rowID) => {
        const { type } = this.state;
        return (
            <div className='item' key={item.id}>
                <div className='item-left' onClick={() => { this.goInfo(item.uid) }} style={{ backgroundImage: `url(${item.userInfo.head_img}),url(${require('./../../assets/imgs/icon/bgImg.jpg').default})`, backgroundSize: 'cover' }}>
                </div>
                <div className='item-right'>
                    <div className='flex felx_left_center name-box'>
                        <div>{item.userInfo.nickname}</div>
                    </div>
                    <div>{item.ctime}</div>
                </div>
                <div className='item-state item-state2 ft12' onClick={() => { this.agreeSet(item.id, 1) }}>
                    <span >同意他查看微信</span>
                </div>
                <div className='item-state item-state1 ft12' onClick={() => { this.agreeSet(item.id, 0) }}>
                    <span >拒绝</span>
                </div>

            </div>
        )
    }

    render() {
        const { isHasMore, isLoading, billData } = this.state;
        return (
            <div className='likePage'>
                <div className='navBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >
                        查看请求
                        {/* <div className='tabs'>
                            <div className={this.state.type == 1 ? 'active' : ''} onClick={() => { this.changeTab(1) }}>
                                我喜欢的
                            </div>
                            <div className={this.state.type == 2 ? 'active' : ''} onClick={() => { this.changeTab(2) }}>
                                喜欢我的
                            </div>
                        </div> */}
                    </NavBar>
                </div>
                <WingBlank size='lg'>
                    <WhiteSpace size='lg' />
                    <div style={{ height: 25 }}></div>
                    <div>
                        {/* <div className='item'>
                            <div className='item-left' style={{ backgroundImage: `url(${require('./../../assets/imgs/icon/bgImg.jpg').default})`, backgroundSize: 'cover' }}>
                            </div>
                            <div className='item-right'>
                                <div className='flex felx_left_center name-box'>
                                    <div>小可爱</div>
                                </div>
                                <div>2021-12-25</div>
                            </div>
                            <div className='item-state item-state2 ft12'>
                                <span >同意他查看微信</span>
                            </div>
                            <div className='item-state item-state1 ft12'>
                                <span >拒绝</span>
                            </div>
                        </div> */}
                        <ListView
                            ref={el => this.listView = el}
                            dataSource={this.state.dataSource}
                            renderFooter={() => (
                                <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                    {
                                        isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                    }
                                    {
                                        (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                    }

                                </div>
                            )}
                            renderRow={this.row} //每行数据渲染
                            style={{
                                minHeight: 'calc(100vh - 20px)', //高度需要定义
                                overflow: 'auto',
                                paddingTop: '15px'
                            }}
                            pageSize={10}  //一次渲染几条数据
                            initialListSize={10}
                            onEndReached={this.onEndReached}//加载新的数据
                            onEndReachedThreshold={500}//距离底部40时候 加载数据
                        />

                    </div>
                </WingBlank>

            </div>
        )
    }
}
export default MineLike;
