import React, { useState, useEffect } from 'react';
import './../assets/style/login.scss';
import { InputItem, WhiteSpace, WingBlank, Button, DatePicker, List, ImagePicker, Picker } from 'antd-mobile';


// const nowTimeStamp = Date.now();
// const now = new Date(nowTimeStamp);
// const minDate = new Date(nowTimeStamp - 60 * 60 * 24 * 365 * 70 * 1000);
// const maxDate = new Date(nowTimeStamp - 60 * 60 * 24 * 365 * 18 * 1000);

const SetInfo2 = (props) => {
    const { nickname, date, file1, age, height, weight, job,jobList } = props.state;
    const handleChange = props.handleChange;
    const onChange1 = props.onChange1;

    const [ageList, setage] = useState([]);
    const [heightList, setheight] = useState([]);
    const [weightList, setweight] = useState([]);
    const [jobLists, setjobs] = useState([]);

    useEffect(() => {
        let arr = []
        for (let i = 18; i < 60; i++) {
            arr.push({
                label: i + '岁',
                value: i
            })
        }
        setage(arr);

        let arr2 = [];
        for (let j = 150; j < 220; j++) {
            arr2.push({
                label: j + 'cm',
                value: j
            })
        }
        setheight(arr2);

        let arr3 = []
        for (let i = 35; i < 100; i++) {
            arr3.push({
                label: i + 'kg',
                value: i
            })
        }
        setweight(arr3)

        let arr4 = []
        for (let i = 0; i < jobList.length; i++) {
            let item = {
                label: jobList[i].title,
                value: jobList[i].title,
            }
            item.children = []
            jobList[i].info.forEach(it => {
                item.children.push({
                    label: it.title,
                    value: it.title
                })
            });
            arr4.push(item)
        }
        setjobs(arr4);

    }, [props])

    return (
        <div >
            <WingBlank size='lg' className='set-info2'>
                <div className='headImgBox'>
                    <ImagePicker
                        style={{ width: '100%', opacity: 0.9 }}
                        files={file1}
                        length={1}
                        onChange={onChange1}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={file1.length < 1}
                        multiple={false}
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                    />
                </div>
                <div className='tip'>请上传清晰的五官头像</div>
                <InputItem
                    className='input-box'
                    value={nickname}
                    type="text"
                    placeholder="请输入您的昵称"
                    onChange={(v) => { handleChange('nickname', v) }}
                >
                    <div>昵称</div>
                </InputItem>
                {/* <WhiteSpace size="lg" /> */}
                {/* <DatePicker
                    className='input-box'
                    mode="date"
                    extra="请选择"
                    value={date}
                    onChange={v => props.handleChange('date', v)}
                    maxDate={maxDate}
                    minDate={minDate}
                >
                    <List.Item arrow="horizontal">生日</List.Item>
                </DatePicker> */}

                {/* 年龄 */}
                <Picker
                    data={ageList}
                    cols={1}
                    className="forss"
                    value={age}
                    extra='去完善'
                    onOk={v => { handleChange('age', v) }} >
                    <List.Item arrow="horizontal" > 年龄 </List.Item>
                </Picker>
                {/* 身高 */}
                <Picker
                    data={heightList}
                    cols={1}
                    className="forss"
                    extra='去完善'
                    value={height}
                    onOk={v => { handleChange('height', v) }} >
                    < List.Item arrow="horizontal" > 身高 </List.Item>
                </Picker>
                {/* 体重 */}
                <Picker
                    data={weightList}
                    cols={1}
                    extra='去完善'
                    className="forss"
                    value={weight}
                    onOk={v => { handleChange('weight', v) }} >
                    <List.Item arrow="horizontal" > 体重 </List.Item>
                </Picker>
                {/* 职业 */}
                <Picker
                    data={jobLists}
                    cols={2}
                    value={job}
                    cascade={true}
                    extra='去完善'
                    onOk={v => { handleChange('job', v) }}

                >
                    <List.Item arrow="horizontal" > 从事职业 </List.Item>
                </Picker>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button className='login-btn change-btn' onClick={props.nextStep}>下一步</Button>
            </WingBlank>

        </div >

    )

}
export default SetInfo2;

