import React, { Component } from 'react';
import { NavBar, Icon, WingBlank, Modal, ListView } from 'antd-mobile';
import '../../assets/style/user_info.scss';
import Tab2Item from '../../components/Tab2_item';
import Api from '../../http/api';
import { Control } from 'react-keeper';

class User_info extends Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });
        this.state = {
            imgList: [],//用户相册
            tab: 0,
            friendInfo: [],//初始本资料
            friendInfoNew: {},//基本详细资料
            friendInfoMore: [],//详细资料
            friendInfoDt: [],//动态列表
            // tags: [],//用户标签
            itemType: 'userInfo',
            showBtns: false,

            page: 1, //页码
            limit: 5, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    componentDidMount() {
        console.log(this.props.params.id);
        window.scrollTo(0, 0)
        this.setState({
            friendInfo: { id: this.props.params.id }
        }, () => {
            this.getList();
        })
    }

    // 动态列表
    getList = () => {
        const { page, limit, friendInfo, isHasMore } = this.state;
        let params = {
            page: page,
            limit: limit,
            uid: friendInfo.id
        }
        if (isHasMore) {
            Api.userDongTai(params).then(res => {
                console.log('用户动态', res)
                if (res.code == 200) { //eslint-disable-line 
                    let allbi = this.state.preBillData.concat(res.data);
                    this.setState({
                        billData: res.data,
                        preBillData: allbi,
                        allBillData: allbi,
                        page: Number(this.state.page) + 1,
                        dataSource: this.state.dataSource.cloneWithRows(allbi),
                        isHasMore: res.data.length > 0 ? true : false,
                        isLoading: false
                    })
                }
            })
        }

    }
    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false,
                isHasMore: false
            })
        }
    }
    chat = () => {
        const { friendInfoNew } = this.state;
        if (friendInfoNew.is_chat === 1) {
            // console.log('可以聊天');
            this.goChat();
        } else {
            console.log('无权聊天');
            Modal.alert('提示', `消费 ${friendInfoNew.chat_auth_cost}元 解锁聊天权限？`, [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                { text: '解锁', onPress: () => this.openChatLimit() },
            ]);
        }
    }
    setJuli = (item) => {
        if (!item && item !== 0) {
            return '';
        } else if (item === 0) {
            return '0m';

        } else {
            item = Number(item)
            if (item > 1000) {
                return (item / 1000).toFixed(2) + 'km'
            } else {
                return item + 'm'
            }
        }
    }
    openChatLimit = () => {
        const { friendInfoNew } = this.state;
        let params = {
            fid: friendInfoNew.id,
        }
        Api.openChatLimit(params).then(res => {
            console.log('解锁聊天权限：', res)
            if (res.code == 200) { //eslint-disable-line 
                console.log('解锁成功：', res)
                const obj = Object.assign({}, friendInfoNew, { is_chat: friendInfoNew.is_chat === 1 ? 0 : 1 });
                this.setState({
                    friendInfoNew: obj
                }, () => {
                    setTimeout(() => {
                        this.chat()
                    }, 500);
                })

            }
        })
    }
    goChat = () => {
        console.log('立即去聊天')
        const { friendInfoNew } = this.state;
        Control.go('/chat/' + friendInfoNew.id + '/' + friendInfoNew.nickname)
    }
    row = (item, sectionID, rowID) => {
        return (
            <div key={rowID}>
                <Tab2Item item={item} itemType={this.state.itemType} />
            </div>
        )
    }


    render() {
        const { isHasMore, isLoading, billData } = this.state;
        return (
            <div className='user-info-page' style={{ paddingBottom: 0 }} >
                <div className='navBox'>
                    <NavBar
                        mode="dark"
                        icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    >动态列表</NavBar>
                </div>
                {/* tabs 内容 */}
                <WingBlank size='lg'>
                    <div style={{ marginTop: 40 }}>
                        <div>
                            <ListView
                                ref={el => this.listView2 = el}
                                dataSource={this.state.dataSource}
                                renderFooter={() => (
                                    <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                        {
                                            isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                        }
                                        {
                                            (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                        }

                                    </div>
                                )}
                                renderRow={this.row} //每行数据渲染
                                style={{
                                    minHeight: 'calc(100vh - 20px)', //高度需要定义
                                    overflow: 'auto',
                                    paddingTop: '15px',
                                    width: '100%'
                                }}
                                pageSize={5}  //一次渲染几条数据
                                initialListSize={5}
                                onEndReached={this.onEndReached}//加载新的数据
                                onEndReachedThreshold={40}//距离底部40时候 加载数据
                            />
                        </div>
                    </div>
                </WingBlank>
            </div>
        )
    }
}
export default User_info;
