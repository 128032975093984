import React, { Component } from 'react';
import { NavBar, Icon, WhiteSpace, WingBlank, Modal, ListView } from 'antd-mobile';
import "../../assets/style/mine_like.scss";
import Api from '../../http/api';
import { Control } from 'react-keeper';



class MineLike extends Component {
    constructor(props) {
        super(props);
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
        });

        this.state = {
            page: 1, //页码
            limit: 50, // 每页条数
            dataSource: ds, //长列表加载数据源
            billData: [],  //每页数据
            preBillData: [], //原始的订单列表
            allBillData: [], //原始的+每一页新的数据
            isLoading: false,  //是否加载中
            isHasMore: true, //是否还有更多数据
        }
    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {
        const { page, limit } = this.state;
        let params = {
            page: page,
            limit: limit
        }
        Api.blacklist(params).then(res => {
            console.log(res)
            if (res.code === 200) {
                if (page === 1) {
                    this.setState({
                        preBillData: []
                    })
                }
                let allbi = this.state.preBillData.concat(res.data);
                this.setState({
                    billData: res.data,
                    preBillData: allbi,
                    allBillData: allbi,
                    page: Number(this.state.page) + 1,
                    dataSource: this.state.dataSource.cloneWithRows(allbi),
                    isHasMore: res.data.length > 0 ? true : false,
                    isLoading: false
                })
            }
        })
    }

    showAlert = (id) => {
        const alertInstance = Modal.alert('提示', '将此用户移除黑名单？', [
            { text: '取消', onPress: () => console.log('取消'), style: 'default' },
            { text: '移除', onPress: () => this.delBlank(id) },
        ]);
        setTimeout(() => {
            // 可以调用close方法以在外部close
            console.log('auto close');
            alertInstance.close();
        }, 50000);
    };
    delBlank = (id) => {
        Api.delBlack({ lock_id: id }).then(res => {
            if (res.code == 200) { //eslint-disable-line
                this.setState({
                    page: 1
                }, () => {
                    this.getList();
                })

            }
        })
    }


    onEndReached = () => {
        const { isHasMore } = this.state
        if (isHasMore) {
            this.setState({
                isLoading: true
            }, () => {
                this.getList()
            })

        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    goInfo = (id) => {
        Control.go('/user_info/' + id+'/0')
    }
    row = (item, sectionID, rowID) => {
        const { type } = this.state;
        return (
            <div className='item' key={rowID}>
                <div className='item-left' onClick={() => { this.goInfo(item.id) }} style={{ backgroundImage: `url(${item.head_img}),url(${require('./../../assets/imgs/icon/bgImg.jpg').default})`, backgroundSize: 'cover' }}>
                    {/* <img src={item.head_img} alt='' /> */}
                </div>
                <div className='item-right'>
                    <div className='flex felx_left_center name-box'>
                        <div>{item.nickname}</div>
                    </div>
                    <div className='ft12 flex  flex_left_center'>
                    <img style={{ opacity: 0.2 }} src={require('./../../assets/imgs/icon/icon_dingwei.png').default} alt='' hidden={!item.region_name || item.region_name == null} />
                        {item.region_name ? item.region_name + ' · ' : ''}{item.distance / 1000}km
                        </div>
                </div>
                <div className='item-state' style={{ top: 'auto',fontSize:12,height:20,lineHeight:20 }} onClick={() => { this.showAlert(item.id) }}> 移除</div>
            </div>
        )
    }

    render() {
        const { isHasMore, isLoading, billData } = this.state;
        return (
            <div className='likePage'>
                <div className='navBox'>
                    <NavBar mode="dark" icon={<Icon type="left" />}
                        onLeftClick={() => {
                            Control.go(-1)
                        }}
                    > 黑名单</NavBar>
                </div>
                <WingBlank size='lg'>
                    <WhiteSpace size='lg' />
                    <div style={{ height: 25 }}></div>
                    <div>
                        <ListView
                            ref={el => this.listView = el}
                            dataSource={this.state.dataSource}
                            renderFooter={() => (
                                <div style={{ padding: 5, textAlign: 'center', fontSize: '14px' }}>
                                    {
                                        isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '已加载')
                                    }
                                    {
                                        (billData.length === 0 && !isHasMore) ? '没有更多了' : ''
                                    }

                                </div>
                            )}
                            renderRow={this.row} //每行数据渲染
                            style={{
                                minHeight: 'calc(100vh - 20px)', //高度需要定义
                                overflow: 'auto',
                                paddingTop: '15px'
                            }}
                            pageSize={50}  //一次渲染几条数据
                            initialListSize={50}
                            onEndReached={this.onEndReached}//加载新的数据
                            onEndReachedThreshold={500}//距离底部40时候 加载数据
                        />

                    </div>
                </WingBlank>

            </div>
        )
    }
}
export default MineLike;
