import React, { useState } from 'react';
import { WingBlank, Toast,WhiteSpace } from 'antd-mobile';


const SetInfo1 = (props) => {
    const sexList = [
        {
            name: '我是男生',
            img: require('./../assets/imgs/login/icon_nan@2x.png').default,
            img2: require('./../assets/imgs/login/icon_nan_1@2x.png').default
        },
        {
            name: '我是女生',
            img: require('./../assets/imgs/login/icon_nv@2x.png').default,
            img2: require('./../assets/imgs/login/icon_nv_1@2x.png').default
        }
    ]
    const [active, setActive] = useState(3);
    const [step, setStep] = useState(1);

    const setIndex = (act) => {
        if (act === active) {
            setActive(3)
        } else {
            setActive(act)
        }
    }
    const next = () => {
        if (active === 3) {
            Toast.fail('请您选择性别')
        } else {
            setStep(2)
        }
    }
    const reChange = () => {
        setStep(1)
    }


    const okok = () => {
        props.setSexx(active + 1);
    }

    return (
        <div className='setSex'>
            <WingBlank size='md' className='login-content'>
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                    <div className='sexBox'>
                        {sexList.map((item, index) => {
                            return (
                                <div key={item.name} className={active === index ? 'sexItem actv' : 'sexItem'} onClick={() => { setIndex(index) }}>
                                    <div> <img src={active === index ? item.img2 : item.img} alt={item.name} width="50%" /></div>
                                    <div>{item.name}</div>
                                </div>
                            )
                        })}
                    </div>
                    <WhiteSpace size='lg'></WhiteSpace>
                    <div className='btnn' onClick={() => { next() }}> 下一步</div>
                </div>
                <div className='sexBox2' style={{ display: step === 2 ? 'flex' : 'none' }}>
                    <div className='ft16 color255 ft_bold'>{active === 3 ? '选择性别' : (active === 0 ? '选择男生' : '选择女生')}</div>
                    <div className='ft14' style={{ color: 'rgb(239, 208,169)', marginBottom: '20px' }}>
                        选择性别后 将无法修改
                    </div>

                    <img src={active !== 3 ? sexList[active].img2 : ''} alt={active !== 3 ? sexList[active].name : ''} width='80px' height='80px' />

                    <div className='ft20 ft_bold les1' style={{ color: 'rgb(239, 208,169)', marginBottom: '40px', marginTop: 20 }}>{active !== 3 ? sexList[active].name : ''}</div>
                    <div className='btnsBox'>
                        <div onClick={() => { reChange() }}>重新选择</div>
                        <div onClick={() => { okok() }}>确认</div>
                    </div>

                </div>
            </WingBlank>

        </div>
    )
}
export default SetInfo1;
